import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from 'reactstrap';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { ModalContainer, TabContainer, Loading } from './styles';
import { Modal } from '~/components';
import { EVALUATION_TYPE, GROUPS_LEADER_APPROVAL } from '~/constants';
import DimensionCheck from '~/components/DimensionCheck';

const ControlVisualization = forwardRef(
  (
    { evaluationId, evaluationType, evaluators, dimensionsList, dimensions, controlVisualization },
    ref
  ) => {
    const [openModalControl, setOpenModalControl] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [evaluatorsList, setEvaluatorsList] = useState([]);

    useEffect(() => {
      const list = [
        ...evaluators.evaluators
          .filter(
            (elm) =>
              evaluators?.leaderApproval?.type === GROUPS_LEADER_APPROVAL['1'] ||
              elm.id !== evaluators?.leaderApproval?.id
          )
          .map((elm) => ({
            id: `e_${elm.id}`,
            name: elm.name,
            controlVisualization: controlVisualization.filter((c) => c.evaluator === `e_${elm.id}`)
          }))
      ];
      setEvaluatorsList(list);
    }, [evaluators, controlVisualization]);

    useImperativeHandle(ref, () => ({
      changeVisibleModal
    }));

    const toggleTab = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };

    const changeVisibleModal = (active) => setOpenModalControl(active);

    return (
      <div>
        <Modal
          isOpen={openModalControl}
          toggle={() => changeVisibleModal(false)}
          title="Controle de Visualização"
          unmountOnClose={false}
        >
          <ModalContainer>
            {loadingDetails && (
              <Loading>
                <Spinner size="md" color="primary" animation="border" />
              </Loading>
            )}
            <Nav tabs className="nav-tab-light">
              {evaluatorsList.map((elm, index) => (
                <NavItem key={`dimension-nav-item-${index}`}>
                  <NavLink
                    className={classNames({ active: activeTab === index })}
                    onClick={() => {
                      toggleTab(index);
                    }}
                  >
                    {elm.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {evaluatorsList.map((elm, index) => (
                <TabPane tabId={index} key={`control-tab-item-${index}`}>
                  <TabContainer>
                    {dimensionsList.map((tabPane, i) => (
                      <DimensionCheck
                        key={`dimension-check-${i}`}
                        evaluationId={evaluationId}
                        evaluationType={evaluationType}
                        evaluatorId={elm.id}
                        setLoadingDetails={setLoadingDetails}
                        controlVisualization={elm.controlVisualization}
                        dimension={{
                          ...dimensions[tabPane],
                          value: tabPane
                        }}
                      />
                    ))}
                  </TabContainer>
                </TabPane>
              ))}
            </TabContent>
          </ModalContainer>
        </Modal>
      </div>
    );
  }
);

ControlVisualization.defaultProps = {
  evaluationId: 0,
  evaluationType: EVALUATION_TYPE.RN_507,
  dimensionsList: [],
  dimensions: [],
  controlVisualization: [],
  evaluators: {
    evaluators: null,
    evaluatorsAdmin: null,
    evaluatorsCompany: null
  }
};

ControlVisualization.propTypes = {
  evaluationId: PropTypes.number,
  evaluationType: PropTypes.number,
  dimensionsList: PropTypes.any,
  controlVisualization: PropTypes.any,
  dimensions: PropTypes.any,
  evaluators: PropTypes.any
};

export default ControlVisualization;
