import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '~/assets/img/brand/logo_w.png';
import { showBasedOnGroup } from '~/config/helpers';
import { SIDE_MENU, SIDE_MENU_TYPES } from '~/constants';
import { SidebarItemList } from '~/components';
import StripBanner from '~/components/StripBanner';
import * as S from './styles';

const Sidebar = ({ bgColor, toggleSidebar }) => {
  const linkA4quality = 'https://a4quality.com/';
  const isDev = process.env.REACT_APP_SCOPE === 'dev';
  const isTest = process.env.REACT_APP_SCOPE === 'test';
  const isProd = process.env.REACT_APP_SCOPE === 'prod';
  const location = useLocation();

  useEffect(() => {
    changeActive(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState(location.pathname);

  const changeActive = (pathname) => setActive(pathname);

  return (
    <div className="sidebar" data={bgColor}>
      <div className="sidebar-wrapper">
        <div className="logo">
          <a
            href={linkA4quality}
            className="simple-text logo-normal"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleSidebar}
          >
            <img src={logo} alt="react-logo" />
          </a>
        </div>
        {!isProd && (
          <StripBanner isDev={isDev} top="-10px" text={`Ambiente de ${isDev ? 'Dev' : 'Teste'}`} />
        )}
        <S.NavBar isDev={isDev || isTest}>
          {SIDE_MENU.map((elm, i) => {
            if (!showBasedOnGroup(elm.group)) return null;
            switch (elm.type) {
              case SIDE_MENU_TYPES.ITEM:
                return (
                  <li key={`key-navlink-${i}`} className={active === elm.route ? 'active' : ''}>
                    <NavLink to={elm.route} className="nav-link" activeClassName="active">
                      <i className={elm.icon} />
                      <p>{elm.name}</p>
                    </NavLink>
                  </li>
                );
              case SIDE_MENU_TYPES.LIST:
                return (
                  <SidebarItemList
                    key={`key-item-list-${i}`}
                    name={elm.name}
                    icon={elm.icon}
                    list={elm.list}
                  />
                );
              case SIDE_MENU_TYPES.TITLE:
                return (
                  <S.TitleMenu key={`key-navtitle-${i}`}>
                    <hr className="separator" />
                    <p className="side-title">{elm.name}</p>
                    <hr className="separator --100" />
                  </S.TitleMenu>
                );
              default:
                return null;
            }
          })}
        </S.NavBar>
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  bgColor: 'primary'
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'blue', 'green'])
};

export default Sidebar;
