import axios from 'axios';
import store from '~/store';
import history from './history';
import { authLogout } from '~/store/modules/auth/actions';
import { PUBLIC } from '~/routes/names';

export const envURL = () => {
  const PROTECTED_URL_DEV = 'https://api-dev.a4quality.com/';
  const PROTECTED_URL_TEST = 'https://api-test.a4quality.com/';
  const PROTECTED_URL_PROD = 'https://api.a4quality.com/';

  switch (process.env.REACT_APP_SCOPE) {
    case 'dev':
      return PROTECTED_URL_DEV;
    case 'test':
      return PROTECTED_URL_TEST;
    case 'prod':
      return PROTECTED_URL_PROD;
    default:
      return PROTECTED_URL_PROD;
  }
};

const client = axios.create({
  baseURL: envURL(),
  timeout: 60000
});

client.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth;
    if (token != null) {
      // eslint-disable-next-line
      config.headers.Authorization = token;
      // config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(authLogout());
      history.push(PUBLIC.LOGIN);
    }
    return Promise.reject(error.response);
  }
);

export default client;
