import styled from 'styled-components';
import {
  Form as UnformForm,
  Select as UnformSelect,
  Input as UnformInput,
  Textarea as TextareaInput
} from '@rocketseat/unform';
import ChevronDown from '../../../assets/img/chevron.png';

export const Wrapper = styled.div`
  background-color: #fff;
`;

export const ToolBar = styled.div`
  width: 100%;
  padding: 30px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 18px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const InputCountContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-top: -12px;
`;

export const Form = styled(UnformForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  label {
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    color: #888888;
  }
  span:nth-last-child(-n + 1) {
    color: red;
  }
`;

export const Input = styled(UnformInput)`
  width: 100%;
  ${(props) => (props.multiline ? '' : 'height: 48px;')}
  ${(props) =>
    props.multiline ? 'padding: 8px 15px !important;' : ''}
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;
  color: #1d1d1d;
  padding: 0 16px;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;

export const InputResume = styled(UnformInput)`
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  line-height: 19px;
  margin-top: 5px;
  color: #1d1d1d;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;

export const Textarea = styled(TextareaInput)`
  width: 100%;
  min-height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;
  color: #1d1d1d;
  padding: 13px 13px;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;

export const Select = styled(UnformSelect)`
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: 8px;
  appearance: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1d1d1d;
  margin-top: 5px;
  background-size: 14px 8px;
  background-image: url(${ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 18px;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;

export const SelectSimple = styled(UnformSelect)`
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 0 13px;
  border-radius: 8px;
  appearance: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #1d1d1d;
  background-size: 14px 8px;
  background-image: url(${ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 18px;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => (props.top ? `margin-top: ${props.top}px` : '')}
`;

export const TitleModal = styled.p`
  font-size: 20px;
  padding-left: 16px;
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;
