import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import Select from 'react-select';
import { Spinner } from 'reactstrap';

const SelectInput = ({
  label,
  name,
  list,
  isMulti,
  loading,
  placeholder,
  onChangeOption,
  userEvaluatorObserver,
  ...rest
}) => {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  const customStyles = {
    control: () => ({
      display: 'flex',
      flexDirection: 'row',
      marginTop: '5px',
      minHeight: '45px',
      border: '1px solid rgba(0,0,0,0.15)',
      backgroundColor: '#fff',
      minWidth: '80px',
      boxSizing: 'border-box',
      borderRadius: '8px'
    })
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      clearValue: (pickerRef) => {
        pickerRef.clear();
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      {loading ? (
        <div>
          <Spinner size="sm" color="primary" animation="border" />
        </div>
      ) : (
        <Select
          placeholder={placeholder}
          styles={customStyles}
          isMulti={isMulti}
          ref={ref}
          name={fieldName}
          onChange={onChangeOption}
          options={list}
          isDisabled={userEvaluatorObserver}
          isSearchable={false}
          {...rest}
        />
      )}
      {error && <span>{error}</span>}
    </>
  );
};

SelectInput.defaultProps = {
  label: '',
  name: '',
  placeholder: '',
  list: [],
  isMulti: true,
  loading: false,
  userEvaluatorObserver: false,
  onChangeOption: () => {}
};

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.any,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  userEvaluatorObserver: PropTypes.bool,
  onChangeOption: PropTypes.func
};

export default SelectInput;
