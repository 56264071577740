import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalReact, ModalHeader, ModalBody } from 'reactstrap';
import * as S from './styles';

const Modal = ({ isOpen, toggle, title, children, ...rest }) => {
  return (
    <ModalReact isOpen={isOpen} toggle={toggle} {...rest}>
      <ModalHeader toggle={toggle}>
        <S.TitleModal>{title}</S.TitleModal>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ModalReact>
  );
};

Modal.defaultProps = {
  isOpen: false,
  title: '',
  toggle: () => {}
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.any
};

export default Modal;
