import { createGlobalStyle } from 'styled-components';
import { colors } from '~/constants';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-fileinput/css/fileinput.min.css';
import 'react-step-progress-bar/styles.css';

import '~/assets/scss/black-dashboard-react.scss';
import '~/assets/demo/demo.css';
import '~/assets/css/nucleo-icons.css';

export default createGlobalStyle`
.Toastify__toast--info {
  background: ${colors.info};
}
.Toastify__toast--success {
  background: ${colors.primary};
}
.Toastify__toast--warning {
  background: ${colors.warning};
}
.Toastify__toast--error {
  background: ${colors.danger};
}

.card.card-chart {
  overflow: visible;
}

.modal-resume-container {
  top: -300px !important;
}

.modal-meeting-container {
  top: -200px !important;
}

.modal-comments-log{
  max-width: 1000px !important;
}

h2.fc-toolbar-title {
  color: ${colors.black} !important;
}

.nav-tab-light{
  & > li > a {
    color: #1e1e2f !important;
    &.active {
      color: #FFFFFF !important;
    }
  }
}
.btn-link {
  &.link-dark{
    color: ${colors.tertiary} !important;
    &:hover {
      color: ${colors.black} !important;
    }
    &:focus {
      color: ${colors.black} !important;
    }
  }
}
`;
