import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(199, 199, 199, 0.5);
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
`;
