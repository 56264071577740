export const SELECT = [
  {
    id: true,
    title: 'Sim'
  },
  {
    id: false,
    title: 'Não'
  }
];

export const GENRE = [
  {
    id: 'M',
    title: 'Masculino'
  },
  {
    id: 'F',
    title: 'Feminino'
  }
];

export const OPTIONS_DEPLOYMENT_TIME_RN507 = [
  { value: null, label: '-' },
  { value: 0, label: '0 Meses' },
  { value: 1, label: '1 Mês' },
  { value: 2, label: '2 Meses' },
  { value: 3, label: '3 Meses' },
  { value: 4, label: '4 Meses' },
  { value: 5, label: '5 Meses' },
  { value: 6, label: '6 Meses' },
  { value: 7, label: '7 Meses' },
  { value: 8, label: '8 Meses' },
  { value: 9, label: '9 Meses' },
  { value: 10, label: '10 Meses' },
  { value: 11, label: '11 Meses' },
  { value: 12, label: '12 Meses' },
  { value: 13, label: '+12 Meses' }
];

export const OPTIONS_DEPLOYMENT_TIME_RN506 = [
  { value: null, label: '-' },
  { value: 0, label: '0 Meses' },
  { value: 1, label: '1 Mês' },
  { value: 2, label: '2 Meses' },
  { value: 3, label: '3 Meses' },
  { value: 4, label: '4 Meses' },
  { value: 5, label: '5 Meses' },
  { value: 6, label: '6 Meses' },
  { value: 7, label: '+6 Meses' }
];

export const fileInputLocale = {
  fileSingle: 'arquivo',
  filePlural: 'arquivos',
  browseLabel: '',
  removeLabel: '',
  removeTitle: 'Remover arquivos selecionados',
  cancelLabel: 'Cancelar',
  cancelTitle: 'Interromper envio em andamento',
  pauseLabel: 'Pausa',
  pauseTitle: 'Pausa do envio',
  uploadLabel: 'Enviar',
  uploadTitle: 'Enviar arquivos selecionados',
  msgNo: 'Não',
  msgNoFilesSelected: 'Nenhum arquivo selecionado',
  msgPaused: 'Pausado',
  msgCancelled: 'Cancelado',
  msgPlaceholder: 'Selecionar {files} ...',
  msgZoomModalHeading: 'Pré-visualização detalhada',
  msgFileRequired: 'Você deve selecionar um arquivo para enviar.',
  msgSizeTooSmall:
    'O arquivo "{name}" (<b>{size} KB</b>) é muito pequeno e deve ser maior que <b>{minSize} KB</b>.',
  msgSizeTooLarge:
    'O arquivo "{name}" (<b>{size} KB</b>) excede o tamanho máximo permitido de <b>{maxSize} KB</b>.',
  msgFilesTooLess: 'Você deve selecionar pelo menos <b>{n}</b> {files} para enviar.',
  msgFilesTooMany:
    'O número de arquivos selecionados para o envio <b>({n})</b> excede o limite máximo permitido de <b>{m}</b>.',
  msgTotalFilesTooMany:
    'Pode enviar no máximo <b>{m}</b> arquivos (<b>{n}</b> arquivos detetados).',
  msgFileNotFound: 'O arquivo "{name}" não foi encontrado!',
  msgFileSecured: 'Restrições de segurança impedem a leitura do arquivo "{name}".',
  msgFileNotReadable: 'O arquivo "{name}" não pode ser lido.',
  msgFilePreviewAborted: 'A pré-visualização do arquivo "{name}" foi interrompida.',
  msgFilePreviewError: 'Ocorreu um erro ao ler o arquivo "{name}".',
  msgInvalidFileName: 'Caracteres inválidos ou não suportados no arquivo "{name}".',
  msgInvalidFileType:
    'Tipo inválido para o arquivo "{name}". Apenas arquivos "{types}" são permitidos.',
  msgInvalidFileExtension:
    'Extensão inválida para o arquivo "{name}". Apenas arquivos "{extensions}" são permitidos.',
  msgFileTypes: {
    image: 'imagem',
    html: 'HTML',
    text: 'texto',
    video: 'vídeo',
    audio: 'audio',
    flash: 'flash',
    pdf: 'PDF',
    object: 'objeto'
  },
  msgUploadAborted: 'O envio do arquivo foi abortado',
  msgUploadThreshold: 'Processando &hellip;',
  msgUploadBegin: 'Inicializando &hellip;',
  msgUploadEnd: 'Concluído',
  msgUploadResume: 'Retomando envio &hellip;',
  msgUploadEmpty: 'Nenhuma informação válida para upload.',
  msgUploadError: 'Erro de Envio',
  msgDeleteError: 'Erro ao Deletar',
  msgProgressError: 'Erro de Envio',
  msgValidationError: 'Erro de validação',
  msgLoading: 'Enviando arquivo {index} de {files} &hellip;',
  msgProgress: 'Enviando arquivo {index} de {files} - {name} - {percent}% completo.',
  msgSelected: '{n} {files} selecionado(s)',
  msgFoldersNotAllowed: 'Arraste e solte apenas arquivos! {n} pasta(s) ignoradas.',
  msgImageWidthSmall: 'Largura do arquivo de imagem "{name}" deve ser pelo menos {size} px.',
  msgImageHeightSmall: 'Altura do arquivo de imagem "{name}" deve ser pelo menos {size} px.',
  msgImageWidthLarge: 'Largura do arquivo de imagem "{name}" não pode exceder {size} px.',
  msgImageHeightLarge: 'Altura do arquivo de imagem "{name}" não pode exceder {size} px.',
  msgImageResizeError: 'Não foi possível obter as dimensões da imagem para redimensionar.',
  msgImageResizeException: 'Erro ao redimensionar a imagem.<pre>{errors}</pre>',
  msgAjaxError: 'Algo deu errado com a operação {operation}. Por favor tente novamente mais tarde!',
  msgAjaxProgressError: '{operation} falhou',
  msgDuplicateFile:
    'O arquivo "{name}" do mesmo tamanho "{size} KB" já foi selecionado. Ignorando a seleção duplicada.',
  msgResumableUploadRetriesExceeded:
    'Envio abortado, excedido <b>{max}</b> tentativas para o arquivo <b>{file}</b>! Detalhes do erro: <pre>{error}</pre>',
  msgPendingTime: '{time} restante',
  msgCalculatingTime: 'calculando o tempo restante',
  ajaxOperations: {
    deleteThumb: 'Exclusão de arquivo',
    uploadThumb: 'Upload de arquivos',
    uploadBatch: 'Carregamento de arquivos em lote',
    uploadExtra: 'Carregamento de dados do formulário'
  },
  dropZoneTitle: 'Arraste e solte os arquivos aqui &hellip;',
  dropZoneClickTitle: '<br>(ou clique para selecionar o(s) arquivo(s))',
  fileActionSettings: {
    removeTitle: 'Remover arquivo',
    uploadTitle: 'Enviar arquivo',
    uploadRetryTitle: 'Repetir envio',
    downloadTitle: 'Baixar arquivo',
    zoomTitle: 'Ver detalhes',
    dragTitle: 'Mover / Reordenar',
    indicatorNewTitle: 'Ainda não enviado',
    indicatorSuccessTitle: 'Enviado',
    indicatorErrorTitle: 'Erro',
    indicatorPausedTitle: 'Envio pausado',
    indicatorLoadingTitle: 'Enviando &hellip;'
  },
  previewZoomButtonTitles: {
    prev: 'Visualizar arquivo anterior',
    next: 'Visualizar próximo arquivo',
    toggleheader: 'Mostrar cabeçalho',
    fullscreen: 'Ativar tela cheia',
    borderless: 'Ativar modo sem borda',
    close: 'Fechar pré-visualização detalhada'
  }
};

export const fileInputThemes = {
  fileActionSettings: {
    removeIcon: '<i class="fa fa-trash"></i>',
    uploadIcon: '<i class="fa fa-upload"></i>',
    uploadRetryIcon: '<i class="fa fa-repeat"></i>',
    downloadIcon: '<i class="fa fa-download"></i>',
    zoomIcon: '<i class="fa fa-search-plus"></i>',
    dragIcon: '<i class="fa fa-arrows"></i>',
    indicatorNew: '<i class="fa fa-plus-circle text-warning"></i>',
    indicatorSuccess: '<i class="fa fa-check-circle text-success"></i>',
    indicatorError: '<i class="fa fa-exclamation-circle text-danger"></i>',
    indicatorLoading: '<i class="fa fa-hourglass text-muted"></i>',
    indicatorPaused: '<i class="fa fa-pause text-info"></i>'
  },
  layoutTemplates: {
    fileIcon: '<i class="fa fa-file kv-caption-icon"></i> '
  },
  previewZoomButtonIcons: {
    prev: '<i class="fa fa-caret-left fa-lg"></i>',
    next: '<i class="fa fa-caret-right fa-lg"></i>',
    toggleheader: '<i class="fa fa-fw fa-arrows-v"></i>',
    fullscreen: '<i class="fa fa-fw fa-arrows-alt"></i>',
    borderless: '<i class="fa fa-fw fa-external-link"></i>',
    close: '<i class="fa fa-fw fa-remove"></i>'
  },
  previewFileIcon: '<i class="fa fa-file"></i>',
  browseIcon: '<i class="fa fa-folder-open"></i>',
  removeIcon: '<i class="fa fa-trash"></i>',
  cancelIcon: '<i class="fa fa-ban"></i>',
  pauseIcon: '<i class="fa fa-pause"></i>',
  uploadIcon: '<i class="fa fa-upload"></i>',
  msgValidationErrorIcon: '<i class="fa fa-exclamation-circle"></i> '
};
