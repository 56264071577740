import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent } from './styles';
import { Modal, MonitoredIndicators } from '~/components';
import { UpdatePrerequisites } from '~/services/private';

const PrerequisitesList = forwardRef(({ prerequisites }, ref) => {
  const [openModalPre, setOpenModalPre] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [initEditDetails, setInitEditDetails] = useState(false);
  const [dateUpdate, setDateUpdate] = useState('');

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  const changeVisibleModal = (active) => setOpenModalPre(active);

  const changePrerequisites = async (id, itHas) => {
    try {
      setLoadingDetails(true);
      await UpdatePrerequisites({
        id,
        itHas
      });
      setDateUpdate(new Date().toLocaleTimeString());
      setInitEditDetails(true);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingDetails(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={openModalPre}
        toggle={() => changeVisibleModal(false)}
        title="Pré-requisitos"
        unmountOnClose={false}
      >
        <ModalContainer>
          {prerequisites.map((elm, ind) => (
            <ModalContent key={`modal-monitored-${ind}`}>
              <MonitoredIndicators
                hasHover={false}
                colorTitle="#252525"
                chosenIndicator={(itHas) => changePrerequisites(elm.id, itHas)}
                itHas={elm.itHas}
                text={elm.details.text}
              />
            </ModalContent>
          ))}
          <div>
            {loadingDetails ? (
              <Saving>
                <Spinner size="sm" animation="border" />
                ...salvando
              </Saving>
            ) : null}
            {initEditDetails && !loadingDetails ? (
              <Saving color="#252525">
                <h6>Dados atualizados em: {dateUpdate}</h6>
              </Saving>
            ) : null}
          </div>
        </ModalContainer>
      </Modal>
    </div>
  );
});

PrerequisitesList.defaultProps = {
  prerequisites: []
};

PrerequisitesList.propTypes = {
  prerequisites: PropTypes.any
};

export default PrerequisitesList;
