import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfile, ChangePass } from '~/services/private';
import { Button } from '~/components';
import { validateDefaultForm, validatePassForm } from '~/config/validateDefaultForm';
import * as L from '~/modules/_layouts/private/styles';
import * as S from './styles';
import { authGetProfile } from '~/store/modules/auth/actions';

const Profile = () => {
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);
  const userData = {
    ...user,
    email: user.account.email
  };
  const dispatch = useDispatch();

  const Update = async (newUser) => {
    try {
      setLoading(true);
      const { data } = await UpdateProfile({
        ...user,
        ...newUser
      });
      loadingProfile();
      toast.success(data);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoading(false);
    }
  };

  const UpdatePass = async (pass) => {
    try {
      setLoadingPass(true);
      const { data } = await ChangePass({
        ...pass
      });
      toast.success(data);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingPass(false);
    }
  };

  const loadingProfile = useCallback(() => {
    dispatch(authGetProfile());
  }, [dispatch]);

  useEffect(() => {
    loadingProfile();
  }, [loadingProfile]);

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Administrativo</h5>
                  <CardTitle tag="h2">Perfil</CardTitle>
                  <h5>Antes de submeter o formulário, preencha todos os campos obrigatórios.</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <L.Form schema={validateDefaultForm} onSubmit={Update} initialData={userData}>
                  <Row>
                    <Col xs="3">
                      <L.InputContainer>
                        <L.Input label="Nome" name="name" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="3">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="cpf-number">
                          CPF
                        </label>
                        <InputMask mask="999.999.999-99" maskChar={null} defaultValue={user.cpf}>
                          {() => <L.Input name="cpf" type="text" id="cpf-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="3">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="phone-number">
                          Telefone
                        </label>
                        <InputMask mask="(99) 99999-9999" maskChar={null} defaultValue={user.phone}>
                          {() => <L.Input name="phone" type="phone" id="phone-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="3">
                      <L.InputContainer>
                        <L.Input label="E-mail" name="email" />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.ButtonContainer>
                        <Button title="Atualizar" type="submit" loading={loading} block />
                      </L.ButtonContainer>
                    </Col>
                  </Row>
                </L.Form>
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Segurança</h5>
                  <CardTitle tag="h2">Alterar a senha</CardTitle>
                  <h5>Digite a senha atual e sua nova senha.</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <L.Form schema={validatePassForm} onSubmit={UpdatePass}>
                  <Row>
                    <Col xs="3">
                      <L.InputContainer>
                        <L.Input label="Senha atual" name="old_pass" type="password" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="3">
                      <L.InputContainer>
                        <L.Input label="Nova senha" name="new_pass" type="password" />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.ButtonContainer>
                        <Button title="Alterar" type="submit" loading={loadingPass} block />
                      </L.ButtonContainer>
                    </Col>
                  </Row>
                </L.Form>
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Profile;
