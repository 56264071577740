import produce from 'immer';
import { USER_REQUEST, USER_SET_ITS_LOADING, USER_SET_USERS } from './patterns';

const INITIAL_STATE = {
  users: {
    active: [],
    inactive: []
  },
  fetch: {
    loading: false,
    error: false
  }
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case USER_REQUEST: {
        draft.fetch.itsLoading = true;
        break;
      }
      case USER_SET_ITS_LOADING: {
        draft.fetch.itsLoading = action.itsLoading;
        break;
      }
      case USER_SET_USERS: {
        draft.users = action.payload.users;
        break;
      }
      default:
    }
  });
}
