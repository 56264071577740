import React, { forwardRef, useImperativeHandle, useState, Fragment } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent, ButtonAdd, Table } from './styles';
import { EVALUATION_TYPE } from '~/constants';
import { Button, Modal } from '~/components';
import { AddAuditedAreas, RemoveAuditedArea } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { alphabeticallySort } from '~/config/helpers';

const AuditedAreas = forwardRef(
  ({ evaluationId, evaluationType, auditedAreas, dimensionsList }, ref) => {
    const [openModal, setOpenModal] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [initEditDetails, setInitEditDetails] = useState(false);
    const [dateUpdate, setDateUpdate] = useState('');

    const [name, setName] = useState(
      dimensionsList.reduce((acc, dimension) => {
        acc[dimension] = '';
        return acc;
      }, {})
    );
    const [auditedAreasState, setAuditedAreasState] = useState(
      dimensionsList.reduce((acc, dimension) => {
        acc[dimension] = alphabeticallySort(auditedAreas[dimension], true, 'name');
        return acc;
      }, {})
    );

    const DimensionNameForType = () => {
      switch (evaluationType) {
        case EVALUATION_TYPE.RN_507:
          return 'Dimensão';
        case EVALUATION_TYPE.RN_506:
          return 'Requisito';
        default:
          return 'Dimensão';
      }
    };

    const changeVisibleModal = (active) => setOpenModal(active);

    const addArea = async (dimension) => {
      try {
        setLoadingDetails(true);
        const { data } = await AddAuditedAreas({
          evaluationId,
          dimension,
          name: name[dimension]
        });
        setDateUpdate(new Date().toLocaleTimeString());
        setInitEditDetails(true);
        setAuditedAreasState({
          ...auditedAreasState,
          [dimension]: alphabeticallySort(
            [
              ...auditedAreasState[dimension],
              {
                id: data.id,
                name: name[dimension]
              }
            ],
            true,
            'name'
          )
        });
        setName({
          ...name,
          [dimension]: ''
        });
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
      } finally {
        setLoadingDetails(false);
      }
    };

    const removeArea = async (dimension, id) => {
      try {
        setLoadingDetails(true);
        await RemoveAuditedArea(id);
        setDateUpdate(new Date().toLocaleTimeString());
        setInitEditDetails(true);
        setAuditedAreasState({
          ...auditedAreasState,
          [dimension]: auditedAreasState[dimension].filter((elm) => elm.id !== id)
        });
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
      } finally {
        setLoadingDetails(false);
      }
    };

    useImperativeHandle(ref, () => ({
      changeVisibleModal
    }));

    const LoadingSaving = () => (
      <div>
        {loadingDetails ? (
          <Saving>
            <Spinner size="sm" animation="border" />
            ...salvando
          </Saving>
        ) : null}
        {initEditDetails && !loadingDetails ? (
          <Saving color="#252525">
            <h6>Dados atualizados em: {dateUpdate}</h6>
          </Saving>
        ) : null}
      </div>
    );

    return (
      <div>
        <Modal
          isOpen={openModal}
          toggle={() => changeVisibleModal(false)}
          title="Áreas auditadas"
          size="lg"
          modalClassName={dimensionsList.length === 1 ? '' : 'modal-meeting-container'}
          unmountOnClose={false}
        >
          {LoadingSaving()}
          <ModalContainer>
            <ModalContent>
              {Object.keys(auditedAreasState).map((dimension) => (
                <Fragment key={`key-audited-list-${dimension}`}>
                  {dimension !== '1' && <hr className="separator" />}
                  <h4>
                    {DimensionNameForType()} {dimension}
                  </h4>
                  <Row>
                    <Col xs="8">
                      <L.Input
                        label="Nome"
                        name="name"
                        value={name[dimension] || ''}
                        onChange={(event) =>
                          setName({
                            ...name,
                            [dimension]: event.target.value
                          })
                        }
                      />
                    </Col>
                    <Col xs="2">
                      <ButtonAdd>
                        <Button
                          disabled={name[dimension] === ''}
                          onClick={() => addArea(dimension)}
                          title={<i className="tim-icons icon-simple-add" />}
                        />
                      </ButtonAdd>
                    </Col>
                  </Row>
                  <h4>
                    Lista de Áreas - {DimensionNameForType()} {dimension}
                  </h4>
                  <Table>
                    <tbody>
                      <tr>
                        <th colSpan="2" className="th-title">
                          Nome
                        </th>
                        <th colSpan="1" className="th-title">
                          Apagar
                        </th>
                      </tr>
                      {auditedAreasState[dimension].map((elm, ind) => (
                        <tr key={`table-list-meeting-${ind}`}>
                          <td colSpan="2" className="center">
                            {elm.name}
                          </td>
                          <td colSpan="1" className="center --yesNo">
                            <Button
                              title={<i className="tim-icons icon-simple-delete" />}
                              onClick={() => removeArea(dimension, elm.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Fragment>
              ))}
            </ModalContent>
          </ModalContainer>
          {LoadingSaving()}
        </Modal>
      </div>
    );
  }
);

AuditedAreas.defaultProps = {
  evaluationId: 0,
  evaluationType: EVALUATION_TYPE.RN_507,
  auditedAreas: []
};

AuditedAreas.propTypes = {
  evaluationId: PropTypes.number,
  evaluationType: PropTypes.number,
  auditedAreas: PropTypes.any
};

export default AuditedAreas;
