import { EVALUATORS_REQUEST, EVALUATORS_SET_ITS_LOADING, EVALUATORS_SET_USERS } from './patterns';

export function evaluatorsRequest() {
  return {
    type: EVALUATORS_REQUEST
  };
}

export function setEvaluators(payload) {
  return {
    type: EVALUATORS_SET_USERS,
    payload
  };
}

export function evaluatorSetLoading(itsLoading) {
  return {
    type: EVALUATORS_SET_ITS_LOADING,
    itsLoading
  };
}
