import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, FormFeedback, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest, authSetLoading } from '~/store/modules/auth/actions';
import { Button } from '~/components';
import Back from '~/assets/video/back.mp4';
import * as S from './styles';
import history from "~/config/history";
import { PUBLIC } from '~/routes/names';

export default function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);

  const { fetch } = useSelector((state) => state.auth);

  useEffect(() => {
    document.querySelector('video').defaultPlaybackRate = 0.5;
    document.querySelector('video').play();
    document.querySelector('video').playbackRate = 0.8;
    dispatch(authSetLoading(false));
  }, [dispatch]);

  const submit = () => {
    dispatch(
      authRequest({
        email,
        pass
      })
    );
  };

  const forgotPass = () => history.push(PUBLIC.FORGOT_PASS);

  return (
    <S.Container>
      <S.Video loop playsInline muted>
        <source
          src={Back}
          type="video/mp4"
        />
      </S.Video>
      <S.Card>
        <Card>
          <S.ContainerHeader>
            <img
              alt="Logo a4quality"
              src="https://a4quality.com/wp-content/uploads/2020/05/logo_a4q.png"
            />
          </S.ContainerHeader>
          <S.ContainerCard>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="*****@***.com"
                type="email"
                invalid={email === ''}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormFeedback>Campo obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>Senha</Label>
              <Input
                placeholder="Informe sua senha"
                type="password"
                invalid={pass === ''}
                onChange={(e) => setPass(e.target.value)}
              />
              <FormFeedback>Campo obrigatório</FormFeedback>
            </FormGroup>
            <Button color="primary" onClick={submit} loading={fetch.itsLoading} title="Entrar" />
          </S.ContainerCard>
          <Button color="link" onClick={forgotPass} title="Esqueceu a senha?" />
        </Card>
      </S.Card>
    </S.Container>
  );
}
