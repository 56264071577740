import * as Yup from 'yup';
import { REGEX } from '~/constants';

export const validateForm = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cpf: Yup.string().required('O CPF é obrigatório'),
  phone: Yup.string()
    .min(14, 'Por favor digite um telefone válido.')
    .required('Este campo é obrigatório'),
  graduate: Yup.string(),
  postGraduate: Yup.string(),
  minimumExperienceInBusinessAudit: Yup.string().required('Este campo é obrigatório'),
  minimumExperienceInControllership: Yup.string().required('Este campo é obrigatório'),
  minimumExperienceInHealthAccreditation: Yup.string().required('Este campo é obrigatório'),
  minimumExperienceInHealthAaudit: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .matches(REGEX.email, {
      message: 'Esse não é um e-mail válido',
      excludeEmptyString: true
    })
    .required('Este campo é obrigatório')
});
