import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 5px;
  }
`;

export const Title = styled.div`
  h2 {
    text-align: center;
    color: #848181 !important;
    font-size: 20px;
  }
  p {
    text-align: center;
  }
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
