import styled from 'styled-components';
import { colors } from '~/constants';

export const Body = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

export const Loading = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const NotFound = styled.h2`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: ${colors.black} !important;
`;
