import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent } from './styles';
import { Modal } from '~/components';
import { UpdateDateStart } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';

const Date = forwardRef(({ id, dateStart, dateEnd }, ref) => {
  const [dateStartState, setDateStartState] = useState(dateStart);
  const [dateEndState, setDateEndState] = useState(dateEnd);

  const [openModal, setOpenModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [myTimeOut, setMyTimeOut] = useState(null);

  const changeVisibleModal = (active) => setOpenModal(active);

  const handleChange = (event, setState) => {
    const text = event.target.value;
    setState(text);
    if (myTimeOut) clearTimeout(myTimeOut);
  };

  const changeDate = useCallback(async () => {
    try {
      setLoadingDetails(true);
      if (dateStartState || dateEndState) {
        await UpdateDateStart({
          id,
          dateStart: dateStartState,
          dateEnd: dateEndState
        });
      }
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático da data');
    } finally {
      setLoadingDetails(false);
    }
  }, [id, dateStartState, dateEndState]);

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  useEffect(() => {
    setMyTimeOut(setTimeout(changeDate, 1000));
  }, [dateStartState, dateEndState, changeDate]);

  const LoadingSaving = () => (
    <div>
      {loadingDetails ? (
        <Saving>
          <Spinner size="sm" animation="border" />
          ...salvando
        </Saving>
      ) : null}
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Datas da Avaliação"
        size="lg"
        unmountOnClose={false}
      >
        {LoadingSaving()}
        <ModalContainer>
          <ModalContent>
            <Row>
              <Col xs="6">
                <L.Input
                  label="Data início"
                  name="dateStart"
                  type="date"
                  value={dateStartState || ''}
                  onChange={(event) => {
                    handleChange(event, setDateStartState);
                  }}
                />
              </Col>
              <Col xs="6">
                <L.Input
                  label="Data encerramento"
                  name="dateEnd"
                  type="date"
                  value={dateEndState || ''}
                  onChange={(event) => {
                    handleChange(event, setDateEndState);
                  }}
                />
              </Col>
            </Row>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

Date.defaultProps = {
  id: 0,
  dateStart: '',
  dateEnd: ''
};

Date.propTypes = {
  id: PropTypes.number,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string
};

export default Date;
