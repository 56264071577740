import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container
} from 'reactstrap';
import { authLogout } from '~/store/modules/auth/actions';
import profile from '~/assets/img/anime3.png';
import history from '~/config/history';
import { ALL } from '~/routes/names';

const AdminNavbar = ({ sidebarOpened, brandText, toggleSidebar }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const updateColor = useCallback(() => {
    setColor(window.innerWidth < 993 && collapseOpen ? 'bg-white' : 'navbar-transparent');
  }, [collapseOpen]);

  const removeEvent = useCallback(() => window.removeEventListener('resize', updateColor), [
    updateColor
  ]);

  useEffect(() => {
    window.addEventListener('resize', updateColor);
    return removeEvent;
  }, [removeEvent, updateColor]);

  const toggleCollapse = () => {
    setColor(collapseOpen ? 'navbar-transparent' : 'bg-white');
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <Navbar className={classNames('navbar-absolute', color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: sidebarOpened
              })}
            >
              <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#admin" onClick={(e) => e.preventDefault()}>
              {brandText}
            </NavbarBrand>
          </div>
          <button
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navigation"
            data-toggle="collapse"
            id="navigation"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={profile} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">{user.name.split(' ')[0]}</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li" onClick={() => history.push(ALL().PROFILE)}>
                    <DropdownItem className="nav-item">Perfil</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li" onClick={() => dispatch(authLogout())}>
                    <DropdownItem className="nav-item">Sair</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
