import {
  EVALUATIONS_REQUEST,
  EVALUATIONS_SET_DIARY_TEMP,
  EVALUATIONS_SET_DIARY_BLACK_LIST,
  EVALUATIONS_SET_ITS_LOADING,
  EVALUATIONS_SET_LIST
} from './patterns';

export function evaluationsRequest(evaluationType, classification, RNType) {
  return {
    type: EVALUATIONS_REQUEST,
    evaluationType,
    classification,
    RNType
  };
}

export function setEvaluations(payload) {
  return {
    type: EVALUATIONS_SET_LIST,
    payload
  };
}

export function setDiary(payload) {
  return {
    type: EVALUATIONS_SET_DIARY_TEMP,
    payload
  };
}

export function setDiaryBlackList(payload) {
  return {
    type: EVALUATIONS_SET_DIARY_BLACK_LIST,
    payload
  };
}

export function evaluationsSetLoading(itsLoading) {
  return {
    type: EVALUATIONS_SET_ITS_LOADING,
    itsLoading
  };
}
