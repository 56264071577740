import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';

const TablePeople = ({ people }) => (
  <S.Table bottomMargin requirementsTable>
    <tbody>
      <tr>
        <th className="th-title" colSpan="3">
          Nome
        </th>
        <th className="th-title">Cargo/Função</th>
      </tr>
      {people.map((elm) => (
        <tr key={uniqId()}>
          <td className="left --people-interviewed" colSpan="3">
            {elm.name}
          </td>
          <td className="left --people-interviewed">{elm.occupation}</td>
        </tr>
      ))}
    </tbody>
  </S.Table>
);

const PeopleInterviewed = ({ people, startPage, totalPages, editionReport }) => {
  const half = Math.ceil(people.length / 2);

  const firstHalf = people.slice(0, half);
  const secondHalf = people.slice(half);

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Pessoas entrevistadas/contatadas durante a avaliação
            </th>
          </tr>
        </tbody>
      </S.Table>
      <Row>
        <Col xs="6">
          <TablePeople people={firstHalf} />
        </Col>
        <Col xs="6">
          <TablePeople people={secondHalf} />
        </Col>
      </Row>
      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

PeopleInterviewed.defaultProps = {
  people: [],
  startPage: 0,
  totalPages: 0,
  editionReport: ''
};

PeopleInterviewed.propTypes = {
  people: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default PeopleInterviewed;
