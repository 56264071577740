import React from 'react';
import { Card, CardBody, Progress, Badge } from 'reactstrap';
import { PropTypes } from 'prop-types';
import * as S from './styles';

const CardEvaluationCompany = ({ img, title, time, status, progress, onClick, badge }) => {
  return (
    <S.Container onClick={onClick}>
      {badge && (
        <S.Badge>
          <Badge color="info">{badge}</Badge>
        </S.Badge>
      )}
      <Card className="card-user">
        <CardBody>
          <div className="author">
            <div className="block block-one" />
            <div className="block block-two" />
            <div className="block block-three" />
            <div className="block block-four" />
            <img alt="..." className="avatar" src={img} />
            <h5 className="title">{title}</h5>
            <S.Description>Status: {status}</S.Description>
            <S.Description>{time}</S.Description>
            <S.Description>{progress}%</S.Description>
            <Progress color="primary" value={progress} animated />
          </div>
        </CardBody>
      </Card>
    </S.Container>
  );
};

CardEvaluationCompany.defaultProps = {
  img: 'unimed.png',
  title: '',
  time: '',
  status: '',
  progress: 0,
  badge: null
};

CardEvaluationCompany.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  status: PropTypes.string,
  progress: PropTypes.number,
  badge: PropTypes.string
};

export default CardEvaluationCompany;
