import React from 'react';
import { PropTypes } from 'prop-types';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';
import { resumeContentEvaluation, VERSIONS_OF_CHANGES } from '~/constants';

const Resume = ({
  data,
  startPage,
  totalPages,
  editionReport,
  type,
  evaluationTextType,
  totalItems,
  dimensions,
  level
}) => {
  const join = resumeContentEvaluation(data, evaluationTextType, totalItems, level)
    [VERSIONS_OF_CHANGES.FIRST_VERSION].filter(
      (elm) => elm.joinReport && elm.forTypes?.includes(type)
    )
    .map((elm) => elm.text)
    .join(' ');

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Resumo da Avaliação
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.ResumeContainer>
        {resumeContentEvaluation({ ...data, dimensions }, evaluationTextType, totalItems, level)[
          VERSIONS_OF_CHANGES.FIRST_VERSION
        ].map((elm, ind) => {
          if (elm.onlyReport) {
            return (
              <S.ResumeContent key={`resume-content-${ind}`}>
                <span>{elm.text}</span>
              </S.ResumeContent>
            );
          }

          if (elm.isTitle)
            return (
              <S.ResumeContent key={`resume-content-${ind}`}>
                <h4>{elm.text}</h4>
              </S.ResumeContent>
            );

          if (elm.pasteJoin)
            return (
              <S.ResumeContent key={`resume-content-${ind}`}>
                <span>{join}</span>
              </S.ResumeContent>
            );

          if (
            !elm.isTitle &&
            !elm.hasFit &&
            !elm.isSelect &&
            !elm.joinReport &&
            elm.showForAll &&
            (elm.allTypes || elm.forTypes?.includes(type))
          )
            return (
              <S.ResumeContent key={`resume-content-${ind}`}>
                <span>{elm.text}</span>
              </S.ResumeContent>
            );
          return null;
        })}
      </S.ResumeContainer>

      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

Resume.defaultProps = {
  data: {
    startDay: '',
    endDay: '',
    month: '',
    year: '',
    customText: ''
  },
  startPage: 0,
  totalPages: 0,
  evaluationTextType: '',
  totalItems: '',
  editionReport: '',
  type: '',
  dimensions: []
};

Resume.propTypes = {
  data: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  evaluationTextType: PropTypes.string,
  totalItems: PropTypes.string,
  editionReport: PropTypes.string,
  type: PropTypes.any,
  dimensions: PropTypes.any
};

export default Resume;
