import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { RN_507_TYPES } from '~/constants';
import Self from './Self';
import Accreditation from './Accreditation';
import Supervision from './Supervision';
import Pre from './Pre';
import * as S from './styles';

const AccreditationTypes = forwardRef(
  ({ accreditation, onlyGaps, withStrongPoints, error }, ref) => {
    switch (accreditation?.type_rn452) {
      case RN_507_TYPES.PRE:
        return (
          <div ref={ref}>
            <Pre
              accreditation={accreditation}
              onlyGaps={onlyGaps}
              withStrongPoints={withStrongPoints}
            />
          </div>
        );
      case RN_507_TYPES.SUPERVISION:
        return (
          <div ref={ref}>
            <Supervision accreditation={accreditation} />
          </div>
        );
      case RN_507_TYPES.ACCREDITATION:
        return (
          <div ref={ref}>
            <Accreditation accreditation={accreditation} />
          </div>
        );
      case RN_507_TYPES.SELF_EVALUATION:
        return (
          <div ref={ref}>
            <Self accreditation={accreditation} />
          </div>
        );
      default:
        return (
          <div ref={ref}>
            <S.NotFound>{error}</S.NotFound>
          </div>
        );
    }
  }
);

AccreditationTypes.defaultProps = {
  accreditation: {},
  onlyGaps: false,
  withStrongPoints: false,
  error: null
};

AccreditationTypes.propTypes = {
  accreditation: PropTypes.any,
  onlyGaps: PropTypes.bool,
  withStrongPoints: PropTypes.bool,
  error: PropTypes.string
};

export default AccreditationTypes;
