import { ADMIN, RN_506_ROUTES, RN_507_ROUTES } from '~/routes/names';
import { RN_506_CLASSIFICATION, RN_506_TYPES, RN_507_CLASSIFICATION, RN_507_TYPES } from './types';
import { GROUPS } from './groups';

export const MENU_NAMES = {
  PRE: 'Pré Avaliação Diagnóstica',
  SUPER: 'Supervisão',
  ACCREDITATION: 'Acreditação',
  SELF: 'Autoavaliação',
  CERTIFICATION: 'Certificação',
  ADMIN: 'Admin',
  DASHBOARD: 'Dashboard',
  REGISTRATIONS: 'Cadastros',
  OPERATORS: 'Operadoras',
  EVALUATORS: 'Avaliadores',
  DIRECTORSHIP: 'Diretoria',
  RN507: 'RN-507',
  MEDICAL_HOSPITAL: 'Médico Hospitalar',
  DENTAL: 'Odontológica',
  SELF_MANAGEMENT: 'Autogestão',
  RN506: 'RN-506',
  APS: 'APS'
};

export const SIDE_MENU_TYPES = {
  ITEM: 'ITEM',
  LIST: 'LIST',
  TITLE: 'TITLE'
};

const SIDE_MENU_COMMON_RN507 = (classificationType, isEvaluator = false) => {
  const ITEMS = [
    {
      name: MENU_NAMES.PRE,
      route: RN_507_ROUTES(classificationType, RN_507_TYPES.PRE, true).LIST
    },
    {
      name: MENU_NAMES.SUPER,
      route: RN_507_ROUTES(classificationType, RN_507_TYPES.SUPERVISION, true).LIST
    },
    {
      name: MENU_NAMES.ACCREDITATION,
      route: RN_507_ROUTES(classificationType, RN_507_TYPES.ACCREDITATION, true).LIST
    }
  ];
  if (!isEvaluator)
    ITEMS.push({
      name: MENU_NAMES.SELF,
      route: RN_507_ROUTES(classificationType, RN_507_TYPES.SELF_EVALUATION, true).LIST
    });
  return ITEMS;
};

const SIDE_MENU_COMMON_RN506 = (classificationType, isEvaluator = false) => {
  const ITEMS = [
    {
      name: MENU_NAMES.PRE,
      route: RN_506_ROUTES(classificationType, RN_506_TYPES.PRE).LIST
    },
    {
      name: MENU_NAMES.SUPER,
      route: RN_506_ROUTES(classificationType, RN_506_TYPES.SUPERVISION).LIST
    },
    {
      name: MENU_NAMES.CERTIFICATION,
      route: RN_506_ROUTES(classificationType, RN_506_TYPES.CERTIFICATION).LIST
    }
  ];
  if (!isEvaluator)
    ITEMS.push({
      name: MENU_NAMES.SELF,
      route: RN_506_ROUTES(classificationType, RN_506_TYPES.SELF_EVALUATION).LIST
    });
  return ITEMS;
};

const SIDE_MENU_ADMIN = [
  {
    name: MENU_NAMES.ADMIN,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.DASHBOARD,
    route: ADMIN.DASHBOARD,
    icon: 'tim-icons icon-chart-pie-36',
    type: SIDE_MENU_TYPES.ITEM,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.RN507,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.MEDICAL_HOSPITAL,
    icon: 'fas fa-clinic-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.ADMIN],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.MEDICAL_HOSPITAL)
  },
  {
    name: MENU_NAMES.DENTAL,
    icon: 'fas fa-tooth',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.ADMIN],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.DENTAL)
  },
  {
    name: MENU_NAMES.SELF_MANAGEMENT,
    icon: 'fas fa-tasks',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.ADMIN],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.SELF_MANAGEMENT)
  },
  {
    name: MENU_NAMES.RN506,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.APS,
    icon: 'fas fa-hand-holding-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.ADMIN],
    list: SIDE_MENU_COMMON_RN506(RN_506_CLASSIFICATION.APS)
  },
  {
    name: MENU_NAMES.REGISTRATIONS,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.OPERATORS,
    route: '/companies',
    icon: 'far fa-building',
    type: SIDE_MENU_TYPES.ITEM,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.EVALUATORS,
    route: '/evaluators',
    icon: 'tim-icons icon-badge',
    type: SIDE_MENU_TYPES.ITEM,
    group: [GROUPS.ADMIN]
  },
  {
    name: MENU_NAMES.DIRECTORSHIP,
    route: '/users',
    icon: 'tim-icons icon-single-02',
    type: SIDE_MENU_TYPES.ITEM,
    group: [GROUPS.ADMIN]
  }
];

const SIDE_MENU_EVALUATOR = [
  {
    name: 'RN-507',
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.EVALUATOR]
  },
  {
    name: MENU_NAMES.MEDICAL_HOSPITAL,
    icon: 'fas fa-clinic-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.EVALUATOR],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.MEDICAL_HOSPITAL, true)
  },
  {
    name: MENU_NAMES.DENTAL,
    icon: 'fas fa-tooth',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.EVALUATOR],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.DENTAL, true)
  },
  {
    name: MENU_NAMES.SELF_MANAGEMENT,
    icon: 'fas fa-tasks',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.EVALUATOR],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.SELF_MANAGEMENT, true)
  },
  {
    name: MENU_NAMES.RN506,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.EVALUATOR]
  },
  {
    name: MENU_NAMES.APS,
    icon: 'fas fa-hand-holding-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.EVALUATOR],
    list: SIDE_MENU_COMMON_RN506(RN_506_CLASSIFICATION.APS, true)
  }
];

const SIDE_MENU_COMPANY_USER = [
  {
    name: 'Home',
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.COMPANY_USER]
  },
  {
    name: 'Início',
    route: '/home',
    icon: 'tim-icons icon-chart-pie-36',
    type: SIDE_MENU_TYPES.ITEM,
    group: [GROUPS.COMPANY_USER]
  },
  {
    name: MENU_NAMES.RN507,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.COMPANY_USER]
  },
  {
    name: MENU_NAMES.MEDICAL_HOSPITAL,
    icon: 'fas fa-clinic-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.COMPANY_USER],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.MEDICAL_HOSPITAL)
  },
  {
    name: MENU_NAMES.DENTAL,
    icon: 'fas fa-tooth',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.COMPANY_USER],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.DENTAL)
  },
  {
    name: MENU_NAMES.SELF_MANAGEMENT,
    icon: 'fas fa-tasks',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.COMPANY_USER],
    list: SIDE_MENU_COMMON_RN507(RN_507_CLASSIFICATION.SELF_MANAGEMENT)
  },
  {
    name: MENU_NAMES.RN506,
    type: SIDE_MENU_TYPES.TITLE,
    group: [GROUPS.COMPANY_USER]
  },
  {
    name: MENU_NAMES.APS,
    icon: 'fas fa-hand-holding-medical',
    type: SIDE_MENU_TYPES.LIST,
    group: [GROUPS.COMPANY_USER],
    list: SIDE_MENU_COMMON_RN506(RN_506_CLASSIFICATION.APS)
  }
];

export const SIDE_MENU = [...SIDE_MENU_ADMIN, ...SIDE_MENU_EVALUATOR, ...SIDE_MENU_COMPANY_USER];
