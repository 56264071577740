import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Collapse, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import RequirementCheck from './RequirementCheck';
import * as S from './styles';
import { CollapseContainer } from '~/components/BasePageEvaluationView/styles';
import { Button } from '~/components';
import { UpdateControlVisualization } from '~/services/private';
import { EVALUATION_TYPE } from '~/constants';

const DimensionCheck = ({
  dimension,
  evaluatorId,
  evaluationType,
  evaluationId,
  controlVisualization,
  setLoadingDetails
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasDimensionChecked =
    controlVisualization.filter(
      (elm) => parseInt(elm.dimension, 10) === dimension.value && elm.requirement === null
    ).length > 0;

  const hasRequirementCheck = (requirement) => {
    return (
      controlVisualization.filter(
        (elm) =>
          parseInt(elm.dimension, 10) === dimension.value &&
          parseInt(elm.requirement, 10) === requirement
      ).length > 0
    );
  };

  const checkDimension = async (checked) => {
    try {
      setLoadingDetails(true);
      await UpdateControlVisualization({
        evaluationId,
        evaluator: evaluatorId,
        dimension: dimension.value,
        requirement: null,
        type: checked ? 'create' : 'delete'
      });
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingDetails(false);
    }
  };

  const checkRequirement = async (checked, requirement) => {
    try {
      setLoadingDetails(true);
      await UpdateControlVisualization({
        evaluationId,
        evaluator: evaluatorId,
        dimension: dimension.value,
        requirement,
        type: checked ? 'create' : 'delete'
      });
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingDetails(false);
    }
  };

  const DimensionNameForType = () => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return 'Dimensão';
      case EVALUATION_TYPE.RN_506:
        return 'Requisito';
      default:
        return 'Dimensão';
    }
  };

  return (
    <S.Container>
      <S.Line>
        <S.CheckContainer>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                defaultChecked={hasDimensionChecked}
                onChange={(event) => checkDimension(event.target.checked)}
              />
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </Label>
          </FormGroup>
        </S.CheckContainer>
        <Button
          title={`${DimensionNameForType()} ${dimension.value}`}
          color="link"
          className="link-dark"
          onClick={() => setIsOpen(!isOpen)}
        />
      </S.Line>
      <CollapseContainer max="8px">
        <Collapse isOpen={isOpen}>
          <div>
            {Object.keys(dimension.requirements).map((key) => {
              return (
                <RequirementCheck
                  requirement={dimension.requirements[key]}
                  isChecked={hasRequirementCheck(dimension.requirements[key].id)}
                  checkRequirement={(checked) =>
                    checkRequirement(checked, dimension.requirements[key].id)
                  }
                />
              );
            })}
          </div>
        </Collapse>
      </CollapseContainer>
    </S.Container>
  );
};

DimensionCheck.defaultProps = {
  evaluationId: 0,
  evaluationType: EVALUATION_TYPE.RN_507,
  dimension: {},
  evaluatorId: '',
  controlVisualization: [],
  setLoadingDetails: () => {}
};

DimensionCheck.propTypes = {
  evaluationId: PropTypes.number,
  evaluationType: PropTypes.number,
  dimension: PropTypes.any,
  evaluatorId: PropTypes.string,
  controlVisualization: PropTypes.any,
  setLoadingDetails: PropTypes.func
};

export default DimensionCheck;
