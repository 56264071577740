import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Collapse, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import uniqId from 'uniqid';
import * as S from './styles';
import { Button, Modal, Select } from '~/components';
import * as L from '~/modules/_layouts/private/styles';
import { validateForm } from './validateForm';
import { DeleteFiles, UpdateComments } from '~/services/private';
import {
  GROUPS,
  RN_507_TYPES,
  OPTIONS_DEPLOYMENT_TIME_RN507,
  OPTIONS_DEPLOYMENT_TIME_RN506,
  TYPES_LOGS,
  EVALUATION_TYPE
} from '~/constants';
import FileView from '~/components/RequirementItem/FileView';
import { showTypeIcon } from '~/config/helpers';
import { LogRequirementItems, RequirementComments } from '~/components/LogsView';

const optionsScope = [
  { value: null, label: '-' },
  { value: 0, label: 'Não Atendido' },
  { value: 1, label: 'Atendido' }
];

const options = [
  { id: '1', title: 'Sim' },
  { id: '0', title: 'Não' }
];

const COUNTER_LIMIT = 1200;

const RequirementItem = ({
  id,
  evaluationType,
  RNType,
  numericMarkers,
  text,
  typeRequirement,
  evidenceTip,
  interpretationTip,
  deploymentTime,
  scope,
  chosenDeploymentTime,
  comment,
  evidence,
  improvementOpportunity,
  strongPoint,
  nonAttendance,
  changedPoint,
  feedback,
  serverFiles,
  chosenScope,
  lastAccreditation,
  userEvaluatorObserver
}) => {
  const defineDeploymentTime = () => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return OPTIONS_DEPLOYMENT_TIME_RN507;
      case EVALUATION_TYPE.RN_506:
        return OPTIONS_DEPLOYMENT_TIME_RN506;
      default:
        return [];
    }
  };

  const OPTIONS_DEPLOYMENT_TIME = defineDeploymentTime();

  const prepare = () => {
    if (changedPoint === true) return '1';
    if (changedPoint === false) return '0';
    return '';
  };
  const refModalRequirements = useRef();
  const refModalComments = useRef();
  const refModalEvidence = useRef();
  const refModalFeedback = useRef();
  const refModalImprovementOpportunity = useRef();
  const refModalStrongPoint = useRef();
  const refModalNonAttendance = useRef();

  const [commentsForm, setCommentsForm] = useState({
    comment,
    evidence,
    improvementOpportunity,
    strongPoint,
    nonAttendance,
    feedback,
    changedPoint: prepare()
  });
  const [time, setTime] = useState(deploymentTime);
  const [scopeValue, setScopeValue] = useState(scope === true ? 1 : 0);
  const [openModalEvidence, setOenModalEvidence] = useState(false);
  const [openModalInterpretation, setOpenModalInterpretation] = useState(false);
  const [openModalComments, setOpenModalComments] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [files, setFiles] = useState([]);
  const [chosenFiles, setChosenFiles] = useState(
    serverFiles.map((elm) => ({
      id: elm.id,
      name: elm.name.split('_-_-_')[0],
      file: elm.name
    }))
  );

  const [collapseComments, setCollapseComments] = useState(false);

  const [countSize, setCountSize] = useState({
    comment: comment ? comment.length : 0,
    evidence: evidence ? evidence.length : 0,
    improvementOpportunity: improvementOpportunity ? improvementOpportunity.length : 0,
    strongPoint: strongPoint ? strongPoint.length : 0,
    nonAttendance: nonAttendance ? nonAttendance.length : 0,
    feedback: feedback ? feedback.length : 0
  });

  const [hasChangedAlert, setHasChangedAlert] = useState(false);

  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const prepareScope = (lastScope = false) => {
    if (lastScope) {
      if (lastAccreditation?.scope === null) return '-';
      if (lastAccreditation?.scope === true) return 'Atendido';
      if (lastAccreditation?.scope === false) return 'Não Atendido';
      return '';
    }
    if (scope === null) return { value: null, label: '-' };
    if (scope === true) return { value: 1, label: 'Atendido' };
    if (scope === false) return { value: 0, label: 'Não Atendido' };
    return '';
  };

  const prepareDeploymentTime = (lastDeploymentTime = false) => {
    if (lastDeploymentTime) {
      const filter = OPTIONS_DEPLOYMENT_TIME.filter(
        (elm) => elm.value === lastAccreditation?.deploymentTime
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return '';
    }

    const filter = OPTIONS_DEPLOYMENT_TIME.filter((elm) => elm.value === deploymentTime);
    if (filter.length > 0) {
      return filter[0];
    }
    return '';
  };

  const onChangeScope = (obj) => {
    setScopeValue(obj.value);
    chosenScope({
      id,
      scope: obj.value
    });
  };

  const onChangeTime = (obj) => {
    setTime(obj.value);
    chosenDeploymentTime({
      id,
      time: obj.value
    });
  };

  const calculateDegree = () => {
    let timeDegree = 12;
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        timeDegree = 12;
        break;
      case EVALUATION_TYPE.RN_506:
        timeDegree = 6;
        break;
      default:
        timeDegree = 12;
        break;
    }
    return scopeValue === 1 && time >= timeDegree ? 'Conforme' : 'Não conforme';
  };

  const calculatePoints = () => {
    let timePoints = 12;
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        timePoints = 12;
        break;
      case EVALUATION_TYPE.RN_506:
        timePoints = 6;
        break;
      default:
        timePoints = 12;
        break;
    }
    return scopeValue === 1 && time >= timePoints ? '1' : '0';
  };

  const deleteFile = async (idDelete) => {
    try {
      setLoadingDelete(true);
      const { data } = await DeleteFiles(
        {
          id: idDelete
        },
        evaluationType
      );
      setChosenFiles((prev) => prev.filter((elm) => elm.id !== idDelete));
      toast.success(data);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoadingDelete(false);
    }
  };

  const confirm = async (newComments) => {
    try {
      setCommentsForm(newComments);
      setLoadingComments(true);
      const base = [];
      for await (const file of files) {
        const base64 = await toBase64(file);
        const split = file.name.split('.');
        const ext = split[split.length - 1];
        const name = split
          .slice(0, split.length - 1)
          .join('-')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        base.push({
          name,
          ext,
          base64
        });
      }

      const { data } = await UpdateComments(
        {
          id,
          ...newComments,
          changedPoint: parseInt(newComments.changedPoint, 10),
          files: base
        },
        evaluationType
      );

      if (base.length > 0) {
        setChosenFiles((prev) => [
          ...prev,
          ...data.map((elm) => ({
            id: elm.id,
            name: elm.name.split('_-_-_')[0],
            file: elm.name
          }))
        ]);
        toast.success('Requerimento atualizado!');
      } else {
        toast.success(data);
      }
      setHasChangedAlert(false);
      setOpenModalComments(false);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a atualização.');
    } finally {
      setLoadingComments(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const choiceFiles = (event) => {
    const filesInput = event?.target?.files;
    if (filesInput.length === 0) {
      toast.error('Escolha os arquivos');
    } else {
      setFiles(filesInput);
    }
  };

  return (
    <S.Container>
      <S.Line>
        <S.Marker>{numericMarkers}</S.Marker>
        <S.Question>
          <S.Text>{text}</S.Text>
          <S.Details>
            <Row>
              <Col xs="6">
                <S.TitleDetails>
                  <S.TitleText>Grau de conformidade: </S.TitleText>
                  <S.Bold>{calculateDegree()}</S.Bold>
                </S.TitleDetails>
              </Col>
              <Col xs="6">
                <Row>
                  <Col xs="6">
                    <S.TitleDetails>
                      <S.TitleText>Pontuação: </S.TitleText>
                      <S.Bold>{calculatePoints()}</S.Bold>
                    </S.TitleDetails>
                  </Col>
                  <Col xs="6">
                    <S.TitleDetails>
                      <S.TitleText>Tipo: </S.TitleText>
                      <S.Bold>
                        <i className={showTypeIcon(typeRequirement, true).icon} /> -{' '}
                        {showTypeIcon(typeRequirement, true).name}
                      </S.Bold>
                    </S.TitleDetails>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <S.HR />
              </Col>
              <Col xs="6">
                {lastAccreditation !== null ? (
                  <S.LastAccreditation>
                    <span>* {prepareScope(true)}</span>
                  </S.LastAccreditation>
                ) : null}
                <Select
                  label="Escopo"
                  name="scope"
                  list={optionsScope}
                  isMulti={false}
                  onChangeOption={onChangeScope}
                  defaultValue={prepareScope()}
                  userEvaluatorObserver={userEvaluatorObserver}
                />
              </Col>
              <Col xs="6">
                {lastAccreditation !== null ? (
                  <S.LastAccreditation>
                    <span>* {prepareDeploymentTime(true)}</span>
                  </S.LastAccreditation>
                ) : null}
                <Select
                  label="Tempo de implantação"
                  name="degreeOfCompliance"
                  list={OPTIONS_DEPLOYMENT_TIME}
                  isMulti={false}
                  onChangeOption={onChangeTime}
                  defaultValue={prepareDeploymentTime()}
                  userEvaluatorObserver={userEvaluatorObserver}
                />
              </Col>
            </Row>
          </S.Details>
        </S.Question>
        <S.Actions>
          <Button
            title={<i className="tim-icons icon-simple-add" />}
            color="link"
            onClick={() => setOpenModalComments(true)}
          />
          <Button
            title={<i className="tim-icons icon-paper" />}
            color="link"
            onClick={() => setOenModalEvidence(true)}
          />
          <Button
            title={<i className="tim-icons icon-chat-33" />}
            color="link"
            onClick={() => setOpenModalInterpretation(true)}
          />
          {groupId === GROUPS.ADMIN && (
            <Button
              title={<i className="tim-icons icon-watch-time" />}
              color="link"
              onClick={() => refModalRequirements.current.changeVisibleModal(true)}
            />
          )}
        </S.Actions>
      </S.Line>
      <LogRequirementItems idType={id} ref={refModalRequirements} evaluationType={evaluationType} />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_COMMENT}
        idType={id}
        ref={refModalComments}
        toggle={() => setOpenModalComments(true)}
      />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_EVIDENCE}
        idType={id}
        ref={refModalEvidence}
        toggle={() => setOpenModalComments(true)}
      />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_FEEDBACK}
        idType={id}
        ref={refModalFeedback}
        toggle={() => setOpenModalComments(true)}
      />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_IMPROVEMENT_OPPORTUNITY}
        idType={id}
        ref={refModalImprovementOpportunity}
        toggle={() => setOpenModalComments(true)}
      />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_STRONG_POINT}
        idType={id}
        ref={refModalStrongPoint}
        toggle={() => setOpenModalComments(true)}
      />
      <RequirementComments
        type={TYPES_LOGS.REQUIREMENTS_ITEMS_NON_ATTENDANCE}
        idType={id}
        ref={refModalNonAttendance}
        toggle={() => setOpenModalComments(true)}
      />
      <Modal
        isOpen={openModalEvidence}
        toggle={() => setOenModalEvidence(false)}
        title="Evidências"
      >
        <S.ModalContainer>{evidenceTip}</S.ModalContainer>
      </Modal>
      <Modal
        isOpen={openModalInterpretation}
        toggle={() => setOpenModalInterpretation(false)}
        title="Interpretação"
      >
        <S.ModalContainer>{interpretationTip}</S.ModalContainer>
      </Modal>
      <Modal
        isOpen={openModalComments}
        toggle={() => {
          if (hasChangedAlert) {
            alert('Você fez alterações e não salvou');
          } else {
            setOpenModalComments(false);
          }
        }}
        title="Comentários"
      >
        <S.ModalContainer>
          {lastAccreditation !== null ? (
            <S.LastAccreditation modal>
              <Button
                title={`${collapseComments ? 'Ocultar' : 'Exibir'} dados da última Acreditação`}
                color="tertiary"
                onClick={() => setCollapseComments(!collapseComments)}
              />
            </S.LastAccreditation>
          ) : null}
          <L.Form schema={validateForm} onSubmit={confirm} initialData={commentsForm}>
            <Row>
              <Col xs="12">
                <L.InputContainer>
                  <L.Input
                    label={
                      <>
                        <S.BtnLogContainer>
                          <label>Comentário</label>
                          {groupId === GROUPS.ADMIN && (
                            <Button
                              title={<i className="tim-icons icon-watch-time" />}
                              color="link"
                              onClick={() => {
                                refModalComments.current.changeVisibleModal(true);
                                setOpenModalComments(false);
                              }}
                            />
                          )}
                        </S.BtnLogContainer>
                      </>
                    }
                    name="comment"
                    rows="3"
                    multiline
                    maxLength={COUNTER_LIMIT}
                    disabled={userEvaluatorObserver}
                    onChange={(event) => {
                      setCountSize({ ...countSize, comment: event?.target?.value?.length });
                      setHasChangedAlert(true);
                    }}
                  />
                  <L.InputCountContainer>
                    {countSize.comment} / {COUNTER_LIMIT}
                  </L.InputCountContainer>
                  <Collapse isOpen={collapseComments}>
                    <S.LastAccreditation color="#498a50">
                      <span>{lastAccreditation?.comment || '---'}</span>
                      <hr className="separator" />
                    </S.LastAccreditation>
                  </Collapse>
                </L.InputContainer>
              </Col>
              <Col xs="12">
                <L.InputContainer>
                  <L.Input
                    label={
                      <>
                        <S.BtnLogContainer>
                          <label>Evidências</label>
                          {groupId === GROUPS.ADMIN && (
                            <Button
                              title={<i className="tim-icons icon-watch-time" />}
                              color="link"
                              onClick={() => {
                                refModalEvidence.current.changeVisibleModal(true);
                                setOpenModalComments(false);
                              }}
                            />
                          )}
                        </S.BtnLogContainer>
                      </>
                    }
                    name="evidence"
                    rows="3"
                    multiline
                    maxLength={COUNTER_LIMIT}
                    disabled={userEvaluatorObserver}
                    onChange={(event) => {
                      setCountSize({ ...countSize, evidence: event?.target?.value?.length });
                      setHasChangedAlert(true);
                    }}
                  />
                  <L.InputCountContainer>
                    {countSize.evidence} / {COUNTER_LIMIT}
                  </L.InputCountContainer>
                  <Collapse isOpen={collapseComments}>
                    <S.LastAccreditation color="#498a50">
                      <span>{lastAccreditation?.evidence || '---'}</span>
                      <hr className="separator" />
                    </S.LastAccreditation>
                  </Collapse>
                </L.InputContainer>
              </Col>
              {RNType === RN_507_TYPES.SELF_EVALUATION && groupId === GROUPS.ADMIN ? (
                <>
                  <Col xs="12">
                    <L.InputContainer>
                      <L.Input
                        label={
                          <>
                            <S.BtnLogContainer>
                              <label>Feedback</label>
                              {groupId === GROUPS.ADMIN && (
                                <Button
                                  title={<i className="tim-icons icon-watch-time" />}
                                  color="link"
                                  onClick={() => {
                                    refModalFeedback.current.changeVisibleModal(true);
                                    setOpenModalComments(false);
                                  }}
                                />
                              )}
                            </S.BtnLogContainer>
                          </>
                        }
                        name="feedback"
                        rows="3"
                        multiline
                        maxLength={COUNTER_LIMIT}
                        onChange={(event) => {
                          setCountSize({ ...countSize, feedback: event?.target?.value?.length });
                          setHasChangedAlert(true);
                        }}
                      />
                      <L.InputCountContainer>
                        {countSize.feedback} / {COUNTER_LIMIT}
                      </L.InputCountContainer>
                      <Collapse isOpen={collapseComments}>
                        <S.LastAccreditation color="#498a50">
                          <span>{lastAccreditation?.feedback || '---'}</span>
                          <hr className="separator" />
                        </S.LastAccreditation>
                      </Collapse>
                    </L.InputContainer>
                  </Col>
                  <Col xs="12">
                    <S.InputFileContainer>
                      <L.InputContainer>
                        <L.Select
                          label="Mudou a pontuação?"
                          name="changedPoint"
                          options={options}
                        />
                      </L.InputContainer>
                    </S.InputFileContainer>
                  </Col>
                </>
              ) : null}

              {RNType !== RN_507_TYPES.SELF_EVALUATION && (
                <>
                  <Col xs="12">
                    <L.InputContainer>
                      <L.Input
                        label={
                          <>
                            <S.BtnLogContainer>
                              <label>Oportunidade de melhoria</label>
                              {groupId === GROUPS.ADMIN && (
                                <Button
                                  title={<i className="tim-icons icon-watch-time" />}
                                  color="link"
                                  onClick={() => {
                                    refModalImprovementOpportunity.current.changeVisibleModal(true);
                                    setOpenModalComments(false);
                                  }}
                                />
                              )}
                            </S.BtnLogContainer>
                          </>
                        }
                        name="improvementOpportunity"
                        rows="3"
                        multiline
                        maxLength={300}
                        disabled={userEvaluatorObserver}
                        onChange={(event) => {
                          setCountSize({
                            ...countSize,
                            improvementOpportunity: event?.target?.value?.length
                          });
                          setHasChangedAlert(true);
                        }}
                      />
                      <L.InputCountContainer>
                        {countSize.improvementOpportunity} / {300}
                      </L.InputCountContainer>
                      <Collapse isOpen={collapseComments}>
                        <S.LastAccreditation color="#498a50">
                          <span>{lastAccreditation?.improvementOpportunity || '---'}</span>
                          <hr className="separator" />
                        </S.LastAccreditation>
                      </Collapse>
                    </L.InputContainer>
                  </Col>
                  <Col xs="12">
                    <L.InputContainer>
                      <L.Input
                        label={
                          <>
                            <S.BtnLogContainer>
                              <label>Ponto Forte</label>
                              {groupId === GROUPS.ADMIN && (
                                <Button
                                  title={<i className="tim-icons icon-watch-time" />}
                                  color="link"
                                  onClick={() => {
                                    refModalStrongPoint.current.changeVisibleModal(true);
                                    setOpenModalComments(false);
                                  }}
                                />
                              )}
                            </S.BtnLogContainer>
                          </>
                        }
                        name="strongPoint"
                        rows="3"
                        multiline
                        maxLength={300}
                        disabled={userEvaluatorObserver}
                        onChange={(event) => {
                          setCountSize({ ...countSize, strongPoint: event?.target?.value?.length });
                          setHasChangedAlert(true);
                        }}
                      />
                      <L.InputCountContainer>
                        {countSize.strongPoint} / {300}
                      </L.InputCountContainer>
                      <Collapse isOpen={collapseComments}>
                        <S.LastAccreditation color="#498a50">
                          <span>{lastAccreditation?.strongPoint || '---'}</span>
                          <hr className="separator" />
                        </S.LastAccreditation>
                      </Collapse>
                    </L.InputContainer>
                  </Col>
                  <Col xs="12">
                    <L.InputContainer>
                      <L.Input
                        label={
                          <>
                            <S.BtnLogContainer>
                              <label>Não atendimento</label>
                              {groupId === GROUPS.ADMIN && (
                                <Button
                                  title={<i className="tim-icons icon-watch-time" />}
                                  color="link"
                                  onClick={() => {
                                    refModalNonAttendance.current.changeVisibleModal(true);
                                    setOpenModalComments(false);
                                  }}
                                />
                              )}
                            </S.BtnLogContainer>
                          </>
                        }
                        name="nonAttendance"
                        rows="3"
                        multiline
                        maxLength={300}
                        disabled={userEvaluatorObserver}
                        onChange={(event) => {
                          setCountSize({
                            ...countSize,
                            nonAttendance: event?.target?.value?.length
                          });
                          setHasChangedAlert(true);
                        }}
                      />
                      <L.InputCountContainer>
                        {countSize.nonAttendance} / {300}
                      </L.InputCountContainer>
                      <Collapse isOpen={collapseComments}>
                        <S.LastAccreditation color="#498a50">
                          <span>{lastAccreditation?.nonAttendance || '---'}</span>
                          <hr className="separator" />
                        </S.LastAccreditation>
                      </Collapse>
                    </L.InputContainer>
                  </Col>
                </>
              )}
              {RNType === RN_507_TYPES.SELF_EVALUATION && groupId === GROUPS.COMPANY_USER ? (
                <Col xs="12">
                  <S.InputFileContainer>
                    <input type="file" id="files" name="files" onChange={choiceFiles} multiple />
                  </S.InputFileContainer>
                </Col>
              ) : null}
              {RNType === RN_507_TYPES.SELF_EVALUATION &&
              (groupId === GROUPS.ADMIN || groupId === GROUPS.COMPANY_USER) ? (
                <Col xs="12">
                  <S.InputFileContainer>
                    {chosenFiles.length > 0 && <S.TitleText>Arquivos:</S.TitleText>}
                    {chosenFiles.map((elm) => (
                      <FileView
                        key={uniqId()}
                        name={elm.name}
                        file={elm.file}
                        id={elm.id}
                        loadingDelete={loadingDelete}
                        deleteFile={deleteFile}
                      />
                    ))}
                  </S.InputFileContainer>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs="4">
                <L.ButtonContainer>
                  <Button title="Salvar" type="submit" loading={loadingComments} block disabled={userEvaluatorObserver}/>
                </L.ButtonContainer>
              </Col>
            </Row>
          </L.Form>
        </S.ModalContainer>
      </Modal>
    </S.Container>
  );
};

RequirementItem.defaultProps = {
  id: '',
  numericMarkers: '',
  text: '',
  typeRequirement: '',
  evidenceTip: '',
  feedback: '',
  interpretationTip: '',
  scope: null,
  deploymentTime: null,
  changedPoint: null,
  serverFiles: [],
  chosenDeploymentTime: () => {},
  chosenScope: () => {},
  lastAccreditation: null
};

RequirementItem.propTypes = {
  id: PropTypes.number,
  numericMarkers: PropTypes.string,
  text: PropTypes.string,
  typeRequirement: PropTypes.number,
  evidenceTip: PropTypes.string,
  interpretationTip: PropTypes.string,
  feedback: PropTypes.string,
  scope: PropTypes.bool,
  deploymentTime: PropTypes.number,
  changedPoint: PropTypes.any,
  serverFiles: PropTypes.any,
  chosenDeploymentTime: PropTypes.func,
  chosenScope: PropTypes.func,
  lastAccreditation: PropTypes.any
};

export default RequirementItem;
