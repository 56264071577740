import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Modal } from '~/components';
import { GetLogs } from '~/services/private';
import { Table, ModalContainer, ModalContent, ModalLoading } from './styles';
import { TYPES_LOGS, GROUPS_NAME } from '~/constants';

const MonitoredIndicators = forwardRef(({ idType }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  const changeVisibleModal = async (active) => {
    setOpenModal(active);
    if (active) {
      try {
        setLoading(true);
        const { data } = await GetLogs({
          type: TYPES_LOGS.MONITORED_INDICATORS,
          idType
        });
        setLogs(data);
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao capturar o LOG.');
      } finally {
        setLoading(false);
      }
    }
  };

  const prepare = (value) => {
    if (value === null) return '-';
    if (value === '1') return 'Sim';
    if (value === '0') return 'Não';
    return '';
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Log"
        unmountOnClose={false}
      >
        <ModalContainer>
          <ModalContent>
            {loading ? (
              <ModalLoading>
                <Spinner size="sm" animation="border" />
              </ModalLoading>
            ) : (
              <Table>
                <tbody>
                  <tr>
                    <th className="th-title">Usuário</th>
                    <th className="th-title">Perfil</th>
                    <th className="th-title">Antes</th>
                    <th className="th-title">Depois</th>
                    <th className="th-title">Horário</th>
                  </tr>
                  {logs.map((elm, ind) => (
                    <tr key={`table-list-meeting-${ind}`}>
                      <td className="center">{elm.userDetails.name}</td>
                      <td className="center">{GROUPS_NAME[parseInt(elm.groupId, 10)]}</td>
                      <td className="center">{prepare(elm.beforeChange)}</td>
                      <td className="center">{prepare(elm.afterChange)}</td>
                      <td className="center">{elm.createdDate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

MonitoredIndicators.defaultProps = {
  idType: null
};

MonitoredIndicators.propTypes = {
  idType: PropTypes.any
};

export default MonitoredIndicators;
