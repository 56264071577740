import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import * as S from './styles';
import { showTypeIcon } from '~/config/helpers';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';
import { EVALUATION_TYPE } from '~/constants';

const TableRequirements = ({ itemElm, evaluationType }) => {
  const showDeploymentTimeRN507 = (deploymentTime) => {
    switch (deploymentTime) {
      case 1:
        return '1 Mês';
      case 13:
        return '+12 Meses';
      default:
        return `${deploymentTime} Meses`;
    }
  };

  const showDeploymentTimeRN506 = (deploymentTime) => {
    switch (deploymentTime) {
      case 1:
        return '1 Mês';
      case 7:
        return '+6 Meses';
      default:
        return `${deploymentTime} Meses`;
    }
  };

  const showDeploymentTime = (deploymentTime) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return showDeploymentTimeRN507(deploymentTime);
      case EVALUATION_TYPE.RN_506:
        return showDeploymentTimeRN506(deploymentTime);
      default:
        return `${deploymentTime} Meses`;
    }
  };

  return (
    <S.Table bottomMarginMin requirementsTable>
      <tbody>
        <tr>
          <td colSpan="5" className="td-title left">
            {`${itemElm.details.numericMarkers} - ${itemElm.details.text}`}
          </td>
        </tr>
        <tr>
          <td className="td-title">Grau de Conformidade:</td>
          <td>{itemElm.degreeOfCompliance ? 'Conforme' : 'Não Conforme'}</td>
          <td className="td-title">Pontuação:</td>
          <td>{itemElm.pointing}</td>
          <td className="td-title --icon-type" rowSpan={2}>
            <i className={showTypeIcon(itemElm.details.type)} />
          </td>
        </tr>
        <tr>
          <td className="td-title">Escopo:</td>
          <td>{itemElm.scope ? 'Atendido' : 'Não Atendido'}</td>
          <td className="td-title">Tempo de implantação:</td>
          <td>{showDeploymentTime(itemElm.deploymentTime)}</td>
        </tr>

        <tr className={`is-comments ${itemElm.feedback ? '--has-feedback' : ''}`}>
          <td colSpan="5">
            <span>Comentários:</span>
            <br />
            {itemElm.comment}
          </td>
        </tr>

        <tr className={`is-comments ${itemElm.feedback ? '--has-feedback' : ''}`}>
          <td colSpan="5">
            <span>Evidências:</span>
            <br />
            {itemElm.evidence}
          </td>
        </tr>

        {itemElm.improvementOpportunity && (
          <tr className={`is-comments ${itemElm.improvementOpportunity ? '--has-feedback' : ''}`}>
            <td colSpan="5">
              <span>Oportunidades de Melhoria:</span>
              <br />
              {itemElm.improvementOpportunity}
            </td>
          </tr>
        )}

        {itemElm.nonAttendance && (
          <tr className={`is-comments ${itemElm.nonAttendance ? '--has-feedback' : ''}`}>
            <td colSpan="5">
              <span>Não Atendimento:</span>
              <br />
              {itemElm.nonAttendance}
            </td>
          </tr>
        )}

        {itemElm.strongPoint && (
          <tr className={`is-comments ${itemElm.strongPoint ? '--has-feedback' : ''}`}>
            <td colSpan="5">
              <span>Pontos Fortes:</span>
              <br />
              {itemElm.strongPoint}
            </td>
          </tr>
        )}

        {itemElm.feedback && (
          <tr className="is-comments --has-feedback">
            <td colSpan="5">
              <span>Feedback:</span>
              <span className="--no-underline">
                {itemElm.changedPoint ? ' (Este feedback ocasionou a mudança da pontuação)' : ''}
              </span>
              <br />
              {itemElm.feedback}
            </td>
          </tr>
        )}
      </tbody>
    </S.Table>
  );
};

const Requirements = ({
  separatorRequirements,
  startPage,
  totalPages,
  editionReport,
  evaluationType
}) => {
  let count = startPage;

  return (
    <>
      {separatorRequirements.map((elm) => {
        return elm.items.map((itemElm) => (
          <S.CoverPage key={uniqId()}>
            <S.CoverPageHeader>
              <S.CoverPageLogo>
                <img src={logo} alt="react-logo" />
              </S.CoverPageLogo>
            </S.CoverPageHeader>
            <S.Table bottomMargin>
              <tbody>
                <tr>
                  <th className="th-title" colSpan="4" scope="row">
                    {elm.title}
                  </th>
                </tr>
              </tbody>
            </S.Table>

            <TableRequirements itemElm={itemElm[0]} evaluationType={evaluationType} />

            {itemElm[1] && (
              <TableRequirements itemElm={itemElm[1]} evaluationType={evaluationType} />
            )}
            <FooterReport
              editionReport={editionReport}
              actualPage={count++}
              totalPage={totalPages}
            />
          </S.CoverPage>
        ));
      })}
    </>
  );
};

Requirements.defaultProps = {
  separatorRequirements: [],
  startPage: 0,
  evaluationType: 0,
  totalPages: 0,
  editionReport: ''
};

Requirements.propTypes = {
  separatorRequirements: PropTypes.any,
  evaluationType: PropTypes.number,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default Requirements;
