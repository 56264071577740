import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 6px;

  margin-bottom: 3px;
  h5 {
    margin-bottom: 0px !important;
  }
  &:hover {
    -webkit-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    -moz-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ItHas = styled.div`
  margin-right: 10px;
`;

export const Marker = styled.h5`
  font-size: 14px;
  margin-right: 5px;
  font-weight: bold;
`;

export const CollapseContainer = styled.div`
  padding-left: 0px;
`;

export const Question = styled.h5`
  width: 100%;
  font-size: 14px;
`;
