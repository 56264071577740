import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { ListGroup, ListGroupItem, Badge, Spinner } from 'reactstrap';
import * as S from './style';

const UploadList = ({ data }) => {
  const { loadingUpload } = useSelector((store) => store.tools);

  return (
    <S.Container>
      <ListGroup>
        {data.length > 0 ? (
          data.map((elm) => (
            <ListGroupItem key={elm.name} className="justify-content-between">
              <S.Content>
                <S.ContentFilesList>
                  <S.NameFolder>{elm.name}</S.NameFolder>
                  {elm.images.map((img) => (
                    <S.NameFile key={img.name}>- {img.name}</S.NameFile>
                  ))}
                </S.ContentFilesList>
                <S.ContentAction>
                  {loadingUpload[elm.name]?.loading ? (
                    <Spinner size="sm" color="white" animation="border" />
                  ) : null}
                  {loadingUpload[elm.name]?.complete && !loadingUpload[elm.name]?.loading ? (
                    <Badge pill color="info">
                      <i className="tim-icons icon-check-2" />
                    </Badge>
                  ) : null}
                </S.ContentAction>
              </S.Content>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem>Nenhuma pasta selecionada</ListGroupItem>
        )}
      </ListGroup>
    </S.Container>
  );
};

UploadList.defaultProps = {
  data: []
};

UploadList.propTypes = {
  data: PropTypes.array
};

export default UploadList;
