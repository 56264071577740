import React from 'react';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="https://a4quality.com">A4Quality HealthCare</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://a4quality.com/conheca-a4quality/">Quem somos</NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} feito com <i className="tim-icons icon-heart-2" /> pela{' '}
          <a href="https://a4quality.com" target="_blank" rel="noopener noreferrer">
            A4Quality.
          </a>{' '}
          Baseado no{' '}
          <a
            href="https://github.com/creativetimofficial/black-dashboard-react"
            target="_blank"
            rel="noopener noreferrer"
          >
            Black Dashboard React
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
