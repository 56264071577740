import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { evaluatorSetLoading, setEvaluators } from './actions';
import { EVALUATORS_REQUEST } from './patterns';
import { GetEvaluators } from '~/services/private';

function* getEvaluators() {
  try {
    const { data } = yield call(GetEvaluators);
    yield put(setEvaluators({ evaluators: data }));
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(evaluatorSetLoading(false));
  }
}

export default all([takeLatest(EVALUATORS_REQUEST, getEvaluators)]);
