import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setEvaluations, evaluationsSetLoading } from './actions';
import { EVALUATIONS_REQUEST } from './patterns';
import { GetEvaluations } from '~/services/private';

function* getEvaluations(action) {
  try {
    const { classification, evaluationType, RNType } = action;

    const { data } = yield call(() => GetEvaluations(evaluationType, classification, RNType));
    yield put(setEvaluations({ evaluations: data }));
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(evaluationsSetLoading(false));
  }
}

export default all([takeLatest(EVALUATIONS_REQUEST, getEvaluations)]);
