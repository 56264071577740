import { parse, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import store from '~/store';
import {
  EVALUATION_TYPE,
  RN_506_CLASSIFICATION_NAME,
  RN_506_TYPES,
  RN_507_CLASSIFICATION_NAME,
  RN_507_TYPES,
  TYPES_REQUIREMENTS
} from '~/constants';

export const timeZone = 'UTC';

export const setTimeZone = (strDate) => utcToZonedTime(strDate, timeZone);

export const parseDateISO = (strDate, formatMask = 'dd/MM/yyyy') =>
  parse(strDate, formatMask, 0, { locale: ptBR, timeZone });

export const formatDate = (strDate, formatMask = 'dd/MM/yyyy', locale = ptBR) =>
  format(setTimeZone(strDate), formatMask, { locale, timeZone });

export const isEvenNumber = (val) => val % 2 === 0;

export const scroll = (id) => {
  const element = document.getElementById(id || 'm-body');
  if (element)
    if (id) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      element.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
};

export const joinEvaluators = (evaluatorsDetails, evaluatorsAdminDetails) => {
  const evaluators = evaluatorsDetails.map((elm) => elm.name);
  const admins = evaluatorsAdminDetails.map((elm) => elm.name);
  const array = [...evaluators, ...admins];
  return array.join(' - ');
};

export const mask = (val, theMask) => {
  let masked = '';
  if (val === '') return masked;
  let k = 0;
  for (let i = 0; i <= theMask.length - 1; i++) {
    if (theMask[i] === '#') {
      if (val[k]) {
        masked += val[k++];
      }
    } else if (theMask[i]) {
      masked += theMask[i];
    }
  }
  return masked;
};

export const showBasedOnGroup = (groups) => {
  const { user } = store.getState().auth;
  return groups.includes(user.account.groupId);
};

export const showTypeIcon = (type, details = false) => {
  switch (type) {
    case TYPES_REQUIREMENTS.EXCELLENCE:
      return details ? { icon: 'fas fa-medal', name: 'Excelência' } : 'fas fa-medal';
    case TYPES_REQUIREMENTS.ESSENTIAL:
      return details ? { icon: 'fas fa-bullseye', name: 'Essencial' } : 'fas fa-bullseye';
    case TYPES_REQUIREMENTS.COMPLEMENTARY:
      return details ? { icon: 'fab fa-medapps', name: 'Complementar' } : 'fab fa-medapps';
    default:
      return '';
  }
};

export const alphabeticallySort = (array, isArrayOfObjects = false, elm = null) => {
  if (isArrayOfObjects) {
    return array.sort((a, b) => {
      if (a[elm] < b[elm]) {
        return -1;
      }
      if (a[elm] > b[elm]) {
        return 1;
      }
      return 0;
    });
  }
  return array.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
};

export const calculateLinesForTexts = (text) => {
  if (!text) return 0;
  if (text.includes('\n')) {
    const lines = text?.split('\n');
    let total = 0;
    lines.forEach((line) => {
      const totalLinesGroup = Math.ceil(line.length / 110);
      total += totalLinesGroup;
    });
    return total;
  }
  return Math.ceil(text.length / 110);
};

export const calculatePages = (
  accreditation,
  startCountPageBeforeDimensions,
  startCountPage,
  startCountDimension,
  stateTotalPages,
  setTotalPages,
  onlyGaps = false,
  withStrongPoints = false
) => {
  const objDefault = accreditation?.dimensionsList.reduce(
    (acc, elm, index) => ({
      ...acc,
      [elm]: {
        start: startCountPageBeforeDimensions, // Qtd de páginas antes de começar a listagem das dimensões.
        total: 0,
        separatorRequirements: [],
        before: index > 0 ? accreditation?.dimensionsList[index - 1] : null
      }
    }),
    {}
  );

  let totalAllPages = startCountPage; // Já contém as anteriores a listagem das dimensões.
  // eslint-disable-next-line
  accreditation.dimensionsList.map((dimension) => {
    const requirements = Object.keys(accreditation.dimensions[dimension].requirements).map(
      (key) => accreditation.dimensions[dimension].requirements[key]
    );

    let totalDimension = dimension === 1 ? startCountDimension + 1 : startCountDimension; // Capa + Indicadores inclusos
    // let totalDimension = startCountDimension; // Capa + Indicadores inclusos

    // eslint-disable-next-line
    requirements.map((requirement) => {
      const obj = {
        title: `${requirement.numericMarkers} ${requirement.text}`,
        items: []
      };

      const requirementsList = Object.create(requirement.items);

      // Exibição apenas de items não conformes e com OP ( Para a PRE)

      if (onlyGaps) {
        if (withStrongPoints) {
          const newRequirementsList = requirementsList.filter(
            (elm) => !elm.degreeOfCompliance || elm.improvementOpportunity || elm.strongPoint
          );

          while (newRequirementsList.length > 0) {
            obj.items.push(newRequirementsList.splice(0, 2));
          }
        } else {
          const newRequirementsList = requirementsList.filter(
            (elm) => !elm.degreeOfCompliance || elm.improvementOpportunity
          );

          while (newRequirementsList.length > 0) {
            obj.items.push(newRequirementsList.splice(0, 2));
          }
        }
      } else {
        while (requirementsList.length > 0) {
          obj.items.push(requirementsList.splice(0, 2));
        }
      }

      totalDimension += obj.items.length;
      objDefault[dimension].separatorRequirements.push(obj);
    });

    objDefault[dimension].total = totalDimension;

    if (objDefault[dimension]?.before) {
      const { before } = objDefault[dimension];
      objDefault[dimension].start = objDefault[before].start + objDefault[before].total;
    }

    totalAllPages += totalDimension;
  });

  if (stateTotalPages === 0) setTotalPages(totalAllPages);

  return objDefault;
};

// export const calculatePagesForLines = (
//   evaluation,
//   startCountPageBeforeDimensions,
//   startCountPage,
//   startCountDimension,
//   stateTotalPages,
//   setTotalPages,
//   onlyGaps = false
// ) => {
//   const objDefault = evaluation?.dimensionsList.reduce(
//     (acc, elm, index) => ({
//       ...acc,
//       [elm]: {
//         start: startCountPageBeforeDimensions, // Qtd de páginas antes de começar a listagem das dimensões.
//         total: 0,
//         separatorRequirements: [],
//         before: index > 0 ? evaluation?.dimensionsList[index - 1] : null
//       }
//     }),
//     {}
//   );
//
//   let totalAllPages = startCountPage; // Já contém as anteriores a listagem das dimensões.
//   // eslint-disable-next-line
//   evaluation.dimensionsList.map((dimension) => {
//     const requirements = Object.keys(evaluation.dimensions[dimension].requirements).map(
//       (key) => evaluation.dimensions[dimension].requirements[key]
//     );
//
//     let totalDimension = startCountDimension; // Capa + Indicadores inclusos
//
//     // eslint-disable-next-line
//     requirements.map((requirement) => {
//       const obj = {
//         title: `${requirement.numericMarkers} ${requirement.text}`,
//         items: []
//       };
//
//       const requirementsList = Object.create(requirement.items);
//
//       const groupItems = [];
//       const countTemp = 0;
//
//       requirementsList.map((elm) => {
//         const commentLines = calculateLinesForTexts(elm.comment);
//         const evidenceLines = calculateLinesForTexts(elm.evidence);
//         const feedbackLines = calculateLinesForTexts(elm.feedback);
//         const strongPointLines = calculateLinesForTexts(elm.strongPoint);
//         const nonAttendanceLines = calculateLinesForTexts(elm.nonAttendance);
//
//         const total =
//           commentLines + evidenceLines + feedbackLines + strongPointLines + nonAttendanceLines;
//         // q;
//       });
//
//       // Exibição apenas de items não conformes e com OP ( Para a PRE)
//
//       if (onlyGaps) {
//         const newRequirementsList = requirementsList.filter(
//           (elm) => !elm.degreeOfCompliance || elm.improvementOpportunity
//         );
//
//         while (newRequirementsList.length > 0) {
//           obj.items.push(newRequirementsList.splice(0, 2));
//         }
//       } else {
//         while (requirementsList.length > 0) {
//           obj.items.push(requirementsList.splice(0, 2));
//         }
//       }
//
//       totalDimension += obj.items.length;
//       objDefault[dimension].separatorRequirements.push(obj);
//     });
//
//     objDefault[dimension].total = totalDimension;
//
//     if (dimension > 1) {
//       const { before } = objDefault[dimension];
//       objDefault[dimension].start = objDefault[before].start + objDefault[before].total;
//     }
//
//     totalAllPages += totalDimension;
//   });
//
//   if (stateTotalPages === 0) setTotalPages(totalAllPages);
//
//   return objDefault;
// };

export const textPageEvaluations = (RNType, type, isClient = false, classification) => {
  switch (parseInt(RNType, 10)) {
    case EVALUATION_TYPE.RN_507:
      switch (parseInt(type, 10)) {
        case RN_507_TYPES.PRE:
          return {
            minTitle: `Módulo RN-507`,
            title: `Pré Avaliação Diagnóstica (${RN_507_CLASSIFICATION_NAME[classification]})`,
            text: `Abaixo estão listadas todas as pré avaliações cadastradas no sistema.`,
            btnText: 'Cadastrar Pré Avaliação'
          };
        case RN_507_TYPES.SUPERVISION:
          return {
            minTitle: `Módulo RN-507`,
            title: `Supervisão (${RN_507_CLASSIFICATION_NAME[classification]})`,
            text: 'Abaixo estão listadas todas as avaliações de supervisão cadastradas no sistema.',
            btnText: 'Cadastrar Supervisão'
          };
        case RN_507_TYPES.ACCREDITATION:
          return {
            minTitle: `Módulo RN-507`,
            title: `Acreditação (${RN_507_CLASSIFICATION_NAME[classification]})`,
            text:
              'Abaixo estão listadas todas as avaliações cadastradas no sistema seprando por status.',
            btnText: 'Cadastrar Acreditação'
          };
        case RN_507_TYPES.SELF_EVALUATION:
          return {
            minTitle: `Módulo RN-507`,
            title: `Autoavaliação (${RN_507_CLASSIFICATION_NAME[classification]})`,
            text: `Abaixo estão listadas todas as ${
              isClient ? 'suas autoavaliações.' : ' autoavaliações vinculadas aos clientes.'
            }`,
            btnText: 'Cadastrar Autoavaliação'
          };
        default:
          return {
            title: ''
          };
      }
    case EVALUATION_TYPE.RN_506:
      switch (parseInt(type, 10)) {
        case RN_506_TYPES.PRE:
          return {
            minTitle: `Módulo RN-506`,
            title: `Pré Avaliação Diagnóstica (${RN_506_CLASSIFICATION_NAME[classification]})`,
            text: `Abaixo estão listadas todas as pré avaliações cadastradas no sistema.`,
            btnText: 'Cadastrar Pré Avaliação'
          };
        case RN_506_TYPES.SUPERVISION:
          return {
            minTitle: `Módulo RN-506`,
            title: `Supervisão (${RN_506_CLASSIFICATION_NAME[classification]})`,
            text: 'Abaixo estão listadas todas as avaliações de supervisão cadastradas no sistema.',
            btnText: 'Cadastrar Supervisão'
          };
        case RN_506_TYPES.CERTIFICATION:
          return {
            minTitle: `Módulo RN-506`,
            title: `Certificação (${RN_506_CLASSIFICATION_NAME[classification]})`,
            text:
              'Abaixo estão listadas todas as avaliações cadastradas no sistema separando por status.',
            btnText: 'Cadastrar Certificação'
          };
        case RN_507_TYPES.SELF_EVALUATION:
          return {
            minTitle: `Módulo RN-506`,
            title: `Autoavaliação (${RN_506_CLASSIFICATION_NAME[classification]})`,
            text: `Abaixo estão listadas todas as ${
              isClient ? 'suas autoavaliações.' : ' autoavaliações vinculadas aos clientes.'
            }`,
            btnText: 'Cadastrar Autoavaliação'
          };
        default:
          return {
            title: ''
          };
      }
    default:
      return {
        title: ''
      };
  }
};
