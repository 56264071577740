import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import logo from '~/assets/img/gear.png';
import * as S from './styles';

const Coming = () => {
  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Administrativo</h5>
                  <CardTitle tag="h2">Em breve...</CardTitle>
                  <h5>Este fluxo está em desenvolvimento e em breve estará disponível.</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <img src={logo} alt="react-logo" />
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Coming;
