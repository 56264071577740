import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import InputMask from 'react-input-mask';

const MaskInput = ({ label, name, mask, ...rest }) => {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      clearValue: (pickerRef) => {
        pickerRef.clear();
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputMask ref={ref} name={fieldName} mask={mask} maskChar={null} {...rest} />
      {error && <span>{error}</span>}
    </>
  );
};

MaskInput.defaultProps = {
  label: '',
  name: '',
  mask: ''
};

MaskInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.string
};

export default MaskInput;
