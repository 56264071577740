import React, { useState } from 'react';
import { Card, Collapse } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Button, MonitoredIndicators } from '~/components';

const CollapseIndicators = ({ indicators, changeIndicators, lastAccreditation , userEvaluatorObserver}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        title="⧂ Documentos e Indicadores requeridos"
        color="link"
        onClick={() => setIsOpen(!isOpen)}
      />
      <Collapse isOpen={isOpen}>
        <Card>
          {indicators.map((elm, index) => (
            <MonitoredIndicators
              id={elm.id}
              key={`monitored-${elm.id}`}
              text={elm.text}
              numericMarkers={elm.numericMarkers}
              chosenIndicator={(chosen) => changeIndicators(elm.id, chosen)}
              itHas={elm.itHas}
              lastAccreditation={lastAccreditation && lastAccreditation[index]}
              userEvaluatorObserver={userEvaluatorObserver}
            />
          ))}
        </Card>
      </Collapse>
    </>
  );
};

CollapseIndicators.defaultProps = {
  indicators: [],
  changeIndicators: () => {},
  lastAccreditation: null
};

CollapseIndicators.propTypes = {
  indicators: PropTypes.any,
  changeIndicators: PropTypes.func,
  lastAccreditation: PropTypes.any
};

export default CollapseIndicators;
