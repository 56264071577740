import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  h5 {
    margin-bottom: 0px !important;
  }
  ${(props) =>
    props.hasHover
      ? `
    &:hover {
      -webkit-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
      -moz-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
      box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    }
  `
      : ''}
`;

export const ItHas = styled.div`
  margin-right: 10px;
`;

export const Marker = styled.h5`
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
  color: ${(props) => (props.colorTitle ? props.colorTitle : '#FFF')} !important;
`;

export const Question = styled.h5`
  width: 100%;
  font-size: 14px;
  color: ${(props) => (props.colorTitle ? props.colorTitle : '#FFF')} !important;
`;

export const LastAccreditation = styled.div`
  width: 100%;
  span {
    font-size: 11px;
    color: ${colors.green};
  }
`;

export const Actions = styled.div`
  min-width: 50px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
