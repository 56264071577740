import styled from 'styled-components';

export const Container = styled.div`
  max-width: 240px;
  cursor: pointer;
  position: relative;

  -webkit-box-shadow: 1px 1px 12px -1px rgba(192, 192, 192, 0.29);
  -moz-box-shadow: 1px 1px 12px -1px rgba(192, 192, 192, 0.29);
  box-shadow: 1px 1px 12px -1px rgba(192, 192, 192, 0.29);

  &:hover {
    -webkit-box-shadow: 1px 1px 12px2px rgba(192, 192, 192, 0.29);
    -moz-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);

    // -webkit-box-shadow: 1px 1px 12px 5px rgba(192,192,192, 0.29);
    // -moz-box-shadow: 1px 1px 12px 5px rgba(192,192,192, 0.29);
    // box-shadow: 1px 1px 12px 5px rgba(192,192,192, 0.29);
  }
`;

export const Description = styled.p``;

export const Badge = styled.div`
  position: absolute;
  z-index: 2;
  top: -10px;
  right: -10px;
`;
