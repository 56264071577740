import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  background-color: #000;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    background-color: rgba(39, 41, 61, 0.9);
  }
`;

export const ContainerCard = styled.div`
  padding: 20px 20px 5px 20px;
`;

export const ContainerHeader = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: ${colors.white};
  width: 100%;
  img {
    width: 200px;
  }
`;

export const Video = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
  opacity: 0.6;
`;