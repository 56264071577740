import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setCompanies, companiesSetLoading } from './actions';
import { COMPANIES_REQUEST } from './patterns';
import { GetCompanies } from '~/services/private';

function* getCompanies() {
  try {
    const { data } = yield call(GetCompanies);
    yield put(setCompanies({ companies: data }));
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(companiesSetLoading(false));
  }
}

export default all([takeLatest(COMPANIES_REQUEST, getCompanies)]);
