import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent, ButtonAdd, Table } from './styles';
import { Button, Modal } from '~/components';
import { UpdatePeopleInterviewed } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { alphabeticallySort } from '~/config/helpers';

const PeopleInterviewed = forwardRef(({ evaluationId, people }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [initEditDetails, setInitEditDetails] = useState(false);
  const [dateUpdate, setDateUpdate] = useState('');

  const [peopleState, setPeopleState] = useState(alphabeticallySort(people, true, 'name'));

  const [personName, setPersonName] = useState('');
  const [personOccupation, setPersonOccupation] = useState('');

  const changeVisibleModal = (active) => setOpenModal(active);

  const changePeople = useCallback(async () => {
    try {
      setLoadingDetails(true);
      await UpdatePeopleInterviewed({
        evaluationId,
        people: peopleState
      });
      setDateUpdate(new Date().toLocaleTimeString());
      setInitEditDetails(true);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingDetails(false);
    }
  }, [evaluationId, peopleState]);

  const addPerson = async () => {
    setPeopleState(
      alphabeticallySort(
        [
          ...peopleState,
          {
            id: null,
            name: personName,
            occupation: personOccupation
          }
        ],
        true,
        'name'
      )
    );
    setPersonName('');
    setPersonOccupation('');
  };

  const removePerson = async (index) => {
    setPeopleState(
      alphabeticallySort(
        peopleState.filter((elm, ind) => ind !== index),
        true,
        'name'
      )
    );
  };

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  useEffect(() => {
    setTimeout(changePeople, 300);
  }, [changePeople, peopleState]);

  const LoadingSaving = () => (
    <div>
      {loadingDetails ? (
        <Saving>
          <Spinner size="sm" animation="border" />
          ...salvando
        </Saving>
      ) : null}
      {initEditDetails && !loadingDetails ? (
        <Saving color="#252525">
          <h6>Dados atualizados em: {dateUpdate}</h6>
        </Saving>
      ) : null}
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Pessoas Entrevistadas"
        size="lg"
        modalClassName="modal-meeting-container"
        unmountOnClose={false}
      >
        {LoadingSaving()}
        <ModalContainer>
          <ModalContent>
            <hr className="separator" />
            <h4>Adicionar Pessoa</h4>
            <Row>
              <Col xs="5">
                <L.Input
                  label="Nome"
                  name="name"
                  value={personName || ''}
                  onChange={(event) => setPersonName(event.target.value)}
                />
              </Col>
              <Col xs="5">
                <L.Input
                  label="Cargo/Função"
                  name="occupation"
                  value={personOccupation || ''}
                  onChange={(event) => setPersonOccupation(event.target.value)}
                />
              </Col>
              <Col xs="2">
                <ButtonAdd>
                  <Button
                    disabled={personName === '' || personOccupation === '' || loadingDetails}
                    onClick={addPerson}
                    title={<i className="tim-icons icon-simple-add" />}
                  />
                </ButtonAdd>
              </Col>
            </Row>
            <hr className="min-separator" />
            <h4>Lista de Pessoas Entrevistadas</h4>
            <Table>
              <tbody>
                <tr>
                  <th className="th-title">Nome</th>
                  <th className="th-title">Cargo/Função</th>
                  <th className="th-title">Apagar</th>
                </tr>
                {peopleState.map((elm, ind) => (
                  <tr key={`table-list-meeting-${ind}`}>
                    <td className="center">{elm.name}</td>
                    <td className="center">{elm.occupation}</td>
                    <td className="center --yesNo">
                      <Button
                        disabled={loadingDetails}
                        title={<i className="tim-icons icon-simple-delete" />}
                        onClick={() => removePerson(ind)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalContent>
        </ModalContainer>
        {LoadingSaving()}
      </Modal>
    </div>
  );
});

PeopleInterviewed.defaultProps = {
  evaluationId: 0,
  people: []
};

PeopleInterviewed.propTypes = {
  evaluationId: PropTypes.number,
  people: PropTypes.any
};

export default PeopleInterviewed;
