import {
  RN_507_TYPES,
  RN_507_CLASSIFICATION,
  RN_506_CLASSIFICATION,
  EVALUATION_TYPE,
  RN_506_TYPES
} from './types';

// ======== All Types ========

export const VERSIONS_OF_CHANGES = {
  FIRST_VERSION: 'FIRST_VERSION',
  SECOND_VERSION: 'SECOND_VERSION',
  THIRD_VERSION: 'THIRD_VERSION',
  FOURTH_VERSION: 'FOURTH_VERSION'
};

const WEATHER_VERSIONS_OF_CHANGES = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    start: 1595991600000, // 29/07/2020 00:00:00
    end: 1635044399000 // 23/10/2021 23:59:59
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    start: 1635044400000, // 24/10/2021 00:00:00
    end: 1648609199000 // 29/03/2022 23:59:59
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    start: 1648609200000, // 30/03/2022 00:00:00
    end: 1661223599000 // 22/08/2022 23:59:59
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    start: 1661223600000, // 23/08/2022 00:00:00
    end: 1898564400000 // 01/03/2030 00:00:00
  }
};

const NEW_FEATURE_RESUME = 1658199600000; // 19/07/2022

export const CALCULATE_FOR_WEATHER_VERSIONS = (TIME) => {
  if (
    TIME >= WEATHER_VERSIONS_OF_CHANGES.FIRST_VERSION.start &&
    TIME <= WEATHER_VERSIONS_OF_CHANGES.FIRST_VERSION.end
  ) {
    return VERSIONS_OF_CHANGES.FIRST_VERSION;
  }
  if (
    TIME >= WEATHER_VERSIONS_OF_CHANGES.SECOND_VERSION.start &&
    TIME <= WEATHER_VERSIONS_OF_CHANGES.SECOND_VERSION.end
  ) {
    return VERSIONS_OF_CHANGES.SECOND_VERSION;
  }
  if (
    TIME >= WEATHER_VERSIONS_OF_CHANGES.THIRD_VERSION.start &&
    TIME <= WEATHER_VERSIONS_OF_CHANGES.THIRD_VERSION.end
  ) {
    return VERSIONS_OF_CHANGES.THIRD_VERSION;
  }
  if (
    TIME >= WEATHER_VERSIONS_OF_CHANGES.FOURTH_VERSION.start &&
    TIME <= WEATHER_VERSIONS_OF_CHANGES.FOURTH_VERSION.end
  ) {
    return VERSIONS_OF_CHANGES.FOURTH_VERSION;
  }
  return VERSIONS_OF_CHANGES.FIRST_VERSION;
};

const FIRST_VERSION_resumeContentEvaluation = (obj, RN = '507', totalItems = '169', level) => [
  {
    allTypes: true,
    onlyReport: true,
    text: `A avaliação foi realizada no período de ${obj?.startDay} a ${obj?.endDay} de ${
      obj?.month
    } de ${obj?.year} de forma ${obj?.isRemote ? 'remota' : 'presencial'}.`
  },
  {
    allTypes: true,
    showForAll: true,
    text: `Foi utilizada a metodologia da própria A4Quality Services, descritas em documentos específicos da A4Quality , com o objetivo de se verificar o grau de conformidade com as dimensões apresentadas no anexo à Resolução Normativa ${RN} da ANS, de 30 de março de 2022, sendo orientada pelos seguintes propósitos:`
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.ACCREDITATION, RN_507_TYPES.PRE],
    text: `a) Buscar evidências da conformidade e pontuação dos itens da RN ${RN};\nb) Buscar evidências do atendimento à listagem de procedimentos, planos, indicadores e registros requeridos;\nc) Apontar as evidências sobre o não atendimento aos itens, assim como as oportunidades de melhoria encontradas.`
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.SUPERVISION],
    text: `a) Buscar evidências da manutenção da pontuação dos itens, conforme avaliação inicial de Acreditação realizada;\nb) Verificar o grau de conformidade dos ${
      level === '1' ? 'itens essenciais e de excelência' : 'itens essenciais atendidos'
    } pontuados nas Dimensões ${obj?.dimensions
      ?.join(', ')
      .replace(/, ([^,]*)$/, ' e $1')} apresentadas no anexo à Resolução Normativa ${RN} da ANS.`
  },
  {
    allTypes: true,
    showForAll: true,
    text: `A obtenção de evidências se deu por meio de entrevistas e se baseou principalmente no exame de registros, documentos e indicadores do Sistema de Gestão e outros documentos que estão listados na seção de cada dimensão. Os dados verificados se referem à situação na data da avaliação.`
  },
  {
    allTypes: true,
    showForAll: true,
    text: `Apesar de todo processo de avaliação envolver riscos por não utilizar amostragem estatística na identificação de evidências de conformidade, a amostragem por julgamento utilizada na metodologia da A4Quality somada a qualificação da equipe avaliadora permitem que as constatações da avaliação, tanto de atendimentos quanto de não atendimentos, sejam adequadas e suficientes para garantir a confiabilidade das decisões e recomendações da equipe de avaliação.`
  },
  {
    allTypes: true,
    showForAll: true,
    text: `Na avaliação das dimensões, foram identificadas constatações de Conformidade (para os itens com atendimento total, ou seja, com escopo adequado e tempo de implantação mínimo de ${
      RN === '507' ? '12 meses' : '6 meses'
    }; pontuação=1) ou Não Conformidade (para os itens que não atenderam integralmente a prática requerida; pontuação=0). Também foram apontados os Pontos Fortes (Boas Práticas) e as Oportunidades de Melhorias identificadas, no final de cada dimensão.`
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.PRE],
    text: `${
      // eslint-disable-next-line radix
      parseInt(obj?.createdDateTimestamp) > NEW_FEATURE_RESUME
        ? 'Como este é um relatório do diagnóstico, os comentários descritos serão apresentados apenas para os itens que não foram pontuados, ou tiveram alguma observação relativa à oportunidade de melhoria ou anotação de ponto forte.'
        : ''
    }`
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.SUPERVISION],
    text:
      'Após identificadas as constatações, os resultados obtidos na Auditoria de Acreditação foram comparados com os resultados obtidos nesta Auditoria de Supervisão. Caso a pontuação obtida no item tenha sido menor do que a obtida na auditoria inicial, será aberto o Relatório de Não Conformidade - RNC.'
  },
  {
    isTitle: true,
    allTypes: true,
    showForAll: true,
    text: `Conclusão da equipe avaliadora:`
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.ACCREDITATION],
    text: `A liderança da Operadora demonstrou grande comprometimento com o seu Sistema de Gestão e envolvimento no Programa de ${RN === '506' ? 'Certificação' : RN === '507' ? 'Acreditação' : null}, assim como todas as equipes.`
  },
  {
    joinReport: true,
    isCustom: true,
    forTypes: [RN_507_TYPES.ACCREDITATION, RN_507_TYPES.PRE, RN_507_TYPES.SUPERVISION],
    text: obj?.customText
  },
  {
    joinReport: true,
    showForAll: true,
    forTypes: [RN_507_TYPES.ACCREDITATION],
    text: `Considerando que a avaliação foi realizada na íntegra em todos os ${RN === '506' ? '68 ' : RN === '507' ? '169 ' : null}itens da RN ${RN} da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários, e ainda considerando a pontuação total obtida,`
  },
  {
    isSelect: true
  },
  {
    joinReport: true,
    hasFit: true,
    forTypes: [RN_507_TYPES.ACCREDITATION],
    text: obj?.isFit
      ? `a equipe avaliadora concluiu que a Operadora está apta a ser ${RN === '506' ? 'certificada' : RN === '507' ? 'acreditada' : null} no Nível ${obj?.level}, segundo o Programa de ${RN === '506' ? 'Certificação' : RN === '507' ? 'Acreditação' : null} de OPS estabelecido pela RN ${RN} da ANS.`
      : `a equipe avaliadora concluiu que esta ainda não está apta a ser ${RN === '506' ? 'certificada' : RN === '507' ? 'acreditada' : null} e orienta para a análise das constatações apresentadas e elaboração de planos de ação para o alcance das conformidades requeridas pela norma. A equipe se coloca a disposição para quaisquer esclarecimentos julgados necessários.`
  },

  {
    pasteJoin: true
  },
  {
    joinReport: true,
    showForAll: true,
    forTypes: [RN_507_TYPES.PRE],
    text: `Considerando o resultado da avaliação,`
  },
  {
    joinReport: true,
    isCustomFit: true,
    forTypes: [RN_507_TYPES.PRE],
    hasFit: true,
    text: obj?.customTextFit
      ? `${obj?.customTextFit}`
      : obj?.isFit
      ? `a equipe de avaliadores recomenda a avaliação para Acreditação e se coloca a disposição para quaisquer esclarecimentos julgados necessários.`
      : `a equipe de avaliadores não recomenda a avaliação para Acreditação e se coloca a disposição para quaisquer esclarecimentos julgados necessários.  `
  },
  {
    showForAll: true,
    forTypes: [RN_507_TYPES.ACCREDITATION],
    text: `Importante destacar que, durante a reunião de abertura e encerramento com a liderança, foi enfatizado pela equipe de avaliadores que qualquer pendência junto a ANS, relativa à planos de recuperação, ajustes ou situações similares podem impedir o deferimento da ${RN === '506' ? 'certificação' : RN === '507' ? 'acreditação' : null} pela ANS.`
  },
  {
    showForAll: true,
    forTypes: [],
    text: `Com relação ao uso do Selo de Acreditação da A4Quality pela Operadora, foi verificado o uso em documentos de divulgação em geral, em vídeos institucionais; fundos de tela, redes sociais, assinaturas de e-mail. Todas as evidências apresentadas apresentaram adequação aos requisitos da NBR ISO 17065:2013 e demais documentos da A4Quality.`
  }
];

// ======== RN 507 ========

const RN_507_SCOPE = 'Operadoras de Planos Privados de Assistência à Saúde.';

const RN_507_OBJECTIVE_PRE =
  'Pré-avaliação diagnóstica com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde.';

const RN_507_OBJECTIVE_SUPERVISION =
  'Supervisão de manutenção da Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde.';

const RN_507_OBJECTIVE_ACCREDITATION =
  'Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde.';

const RN_507_OBJECTIVE_ACCREDITATION_SECOND_VERSION =
  'Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde, de 09 de março de 2020, e alterações constantes da RN 471, de 29 de setembro de 2021.';

const RN_507_OBJECTIVE_ACCREDITATION_THIRD_VERSION =
  'Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde, de 30 de março de 2022.';

const RN_507_OBJECTIVE_ACCREDITATION_FOURTH_VERSION =
  'Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde, de 30 de março de 2022.';

const RN_507_OBJECTIVE_SELF =
  'Autoavaliação assistida para a Acreditação da Operadora com base na RN 507 da ANS - Programa de Acreditação de Operadoras de Planos de Saúde.';

const RN507_FOLLOW = {
  MEDICAL_HOSPITAL: 'Seguimento Médico Hospitalar.',
  DENTAL: 'Seguimento Odontológico.',
  SELF_MANAGEMENT: 'Seguimento Autogestão.'
};

const RN507_coveredTopicsInitialMeeting = [
  'Agradecimentos iniciais pela receptividade;',
  'Solicitação de assinatura na lista de presença;',
  'Apresentações – equipe de avaliadores;',
  'Esclarecimentos sobre aspectos de imparcialidade e confidencialidade;',
  'Esclarecimentos sobre o propósito e escopo da auditoria (Acreditação RN 507);',
  'Esclarecimentos sobre o processo de acreditação de OPS segundo RN 507 ANS e os critérios (4 Dimensões);',
  'Informação sobre constatações e registros (Conforme / Não Conforme / Pontos de Melhoria / Boas Práticas). Se qualquer item essencial for igual a zero, o requisito será igual a 0;',
  'Esclarecimentos sobre as possíveis situações adversas (condições para pontuação nível I (IDSS, itens de excelência) ou OPS não atingir pontuação mínima em uma ou mais  dimensões;',
  'Métodos e procedimentos a serem usados para realização da auditoria com base em amostragem e riscos envolvidos;',
  'Confirmação de que durante a auditoria, o cliente será mantido informado do progresso da auditoria e de quaisquer preocupações;',
  'Confirmação dos canais formais de comunicação entre a equipe de auditoria e o auditado;',
  'Condições sobre as quais a auditoria pode ser encerrada;',
  'Informações sobre o processo A4Quality de reclamações e os processos de apelação;',
  'Confirmação dos procedimentos de saúde, segurança e emergência;',
  'Confirmação de que os recursos e instalações necessários à equipe de auditoria estão disponíveis;',
  'Confirmação do número exato de colaboradores e de beneficiários;',
  'Confirmação da Matriz de Responsabilidades pelas Dimensões;',
  'Ajustes na agenda, quando aplicável;',
  'Confirmação do idioma a ser usado durante a auditoria;',
  'Divulgação do Banco de Boas Práticas da A4Quality;',
  'Respostas a eventuais dúvidas.'
];

const RN507_coveredTopicsFinalMeeting = [
  'Agradecimentos finais pela receptividade;',
  'Solicitação de assinatura na lista de presença;',
  'Descrição sucinta do que foi encontrado;',
  'Principais pontos fortes;',
  'Principais oportunidades de melhoria e boas práticas identificadas;',
  'Esclarecimentos sobre como são relatadas as constatações;',
  'Informação de que as evidências coletadas na auditoria foram baseadas em uma amostra das informações, introduzindo assim um elemento de incertezas;',
  'Comunicação da pontuação final com a recomendação da equipe;',
  'Esclarecimentos sobre o envio do relatório final, no prazo máximo de 15 dias úteis, após o parecer da equipe de análise e decisão da A4Quality, que poderá alterar a recomendação da equipe, se necessário;',
  'Informação de que, por força da RN 507, o resultado da auditoria será enviado a ANS, com a recomendação da equipe auditora e que a ANS poderá, a seu julgamento, manter ou indeferir a recomendação, considerando inclusive, quaisquer pendências junto a esta, relativas à planos de recuperação, ajustes ou situações similares que possam impedir o deferimento da acreditação;',
  'Alerta de que, a Operadora somente poderá divulgar a acreditação, quando for o caso, após recebimento do Ofício e Nota Técnica da ANS;',
  'Informação de que a A4Quality divulga no website as certificações emitidas, e que caso a empresa seja certificada deverá informar se existe alguma objeção à divulgação;',
  'Esclarecimentos sobre a emissão da Certidão de Acreditação;',
  'Informações sobre o processo A4Quality de reclamações e os processos de apelação;',
  'Divulgação do Banco de Boas Práticas da A4Quality;',
  'Esclarecimentos sobre dúvidas;',
  'Agradecimentos finais.'
];

// ======== RN 506 ========

const RN_506_SCOPE = 'Operadoras de Planos Privados de Assistência à Saúde.';

const RN506_FOLLOW = {
  APS: 'Seguimento APS.'
};

const RN_506_OBJECTIVE_PRE =
  'Pré-avaliação diagnóstica com base na RN 506 da ANS - Programa de Boas Práticas em Atenção à Saúde.';

const RN_506_OBJECTIVE_SUPERVISION =
  'Supervisão de manutenção da Certificação da Operadora com base na RN 506 da ANS - Programa de Boas Práticas em Atenção à Saúde.';

const RN_506_OBJECTIVE_CERTIFICATION =
  'Certificação da Operadora com base na RN 506 da ANS - Programa de Boas Práticas em Atenção à Saúde.';

const RN_506_OBJECTIVE_SELF =
  'Autoavaliação assistida para a Acreditação da Operadora com base na RN 506 da ANS - Programa de Boas Práticas em Atenção à Saúde.';

const RN506_coveredTopicsInitialMeeting = [
  'Agradecimentos iniciais pela receptividade;',
  'Solicitação de assinatura na lista de presença;',
  'Apresentações - equipe de avaliadores;',
  'Esclarecimentos sobre aspectos de imparcialidade e confidencialidade;',
  'Esclarecimentos sobre propósito e escopo da auditoria (Certificação em APS - RN 506);',
  'Esclarecimentos sobre o processo de Certificação APS segundo RN 506 ANS e os critérios (7 Requisitos);',
  'Informação sobre constatações e registros (Conforme / Não Conforme / Pontos de Melhoria / Boas Práticas). Se qualquer item essencial for igual a zero, o requisito será igual a 0;',
  'Esclarecimentos sobre as possíveis situações adversas (condições para pontuação nível I (IDSS, itens de excelência) ou OPS não atingir pontuação mínima em uma ou mais dimensões;',
  'Métodos e procedimentos a serem usados para realização da auditoria com base em amostragem e riscos envolvidos;',
  'Confirmação de que durante a auditoria, o cliente será mantido informado do progresso da auditoria e de quaisquer preocupações;',
  'Confirmação dos canais formais de comunicação entre a equipe de auditoria e o auditado;',
  'Condições sobre as quais a auditoria pode ser encerrada;',
  'Informações sobre o processo A4Quality de reclamações e os processos de apelação;',
  'Confirmação dos procedimentos de saúde, segurança e emergência;',
  'Confirmação de que os recursos e instalações necessários à equipe de auditoria estão disponíveis;',
  'Confirmação do número exato de colaboradores e de beneficiários;',
  'Confirmação da Matriz de Responsabilidades por requisitos;',
  'Ajustes na agenda, quando aplicável;',
  'Confirmação do idioma a ser usado durante a auditoria;',
  'Divulgação do Banco de Boas Práticas da A4Quality;',
  'Respostas a eventuais dúvidas.'
];

const RN506_coveredTopicsFinalMeeting = [
  'Agradecimentos finais pela receptividade;',
  'Solicitação de assinatura na lista de presença;',
  'Descrição sucinta do que foi encontrado;',
  'Principais pontos fortes;',
  'Principais oportunidades de melhoria e boas práticas identificadas;',
  'Esclarecimentos sobre como são relatadas as constatações;',
  'Informação de que as evidências coletadas na auditoria foram baseadas em uma amostra das informações, introduzindo assim um elemento de incertezas;',
  'Comunicação da pontuação final com a recomendação da equipe;',
  'Esclarecimentos sobre o envio do relatório final, no prazo máximo de 15 dias úteis, após o parecer da equipe de análise e decisão da A4Quality, que poderá alterar a recomendação da equipe, se necessário;',
  'Informação de que, por força da RN 506, o resultado da auditoria será enviado a ANS, com a recomendação da equipe auditora e que a ANS poderá, a seu julgamento, manter ou indeferir a recomendação, considerando inclusive, quaisquer pendências junto a esta, relativas à planos de recuperação, ajustes ou situações similares que possam impedir o deferimento da certificação;',
  'Alerta de que, a Operadora somente poderá divulgar a Certificação, quando for o caso, após recebimento do Ofício e Nota Técnica da ANS;',
  'Informação de que a A4Quality divulga no website as certificações emitidas, e que caso a empresa seja certificada deverá informar se existe alguma objeção à divulgação;',
  'Esclarecimentos sobre a emissão do Certificado de Conformidade-APS;',
  'Informações sobre o processo A4Quality de reclamações e os processos de apelação;',
  'Divulgação do Banco de Boas Práticas da A4Quality;',
  'Esclarecimentos sobre dúvidas;',
  'Agradecimentos finais.'
];

// ======== Exports ========
// ======== All Types ========

export const DIMENSIONS = [
  {
    value: 1,
    label: 'Dimensão 1'
  },
  {
    value: 2,
    label: 'Dimensão 2'
  },
  {
    value: 3,
    label: 'Dimensão 3'
  },
  {
    value: 4,
    label: 'Dimensão 4'
  }
];

export const REQUIREMENT_NUMBERS = [
  {
    value: 1,
    label: 'Requisito 1'
  },
  {
    value: 2,
    label: 'Requisito 2'
  },
  {
    value: 3,
    label: 'Requisito 3'
  },
  {
    value: 4,
    label: 'Requisito 4'
  },
  {
    value: 5,
    label: 'Requisito 5'
  },
  {
    value: 6,
    label: 'Requisito 6'
  },
  {
    value: 7,
    label: 'Requisito 7'
  }
];

export const resumeContentEvaluation = (obj, RN = '507', totalItems = '169', level) => ({
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: FIRST_VERSION_resumeContentEvaluation(
    obj,
    RN,
    totalItems,
    level
  ),
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: FIRST_VERSION_resumeContentEvaluation(
    obj,
    RN,
    totalItems,
    level
  ),
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: FIRST_VERSION_resumeContentEvaluation(
    obj,
    RN,
    totalItems,
    level
  ),
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: FIRST_VERSION_resumeContentEvaluation(
    obj,
    RN,
    totalItems,
    level
  )
});

export const coveredTopicsInitialMeeting = {
  [EVALUATION_TYPE.RN_507]: RN507_coveredTopicsInitialMeeting,
  [EVALUATION_TYPE.RN_506]: RN506_coveredTopicsInitialMeeting
};

export const coveredTopicsFinalMeeting = {
  [EVALUATION_TYPE.RN_507]: RN507_coveredTopicsFinalMeeting,
  [EVALUATION_TYPE.RN_506]: RN506_coveredTopicsFinalMeeting
};

export const ATTACHMENT_REPORT = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    [EVALUATION_TYPE.RN_507]: 'RN 507 – Anexo – Dimensões 1 a 4',
    [EVALUATION_TYPE.RN_506]: 'RN 506 – Anexo IV – Requisitos 1 a 7'
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    [EVALUATION_TYPE.RN_507]: 'RN 507 – Anexo – Dimensões 1 a 4 e alterações RN 471.',
    [EVALUATION_TYPE.RN_506]: 'RN 506 – Anexo IV – Requisitos 1 a 7'
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    [EVALUATION_TYPE.RN_507]: 'RN 507 – Anexo – Dimensões 1 a 4.',
    [EVALUATION_TYPE.RN_506]: 'RN 506 – Anexo IV – Requisitos 1 a 7'
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    [EVALUATION_TYPE.RN_507]: 'RN 507 – Anexo – Dimensões 1 a 4.',
    [EVALUATION_TYPE.RN_506]: 'RN 506 – Anexo IV – Requisitos 1 a 7'
  }
};

export const ACCREDITED_REPORT = {
  [EVALUATION_TYPE.RN_507]: 'Acreditada',
  [EVALUATION_TYPE.RN_506]: 'Certificada'
};

export const DIMENSIONS_TITLE_RN_507 = {
  1: 'Gestão Organizacional',
  2: 'Gestão da Rede Prestadora de Serviços de Saúde',
  3: 'Gestão em Saúde',
  4: 'Experiência do Beneficiário'
};

export const DIMENSIONS_TITLE_RN_506 = {
  1: 'PLANEJAMENTO E ESTRUTURAÇÃO TÉCNICA',
  2: 'AMPLIAÇÃO E QUALIFICAÇÃO DO ACESSO',
  3: 'QUALIDADE E CONTINUIDADE DO CUIDADO',
  4: 'INTERAÇÕES CENTRADAS NO PACIENTE',
  5: 'MONITORAMENTO E AVALIAÇÃO DA QUALIDADE',
  6: 'EDUCAÇÃO PERMANENTE',
  7: 'MODELOS INOVADORES DE REMUNERAÇÃO BASEADOS EM VALOR'
};

export const CONSIDERATIONS_PRE_REPORT = (TOTAL) => ({
  [EVALUATION_TYPE.RN_507]: `Este relatório apresenta a pontuação de cada um dos ${TOTAL} itens das quatro Dimensões, porém somente serão comentados os itens que apresentaram lacunas quanto ao atendimento, ou seja, os itens não conformes.`,
  [EVALUATION_TYPE.RN_506]: `Este relatório apresenta a pontuação de cada um dos ${TOTAL} itens dos sete Requisitos, porém somente serão comentados os itens que apresentaram lacunas quanto ao atendimento, ou seja, os itens não conformes.`
});

export const CONSIDERATIONS_REPORT = (TOTAL, level = '2') => ({
  [EVALUATION_TYPE.RN_507]: `Este relatório apresenta detalhadamente a avaliação das quatro Dimensões, com identificação das constatações da situação de atendimento encontrada; a pontuação alcançada por item, requisito e por dimensão, assim como a pontuação final obtida pela Operadora. A avaliação foi realizada na íntegra em todos os ${TOTAL} itens da RN 507 da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários`,
  [EVALUATION_TYPE.RN_507]: {
    [RN_507_TYPES.PRE]: `Este relatório apresenta detalhadamente a avaliação das quatro Dimensões, com identificação das constatações da situação de atendimento encontrada; a pontuação alcançada por item, requisito e por dimensão, assim como a pontuação final obtida pela Operadora. A avaliação foi realizada na íntegra em todos os ${TOTAL} itens da RN 507 da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários`,
    [RN_507_TYPES.SUPERVISION]: `Este relatório apresenta detalhadamente a avaliação de cada item, a pontuação alcançada em cada um deles, por requisito e em cada dimensão avaliada durante o processo de Supervisão. A avaliação de Supervisão foi realizada considerando todos os itens essenciais${
      level === '1' ? ' e de excelência' : ''
    } atendidos na avaliação inicial de Acreditação, considerando apenas as dimensões selecionadas para esta Supervisão. O objetivo é confirmar a manutenção das práticas pontuadas.`,
    [RN_507_TYPES.ACCREDITATION]: `Este relatório apresenta detalhadamente a avaliação das quatro Dimensões, com identificação das constatações da situação de atendimento encontrada; a pontuação alcançada por item, requisito e por dimensão, assim como a pontuação final obtida pela Operadora. A avaliação foi realizada na íntegra em todos os ${TOTAL} itens da RN 507 da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários`
  },
  [EVALUATION_TYPE.RN_506]: {
    [RN_506_TYPES.CERTIFICATION]: `Este relatório apresenta detalhadamente a avaliação dos sete Requisitos, com identificação das constatações da situação de atendimento encontrada; a pontuação alcançada por item, requisito e por dimensão, assim como a pontuação final obtida pela Operadora. A avaliação foi realizada na íntegra em todos os ${TOTAL} itens da RN 506 da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários`,
    [RN_506_TYPES.SUPERVISION]: `Este relatório apresenta detalhadamente a avaliação dos sete Requisitos, com identificação das constatações da situação de atendimento encontrada; a pontuação alcançada por item, requisito e por dimensão, assim como a pontuação final obtida pela Operadora. A avaliação foi realizada considerando ${TOTAL} itens classificados como essenciais e de excelência da RN 506 da ANS, em todos os processos e planos oferecidos pela Operadora a todos os seus beneficiários`
  }
});

// ======== RN 507 ========

export const RN_507_SCOPE_REPORT = {
  [RN_507_TYPES.PRE]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
    [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.DENTAL}`,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_SCOPE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
  },
  [RN_507_TYPES.SUPERVISION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
    [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.DENTAL}`,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_SCOPE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
  },
  [RN_507_TYPES.ACCREDITATION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
    [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.DENTAL}`,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_SCOPE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
  },
  [RN_507_TYPES.SELF_EVALUATION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
    [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_SCOPE} ${RN507_FOLLOW.DENTAL}`,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_SCOPE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
  }
};

export const RN_507_REPORT_OBJECTIVE = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_ACCREDITATION} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_ACCREDITATION} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_ACCREDITATION} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    }
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_PRE} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SUPERVISION} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_ACCREDITATION_SECOND_VERSION} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_ACCREDITATION_SECOND_VERSION} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_ACCREDITATION_SECOND_VERSION} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.MEDICAL_HOSPITAL}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.DENTAL}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SELF} ${RN507_FOLLOW.SELF_MANAGEMENT}`
    }
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_PRE}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_PRE}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_PRE}`
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SUPERVISION}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SUPERVISION}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SUPERVISION}`
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_ACCREDITATION_THIRD_VERSION}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_ACCREDITATION_THIRD_VERSION}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_ACCREDITATION_THIRD_VERSION}`
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SELF}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SELF}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SELF}`
    }
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_PRE}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_PRE}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_PRE}`
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SUPERVISION}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SUPERVISION}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SUPERVISION}`
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_ACCREDITATION_FOURTH_VERSION}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_ACCREDITATION_FOURTH_VERSION}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_ACCREDITATION_FOURTH_VERSION}`
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: `${RN_507_OBJECTIVE_SELF}`,
      [RN_507_CLASSIFICATION.DENTAL]: `${RN_507_OBJECTIVE_SELF}`,
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: `${RN_507_OBJECTIVE_SELF}`
    }
  }
};

export const RN_507_MINIMUM_OF_EXCELLENCE_ITEMS = {
  [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 36,
  [RN_507_CLASSIFICATION.DENTAL]: 25,
  [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 25
};

export const RN_507_CONSIDERATIONS_ITEMS = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 168,
    [RN_507_CLASSIFICATION.DENTAL]: 134,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 134
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 169,
    [RN_507_CLASSIFICATION.DENTAL]: 135,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 135
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 169,
    [RN_507_CLASSIFICATION.DENTAL]: 135,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 135
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 169,
    [RN_507_CLASSIFICATION.DENTAL]: 135,
    [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 135
  }
};

export const EDITION_REPORT_RN_507 = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.016 - Edição 04',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.047 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.051 - Edição 01'
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.005 - Edição 01',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.048 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.053 - Edição 01'
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.001 - Edição 04',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.031 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.052 - Edição 01'
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.039 - Edição 01',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.049 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.050 - Edição 01'
    }
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.016 - Edição 05',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.047 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.051 - Edição 01'
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.005 - Edição 01',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.048 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.053 - Edição 01'
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.001 - Edição 05',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.031 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.052 - Edição 01'
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.039 - Edição 01',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.049 - Edição 01',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.050 - Edição 01'
    }
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.016 - Edição 06',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.047 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.051 - Edição 02'
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.005 - Edição 02',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.048 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.053 - Edição 02'
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.001 - Edição 06',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.031 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.052 - Edição 02'
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.039 - Edição 02',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.049 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.050 - Edição 02'
    }
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    [RN_507_TYPES.PRE]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.016 - Edição 06',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.047 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.051 - Edição 02'
    },
    [RN_507_TYPES.SUPERVISION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.005 - Edição 04',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.048 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.053 - Edição 02'
    },
    [RN_507_TYPES.ACCREDITATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.001 - Edição 06',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.031 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.052 - Edição 02'
    },
    [RN_507_TYPES.SELF_EVALUATION]: {
      [RN_507_CLASSIFICATION.MEDICAL_HOSPITAL]: 'REL.039 - Edição 02',
      [RN_507_CLASSIFICATION.DENTAL]: 'REL.049 - Edição 02',
      [RN_507_CLASSIFICATION.SELF_MANAGEMENT]: 'REL.050 - Edição 02'
    }
  }
};

// ======== RN 506 ========

export const RN_506_SCOPE_REPORT = {
  [RN_506_TYPES.PRE]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_SCOPE} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.SUPERVISION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_SCOPE} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.CERTIFICATION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_SCOPE} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.SELF_EVALUATION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_SCOPE} ${RN506_FOLLOW.APS}`
  }
};

export const RN_506_REPORT_OBJECTIVE = {
  [RN_506_TYPES.PRE]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_OBJECTIVE_PRE} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.SUPERVISION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_OBJECTIVE_SUPERVISION} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.CERTIFICATION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_OBJECTIVE_CERTIFICATION} ${RN506_FOLLOW.APS}`
  },
  [RN_506_TYPES.SELF_EVALUATION]: {
    [RN_506_CLASSIFICATION.APS]: `${RN_506_OBJECTIVE_SELF} ${RN506_FOLLOW.APS}`
  }
};

export const RN_506_MINIMUM_OF_EXCELLENCE_ITEMS = {
  [RN_506_CLASSIFICATION.APS]: 13
};

export const RN_506_CONSIDERATIONS_ITEMS = {
  [RN_506_CLASSIFICATION.APS]: {
    [RN_506_TYPES.CERTIFICATION]: 63,
    [RN_506_TYPES.SUPERVISION]: 57
  }
};

export const EDITION_REPORT_RN_506 = {
  [VERSIONS_OF_CHANGES.FIRST_VERSION]: {
    [RN_506_TYPES.PRE]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.025 - Edição 02'
    },
    [RN_506_TYPES.SUPERVISION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.009 - Edição 02'
    },
    [RN_506_TYPES.CERTIFICATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.008 - Edição 03'
    },
    [RN_506_TYPES.SELF_EVALUATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.054 - Edição 01'
    }
  },
  [VERSIONS_OF_CHANGES.SECOND_VERSION]: {
    [RN_506_TYPES.PRE]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.025 - Edição 02'
    },
    [RN_506_TYPES.SUPERVISION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.009 - Edição 02'
    },
    [RN_506_TYPES.CERTIFICATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.008 - Edição 03'
    },
    [RN_506_TYPES.SELF_EVALUATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.054 - Edição 01'
    }
  },
  [VERSIONS_OF_CHANGES.THIRD_VERSION]: {
    [RN_506_TYPES.PRE]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.025 - Edição 03'
    },
    [RN_506_TYPES.SUPERVISION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.009 - Edição 03'
    },
    [RN_506_TYPES.CERTIFICATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.008 - Edição 04'
    },
    [RN_506_TYPES.SELF_EVALUATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.054 - Edição 02'
    }
  },
  [VERSIONS_OF_CHANGES.FOURTH_VERSION]: {
    [RN_506_TYPES.PRE]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.025 - Edição 03'
    },
    [RN_506_TYPES.SUPERVISION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.009 - Edição 03'
    },
    [RN_506_TYPES.CERTIFICATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.008 - Edição 04'
    },
    [RN_506_TYPES.SELF_EVALUATION]: {
      [RN_506_CLASSIFICATION.APS]: 'REL.054 - Edição 02'
    }
  }
};
