import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import { useSelector } from 'react-redux';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import { mask } from '~/config/helpers';
import FooterReport from './FooterReport';
import { SERVER_SUBSCRIPTIONS_IMG } from '~/constants';

const Cover = ({
  accreditation,
  evaluationType,
  evaluationObjective,
  attachmentReport,
  accredited,
  evaluationScope,
  evaluators,
  totalPages,
  editionReport,
  isPre,
  isSelf,
  isSuper
}) => {
  const { users } = useSelector((state) => state.users);

  const listEvaluators = evaluators.map((elm) => {
    if (
      elm.id === accreditation?.leaderApproval?.id &&
      elm.leaderType === accreditation?.leaderApproval?.leaderType
    )
      return {
        ...elm,
        isLeader: true
      };
    return elm;
  });

  const listFirstLeader = [
    ...listEvaluators.filter((elm) => elm?.isLeader),
    ...listEvaluators.filter((elm) => !elm?.isLeader)
  ];

  const positionType = (isLeader, hasP = false) => {
    if (!isSelf) {
      if (isLeader) return !hasP ? '(Líder e Especialista)' : 'Líder e Especialista';
      return !hasP ? '(Avaliador(a) e Especialista)' : 'Avaliador(a) e Especialista';
    }
    return '';
  };

  const time = `${
    accreditation?.reportStartDate || accreditation?.createdDate?.split(' - ')[0]
  } até ${accreditation?.reportEndDate || 'Em andamento'}`;

  const showDetailsEvaluator = (elm) => {
    if (!isSelf && !isPre) {
      let details = '';

      const hasUniversityGraduate = elm.universityGraduate && elm.universityGraduate !== '';
      const hasPostGraduate = elm.postGraduate && elm.postGraduate !== '';

      if (hasUniversityGraduate && hasPostGraduate)
        details += `, declaro que possuo formação universitária em ${elm.universityGraduate}, com pós graduação em ${elm.postGraduate} `;

      if (hasUniversityGraduate && !hasPostGraduate)
        details += `, declaro que possuo formação universitária em ${elm.universityGraduate} `;

      if (!hasUniversityGraduate && hasPostGraduate)
        details += `, declaro que possuo pós graduação em ${elm.postGraduate} `;

      const init =
        elm.minimumExperienceInBusinessAudit ||
        elm.minimumExperienceInControllership ||
        elm.minimumExperienceInHealthAccreditation ||
        elm.minimumExperienceInHealthAaudit
          ? 'e possuo experiência mínima de 5 (cinco) anos em'
          : '.';

      details += init;

      let countBefore = 0;
      let countActual = 0;
      if (elm.minimumExperienceInBusinessAudit) countBefore++;
      if (elm.minimumExperienceInControllership) countBefore++;
      if (elm.minimumExperienceInHealthAccreditation) countBefore++;
      if (elm.minimumExperienceInHealthAaudit) countBefore++;

      if (elm.minimumExperienceInBusinessAudit) {
        details += ` auditoria empresarial`;
        countActual++;
        if (countBefore - countActual === 1) {
          details += ` e`;
        }
        if (countBefore - countActual > 1) {
          details += `,`;
        }
        if (countBefore - countActual === 0) {
          details += `.`;
        }
      }

      if (elm.minimumExperienceInControllership) {
        details += ` controladoria`;
        countActual++;
        if (countBefore - countActual === 1) {
          details += ` e`;
        }
        if (countBefore - countActual > 1) {
          details += `,`;
        }
        if (countBefore - countActual === 0) {
          details += `.`;
        }
      }

      if (elm.minimumExperienceInHealthAccreditation) {
        details += ` acreditação em saúde`;
        countActual++;
        if (countBefore - countActual === 1) {
          details += ` e`;
        }
        if (countBefore - countActual > 1) {
          details += `,`;
        }
        if (countBefore - countActual === 0) {
          details += `.`;
        }
      }

      if (elm.minimumExperienceInHealthAaudit) {
        details += ` auditoria em saúde`;
        countActual++;
        if (countBefore - countActual === 1) {
          details += ` e`;
        }
        if (countBefore - countActual > 1) {
          details += `,`;
        }
        if (countBefore - countActual === 0) {
          details += `.`;
        }
      }

      return details;
    }
    return '.';
  };

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
        <div>
          <h4>A4Quality Healthcare – Auditoria e Certificação Ltda</h4>
          <p>Praia de Botafogo 501 Torre A - 1 andar CEP: 22250-040 Fone: 55(21) 2586-6255</p>
        </div>
      </S.CoverPageHeader>

      <S.Table bottomMarginMinForCover>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              {evaluationType}
            </th>
          </tr>
        </tbody>
      </S.Table>

      <S.Table bottomMarginMinForCover>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Dados da Operadora
            </th>
          </tr>
          <tr>
            <td className="left td-title">Nome</td>
            <td colSpan="3">{accreditation?.company?.name}</td>
          </tr>
          <tr>
            <td className="left td-title">Registro ANS</td>
            <td>{accreditation?.company?.ansRecord}</td>
            <td className="td-title --2th right">CNPJ</td>
            <td>{mask(accreditation?.company?.cnpj, '##.###.###/####-##')}</td>
          </tr>
          <tr>
            <td className="left td-title">Segmentação</td>
            <td colSpan="3">{accreditation?.company?.segmentation}</td>
          </tr>
          <tr>
            <td className="left td-title">Pessoa de contato</td>
            <td colSpan="3">{accreditation?.company?.contactPerson}</td>
          </tr>
          <tr>
            <td className="left td-title">Endereço</td>
            <td colSpan="3">{accreditation?.company?.address}</td>
          </tr>
          <tr>
            <td className="left td-title">Telefone</td>
            <td colSpan="3">{mask(accreditation?.company?.phone, '(##) #####-####')}</td>
          </tr>
          <tr>
            <td className="left td-title --2th">E-mail</td>
            <td colSpan="3">{accreditation?.company?.email}</td>
          </tr>
        </tbody>
      </S.Table>

      <S.Table bottomMarginMinForCover>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Objetivo da Avaliação
            </th>
          </tr>
          <tr>
            <td colSpan="4" className="center">
              {evaluationObjective}
            </td>
          </tr>
          <tr>
            <td className="left td-title">Data da Auditoria</td>
            <td>{time}</td>
          </tr>
          <tr>
            <td className="left td-title">Escopo</td>
            <td colSpan="3">{evaluationScope}</td>
          </tr>
          <tr>
            <td className="left td-title">Critério da avaliação</td>
            <td colSpan="3">{attachmentReport}</td>
          </tr>
          {!isSelf && (
            <tr>
              {isSuper ? (
                <>
                  <td className="left td-title">Situação na última Acreditação</td>
                  <td colSpan="3">
                    {accreditation?.resumeLastAccreditation?.isFit
                      ? `${accredited} - Nível ${
                          accreditation?.resumeLastAccreditation?.level || '---'
                        }`
                      : `Não ${accredited}`}
                  </td>
                </>
              ) : (
                <>
                  <td className="left td-title">Situação</td>
                  <td colSpan="3">
                    {accreditation?.resume?.isFit
                      ? `${accredited} - Nível ${accreditation?.resume?.level || '---'}`
                      : `Não ${accredited}`}
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </S.Table>

      <S.Table bottomMarginMinForCover>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Equipe de Avaliação
            </th>
          </tr>
          {!isPre &&
            listFirstLeader.map((elm) => (
              <tr key={uniqId()}>
                <td className="left td-title wrap --isListEvaluators">{`${
                  elm?.name
                }\n${positionType(elm?.isLeader)}`}</td>
                <td className="center --isListEvaluators">
                  Eu, {elm?.name}, {mask(elm?.phone, '(##) #####-####')}, {elm?.account?.email},
                  inscrito(a) no CPF sob o nº {mask(elm?.cpf, '###.###.###-##')}
                  {showDetailsEvaluator(elm)}
                </td>
              </tr>
            ))}
          {isPre &&
            listFirstLeader.map((elm) => (
              <tr key={uniqId()}>
                <td className="left td-title --isListEvaluatorsPre">{`${positionType(
                  elm?.isLeader,
                  true
                )}`}</td>
                <td className="--isListEvaluatorsPre">{elm?.name}</td>
              </tr>
            ))}
          {!isSelf && !isPre ? (
            <>
              <tr>
                <td colSpan="4" className="center --withPadding">
                  Assim, assumimos integral responsabilidade pela fidedignidade das declarações ora
                  prestadas ou imperícia, ficando a ANS, desde já, autorizada a delas fazer, nos
                  limites legais e em juízo ou fora dele, o uso que lhe aprouver.
                  <S.ContainerSubscriptions>
                    {listFirstLeader.map((elm) => (
                      <div key={uniqId()}>
                        <S.CoverSubscription>
                          <img
                            src={SERVER_SUBSCRIPTIONS_IMG + elm?.subscription}
                            alt="Assinatura"
                          />
                        </S.CoverSubscription>
                        <p className="center">{elm?.name}</p>
                      </div>
                    ))}
                  </S.ContainerSubscriptions>
                </td>
              </tr>
              <tr>
                <th className="th-title" colSpan="4" scope="row">
                  Representante da Entidade Acreditadora perante a ANS
                </th>
              </tr>
              <tr>
                <td colSpan="4">
                  <S.ContainerSubscriptions>
                    <div>
                      <S.CoverSubscription>
                        <div className="withImg">
                          <img
                            src={
                              SERVER_SUBSCRIPTIONS_IMG +
                              users.active.filter((elm) => elm.name.includes('Catunda'))[0]
                                ?.subscription
                            }
                            alt="Assinatura"
                          />
                        </div>
                      </S.CoverSubscription>
                      <p className="center">
                        {users.active.filter((elm) => elm.name.includes('Catunda'))[0]?.name}
                      </p>
                    </div>
                  </S.ContainerSubscriptions>
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </S.Table>

      {isSelf ? (
        <S.Table bottomMargin>
          <tbody>
            <tr>
              <th colSpan="4" className="th-title">
                Informações da Operadora
              </th>
            </tr>
            <tr>
              <td className="left td-title">Porte</td>
              <td>{accreditation?.companyPort}</td>
              <td className="left td-title">IDSS</td>
              <td>{accreditation?.companyIdss}</td>
            </tr>
            <tr>
              <td className="left td-title">Nº Colaboradores</td>
              <td>{accreditation?.companyNumberOfEmployees}</td>
              <td className="left td-title">Nº Beneficiários</td>
              <td>{accreditation?.companyNumberOfBeneficiaries}</td>
            </tr>
          </tbody>
        </S.Table>
      ) : null}

      {isSelf ? (
        <S.Table bottomMargin>
          <tbody>
            <tr>
              <th colSpan="4" className="th-title">
                Pontuação Total: {accreditation?.totalPoints}
              </th>
            </tr>
            <tr>
              {accreditation?.dimensionsList.map((elm) => (
                <td key={uniqId()} className="td-title">
                  Dimensão {elm}
                </td>
              ))}
            </tr>
            <tr>
              {accreditation?.dimensionsList.map((elm) => (
                <td key={uniqId()} className="center">
                  {accreditation?.dimensions[elm].dimensionScore}
                </td>
              ))}
            </tr>
            {isSelf && (
              <tr>
                <td className="td-title" colSpan="2">
                  Total de itens de excelência atendidos
                </td>
                <td className="center">{accreditation?.totalItemsOfExcellence}</td>
                <td className="center" colSpan="1">
                  Mínimo de 36 para Níve I
                </td>
              </tr>
            )}
          </tbody>
        </S.Table>
      ) : null}

      <FooterReport actualPage={1} editionReport={editionReport} totalPage={totalPages} />
    </S.CoverPage>
  );
};

Cover.defaultProps = {
  evaluationType: '',
  evaluationObjective: '',
  attachmentReport: '',
  accredited: '',
  evaluationScope: '',
  accreditation: {},
  evaluators: [],
  totalPages: 0,
  editionReport: ''
};

Cover.propTypes = {
  evaluationType: PropTypes.string,
  evaluationObjective: PropTypes.string,
  attachmentReport: PropTypes.string,
  accredited: PropTypes.string,
  evaluationScope: PropTypes.string,
  accreditation: PropTypes.any,
  evaluators: PropTypes.any,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default Cover;
