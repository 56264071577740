import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setGraphsDash, graphsDashLoading } from './actions';
import { REQUEST_GRAPHS_DASH } from './patterns';
import { GetGraphicsHome } from '~/services/private';

function* getGraphs(action) {
  try {
    const { typeEvaluation } = action;

    const { data } = yield call(() => GetGraphicsHome(typeEvaluation));
    yield put(
      setGraphsDash({
        companies: data.companies,
        allClients: data.allClients,
        allAccreditationTypes: data.allTypes,
        allAccreditationMonths: data.allCounts
      })
    );
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(graphsDashLoading(false));
  }
}

export default all([takeLatest(REQUEST_GRAPHS_DASH, getGraphs)]);
