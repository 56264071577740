import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import evaluations from './evaluations/sagas';
import tools from './tools/sagas';
import companies from './companies/sagas';
import users from './users/sagas';
import evaluators from './evaluators/sagas';
import graphs from './graphs/sagas';

export default function* rootSaga() {
  return yield all([tools, auth, evaluations, companies, users, evaluators, graphs]);
}
