import styled from 'styled-components';

export const Body = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

export const BtnContainer = styled.div`
  padding: 10px 0 0 0;
  max-width: 200px;
`;
