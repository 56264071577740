import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    max-width: 300px;
  }
`;
