import Http from '~/config/http';

export const GetGraphicsDimensions = (typeEvaluation, classification, type) =>
  Http.get(`/graphics/dimensions/${typeEvaluation}/${classification}/${type}`);

export const GetGraphicsHome = (typeEvaluation) => Http.get(`/graphics/home/${typeEvaluation}`);

export const ExportReport = (body) => Http.post(`/graphics/export/report`, body);

export const CustomGraphs = (body) => Http.post(`/graphics/custom`, body);
