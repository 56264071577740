import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import * as S from './styles';
import { GROUPS, SERVER_FILES_COMMENTS } from '~/constants';

const FileView = ({ id, file, name, deleteFile, loadingDelete }) => {
  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  return (
    <S.File>
      <a
        href={`${SERVER_FILES_COMMENTS}${file}`}
        download
        rel="noopener noreferrer"
        target="_blank"
      >
        {`${name}.${file.split('.')[1]}`}
      </a>
      {groupId === GROUPS.COMPANY_USER && !loadingDelete && (
        <i className="tim-icons icon-trash-simple" onClick={() => deleteFile(id)} />
      )}
      {groupId === GROUPS.COMPANY_USER && loadingDelete && (
        <Spinner size="sm" color="primary" animation="border" />
      )}
    </S.File>
  );
};

FileView.defaultProps = {
  id: '',
  file: '',
  name: '',
  loadingDelete: false,
  deleteFile: () => {}
};

FileView.propTypes = {
  id: PropTypes.number,
  file: PropTypes.string,
  name: PropTypes.string,
  loadingDelete: PropTypes.bool,
  deleteFile: PropTypes.func
};

export default FileView;
