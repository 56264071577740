import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Spinner } from 'reactstrap';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as S from './style';

const DataTable = ({ columns, data, onCellClick, loading, ...rest }) => {
  const options = {
    filter: false,
    print: false,
    search: false,
    onCellClick,
    isRowSelectable: () => false,
    disableToolbarSelect: false,
    download: false,
    viewColumns: false,
    elevation: 0,
    rowsPerPage: 10,
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum registro correspondente encontrado',
        toolTip: 'Ordenação',
        columnHeaderTooltip: (column) => `Ordenação por ${column.label}`
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Mostrar',
        displayRows: 'de'
      }
    }
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            marginTop: 30,
            backgroundColor: '#fefefe',
            boxShadow: 'none',
            width: '100%'
          }
        },
        MUIDataTableToolbarSelect: {
          root: {
            display: 'none'
          }
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: '#fefefe',
            borderBottom: 'none'
          }
        },
        MUIDataTableHeadCell: {
          data: {
            fontWeight: 'bold'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#FAFAFA',
            cursor: 'pointer'
          }
        }
      }
    });

  const Content = () => {
    return loading ? (
      <S.Spinner>
        <Spinner size="lg" />
      </S.Spinner>
    ) : (
      <></>
    );
  };

  return (
    <S.Container>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} {...rest} />
        <Content />
      </MuiThemeProvider>
    </S.Container>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCellClick: PropTypes.func
};

DataTable.defaultProps = {
  columns: [],
  data: [],
  loading: false,
  onCellClick: () => {}
};

export default DataTable;
