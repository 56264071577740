import styled from 'styled-components';

export const Body = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

export const TabContainer = styled.div`
  padding-top: 25px;
`;

export const TabEmpty = styled.div`
  text-align: center;
`;

export const Loading = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
