import {
  SET_GRAPHS_DASH,
  LOADING_GRAPHS_DASH,
  REQUEST_GRAPHS_DASH,
  SET_CUSTOM_GRAPHS
} from './patterns';

export function graphsDashRequest(typeEvaluation) {
  return {
    type: REQUEST_GRAPHS_DASH,
    typeEvaluation
  };
}

export function setGraphsDash(payload) {
  return {
    type: SET_GRAPHS_DASH,
    payload
  };
}

export function setCustomGraphs(payload) {
  return {
    type: SET_CUSTOM_GRAPHS,
    payload
  };
}

export function graphsDashLoading(itsLoading) {
  return {
    type: LOADING_GRAPHS_DASH,
    itsLoading
  };
}
