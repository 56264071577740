export const isDEV = process.env.REACT_APP_SCOPE === 'dev';

export const REGEX = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export const keyboard = {
  ENTER: 13,
  SPACE: 32,
  TAB: 9
};
