import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import Cover from '~/components/Report/Cover';
import DimensionsData from '~/components/Report/DimensionsData';
import Resume from '~/components/Report/Resume';
import Meeting from '~/components/Report/Meeting';
import PeopleInterviewed from '~/components/Report/PeopleInterviewed';
import FinalComments from '~/components/Report/FinalComments';
import BackCover from '~/components/Report/BackCover';
// import Diary from '~/components/Report/Diary';
import { calculatePages } from '~/config/helpers';

import {
  TYPES_MEETINGS,
  RN_506_TYPES,
  RN_506_CLASSIFICATION_NAME,
  ATTACHMENT_REPORT,
  EVALUATION_TYPE,
  ACCREDITED_REPORT,
  CONSIDERATIONS_PRE_REPORT,
  EVALUATION_TYPE_TEXT,
  EDITION_REPORT_RN_506,
  RN_506_REPORT_OBJECTIVE,
  RN_506_SCOPE_REPORT,
  RN_506_CONSIDERATIONS_ITEMS,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';

const Pre = ({ evaluation, onlyGaps }) => {
  const [totalPages, setTotalPages] = useState(0);
  const pagesDetails = calculatePages(
    evaluation,
    7,
    6,
    2,
    totalPages,
    (total) => setTotalPages(total + 1),
    onlyGaps
  );

  evaluation.resume['createdDateTimestamp'] = evaluation?.createdDateTimestamp;

  return (
    <>
      <Cover
        evaluationType={`Relatório de Avaliação - Pré-Avaliação (${
          RN_506_CLASSIFICATION_NAME[evaluation?.classification]
        })`}
        evaluationObjective={
          RN_506_REPORT_OBJECTIVE[RN_506_TYPES.PRE][evaluation?.classification]
        }
        evaluationScope={
          RN_506_SCOPE_REPORT[RN_506_TYPES.PRE][evaluation?.classification]
        }
        attachmentReport={
          ATTACHMENT_REPORT[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][EVALUATION_TYPE.RN_506]
        }
        accredited={ACCREDITED_REPORT[EVALUATION_TYPE.RN_506]}
        accreditation={evaluation}
        evaluators={[...evaluation?.evaluators, ...evaluation?.evaluatorsAdmin]}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
        isPre={evaluation?.type_rn440 === RN_506_TYPES.PRE}
        isSelf={evaluation?.type_rn440 === RN_506_TYPES.SELF_EVALUATION}
        isSuper={evaluation?.type_rn440 === RN_506_TYPES.SUPERVISION}
      />
      <BackCover
        accreditation={evaluation}
        startPage={2}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
        considerations={
          CONSIDERATIONS_PRE_REPORT(
            RN_506_CONSIDERATIONS_ITEMS[evaluation?.classification]
          )[EVALUATION_TYPE.RN_506]
        }
        isPre={evaluation?.type_rn440 === RN_506_TYPES.PRE}
        isSelf={evaluation?.type_rn440 === RN_506_TYPES.SELF_EVALUATION}
        isSuper={evaluation?.type_rn440 === RN_506_TYPES.SUPERVISION}
      />
      <Resume
        data={evaluation?.resume}
        startPage={3}
        evaluationTextType={EVALUATION_TYPE_TEXT.RN_506}
        totalItems={
          RN_506_CONSIDERATIONS_ITEMS[evaluation?.classification]
        }
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
        type={RN_506_TYPES.PRE}
        dimensions={evaluation?.dimensionsList}
      />
      <Meeting
        meeting={evaluation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.INITIAL)[0]}
        startPage={4}
        totalPages={totalPages}
        evaluationType={EVALUATION_TYPE.RN_506}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
      />
      <PeopleInterviewed
        people={evaluation?.peopleInterviewed}
        startPage={5}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
      />
      <Meeting
        meeting={evaluation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.FINAL)[0]}
        startPage={6}
        totalPages={totalPages}
        evaluationType={EVALUATION_TYPE.RN_506}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
      />
      {/* <Diary */}
      {/*  diary={evaluation?.diary} */}
      {/*  startPage={7} */}
      {/*  totalPages={totalPages} */}
      {/*  editionReport={EDITION_REPORT_RN_506_PRE[VERSIONS_OF_CHANGES.FIRST_VERSION][evaluation?.classification]} */}
      {/*  evaluators={{ */}
      {/*    evaluators: evaluation?.evaluators, */}
      {/*    evaluatorsAdmin: evaluation?.evaluatorsAdmin, */}
      {/*    evaluatorsCompany: evaluation?.evaluatorsCompany */}
      {/*  }} */}
      {/* /> */}
      {evaluation?.dimensionsList.map((elm) => (
        <DimensionsData
          key={uniqId()}
          id={elm}
          showIndicators={false}
          data={evaluation.dimensions[elm]}
          startPage={pagesDetails[elm].start}
          separatorRequirements={pagesDetails[elm].separatorRequirements}
          totalPages={totalPages}
          auditedAreas={evaluation?.auditedAreas[elm]}
          evaluationType={EVALUATION_TYPE.RN_506}
          editionReport={
            EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
              evaluation?.classification
            ]
          }
        />
      ))}
      <FinalComments
        accreditation={evaluation}
        RNType="506"
        startPage={totalPages}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.PRE][
            evaluation?.classification
          ]
        }
      />
    </>
  );
};

Pre.defaultProps = {
  evaluation: {},
  onlyGaps: false
};

Pre.propTypes = {
  evaluation: PropTypes.any,
  onlyGaps: PropTypes.bool
};

export default Pre;
