import styled from 'styled-components';
import { Nav } from 'reactstrap';

export const TitleMenu = styled.div`
  display: flex;
  font-weight: 700 !important;
  flex-direction: row;
  font-size: 0.6975rem;
  margin-top: 5px !important;
  margin-bottom: -17px !important;

  .separator {
    width: 44px;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0)
    );
    &.--100 {
      width: 100% !important;
    }
  }

  .side-title {
    font-size: 10px;
  }
`;

export const NavBar = styled(Nav)`
  margin-top: ${(props) => (props.isDev ? '20px' : '-5px')} !important;
`;
