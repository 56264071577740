import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionsButtons } from '~/components';
import { evaluatorsRequest } from '~/store/modules/evaluators/actions';
import { EnabledEvaluator } from '~/services/private';

import history from '~/config/history';
import BasePage from '~/components/BasePage';
import { ADMIN } from '~/routes/names';

const Evaluators = () => {
  const { evaluators, fetch } = useSelector((state) => state.evaluators);
  const dispatch = useDispatch();

  const listEvaluators = useCallback(() => dispatch(evaluatorsRequest()), [dispatch]);

  const ActiveEvaluator = async (account) => {
    try {
      const { data } = await EnabledEvaluator({
        idAccount: account.id,
        status: !account.active
      });
      toast.success(data.result);
      listEvaluators();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    }
  };

  const AddEvaluator = () => {
    history.push(ADMIN.EVALUATORS_NEW);
  };

  const EditEvaluator = (userID, account) => {
    const userFilter = evaluators[account.active ? 'active' : 'inactive'].filter(
      (elm) => elm.id === userID
    );

    const evaluator = {
      ...userFilter[0],
      minimumExperienceInBusinessAudit: userFilter[0].minimumExperienceInBusinessAudit ? '1' : '0',
      minimumExperienceInControllership: userFilter[0].minimumExperienceInControllership
        ? '1'
        : '0',
      minimumExperienceInHealthAccreditation: userFilter[0].minimumExperienceInHealthAccreditation
        ? '1'
        : '0',
      minimumExperienceInHealthAaudit: userFilter[0].minimumExperienceInHealthAaudit ? '1' : '0',
      graduate: userFilter[0].universityGraduate,
      accountId: userFilter[0].account.id,
      email: userFilter[0].account.email
    };
    history.push(ADMIN.EVALUATORS_EDIT, { evaluator });
  };

  useEffect(() => {
    listEvaluators();
  }, [listEvaluators]);

  const columns = [
    {
      name: 'name',
      label: 'Nome'
    },
    {
      name: 'cpf',
      label: 'Cpf'
    },
    {
      name: 'phone',
      label: 'Telefone'
    },
    {
      name: 'account',
      label: 'E-Mails',
      options: {
        customBodyRender: (value) => value.email
      }
    },
    {
      name: 'account',
      label: 'Ativo',
      options: {
        customBodyRender: (value) => (value.active ? 'Sim' : 'Não')
      }
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, data) => (
          <ActionsButtons
            labelModal="o avaliador"
            hasActive
            active={data.rowData[3]?.active}
            hasEdit
            actionEdit={() => EditEvaluator(value, data.rowData[3])}
            actionPower={() => ActiveEvaluator(data.rowData[3])}
          />
        )
      }
    }
  ];

  return (
    <BasePage
      title="Avaliadores"
      text="Os avaliadores abaixo possuem acesso ao gerenciador."
      btnText="Cadastrar novo avaliador"
      btnAction={AddEvaluator}
      columns={columns}
      itsLoading={fetch.itsLoading}
      data={evaluators}
    />
  );
};
export default Evaluators;
