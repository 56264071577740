import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent } from './styles';
import { Modal } from '~/components';
import { CompanyEditFields } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';

const CompanyFields = forwardRef(({ id, fields }, ref) => {
  const [fieldsState, setFieldsState] = useState(fields);

  const [openModal, setOpenModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [myTimeOut, setMyTimeOut] = useState(null);

  const changeVisibleModal = (active) => setOpenModal(active);

  const handleChange = (event, field) => {
    const text = event.target.value;
    setFieldsState({
      ...fieldsState,
      [field]: text
    });
    if (myTimeOut) clearTimeout(myTimeOut);
  };

  const changeFields = useCallback(async () => {
    try {
      setLoadingDetails(true);
      await CompanyEditFields({
        id,
        ...fieldsState
      });
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático da data');
    } finally {
      setLoadingDetails(false);
    }
  }, [id, fieldsState]);

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  useEffect(() => {
    setMyTimeOut(setTimeout(changeFields, 1000));
  }, [fieldsState, changeFields]);

  const LoadingSaving = () => (
    <div>
      {loadingDetails ? (
        <Saving>
          <Spinner size="sm" animation="border" />
          ...salvando
        </Saving>
      ) : null}
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Dados da operadora"
        size="lg"
        unmountOnClose={false}
      >
        {LoadingSaving()}
        <ModalContainer>
          <ModalContent>
            <Row>
              <Col xs="6">
                <L.Input
                  label="Porte"
                  name="companyPort"
                  value={fieldsState?.companyPort || ''}
                  onChange={(event) => {
                    handleChange(event, 'companyPort');
                  }}
                />
              </Col>
              <Col xs="6">
                <L.Input
                  label="IDSS"
                  name="companyIdss"
                  value={fieldsState?.companyIdss || ''}
                  onChange={(event) => {
                    handleChange(event, 'companyIdss');
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <L.Input
                  label="Beneficiários"
                  name="companyNumberOfBeneficiaries"
                  value={fieldsState?.companyNumberOfBeneficiaries || ''}
                  onChange={(event) => {
                    handleChange(event, 'companyNumberOfBeneficiaries');
                  }}
                />
              </Col>
              <Col xs="6">
                <L.Input
                  label="Colaboradores"
                  name="companyNumberOfEmployees"
                  value={fieldsState?.companyNumberOfEmployees || ''}
                  onChange={(event) => {
                    handleChange(event, 'companyNumberOfEmployees');
                  }}
                />
              </Col>
            </Row>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

CompanyFields.defaultProps = {
  id: 0,
  fields: {}
};

CompanyFields.propTypes = {
  id: PropTypes.number,
  fields: PropTypes.object
};

export default CompanyFields;
