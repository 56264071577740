import produce from 'immer';
import {
  EVALUATIONS_REQUEST,
  EVALUATIONS_SET_LIST,
  EVALUATIONS_SET_DIARY_TEMP,
  EVALUATIONS_SET_DIARY_BLACK_LIST,
  EVALUATIONS_SET_ITS_LOADING
} from './patterns';

const INITIAL_STATE = {
  evaluations: {
    open: [],
    started: [],
    submittedForApproval: [],
    analysisAndDecision: [],
    correctionAndAdjustments: [],
    finished: []
  },
  diaryTemp: [],
  diaryTempBlackList: [],
  fetch: {
    loading: false,
    error: false
  }
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case EVALUATIONS_REQUEST: {
        draft.fetch.itsLoading = true;
        break;
      }
      case EVALUATIONS_SET_DIARY_TEMP: {
        draft.diaryTemp = action.payload;
        break;
      }
      case EVALUATIONS_SET_DIARY_BLACK_LIST: {
        draft.diaryTempBlackList = action.payload;
        break;
      }
      case EVALUATIONS_SET_LIST: {
        draft.evaluations = action.payload.evaluations;
        break;
      }
      case EVALUATIONS_SET_ITS_LOADING: {
        draft.fetch.itsLoading = action.itsLoading;
        break;
      }
      default:
    }
  });
}
