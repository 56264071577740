import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const Input = styled(NumberFormat)`
  width: 100%;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;
  color: #1d1d1d;
  padding: 0 16px;
  &:focus {
    border: 1px solid #1d1d1d;
  }
  &:hover {
    border: 1px solid #1d1d1d;
  }
`;
