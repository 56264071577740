import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { Button, DataTable } from '~/components';

import * as S from './styles';

const BasePage = ({
  title,
  text,
  columns,
  btnText,
  btnAction,
  itsLoading,
  data,
  hideForSubpage,
  btnProps
}) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  {!hideForSubpage && <h5 className="card-category">Administrativo</h5>}
                  {!hideForSubpage && <CardTitle tag="h2">{title}</CardTitle>}
                  <h5>{text}</h5>
                  {btnText && <Button title={btnText} onClick={btnAction} {...btnProps} />}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Ativos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      Inativos
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <DataTable loading={itsLoading} columns={columns} data={data.active} />
                  </TabPane>
                  <TabPane tabId="2">
                    <DataTable loading={itsLoading} columns={columns} data={data.inactive} />
                  </TabPane>
                </TabContent>
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

BasePage.defaultProps = {
  title: '',
  text: '',
  itsLoading: false,
  columns: [],
  data: [],
  btnText: undefined,
  btnProps: {},
  hideForSubpage: false,
  btnAction: () => {}
};

BasePage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  itsLoading: PropTypes.bool,
  columns: PropTypes.any,
  data: PropTypes.any,
  btnText: PropTypes.string,
  btnAction: PropTypes.any,
  btnProps: PropTypes.any,
  hideForSubpage: PropTypes.bool
};

export default BasePage;
