import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { CreateEvaluation, GetCompanyUsers } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { Button, Modal, Select } from '~/components';
import { validateForm } from './validateForm';
import { companiesRequest } from '~/store/modules/companies/actions';
import { evaluatorsRequest } from '~/store/modules/evaluators/actions';
import { userRequest } from '~/store/modules/users/actions';
import {
  RN_507_TYPES,
  EVALUATION_TYPE,
  DIMENSIONS,
  REQUIREMENT_NUMBERS,
  RN_506_TYPES
} from '~/constants';
import * as S from '../styles';

const INITIAL_STATE_FORM = {
  id: '',
  company: '',
  director: '',
  evaluators: [],
  dimensions: [],
  requirementNumbers: [],
  leader: '',
  leaderSelf: ''
};

const ModalALLRN = ({
  evaluationType,
  classification,
  RNType,
  openModal,
  setOpenModal,
  textPage,
  listEvaluations
}) => {
  const { companies } = useSelector((state) => state.companies);
  const { evaluators } = useSelector((state) => state.evaluators);
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const location = useLocation();

  const [evaluationForm, setEvaluationForm] = useState(INITIAL_STATE_FORM);
  const [listCompany, setListCompany] = useState([]);
  const [listEvaluator, setListEvaluator] = useState([]);
  const [listEvaluatorClean, setListEvaluatorClean] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingListCompanyUsers, setLoadingListCompanyUsers] = useState(false);
  const [listCompanyUsers, setListCompanyUsers] = useState([]);
  const [errorDecision, setErrorDecision] = useState(false);

  const [leader, setLeader] = useState({});
  const [director, setDirector] = useState({});

  const listCompanies = useCallback(() => dispatch(companiesRequest()), [dispatch]);
  const listEvaluators = useCallback(() => dispatch(evaluatorsRequest()), [dispatch]);
  const listUsersAdmin = useCallback(() => dispatch(userRequest()), [dispatch]);

  const getCompaniesUsers = async (event) => {
    try {
      const { value: companyId } = event.target;

      setLoadingListCompanyUsers(true);
      setLeader({});
      const { data } = await GetCompanyUsers(companyId);
      const prepareUsers = data.active.map((elm) => ({
        value: `c_${elm.id}`,
        label: elm.name
      }));
      setListCompanyUsers(prepareUsers);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar na listagem de usuários.');
    } finally {
      setLoadingListCompanyUsers(false);
    }
  };

  useEffect(() => {
    listCompanies();
    listEvaluators();
    listUsersAdmin();
  }, [
    location.pathname,
    listEvaluations,
    listCompanies,
    listEvaluators,
    listUsersAdmin,
    RNType,
    classification
  ]);

  useEffect(() => {
    if (openModal) {
      setEvaluationForm(INITIAL_STATE_FORM);
    }
  }, [openModal]);

  useEffect(() => {
    const prepareCompanies = companies.active.map((elm) => ({
      id: elm.id,
      title: elm.name
    }));
    setListCompany(prepareCompanies);

    const prepareUsers = users.active.map((elm) => ({
      value: elm.id,
      label: elm.name
    }));
    setListUsers(prepareUsers);

    const prepareGroupEvaluator = evaluators.active.map((elm) => ({
      value: `e_${elm.id}`,
      label: elm.name
    }));

    const prepareGroupUsers = users.active.map((elm) => ({
      value: `d_${elm.id}`,
      label: elm.name
    }));

    const groupedOptions = [
      {
        label: 'Avaliadores',
        options: prepareGroupEvaluator
      },
      {
        label: 'Diretoria',
        options: prepareGroupUsers
      }
    ];
    setListEvaluator(groupedOptions);
    setListEvaluatorClean(
      evaluators.active.map((elm) => ({
        value: elm.id,
        label: elm.name
      }))
    );
  }, [companies, evaluators, users]);

  const createNewRN507 = async (data) => {
    try {
      const dimensions = data?.dimensions
        ? {
            dimensions: data.dimensions.map((elm) => elm.value)
          }
        : {};

      const newObj = {
        company: data.company,
        evaluators: data.evaluators ? data.evaluators.map((elm) => elm.value) : null,
        observerEvaluators: data.evaluatorObserver
          ? data.evaluatorObserver.map((elm) => elm.value)
          : null,
        leader: leader.value,
        director: director.value,
        ...dimensions
      };

      if (
        RNType !== RN_507_TYPES.SELF_EVALUATION &&
        newObj.evaluators.includes(`d_${newObj.director}`)
      ) {
        setErrorDecision(true);
      } else {
        await create(newObj);
      }
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoading(false);
    }
  };

  const createNewRN506 = async (data) => {
    try {
      const requirementNumbers = data?.requirementNumbers
        ? {
            requirementNumbers: data.requirementNumbers.map((elm) => elm.value)
          }
        : {};

      const newObj = {
        company: data.company,
        evaluators: data.evaluators ? data.evaluators.map((elm) => elm.value) : null,
        observerEvaluators: data.evaluatorObserver
          ? data.evaluatorObserver.map((elm) => elm.value)
          : null,
        leader: leader.value,
        director: director.value,
        ...requirementNumbers
      };

      if (
        RNType !== RN_506_TYPES.SELF_EVALUATION &&
        newObj.evaluators.includes(`d_${newObj.director}`)
      ) {
        setErrorDecision(true);
      } else {
        await create(newObj);
      }
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoading(false);
    }
  };

  const create = async (newObj) => {
    try {
      setErrorDecision(false);
      setLoading(true);
      const obj = await CreateEvaluation(
        {
          ...newObj,
          type: RNType,
          classification
        },
        evaluationType
      );
      toast.success(obj.data);
      setOpenModal(false);
      await listEvaluations();
      setLeader({});
      setDirector({});
      setEvaluationForm(INITIAL_STATE_FORM);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoading(false);
    }
  };

  const FormRN507 = () => (
    <L.Form schema={validateForm(RNType)} onSubmit={createNewRN507} initialData={evaluationForm}>
      <Row>
        <Col xs="6">
          <L.InputContainer>
            <L.Select
              label="Cliente"
              name="company"
              options={listCompany}
              onChange={getCompaniesUsers}
            />
          </L.InputContainer>
        </Col>
        {RNType === RN_507_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label="Líder"
                isMulti={false}
                loading={loadingListCompanyUsers}
                name="leaderSelf"
                list={listCompanyUsers}
                onChangeOption={setLeader}
              />
            </L.InputContainer>
          </Col>
        )}
        {RNType !== RN_507_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select label="Avaliadores" name="evaluators" list={listEvaluator} />
            </L.InputContainer>
          </Col>
        )}
        {RNType !== RN_507_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label="Líder"
                isMulti={false}
                name="leader"
                list={listEvaluator}
                onChangeOption={setLeader}
              />
            </L.InputContainer>
          </Col>
        )}
        <Col xs="6">
          <L.InputContainer>
            <Select
              label="Avaliadores Observadores"
              name="evaluatorObserver"
              list={listEvaluatorClean}
            />
          </L.InputContainer>
        </Col>
        {RNType === RN_507_TYPES.ACCREDITATION || RNType === RN_507_TYPES.SELF_EVALUATION ? (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label={`Responsável ${
                  RNType === RN_507_TYPES.SELF_EVALUATION
                    ? 'pelo Feedback'
                    : 'pela análise e decisão'
                }`}
                isMulti={false}
                name="director"
                onChangeOption={setDirector}
                list={listUsers}
              />
            </L.InputContainer>
          </Col>
        ) : null}

        {RNType === RN_507_TYPES.SUPERVISION && (
          <Col xs="6">
            <L.InputContainer>
              <Select label="Dimensões Avaliadas" name="dimensions" list={DIMENSIONS} />
            </L.InputContainer>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="6">
          {errorDecision && (
            <S.Error>
              A diretoria que realizará a análise e decisão não deverá participar da avaliação
              inicial.
            </S.Error>
          )}
          <L.ButtonContainer>
            <Button title="Cadastrar" type="submit" loading={loading} block />
          </L.ButtonContainer>
        </Col>
      </Row>
    </L.Form>
  );

  const FormRN506 = () => (
    <L.Form
      schema={validateForm(RNType, evaluationType)}
      onSubmit={createNewRN506}
      initialData={evaluationForm}
    >
      <Row>
        <Col xs="6">
          <L.InputContainer>
            <L.Select
              label="Cliente"
              name="company"
              options={listCompany}
              onChange={getCompaniesUsers}
            />
          </L.InputContainer>
        </Col>
        {RNType === RN_506_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label="Líder"
                isMulti={false}
                loading={loadingListCompanyUsers}
                name="leaderSelf"
                list={listCompanyUsers}
                onChangeOption={setLeader}
              />
            </L.InputContainer>
          </Col>
        )}
        {RNType !== RN_506_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select label="Avaliadores" name="evaluators" list={listEvaluator} />
            </L.InputContainer>
          </Col>
        )}
        {RNType !== RN_506_TYPES.SELF_EVALUATION && (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label="Líder"
                isMulti={false}
                name="leader"
                list={listEvaluator}
                onChangeOption={setLeader}
              />
            </L.InputContainer>
          </Col>
        )}
        <Col xs="6">
          <L.InputContainer>
            <Select
              label="Avaliadores Observadores"
              name="evaluatorObserver"
              list={listEvaluatorClean}
            />
          </L.InputContainer>
        </Col>
        {RNType === RN_506_TYPES.CERTIFICATION || RNType === RN_506_TYPES.SELF_EVALUATION ? (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label={`Responsável ${
                  RNType === RN_506_TYPES.SELF_EVALUATION
                    ? 'pelo Feedback'
                    : 'pela análise e decisão'
                }`}
                isMulti={false}
                name="director"
                onChangeOption={setDirector}
                list={listUsers}
              />
            </L.InputContainer>
          </Col>
        ) : null}

        {RNType === RN_506_TYPES.SUPERVISION && (
          <Col xs="6">
            <L.InputContainer>
              <Select
                label="Requisitos Avaliados"
                name="requirementNumbers"
                list={REQUIREMENT_NUMBERS}
              />
            </L.InputContainer>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="6">
          {errorDecision && (
            <S.Error>
              A diretoria que realizará a análise e decisão não deverá participar da avaliação
              inicial.
            </S.Error>
          )}
          <L.ButtonContainer>
            <Button title="Cadastrar" type="submit" loading={loading} block />
          </L.ButtonContainer>
        </Col>
      </Row>
    </L.Form>
  );

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      title={`Cadastrar ${textPage.title}`}
    >
      {evaluationType === EVALUATION_TYPE.RN_507 && FormRN507()}
      {evaluationType === EVALUATION_TYPE.RN_506 && FormRN506()}
    </Modal>
  );
};
export default ModalALLRN;
