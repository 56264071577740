import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import Cover from '~/components/Report/Cover';
import BackCover from '~/components/Report/BackCover';
import DimensionsData from '~/components/Report/DimensionsData';
import Prerequisites from '~/components/Report/Prerequisites';
import Resume from '~/components/Report/Resume';
import Meeting from '~/components/Report/Meeting';
// import Diary from '~/components/Report/Diary';
import FinalComments from '~/components/Report/FinalComments';
import PeopleInterviewed from '~/components/Report/PeopleInterviewed';
import { calculatePages } from '~/config/helpers';
import {
  TYPES_MEETINGS,
  EDITION_REPORT_RN_507,
  RN_507_TYPES,
  EVALUATION_TYPE,
  RN_507_CLASSIFICATION_NAME,
  ATTACHMENT_REPORT,
  ACCREDITED_REPORT,
  CONSIDERATIONS_REPORT,
  EVALUATION_TYPE_TEXT,
  RN_507_REPORT_OBJECTIVE,
  RN_507_SCOPE_REPORT,
  RN_507_MINIMUM_OF_EXCELLENCE_ITEMS,
  RN_507_CONSIDERATIONS_ITEMS,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';

const Accreditation = ({ accreditation }) => {
  const [totalPages, setTotalPages] = useState(0);
  const pagesDetails = calculatePages(accreditation, 8, 7, 2, totalPages, (total) =>
    setTotalPages(total + 1)
  );

  return (
    <>
      <Cover
        evaluationType={`Relatório de Acreditação ${
          RN_507_CLASSIFICATION_NAME[accreditation?.classification]
        }`}
        evaluationObjective={
          RN_507_REPORT_OBJECTIVE[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
        evaluationScope={
          RN_507_SCOPE_REPORT[RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
        attachmentReport={
          ATTACHMENT_REPORT[CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)][
            EVALUATION_TYPE.RN_507
          ]
        }
        accredited={ACCREDITED_REPORT[EVALUATION_TYPE.RN_507]}
        accreditation={accreditation}
        evaluators={[...accreditation?.evaluators, ...accreditation?.evaluatorsAdmin]}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
        isPre={accreditation?.type_rn452 === RN_507_TYPES.PRE}
        isSelf={accreditation?.type_rn452 === RN_507_TYPES.SELF_EVALUATION}
        isSuper={accreditation?.type_rn452 === RN_507_TYPES.SUPERVISION}
      />
      <BackCover
        accreditation={accreditation}
        startPage={2}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
        minimumOfExcellenceItems={RN_507_MINIMUM_OF_EXCELLENCE_ITEMS[accreditation?.classification]}
        considerations={
          CONSIDERATIONS_REPORT(
            RN_507_CONSIDERATIONS_ITEMS[
              CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
            ][accreditation?.classification]
          )[EVALUATION_TYPE.RN_507][RN_507_TYPES.ACCREDITATION]
        }
        isPre={accreditation?.type_rn452 === RN_507_TYPES.PRE}
        isSelf={accreditation?.type_rn452 === RN_507_TYPES.SELF_EVALUATION}
        isSuper={accreditation?.type_rn452 === RN_507_TYPES.SUPERVISION}
      />
      <Prerequisites
        prerequisites={accreditation?.prerequisites}
        startPage={3}
        reportName="Acreditação"
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
      />
      <Resume
        data={accreditation?.resume}
        startPage={4}
        totalPages={totalPages}
        evaluationTextType={EVALUATION_TYPE_TEXT.RN_507}
        totalItems={
          RN_507_CONSIDERATIONS_ITEMS[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][accreditation?.classification]
        }
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
        type={RN_507_TYPES.ACCREDITATION}
      />
      <Meeting
        meeting={accreditation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.INITIAL)[0]}
        startPage={5}
        totalPages={totalPages}
        evaluationType={EVALUATION_TYPE.RN_507}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
      />
      <PeopleInterviewed
        people={accreditation?.peopleInterviewed}
        startPage={6}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
      />
      <Meeting
        meeting={accreditation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.FINAL)[0]}
        startPage={7}
        totalPages={totalPages + 1}
        evaluationType={EVALUATION_TYPE.RN_507}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
      />
      {/* <Diary */}
      {/*  diary={accreditation?.diary} */}
      {/*  startPage={8} */}
      {/*  totalPages={totalPages} */}
      {/*  editionReport={EDITION_REPORT_RN_507_ACCREDITATION[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][accreditation?.classification]} */}
      {/*  evaluators={{ */}
      {/*    evaluators: accreditation?.evaluators, */}
      {/*    evaluatorsAdmin: accreditation?.evaluatorsAdmin, */}
      {/*    evaluatorsCompany: accreditation?.evaluatorsCompany */}
      {/*  }} */}
      {/* /> */}
      {accreditation?.dimensionsList.map((elm) => (
        <DimensionsData
          key={uniqId()}
          id={elm}
          data={accreditation.dimensions[elm]}
          startPage={pagesDetails[elm].start}
          separatorRequirements={pagesDetails[elm].separatorRequirements}
          totalPages={totalPages}
          auditedAreas={accreditation?.auditedAreas[elm]}
          evaluationType={EVALUATION_TYPE.RN_507}
          editionReport={
            EDITION_REPORT_RN_507[
              CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
            ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
          }
        />
      ))}
      <FinalComments
        accreditation={accreditation}
        startPage={totalPages}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.ACCREDITATION][accreditation?.classification]
        }
      />
    </>
  );
};

Accreditation.defaultProps = {
  accreditation: {}
};

Accreditation.propTypes = {
  accreditation: PropTypes.any
};

export default Accreditation;
