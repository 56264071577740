import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle, Row, Col, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import * as S from './styles';
import { chartOptions as chartDefault, defaultDatasets } from '~/constants/charts';
import { colors, EVALUATION_TYPE, RN_507_CLASSIFICATION, RN_507_TYPES } from '~/constants';
import { GetGraphicsDimensions } from '~/services/private';

const Home = () => {
  const { user } = useSelector((state) => state.auth);
  const [loadingChart, setLoadingChart] = useState(false);
  const [dimensionList, setDimensionList] = useState([]);
  const [media, setMedia] = useState([]);
  const [my, setMy] = useState([]);

  const dataChart = (canvas) => {
    return {
      labels: dimensionList,
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Média',
          borderColor: colors.primary,
          pointBackgroundColor: colors.primary,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: colors.primary,
          data: media.map((elm) => elm.toFixed(2))
        },
        {
          ...defaultDatasets(canvas),
          label: 'Atingida',
          borderColor: '#1f8ef1',
          pointBackgroundColor: '#1f8ef1',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#1f8ef1',
          data: my.map((elm) => elm.toFixed(2))
        }
      ]
    };
  };

  const chartOptions = {
    ...chartDefault,
    legend: {
      display: true
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 20,
            padding: 20,
            fontColor: '#9a9a9a'
          }
        }
      ]
    }
  };

  const getGraph = useCallback(async () => {
    try {
      setLoadingChart(true);
      const { data } = await GetGraphicsDimensions(
        EVALUATION_TYPE.RN_507,
        RN_507_CLASSIFICATION.MEDICAL_HOSPITAL,
        RN_507_TYPES.SELF_EVALUATION
      );

      setDimensionList(Object.keys(data.my).map((key) => `Dimensão ${key}`));
      setMedia(Object.keys(data.media).map((key) => parseFloat(data.media[key].dimensionScore)));
      setMy(Object.keys(data.my).map((key) => parseFloat(data.my[key].dimensionScore)));
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao gerar o gráfico.');
    } finally {
      setLoadingChart(false);
    }
  }, []);

  useEffect(() => {
    getGraph().finally(() => {});
  }, [getGraph]);

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Área do cliente</h5>
                  <CardTitle tag="h2">Bem vindo - {user.name}</CardTitle>
                  <CardSubtitle>Você está no sistema de gestão de avaliações.</CardSubtitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <S.Text>
                  Somos a única entidade acreditadora dedicada às regras da ANS, com mais
                  experiência em Acreditação e a primeira homologada para a Certificação em APS
                </S.Text>
                <S.Text>
                  <span>
                    Com foco em organizações do setor de Saúde, a A4Quality disponibiliza um
                    conjunto de produtos e serviços que visam agregar valor aos seus clientes na
                    busca pela perpetuação e sucesso de seus negócios. Os nossos produtos contribuem
                    para o aprendizado organizacional dos seus clientes, e melhoria de desempenho de
                    seus negócios.
                  </span>
                </S.Text>
                <S.Text bold>
                  <span>
                    Abaixo está representado sua média perante outras operadoras. Continue
                    realizando suas avaliações para que você possa atuar nos seus pontos de
                    melhoria.
                  </span>
                </S.Text>
                {loadingChart ? (
                  <div className="content">
                    <Row>
                      <Col xs="12">
                        <S.Loading>
                          <Spinner size="md" color="white" animation="border" />
                        </S.Loading>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="chart-area">
                    <Line data={dataChart} options={chartOptions} />
                  </div>
                )}
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Home;
