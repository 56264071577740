import * as Yup from 'yup';
import { REGEX } from '~/constants';

export const validateForm = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cnpj: Yup.string().required('O CNPJ é obrigatório'),
  ansRecord: Yup.string().required('O Registro ANS é obrigatório'),
  segmentation: Yup.string().required('Este campo é obrigatório'),
  address: Yup.string().required('Este campo é obrigatório'),
  port: Yup.string().required('Este campo é obrigatório'),
  numberOfEmployees: Yup.string().required('Este campo é obrigatório'),
  numberOfBeneficiaries: Yup.string().required('Este campo é obrigatório'),
  idss: Yup.string().required('Este campo é obrigatório'),
  contactPerson: Yup.string().required('A pessoa de contato é obrigatória'),
  phone: Yup.string()
    .min(14, 'Por favor digite um telefone válido.')
    .required('Este campo é obrigatório'),
  email: Yup.string()
    .matches(REGEX.email, {
      message: 'Esse não é um e-mail válido',
      excludeEmptyString: true
    })
    .required('Este campo é obrigatório')
});

export const validateFormUser = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cpf: Yup.string().required('O CPF é obrigatório'),
  phone: Yup.string()
    .min(14, 'Por favor digite um telefone válido.')
    .required('Este campo é obrigatório'),
  type: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .matches(REGEX.email, {
      message: 'Esse não é um e-mail válido',
      excludeEmptyString: true
    })
    .required('Este campo é obrigatório')
});
