import React from 'react';
import { Button as Btn, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';

const Button = ({ loading, title, disabled, ...rest }) => {
  return (
    <Btn disabled={disabled || loading} {...rest}>
      {!loading ? title : <Spinner size="sm" color="white" animation="border" />}
    </Btn>
  );
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  title: 'Título'
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.any
};

export default Button;
