export const EVALUATION_TYPE = {
  RN_507: 1,
  RN_506: 2
};

export const EVALUATION_TYPE_TEXT = {
  RN_506: '506',
  RN_507: '507'
};

export const RN_507_TYPES = {
  PRE: 1,
  SUPERVISION: 2,
  ACCREDITATION: 3,
  SELF_EVALUATION: 4
};

export const RN_506_TYPES = {
  PRE: 1,
  SUPERVISION: 2,
  CERTIFICATION: 3,
  SELF_EVALUATION: 4
};

export const RN_507_CLASSIFICATION = {
  MEDICAL_HOSPITAL: 1,
  DENTAL: 2,
  SELF_MANAGEMENT: 3
};

export const RN_507_CLASSIFICATION_NAME = {
  1: 'Médico Hospitalar',
  2: 'Odontológica',
  3: 'Autogestão'
};

export const RN_507_CLASSIFICATION_NAME_FOR_REPORT = {
  1: 'Médico Hospitalar',
  2: 'Odontológico',
  3: 'Autogestão'
};

export const RN_506_CLASSIFICATION = {
  APS: 1
};

export const RN_506_CLASSIFICATION_NAME = {
  1: 'APS'
};

export const RN_507_CLASSIFICATION_ROUTES = {
  MEDICAL_HOSPITAL: '/medical-hospital/',
  DENTAL: '/dental/',
  SELF_MANAGEMENT: '/self-management/'
};

export const RN_506_CLASSIFICATION_ROUTES = {
  APS: '/APS/'
};

export const RN_507_CLASSIFICATION_FOR_ROUTER = (path) => {
  if (path.includes(RN_507_CLASSIFICATION_ROUTES.MEDICAL_HOSPITAL))
    return RN_507_CLASSIFICATION.MEDICAL_HOSPITAL;
  if (path.includes(RN_507_CLASSIFICATION_ROUTES.DENTAL)) return RN_507_CLASSIFICATION.DENTAL;
  if (path.includes(RN_507_CLASSIFICATION_ROUTES.SELF_MANAGEMENT))
    return RN_507_CLASSIFICATION.SELF_MANAGEMENT;
  return null;
};

export const TYPES_REQUIREMENTS = {
  EXCELLENCE: 1,
  ESSENTIAL: 2,
  COMPLEMENTARY: 3
};

export const TYPES_MEETINGS = {
  INITIAL: 1,
  FINAL: 2
};

export const TYPES_LOGS = {
  MONITORED_INDICATORS: 1,
  REQUIREMENTS_ITEMS_SCOPE: 2,
  REQUIREMENTS_ITEMS_DEPLOYMENT_TIME: 3,
  REQUIREMENTS_ITEMS_COMMENT: 4,
  REQUIREMENTS_ITEMS_EVIDENCE: 5,
  REQUIREMENTS_ITEMS_FEEDBACK: 6,
  REQUIREMENTS_ITEMS_CHANGED_POINT: 7,
  REQUIREMENTS_ITEMS_IMPROVEMENT_OPPORTUNITY: 8,
  REQUIREMENTS_ITEMS_STRONG_POINT: 9,
  REQUIREMENTS_ITEMS_NON_ATTENDANCE: 10,
  PRE_REQUISITES: 11,
  RESUME: 12,
  MEETING: 13,
  PEOPLE_INTERVIEWED: 14,
  AUDITED_AREAS: 15
};

export const TYPES_LOGS_NAME = {
  1: 'Indicadores Monitorados',
  2: 'Escopo',
  3: 'Tempo de implantação',
  4: 'Comentários',
  5: 'Evidências',
  6: 'Feedback',
  7: 'Mudança de Pontuação',
  8: 'Oportunidade de melhoria',
  9: 'Pontos fortes',
  10: 'Não atendimento',
  11: 'Pré requisitos',
  12: 'Resumo',
  13: 'Reunião',
  14: 'Pessoas Entrevistadas',
  15: 'Áreas auditadas'
};
