import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';

const Indicators = ({ id, data, auditedAreas, startPage, totalPages, editionReport }) => {
  const newAudited =
    auditedAreas && Array.isArray(auditedAreas)
      ? auditedAreas.reduce((acc, elm, index) => {
          if (index % 2 === 0) {
            acc.push([elm.name, auditedAreas[index + 1]?.name]);
          }
          return acc;
        }, [])
      : [];

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      {id !== 1 && auditedAreas && Array.isArray(auditedAreas) && (
        <S.Table bottomMarginMin>
          <tbody>
            <tr>
              <th className="th-title" colSpan="4" scope="row">
                Áreas Auditadas
              </th>
            </tr>
            {newAudited.map((elm) => (
              <tr key={uniqId()}>
                <td colSpan="2" className="left --is-audited-areas">
                  {elm[0]}
                </td>
                <td colSpan="2" className="left --is-audited-areas">
                  {elm[1]}
                </td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      )}
      <S.Table bottomMarginMin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Documentos e Indicadores requeridos
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.Table bottomMarginMin requirementsTable>
        <tbody>
          <tr>
            <th className="th-title" colSpan="3">
              Descrição
            </th>
            <th className="th-title">Possui</th>
          </tr>
          {data?.indicators?.map((elm) => (
            <tr key={uniqId()}>
              <td className="td-title left" colSpan="3">{`${elm.numericMarkers} ${elm.text}`}</td>
              <td className="td-title --yesNo">{elm.itHas ? 'Sim' : 'Não'}</td>
            </tr>
          ))}
        </tbody>
      </S.Table>
      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

Indicators.defaultProps = {
  id: 0,
  data: {
    indicators: [],
    requirements: {},
    dimensionScore: ''
  },
  startPage: 0,
  totalPages: 0,
  auditedAreas: null,
  editionReport: ''
};

Indicators.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
  auditedAreas: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default Indicators;
