import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import Cover from '~/components/Report/Cover';
import DimensionsData from '~/components/Report/DimensionsData';
import { calculatePages } from '~/config/helpers';
import {
  ACCREDITED_REPORT,
  ATTACHMENT_REPORT,
  EVALUATION_TYPE,
  RN_506_CLASSIFICATION_NAME,
  RN_506_TYPES,
  EDITION_REPORT_RN_506,
  RN_506_REPORT_OBJECTIVE,
  RN_506_SCOPE_REPORT,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';

const Self = ({ evaluation }) => {
  const [totalPages, setTotalPages] = useState(0);
  const pagesDetails = calculatePages(evaluation, 2, 1, 2, totalPages, setTotalPages);

  return (
    <>
      <Cover
        evaluationType={`Relatório de Autoavaliação Assistida (${
          RN_506_CLASSIFICATION_NAME[evaluation?.classification]
        })`}
        evaluationObjective={
          RN_506_REPORT_OBJECTIVE[RN_506_TYPES.SELF_EVALUATION][evaluation?.classification]
        }
        evaluationScope={
          RN_506_SCOPE_REPORT[RN_506_TYPES.SELF_EVALUATION][evaluation?.classification]
        }
        attachmentReport={
          ATTACHMENT_REPORT[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][EVALUATION_TYPE.RN_506]
        }
        accredited={ACCREDITED_REPORT[EVALUATION_TYPE.RN_506]}
        accreditation={evaluation}
        evaluators={evaluation?.evaluatorsCompany}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.SELF_EVALUATION][
            evaluation?.classification
          ]
        }
        isPre={evaluation?.type_rn440 === RN_506_TYPES.PRE}
        isSelf={evaluation?.type_rn440 === RN_506_TYPES.SELF_EVALUATION}
        isSuper={evaluation?.type_rn440 === RN_506_TYPES.SUPERVISION}
      />
      {evaluation?.dimensionsList.map((elm) => (
        <DimensionsData
          key={uniqId()}
          id={elm}
          showIndicators={false}
          data={evaluation.dimensions[elm]}
          startPage={pagesDetails[elm].start}
          separatorRequirements={pagesDetails[elm].separatorRequirements}
          totalPages={totalPages}
          evaluationType={EVALUATION_TYPE.RN_506}
          editionReport={
            EDITION_REPORT_RN_506[CALCULATE_FOR_WEATHER_VERSIONS(evaluation?.createdDateTimestamp)][RN_506_TYPES.SELF_EVALUATION][
              evaluation?.classification
            ]
          }
        />
      ))}
    </>
  );
};

Self.defaultProps = {
  evaluation: {}
};

Self.propTypes = {
  evaluation: PropTypes.any
};

export default Self;
