import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { companiesRequest } from '~/store/modules/companies/actions';

import BasePage from '~/components/BasePage';
import { ActionsButtons } from '~/components';
import { EnabledCompany } from '~/services/private';
import history from '~/config/history';
import { ADMIN } from '~/routes/names';

const Users = () => {
  const { companies, fetch } = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  const listCompanies = useCallback(() => dispatch(companiesRequest()), [dispatch]);

  useEffect(() => {
    listCompanies();
  }, [listCompanies]);

  const ActiveCompany = async (id, status) => {
    try {
      const { data } = await EnabledCompany({
        id,
        status: !status
      });
      toast.success(data.result);
      listCompanies();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    }
  };

  const AddCompany = () => {
    history.push(ADMIN.COMPANIES_NEW);
  };

  const EditCompany = (id, active) => {
    const companyFilter = companies[active ? 'active' : 'inactive'].filter((elm) => elm.id === id);

    const company = {
      ...companyFilter[0]
    };
    history.push(ADMIN.COMPANIES_EDIT, { company });
  };

  const columns = [
    {
      name: 'name',
      label: 'Nome'
    },
    {
      name: 'cnpj',
      label: 'CNPJ'
    },
    {
      name: 'active',
      label: 'Ativo',
      options: {
        customBodyRender: (value) => (value ? 'Sim' : 'Não')
      }
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, data) => (
          <ActionsButtons
            labelModal="a empresa"
            hasActive
            active={data.rowData[2]}
            hasEdit
            actionEdit={() => EditCompany(value, data.rowData[2])}
            actionPower={() => ActiveCompany(value, data.rowData[2])}
          />
        )
      }
    }
  ];

  return (
    <BasePage
      title="Clientes do sistema"
      text="Abaixo estão listadas todos os clientes da empresa, para visualizar os usuários acesse a página do cliente."
      itsLoading={fetch.itsLoading}
      columns={columns}
      btnText="Cadastrar novo cliente"
      btnAction={AddCompany}
      data={companies}
    />
  );
};
export default Users;
