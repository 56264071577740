import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Collapse } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';
import * as S from './styles';

const SidebarItemList = ({ name, icon, list }) => {
  const location = useLocation();

  useEffect(() => {
    changeActive(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const changeActive = (pathname) => setActive(pathname);

  return (
    <>
      <li className={isOpen ? 'active' : ''}>
        <S.Container
          onClick={() => setIsOpen(!isOpen)}
          className="nav-link"
          activeClassName="active"
        >
          <i className={icon} />
          <p>{name}</p>
        </S.Container>
      </li>
      <Collapse isOpen={isOpen}>
        <S.MenuContainer>
          {list.map((elm, i) => (
            <li key={`key-sidebar-item-list-${i}`} className={active === elm.route ? 'active' : ''}>
              <NavLink to={elm.route} className="nav-link" activeClassName="active">
                <p>{elm.name}</p>
              </NavLink>
            </li>
          ))}
        </S.MenuContainer>
      </Collapse>
    </>
  );
};

SidebarItemList.defaultProps = {
  name: 'Dashboard',
  icon: 'tim-icons icon-chart-pie-36',
  list: []
};

SidebarItemList.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  list: PropTypes.any
};

export default SidebarItemList;
