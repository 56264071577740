import Http from '~/config/http';

export const GetResume = (evaluationId) => Http.get(`/evaluation/resume/${evaluationId}`);

export const UpdateResume = (body) => Http.put(`/evaluation/resume`, body);

export const UpdateDateStart = (body) => Http.put(`/evaluation/date-start-end`, body);

export const UpdateDateStartValidity = (body) =>
  Http.put(`/evaluation/validity-date-start-end`, body);

export const CompanyEditFields = (body) => Http.put(`/evaluation/company-fields`, body);

export const UpdateMeeting = (body) => Http.put(`/evaluation/meeting`, body);

export const UpdatePeopleInterviewed = (body) => Http.put(`/evaluation/people-interviewed`, body);

export const AddAuditedAreas = (body) => Http.post(`/evaluation/audited-areas`, body);

export const RemoveAuditedArea = (id) => Http.delete(`/evaluation/audited-areas/${id}`);

export const UpdateDiary = (body) => Http.put(`/evaluation/upload-diary`, body);

export const UpdateEvaluationEvaluators = (body) => Http.put(`/evaluation/upload-evaluators`, body);

export const UpdateControlVisualization = (body) =>
  Http.put(`/evaluation/control-visualization`, body);
