import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { authSetLoading, authLogin } from './actions';
import { AUTH_REQUEST, AUTH_PROFILE_REQUEST } from './patterns';
import { Login } from '~/services/public';
import { GetProfile } from '~/services/private';

function* login({ payload }) {
  try {
    const { data, headers } = yield call(Login, payload);
    yield put(authLogin({ token: headers.authorization, user: data }));
    toast.success(`Bem vindo(a): ${data.name.split(' ')[0]}`);
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(authSetLoading(false));
  }
}

function* getProfile() {
  try {
    const { data, headers } = yield call(GetProfile);
    yield put(authLogin({ token: headers.authorization, user: data }));
    toast.success(`Perfil carregado`);
  } catch (err) {
    toast.error(err.data.result);
  } finally {
    yield put(authSetLoading(false));
  }
}

export default all([takeLatest(AUTH_REQUEST, login), takeLatest(AUTH_PROFILE_REQUEST, getProfile)]);
