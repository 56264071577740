import styled from 'styled-components';
import { colors } from '~/constants';

export const Body = styled.div`
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

export const EvaluationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 10px 20px 10px 20px !important;
  margin-bottom: 50px;
  h5 {
    margin-bottom: 0px !important;
  }
  // &:hover {
  //   -webkit-box-shadow: 0.5px 0.5px 12px 0.5px rgba(192, 192, 192, 0.19);
  //   -moz-box-shadow: 0.5px 0.5px 12px 0.5px rgba(192, 192, 192, 0.19);
  //   box-shadow: 0.5px 0.5px 12px 2px 0.5px rgba(192, 192, 192, 0.19);
  // }
`;

export const Title = styled.h4`
  margin-left: -10px !important;
  margin-bottom: 5px !important;
`;

export const Text = styled.p`
  margin-left: -10px !important;
`;

export const Btn = styled.div`
  max-width: 100px;
`;

export const ProgressContainer = styled.div`
  margin-top: 50px !important;
`;

export const ProgressTitleContainer = styled.div`
  width: 33px !important;
`;

export const ProgressTitle = styled.p`
  font-weight: bold;
  position: absolute;
  z-index: 2;
  top: -20px;
  right: ${(props) => (props.right ? props.right : '-13px')};
`;

export const Loading = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TabEmpty = styled.div`
  text-align: center;
  margin-top: 50px !important;
  h4 {
    font-size: 17pt !important;
  }
`;

export const ImgContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 50%;
`;

export const HR = styled.div`
  margin-top: 50px !important;
  clear: both !important;
  display: block !important;
  border: 0 !important;
  height: 1px !important;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0) !important;
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0) !important;
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0) !important;
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0) !important;
`;
