import { USER_REQUEST, USER_SET_USERS, USER_SET_ITS_LOADING } from './patterns';

export function userRequest() {
  return {
    type: USER_REQUEST
  };
}

export function setUsers(payload) {
  return {
    type: USER_SET_USERS,
    payload
  };
}

export function userSetLoading(itsLoading) {
  return {
    type: USER_SET_ITS_LOADING,
    itsLoading
  };
}
