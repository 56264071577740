import React from 'react';
import { PropTypes } from 'prop-types';
import DimensionCover from './DimensionCover';
import AuditedAreas from './AuditedAreas';
import Indicators from './Indicators';
import Requirements from './Requirements';

const DimensionsData = ({
  id,
  data,
  separatorRequirements,
  showIndicators,
  startPage,
  totalPages,
  auditedAreas,
  editionReport,
  evaluationType
}) => {
  return (
    <>
      <DimensionCover
        id={id}
        data={data}
        startPage={startPage}
        totalPages={totalPages}
        editionReport={editionReport}
        evaluationType={evaluationType}
      />
      {!showIndicators && (
        <AuditedAreas
          startPage={startPage + 1}
          totalPages={totalPages}
          auditedAreas={auditedAreas}
          editionReport={editionReport}
        />
      )}
      {showIndicators && id === 1 ? (
        <>
          <AuditedAreas
            startPage={startPage + 1}
            totalPages={totalPages}
            auditedAreas={auditedAreas}
            editionReport={editionReport}
          />
          <Indicators
            id={id}
            data={data}
            startPage={startPage + 2}
            totalPages={totalPages}
            auditedAreas={auditedAreas}
            editionReport={editionReport}
          />
        </>
      ) : showIndicators ? (
        <Indicators
          id={id}
          data={data}
          startPage={startPage + 1}
          totalPages={totalPages}
          auditedAreas={auditedAreas}
          editionReport={editionReport}
        />
      ) : null}
      <Requirements
        id={id}
        evaluationType={evaluationType}
        separatorRequirements={separatorRequirements}
        startPage={id === 1 ? startPage + 3 : startPage + 2}
        totalPages={totalPages}
        editionReport={editionReport}
      />
    </>
  );
};

DimensionsData.defaultProps = {
  id: 1,
  data: {
    indicators: [],
    requirements: {},
    dimensionScore: ''
  },
  separatorRequirements: [],
  startPage: 0,
  totalPages: 0,
  evaluationType: 0,
  auditedAreas: null,
  showIndicators: true,
  editionReport: ''
};

DimensionsData.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
  separatorRequirements: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  evaluationType: PropTypes.number,
  auditedAreas: PropTypes.any,
  showIndicators: PropTypes.bool,
  editionReport: PropTypes.string
};

export default DimensionsData;
