import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { evaluationsRequest } from '~/store/modules/evaluations/actions';
import { authConfirmTerm } from '~/store/modules/auth/actions';
import BasePageEvaluation from '~/components/BasePageEvaluation';
import { STATUS_EVALUATION, RN_507_TYPES, EVALUATION_TYPE, RN_506_TYPES } from '~/constants';
import history from '~/config/history';
import { Button, Modal } from '~/components';
import {
  ConfirmTerm,
  SubmitAnalysis,
  SubmitFinished,
  SubmitFinishedSupervision
} from '~/services/private';
import { textPageEvaluations } from '~/config/helpers';
import { RN_506_ROUTES, RN_507_ROUTES } from '~/routes/names';

const EvaluationListCompany = ({ evaluationType, classification, match }) => {
  const location = useLocation();
  const RNType = parseInt(match.params.type, 10);

  const { evaluations, fetch } = useSelector((store) => store.evaluations);
  const { user } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [modalSupervision, setModalSupervision] = useState(false);
  const [modalIsPre, setModalIsPre] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [confirmEvaluationID, setConfirmEvaluationID] = useState(null);
  const [confirmRNID, setConfirmRNID] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingTerm, setLoadingTerm] = useState(false);
  const [openModalTerm, setOpenModalTerm] = useState(false);

  const listEvaluations = useCallback(
    () => dispatch(evaluationsRequest(evaluationType, classification, RNType)),
    [dispatch, evaluationType, classification, RNType]
  );

  const [textPage, setTextPage] = useState(
    textPageEvaluations(evaluationType, RNType, false, classification)
  );

  useEffect(() => {
    listEvaluations();
    setTextPage(textPageEvaluations(evaluationType, RNType, false, classification));

    if (!user.statementOfResponsibility) {
      setOpenModalTerm(true);
    }
  }, [
    location.pathname,
    listEvaluations,
    user.statementOfResponsibility,
    RNType,
    match.path,
    evaluationType,
    classification
  ]);

  const clickEvaluation = (id, status, progress, leaderApproval, analysisUser, evaluationId) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        clickRNALLTypes({
          id,
          status,
          progress,
          leaderApproval,
          analysisUser,
          evaluationId
        });
        break;
      case EVALUATION_TYPE.RN_506:
        clickRNALLTypes({
          id,
          status,
          progress,
          leaderApproval,
          analysisUser,
          evaluationId
        });
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const clickRNALLTypes = ({ id, status, progress, leaderApproval, evaluationId }) => {
    switch (status) {
      case STATUS_EVALUATION.OPEN:
      case STATUS_EVALUATION.STARTED:
        if (
          progress === 100 &&
          leaderApproval.type === 'evaluator' &&
          leaderApproval.id === user.id
        ) {
          prepareNextSteps(evaluationId, id);
        } else {
          fillEvaluation(id);
        }
        break;
      case STATUS_EVALUATION.ANALYSIS_AND_DECISION:
        setModalAlert(true);
        break;
      case STATUS_EVALUATION.FINISHED:
        toast.warning('Você não pode visualizar este relatório.');
        break;
      default:
        toast.error('Status não localizado');
    }
  };

  const confirmResponsibilityTerm = async () => {
    try {
      setLoadingTerm(true);
      const { data } = await ConfirmTerm();
      toast.success(data);
      setOpenModalTerm(false);
      dispatch(authConfirmTerm());
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingTerm(false);
    }
  };

  const prepareNextSteps = (evaluationId, RNID) => {
    setConfirmRNID(RNID);
    setConfirmEvaluationID(evaluationId);
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        setModalIsPre(RNType === RN_507_TYPES.PRE);
        setModalSupervision(RNType === RN_507_TYPES.SUPERVISION);
        setOpenModalSubmit(true);
        break;
      case EVALUATION_TYPE.RN_506:
        setModalIsPre(RNType === RN_506_TYPES.PRE);
        setModalSupervision(RNType === RN_506_TYPES.SUPERVISION);
        setOpenModalSubmit(true);
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const submitNextStep = async () => {
    try {
      setLoadingSubmit(true);
      if (modalIsPre) {
        const { data } = await SubmitFinished({
          id: confirmEvaluationID
        });
        toast.success(data);
        listEvaluations();
        setOpenModalSubmit(false);
      } else if (modalSupervision) {
        submitEndSupervision();
      } else {
        submitAnalysis();
      }
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const submitAnalysis = async () => {
    try {
      setLoadingSubmit(true);

      const { data } = await SubmitAnalysis({
        id: confirmEvaluationID
      });

      toast.success(data);
      listEvaluations();
      setOpenModalSubmit(false);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const submitEndSupervision = async () => {
    try {
      setLoadingSubmit(true);

      const { data } = await SubmitFinishedSupervision({
        id: confirmEvaluationID
      });

      toast.success(data);
      listEvaluations();
      setOpenModalSubmit(false);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const fillEvaluation = (id) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        history.push(RN_507_ROUTES(classification, id, true).FILL);
        break;
      case EVALUATION_TYPE.RN_506:
        history.push(RN_506_ROUTES(classification, id, true).FILL);
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const needEditions = () => fillEvaluation(confirmRNID);

  return (
    <>
      <BasePageEvaluation
        module={textPage.minTitle}
        type={RNType}
        title={textPage.title}
        text={textPage.text}
        itsLoading={fetch.itsLoading}
        data={evaluations}
        clickEvaluation={clickEvaluation}
      />
      <Modal
        isOpen={modalAlert}
        toggle={() => setModalAlert(false)}
        title="Avaliação em Análise e decisão"
      >
        <div className="content">
          <Row>
            <Col xs="12">Esta avaliação está sendo revisada, aguarde novas atualizações.</Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={openModalSubmit}
        toggle={() => setOpenModalSubmit(false)}
        title={
          modalIsPre
            ? 'Finalizar avaliação'
            : modalSupervision
            ? 'Finalizar avaliação'
            : 'Enviar para Análise e decisão'
        }
      >
        <div className="content">
          <Row>
            <Col xs="12">
              {modalIsPre
                ? 'A Avaliação já está preenchida, deseja finalizá-la?'
                : modalSupervision
                ? 'A Avaliação já está preenchida, deseja finalizá-la?'
                : 'Deseja submeter para Análise e Decisão?'}
            </Col>
          </Row>
          <div style={{ marginTop: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  color="info"
                  title="Não, ainda preciso analisar"
                  onClick={needEditions}
                  block
                />
              </Col>
              <Col xs="6">
                <Button
                  color="primary"
                  title="Sim"
                  onClick={submitNextStep}
                  loading={loadingSubmit}
                  block
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openModalTerm} title="TERMO DE RESPONSABILIDADE E SIGILO">
        <div className="content">
          <Row>
            <Col xs="12" style={{ marginBottom: 10 }}>
              Antes de utilizar a ferramenta de avaliação dos critérios para acreditação/
              certificação, desenvolvida pela A4Quality HealthCare, é importante concordar com o
              termo de confidencialidade pela importância dos dados aqui inseridos.
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              Eu, {user.name}, inscrito(a) no CPF sob o nº {user.cpf} assumo o compromisso de manter
              confidencialidade e sigilo sobre todas as informações técnicas e outras obtidas
              enquanto avaliador da A4Quality HealthCare. Comprometo-me também:
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>1.</strong> A não utilizar as informações confidenciais a que tiver acesso,
              tanto da A4Quality Services quanto das organizações por ela avaliadas, para gerar
              benefício próprio exclusivo e/ou unilateral, presente ou futuro, ou para o uso de
              terceiros;
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>2.</strong> A não efetuar nenhuma gravação ou cópia da documentação
              confidencial a que tiver acesso;
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>3.</strong> A não apropriar-se para si ou para outrem de material confidencial
              e/ou sigiloso da tecnologia que venha a ser disponível;
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>4.</strong> A não repassar o conhecimento das informações confidenciais
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>5.</strong> A ressarcir a ocorrência de qualquer dano e/ou prejuízo oriundo de
              uma eventual quebra de sigilo das informações fornecidas;
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>6.</strong> A não tomar, sem autorização da A4Quality HealthCare, qualquer
              medida com vistas a obter para si ou para terceiros, os direitos de propriedade
              intelectual relativos às informações sigilosas a que tenham acesso.
            </Col>
            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>7.</strong> Comunicar imediatamente à A4Quality qualquer falha na segurança
              das informações confidenciais.
            </Col>

            <Col xs="12" style={{ marginBottom: 10 }}>
              Neste termo, as seguintes expressões serão assim definidas:
            </Col>

            <Col xs="12" style={{ marginBottom: 10 }}>
              Repasse de Informação considerada <strong>Confidencial</strong> significará toda
              informação revelada sob a forma escrita, verbal ou por quaisquer outros meios.
            </Col>

            <Col xs="12" style={{ marginBottom: 10 }}>
              <strong>Informação Confidencial</strong> inclui, mas não se limita a, informação
              relativa às operações, processos, planos ou intenções, informações sobre instalações,
              equipamentos, segredos de negócio, dados, habilidades especializadas, projetos,
              métodos e metodologia, fluxogramas, oportunidades de mercado e questões reveladas em
              processos de avaliação para acreditação.
            </Col>

            <Col xs="12" style={{ marginBottom: 10 }}>
              A vigência da obrigação de confidencialidade e sigilo, assumida pela minha pessoa por
              meio deste termo, terá validade enquanto a informação não for tornada de conhecimento
              público por qualquer outra pessoa, ou mediante autorização escrita, concedida a minha
              pessoa pela A4Quality ou pela organização avaliada. E este compromisso de
              confidencialidade valerá inclusive nos casos de rescisão contatual da minha parte com
              a A4Quality.
            </Col>

            <Col xs="12" style={{ marginBottom: 10 }}>
              Estou ciente de todas as sanções judiciais que poderão advir caso não cumpra o
              presente Termo de Confidencialidade e Sigilo.
            </Col>

            <Col xs="4">
              <Button
                title="Concordo"
                onClick={confirmResponsibilityTerm}
                loading={loadingTerm}
                block
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default EvaluationListCompany;
