import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent } from './styles';
import { Button, Modal, Select } from '~/components';
import * as L from '~/modules/_layouts/private/styles';
import { userRequest } from '~/store/modules/users/actions';
import { evaluatorsRequest } from '~/store/modules/evaluators/actions';
import { UpdateEvaluationEvaluators } from '~/services/private';

const ControlEvaluators = forwardRef(({ evaluationId, evaluatorsChoose, leader }, ref) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [initEditDetails, setInitEditDetails] = useState(false);
  const [dateUpdate, setDateUpdate] = useState('');
  const [listEvaluator, setListEvaluator] = useState([]);
  const [listEvaluatorClean, setListEvaluatorClean] = useState([]);
  const [evaluatorsChooseState, setEvaluatorsChooseState] = useState([]);
  const [evaluatorsObserverChooseState, setEvaluatorsObserverChooseState] = useState([]);
  const [leaderState, setLeaderState] = useState({
    value: `${leader?.type[0]}_${leader?.id}`,
    label: leader?.name
  });

  const { users } = useSelector((state) => state.users);
  const { evaluators } = useSelector((state) => state.evaluators);

  const listUsersAdmin = useCallback(() => dispatch(userRequest()), [dispatch]);
  const listEvaluators = useCallback(() => dispatch(evaluatorsRequest()), [dispatch]);

  useEffect(() => {
    listUsersAdmin();
    listEvaluators();
  }, [listUsersAdmin, listEvaluators]);

  useEffect(() => {
    const prepareGroupEvaluator = evaluators.active.map((elm) => ({
      value: `e_${elm.id}`,
      label: elm.name
    }));

    const prepareGroupUsers = users.active.map((elm) => ({
      value: `d_${elm.id}`,
      label: elm.name
    }));

    const groupedOptions = [
      {
        label: 'Avaliadores',
        options: prepareGroupEvaluator
      },
      {
        label: 'Diretoria',
        options: prepareGroupUsers
      }
    ];
    setListEvaluator(groupedOptions);
    setListEvaluatorClean(
      evaluators.active.map((elm) => ({
        value: elm.id,
        label: elm.name
      }))
    );
  }, [evaluators, users]);

  useEffect(() => {
    const prepareGroupEvaluator = evaluatorsChoose.evaluators.map((elm) => ({
      value: `e_${elm.id}`,
      label: elm.name
    }));

    const prepareGroupUsers = evaluatorsChoose.evaluatorsAdmin.map((elm) => ({
      value: `d_${elm.id}`,
      label: elm.name
    }));

    const prepareEvaluatorObserver = evaluatorsChoose.evaluatorObserver.map((elm) => ({
      value: elm.id,
      label: elm.name
    }));

    setEvaluatorsChooseState([...prepareGroupEvaluator, ...prepareGroupUsers]);
    setEvaluatorsObserverChooseState(prepareEvaluatorObserver);
  }, [evaluatorsChoose]);

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  const changeVisibleModal = (active) => setOpenModal(active);

  const LoadingSaving = () => (
    <div>
      {loadingDetails ? (
        <Saving>
          <Spinner size="sm" animation="border" />
          ...salvando
        </Saving>
      ) : null}
      {initEditDetails && !loadingDetails ? (
        <Saving color="#252525">
          <h6>Dados atualizados em: {dateUpdate}</h6>
        </Saving>
      ) : null}
    </div>
  );

  const save = async () => {
    try {
      const evaluatorsPrepare = evaluatorsChooseState.map((elm) => elm.value);
      const evaluatorsObserverPrepare = evaluatorsObserverChooseState.map((elm) => elm.value);
      setLoadingDetails(true);
      await UpdateEvaluationEvaluators({
        evaluationId,
        evaluators: evaluatorsPrepare,
        observerEvaluators: evaluatorsObserverPrepare,
        leader: leaderState.value
      });
      setDateUpdate(new Date().toLocaleTimeString());
      setInitEditDetails(true);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático da data');
    } finally {
      setLoadingDetails(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Editar Avaliadores"
        size="lg"
        unmountOnClose={false}
      >
        {LoadingSaving()}
        <ModalContainer>
          <ModalContent>
            <hr className="separator-min" />
            <L.InputContainer>
              <Select
                label="Avaliadores"
                name="evaluators"
                value={evaluatorsChooseState}
                list={listEvaluator}
                onChange={(value) => {
                  setEvaluatorsChooseState(value);
                }}
              />
            </L.InputContainer>
            <L.InputContainer>
              <Select
                label="Líder"
                value={leaderState}
                isMulti={false}
                name="leader"
                list={listEvaluator}
                onChange={(value) => {
                  setLeaderState(value);
                }}
              />
            </L.InputContainer>
            <L.InputContainer>
              <Select
                label="Avaliadores Observadores"
                name="evaluatorObserver"
                value={evaluatorsObserverChooseState}
                list={listEvaluatorClean}
                onChange={(value) => {
                  setEvaluatorsObserverChooseState(value);
                }}
              />
            </L.InputContainer>
            <Button title="Salvar" loading={loadingDetails} block onClick={save} />
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

ControlEvaluators.defaultProps = {
  evaluationId: 0,
  evaluatorsChoose: [],
  leader: {}
};

ControlEvaluators.propTypes = {
  evaluationId: PropTypes.number,
  evaluatorsChoose: PropTypes.any,
  leader: PropTypes.any
};

export default ControlEvaluators;
