import { combineReducers } from 'redux';

import auth from './auth/reducer';
import evaluations from './evaluations/reducer';
import tools from './tools/reducer';
import companies from './companies/reducer';
import users from './users/reducer';
import evaluators from './evaluators/reducer';
import graphs from './graphs/reducer';

export default combineReducers({
  auth,
  evaluations,
  tools,
  companies,
  users,
  evaluators,
  graphs
});
