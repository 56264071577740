import * as Yup from 'yup';

export const validateForm = (isGraphs) => {
  return Yup.object().shape({
    companies: Yup.array().required('Este campo é obrigatório').nullable(),
    type: Yup.string().required('Este campo é obrigatório').nullable(),
    firstDay: isGraphs ? Yup.string().required('Este campo é obrigatório') : Yup.string(),
    lastDay: isGraphs ? Yup.string().required('Este campo é obrigatório') : Yup.string()
  });
};
