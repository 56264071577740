import * as Yup from 'yup';

export const validateForm = Yup.object().shape({
  comment: Yup.string(),
  evidence: Yup.string(),
  feedback: Yup.string(),
  changedPoint: Yup.string(),
  improvementOpportunity: Yup.string(),
  strongPoint: Yup.string(),
  nonAttendance: Yup.string()
});
