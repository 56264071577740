import styled from 'styled-components';
import { colors } from '~/constants';


export const Container = styled.div``;

export const Navbar = styled.div`
  background-color: ${colors.primary};
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 20px;
`;

export const Img = styled.img`
  max-width: 200px;
  cursor: pointer;
`;

export const Body = styled.div`
  min-height: calc(100vh - 176px);
`;

export const CardBody = styled.div`
  min-height: calc(100vh - 176px);
  padding: 20px;
  color: ${colors.white};
  h3 {
    font-size: 15pt;
    //text-decoration: underline;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 13pt;
    font-weight: bold;
    //text-decoration: underline;
    margin-bottom: 20px;
  }
  p {
    font-size: 12pt;
    margin-bottom: 20px;
    text-align: justify;
  }
  span.brand {
    font-weight: bold;
  }
`;