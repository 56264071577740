import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import uniqId from 'uniqid';
import {
  Private,
  Public,
  ForgotPass,
  Login,
  Policy,
  Dashboard,
  CustomGraphs,
  Companies,
  CompaniesEdit,
  Users,
  UserEdit,
  Evaluators,
  EvaluatorsEdit,
  HomeCompanyUser,
  EvaluationList,
  EvaluationListEvaluator,
  EvaluationListCompany,
  RN507Fill,
  RN506Fill,
  RN507Report,
  RN506Report,
  Profile,
  Coming
} from '~/modules';
import { GROUPS, RN_507_CLASSIFICATION, RN_506_CLASSIFICATION, EVALUATION_TYPE } from '~/constants';
import { authLogout } from '~/store/modules/auth/actions';
import { PUBLIC, ALL, ADMIN, RN_507_ROUTES, RN_506_ROUTES } from './names';

const RN507Common = (group) => {
  const LIST = [];
  Object.keys(RN_507_CLASSIFICATION).forEach((key) => {
    if (group === GROUPS.ADMIN)
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).LIST,
        component: ({ match }) => (
          <EvaluationList
            evaluationType={EVALUATION_TYPE.RN_507}
            classification={RN_507_CLASSIFICATION[key]}
            match={match}
          />
        )
      });
    if (group === GROUPS.EVALUATOR)
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).LIST,
        component: ({ match }) => (
          <EvaluationListEvaluator
            evaluationType={EVALUATION_TYPE.RN_507}
            classification={RN_507_CLASSIFICATION[key]}
            match={match}
          />
        )
      });
    if (group === GROUPS.COMPANY_USER)
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).LIST,
        component: ({ match }) => (
          <EvaluationListCompany
            evaluationType={EVALUATION_TYPE.RN_507}
            classification={RN_507_CLASSIFICATION[key]}
            match={match}
          />
        )
      });

    LIST.push({
      path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).FILL,
      component: RN507Fill
    });
    LIST.push({
      path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).REPORT,
      component: RN507Report
    });

    if (group !== GROUPS.COMPANY_USER) {
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).REPORT_PREVIEW,
        component: RN507Report
      });
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).REPORT_ONLY_GAPS,
        component: RN507Report
      });
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true).REPORT_PREVIEW_ONLY_GAPS,
        component: RN507Report
      });

      // Report with only gaps and strong points
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true)
          .REPORT_ONLY_GAPS_WITH_STRONG_POINTS,
        component: RN507Report
      });
      LIST.push({
        path: RN_507_ROUTES(RN_507_CLASSIFICATION[key], null, true)
          .REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS,
        component: RN507Report
      });
    }
  });

  return LIST;
};

const RN506Common = (group) => {
  const LIST = [];
  Object.keys(RN_506_CLASSIFICATION).forEach((key) => {
    if (group === GROUPS.ADMIN)
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).LIST,
        component: ({ match }) => (
          <EvaluationList
            evaluationType={EVALUATION_TYPE.RN_506}
            classification={RN_506_CLASSIFICATION[key]}
            match={match}
          />
        )
      });
    if (group === GROUPS.EVALUATOR)
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).LIST,
        component: ({ match }) => (
          <EvaluationListEvaluator
            evaluationType={EVALUATION_TYPE.RN_506}
            classification={RN_506_CLASSIFICATION[key]}
            match={match}
          />
        )
      });
    if (group === GROUPS.COMPANY_USER)
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).LIST,
        component: ({ match }) => (
          <EvaluationListCompany
            evaluationType={EVALUATION_TYPE.RN_506}
            classification={RN_506_CLASSIFICATION[key]}
            match={match}
          />
        )
      });

    LIST.push({
      path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).FILL,
      component: RN506Fill
    });
    LIST.push({
      path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).REPORT,
      component: RN506Report
    });

    if (group !== GROUPS.COMPANY_USER) {
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).REPORT_PREVIEW,
        component: RN506Report
      });
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).REPORT_ONLY_GAPS,
        component: RN506Report
      });
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).REPORT_PREVIEW_ONLY_GAPS,
        component: RN506Report
      });

      // Report with only gaps and strong points
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null).REPORT_ONLY_GAPS_WITH_STRONG_POINTS,
        component: RN506Report
      });
      LIST.push({
        path: RN_506_ROUTES(RN_506_CLASSIFICATION[key], null)
          .REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS,
        component: RN506Report
      });
    }
  });

  return LIST;
};

const Routes = () => {
  const {
    isLogged,
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const RoutePublic = () => (
    <Public>
      <Switch>
        <Route exact path={PUBLIC.LOGIN} component={Login} />
        <Route exact path={PUBLIC.FORGOT_PASS} component={ForgotPass} />
        <Route exact path={PUBLIC.POLICY} component={Policy} />
        <Redirect to={{ pathname: PUBLIC.LOGIN }} />
      </Switch>
    </Public>
  );

  const RouteAdmin = () => (
    <Private>
      <Switch>
        <Route exact path={ADMIN.DASHBOARD} component={Dashboard} />
        <Route exact path={ADMIN.CUSTOM_GRAPHS} component={CustomGraphs} />
        <Route exact path={ADMIN.COMPANIES} component={Companies} />
        <Route exact path={ADMIN.COMPANIES_EDIT} component={CompaniesEdit} />
        <Route exact path={ADMIN.COMPANIES_NEW} component={CompaniesEdit} />
        <Route exact path={ADMIN.USERS} component={Users} />
        <Route exact path={ADMIN.USERS_NEW} component={UserEdit} />
        <Route exact path={ADMIN.USERS_EDIT} component={UserEdit} />

        <Route exact path={ADMIN.EVALUATORS} component={Evaluators} />
        <Route exact path={ADMIN.EVALUATORS_NEW} component={EvaluatorsEdit} />
        <Route exact path={ADMIN.EVALUATORS_EDIT} component={EvaluatorsEdit} />
        <Route exact path={ALL().PROFILE} component={Profile} />
        <Route exact path={ALL().COMING_SOON} component={Coming} />

        {RN507Common(GROUPS.ADMIN).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        {RN506Common(GROUPS.ADMIN).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        <Route exact path={PUBLIC.POLICY} component={Policy} />
        <Redirect to={ADMIN.DASHBOARD} />
      </Switch>
    </Private>
  );

  const RouteEvaluator = () => (
    <Private>
      <Switch>
        <Route exact path={ALL().PROFILE} component={Profile} />
        <Route exact path={ALL().COMING_SOON} component={Coming} />

        {RN507Common(GROUPS.EVALUATOR).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        {RN506Common(GROUPS.EVALUATOR).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        <Route exact path={PUBLIC.POLICY} component={Policy} />
        <Redirect to={ALL().PROFILE} />
      </Switch>
    </Private>
  );

  const RouteCompanyUser = () => (
    <Private>
      <Switch>
        <Route exact path={ALL().HOME} component={HomeCompanyUser} />
        <Route exact path={ALL().PROFILE} component={Profile} />
        <Route exact path={ALL().COMING_SOON} component={Coming} />

        {RN507Common(GROUPS.COMPANY_USER).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        {RN506Common(GROUPS.COMPANY_USER).map((elm) => (
          <Route exact path={elm.path} component={elm.component} key={uniqId()} />
        ))}

        <Route exact path={PUBLIC.POLICY} component={Policy} />
        <Redirect to={ALL().HOME} />
      </Switch>
    </Private>
  );

  const VerifyPrivateRoutes = () => {
    switch (groupId) {
      case GROUPS.ADMIN:
        return RouteAdmin();
      case GROUPS.EVALUATOR:
        return RouteEvaluator();
      case GROUPS.COMPANY_USER:
        return RouteCompanyUser();
      default:
        dispatch(authLogout());
        return RoutePublic();
    }
  };

  return isLogged ? VerifyPrivateRoutes() : RoutePublic();
};

export default Routes;
