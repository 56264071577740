import React, { useState } from 'react';
import { Card, Collapse } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Button, RequirementList } from '~/components';
import { CollapseContainer } from '~/components/BasePageEvaluationView/styles';
import { EVALUATION_TYPE } from '~/constants';

const CollapseRequirements = ({
  RNType,
  evaluationType,
  dimension,
  requirements,
  changeRequirements,
  showControlDetails,
  evaluatorsListControl,
  isObs,
  lastAccreditation,
  userEvaluatorObserver
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const validateDimensionControl = (requirementId) => {
    const test = evaluatorsListControl.filter(
      (elm) =>
        parseInt(elm.dimension, 10) === dimension && parseInt(elm.requirement, 10) === requirementId
    );
    return test.length > 0 || isObs;
  };

  return (
    <>
      {evaluationType !== EVALUATION_TYPE.RN_506 && (
        <Button title="⧂ Requisitos" color="link" onClick={() => setIsOpen(!isOpen)} />
      )}
      <CollapseContainer max="8px">
        <Collapse isOpen={evaluationType === EVALUATION_TYPE.RN_506 ? true : isOpen}>
          <Card>
            {Object.keys(requirements).map((key) => {
              if (showControlDetails || validateDimensionControl(requirements[key].id))
                return (
                  <RequirementList
                    key={`requirement-list-${requirements[key].id}`}
                    evaluationType={evaluationType}
                    RNType={RNType}
                    changedPoint={requirements[key].changedPoint}
                    feedback={requirements[key].feedback}
                    text={requirements[key].text}
                    numericMarkers={requirements[key].numericMarkers}
                    chosenScope={(chosen) =>
                      changeRequirements(chosen.id, chosen.scope, null, 'SCOPE')
                    }
                    chosenDeploymentTime={(chosen) =>
                      changeRequirements(chosen.id, null, chosen.time, 'DEPLOYMENT_TIME')
                    }
                    items={requirements[key].items}
                    lastAccreditation={lastAccreditation?.[key]?.items}
                    userEvaluatorObserver={userEvaluatorObserver}
                  />
                );
              return null;
            })}
          </Card>
        </Collapse>
      </CollapseContainer>
    </>
  );
};

CollapseRequirements.defaultProps = {
  RNType: null,
  evaluationType: null,
  dimension: null,
  requirements: [],
  changeRequirements: () => {},
  showControlDetails: false,
  isObs: false,
  evaluatorsListControl: [],
  lastAccreditation: null
};

CollapseRequirements.propTypes = {
  RNType: PropTypes.number,
  evaluationType: PropTypes.number,
  dimension: PropTypes.number,
  requirements: PropTypes.any,
  changeRequirements: PropTypes.func,
  showControlDetails: PropTypes.bool,
  isObs: PropTypes.bool,
  evaluatorsListControl: PropTypes.any,
  lastAccreditation: PropTypes.any
};

export default CollapseRequirements;
