import {
  AUTH_LOGIN,
  AUTH_REQUEST,
  AUTH_PROFILE_REQUEST,
  AUTH_SET_ITS_LOADING,
  AUTH_LOGOUT,
  AUTH_TERM
} from './patterns';

export function authRequest(payload) {
  return {
    type: AUTH_REQUEST,
    payload
  };
}

export function authGetProfile() {
  return {
    type: AUTH_PROFILE_REQUEST
  };
}

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload
  };
}

export function authSetLoading(itsLoading) {
  return {
    type: AUTH_SET_ITS_LOADING,
    itsLoading
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT
  };
}

export function authConfirmTerm() {
  return {
    type: AUTH_TERM
  };
}
