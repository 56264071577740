import styled from 'styled-components';

export const Body = styled.div`
  padding: 0 10px 0 10px;
  color: #eae9e9 !important;
`;

export const Text = styled.div`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  span {
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
