import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { evaluationsRequest } from '~/store/modules/evaluations/actions';
import { SubmitAnalysis, SubmitFinished } from '~/services/private';
import BasePageEvaluation from '~/components/BasePageEvaluation';
import { Button, Modal } from '~/components';
import { STATUS_EVALUATION, RN_507_TYPES, EVALUATION_TYPE, RN_506_TYPES } from '~/constants';
import history from '~/config/history';
import { textPageEvaluations } from '~/config/helpers';
import { RN_507_ROUTES, RN_506_ROUTES } from '~/routes/names';
import ModalALLRN from '~/modules/Private/Admin/EvaluationList/Forms/ModalALLRN';

const EvaluationList = ({ evaluationType, classification, match }) => {
  const { evaluations, fetch } = useSelector((state) => state.evaluations);
  const { user } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const location = useLocation();
  const RNType = parseInt(match.params.type, 10);

  const [openModal, setOpenModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [confirmID, setConfirmID] = useState(null);
  const [confirmEvaluationID, setConfirmEvaluationID] = useState(null);
  const [titleModalSubmit, setTitleModalSubmit] = useState('');
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [submitIsAnalysis, setSubmitIsAnalysis] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [textPage, setTextPage] = useState(
    textPageEvaluations(evaluationType, RNType, false, classification)
  );

  const listEvaluations = useCallback(
    () => dispatch(evaluationsRequest(evaluationType, classification, RNType)),
    [dispatch, evaluationType, classification, RNType]
  );

  useEffect(() => {
    listEvaluations();
    setTextPage(textPageEvaluations(evaluationType, RNType, false, classification));
  }, [evaluationType, location.pathname, listEvaluations, RNType, match.path, classification]);

  const addAccreditation = () => {
    setOpenModal(true);
  };

  const prepareModal = (id, evaluationID, title, isAnalysis, disabled = false) => {
    setConfirmID(id);
    setTitleModalSubmit(title);
    setSubmitIsAnalysis(isAnalysis);
    setOpenModalSubmit(true);
    setDisableSubmit(disabled);
    setConfirmEvaluationID(evaluationID);
  };

  const clickRNALLTypes = ({
    id,
    status,
    progress,
    leaderApproval,
    analysisUser,
    evaluationId,
    isSelfEvaluation,
    isPre
  }) => {
    if (!isSelfEvaluation) {
      switch (status) {
        case STATUS_EVALUATION.OPEN:
        case STATUS_EVALUATION.STARTED:
          if (progress === 100) {
            if (
              leaderApproval.type === 'director' &&
              parseInt(leaderApproval.id, 10) === parseInt(user.id, 10)
            ) {
              prepareModal(
                id,
                evaluationId,
                !isPre
                  ? 'Deseja submeter para Análise e Decisão?'
                  : 'Deseja finalizar o relatório?',
                !isPre
              );
            } else {
              prepareModal(
                id,
                evaluationId,
                'A Avaliação já está preenchida, mas você não está designado como líder aprovador. Porém poderá acompanhar o preenchimento.',
                isPre,
                true
              );
            }
          } else {
            fillEvaluation(id);
          }
          break;
        case STATUS_EVALUATION.ANALYSIS_AND_DECISION:
          prepareModal(
            id,
            evaluationId,
            'Já realizou a análise? Deseja finalizar esta avaliação?',
            false
          );
          break;
        case STATUS_EVALUATION.FINISHED:
          clickReport(evaluationId);
          break;
        default:
          toast.error('Status não localizado');
      }
    } else {
      switch (status) {
        case STATUS_EVALUATION.OPEN:
        case STATUS_EVALUATION.STARTED:
          fillEvaluation(id);
          break;
        case STATUS_EVALUATION.FEEDBACK:
          if (parseInt(analysisUser, 10) === user.id) {
            prepareModal(
              id,
              evaluationId,
              'Já realizou o Feedback? Deseja finalizar esta avaliação?',
              false,
              false
            );
          } else {
            prepareModal(
              id,
              evaluationId,
              'Você não está designado para realizar o Feedback, mas poderá ajudar no preenchimento.',
              false,
              true
            );
          }
          break;
        case STATUS_EVALUATION.FINISHED:
          clickReport(evaluationId);
          break;
        default:
          toast.error('Status não localizado');
      }
    }
  };

  const clickEvaluation = (id, status, progress, leaderApproval, analysisUser, evaluationId) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        clickRNALLTypes({
          id,
          status,
          progress,
          leaderApproval,
          analysisUser,
          evaluationId,
          isSelfEvaluation: RNType === RN_507_TYPES.SELF_EVALUATION,
          isPre: RNType === RN_507_TYPES.PRE
        });
        break;
      case EVALUATION_TYPE.RN_506:
        clickRNALLTypes({
          id,
          status,
          progress,
          leaderApproval,
          analysisUser,
          evaluationId,
          isSelfEvaluation: RNType === RN_506_TYPES.SELF_EVALUATION,
          isPre: RNType === RN_506_TYPES.PRE
        });
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const fillEvaluation = (id) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        history.push(RN_507_ROUTES(classification, id, true).FILL);
        break;
      case EVALUATION_TYPE.RN_506:
        history.push(RN_506_ROUTES(classification, id, true).FILL);
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const clickReport = (id) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        switch (RNType) {
          case RN_507_TYPES.PRE:
          case RN_507_TYPES.SUPERVISION:
          case RN_507_TYPES.ACCREDITATION:
          case RN_507_TYPES.SELF_EVALUATION:
            history.push(RN_507_ROUTES(classification, id, true).REPORT);
            break;
          default:
            toast.warning('O relatório para esse tipo de avaliação ainda está em desenvolvimento');
        }
        break;
      case EVALUATION_TYPE.RN_506:
        switch (RNType) {
          case RN_507_TYPES.PRE:
          case RN_507_TYPES.SUPERVISION:
          case RN_507_TYPES.ACCREDITATION:
          case RN_507_TYPES.SELF_EVALUATION:
            history.push(RN_506_ROUTES(classification, id, true).REPORT);
            break;
          default:
            toast.warning('O relatório para esse tipo de avaliação ainda está em desenvolvimento');
        }
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const submitNextStep = async () => {
    if (!disableSubmit) {
      try {
        setLoadingSubmit(true);
        if (submitIsAnalysis) {
          const { data } = await SubmitAnalysis({
            id: confirmEvaluationID
          });
          toast.success(data);
          listEvaluations();
          setOpenModalSubmit(false);
        } else {
          const { data } = await SubmitFinished({
            id: confirmEvaluationID
          });
          toast.success(data);
          listEvaluations();
          setOpenModalSubmit(false);
        }
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao realizar a operação');
      } finally {
        setLoadingSubmit(false);
      }
    }
  };

  const needEditions = () => {
    fillEvaluation(confirmID);
  };

  return (
    <>
      <BasePageEvaluation
        module={textPage.minTitle}
        type={RNType}
        title={textPage.title}
        text={textPage.text}
        itsLoading={fetch.itsLoading}
        data={evaluations}
        btnText={textPage.btnText}
        clickEvaluation={clickEvaluation}
        btnAction={addAccreditation}
      />
      <ModalALLRN
        evaluationType={evaluationType}
        classification={classification}
        RNType={RNType}
        textPage={textPage}
        listEvaluations={listEvaluations}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Modal isOpen={openModalSubmit} toggle={() => setOpenModalSubmit(false)} title="Etapa final">
        <div className="content">
          <Row>
            <Col xs="12">{titleModalSubmit}</Col>
          </Row>
          <div style={{ marginTop: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  color="info"
                  title={
                    !disableSubmit ? 'Não, ainda preciso analisar' : 'Acompanhar preenchimento'
                  }
                  onClick={needEditions}
                  block
                />
              </Col>
              <Col xs="6">
                <Button
                  color="primary"
                  title="Sim"
                  disabled={disableSubmit}
                  onClick={submitNextStep}
                  loading={loadingSubmit}
                  block
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EvaluationList;
