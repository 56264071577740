import React, { useCallback, useEffect } from 'react';
import $ from 'jquery';
import 'bootstrap-fileinput';
import PropTypes from 'prop-types';
import { fileInputLocale, fileInputThemes } from '~/constants';

const FileInput = ({ chosenFile, preview }) => {
  const createFileInput = useCallback(() => {
    try {
      const previewConfig = {};

      if (preview.id && preview.url) {
        previewConfig.initialPreview = [preview.url];
        previewConfig.initialPreviewAsData = true;
        previewConfig.initialPreviewConfig = {
          caption: preview.name,
          downloadUrl: preview.url,
          size: 930321,
          width: '120px',
          key: preview.id
        };
      }

      $.fn.fileinputLocales['pt-BR'] = fileInputLocale;
      $.fn.fileinputThemes.fa = fileInputThemes;
      $('#single-input').fileinput({
        theme: 'fa',
        language: 'pt-BR',
        fileActionSettings: {
          showZoom: false
        },
        showUpload: false,
        showCancel: false,
        showClose: false,
        allowedFileExtensions: ['jpg', 'jpeg', 'gif', 'png'],
        ...previewConfig
      });
      $('.fileinput-remove-button').on('click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        chosenFile({});
      });
    } catch (e) {
      // TODO - Criar fluxo de erro
    }
  }, [chosenFile, preview]);

  useEffect(() => {
    createFileInput();
  }, [createFileInput]);

  const changeInput = async (event) => {
    const files = event?.target?.files;
    if (files[0] !== undefined) {
      const img = {
        name: files[0].name,
        type: files[0].type,
        base64: await toBase64(files[0])
      };
      chosenFile(img);
    } else {
      chosenFile({});
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <input
      id="single-input"
      name="single-input"
      type="file"
      data-browse-on-zone-click="true"
      onChange={changeInput}
    />
  );
};

FileInput.propTypes = {
  chosenFile: PropTypes.func,
  preview: PropTypes.object
};

FileInput.defaultProps = {
  chosenFile: () => {},
  preview: {
    id: null,
    url: null,
    name: null
  }
};

export default FileInput;
