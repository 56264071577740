import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';

const AuditedAreas = ({ auditedAreas, startPage, totalPages, editionReport }) => {
  const newAudited =
    auditedAreas && Array.isArray(auditedAreas)
      ? auditedAreas.reduce((acc, elm, index) => {
          if (index % 2 === 0) {
            acc.push([elm.name, auditedAreas[index + 1]?.name]);
          }
          return acc;
        }, [])
      : [];

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMarginMin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Áreas Auditadas
            </th>
          </tr>
        </tbody>
      </S.Table>
      {auditedAreas && Array.isArray(auditedAreas) && (
        <S.Table bottomMarginMin>
          <tbody>
            {newAudited.map((elm) => (
              <tr key={uniqId()}>
                <td colSpan="2" className="left --is-audited-areas">
                  {elm[0]}
                </td>
                <td colSpan="2" className="left --is-audited-areas">
                  {elm[1]}
                </td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      )}

      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

AuditedAreas.defaultProps = {
  startPage: 0,
  totalPages: 0,
  auditedAreas: null,
  editionReport: ''
};

AuditedAreas.propTypes = {
  auditedAreas: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default AuditedAreas;
