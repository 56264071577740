import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { Button, Select } from '~/components';
import { LogMonitoredIndicators } from '~/components/LogsView';
import { GROUPS, TYPES_LOGS } from '~/constants';

const options = [
  { value: null, label: '-' },
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' }
];

const MonitoredIndicators = ({
  id,
  numericMarkers,
  text,
  itHas,
  chosenIndicator,
  hasHover,
  colorTitle,
  lastAccreditation,
  userEvaluatorObserver
}) => {
  const refModal = useRef();

  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const prepare = (lastItHas = null) => {
    if (lastItHas) {
      if (lastAccreditation.itHas === null) return { value: null, label: '-' };
      if (lastAccreditation.itHas === true) return { value: 1, label: 'Sim' };
      if (lastAccreditation.itHas === false) return { value: 0, label: 'Não' };
      return '';
    }
    if (itHas === null) return { value: null, label: '-' };
    if (itHas === true) return { value: 1, label: 'Sim' };
    if (itHas === false) return { value: 0, label: 'Não' };
    return '';
  };

  const changeItHas = (e) => {
    chosenIndicator(e.value);
  };

  return (
    <S.Container hasHover={hasHover}>
      <S.ItHas>
        <Select
          name="itHas"
          list={options}
          isMulti={false}
          onChangeOption={changeItHas}
          defaultValue={prepare()}
          userEvaluatorObserver={userEvaluatorObserver}
        />
      </S.ItHas>
      <S.Marker colorTitle={colorTitle}>{numericMarkers}</S.Marker>
      <S.Question colorTitle={colorTitle}>
        {text}
        {lastAccreditation !== null ? (
          <S.LastAccreditation>
            <span>* {prepare(true).label}</span>
          </S.LastAccreditation>
        ) : null}
      </S.Question>
      {groupId === GROUPS.ADMIN && (
        <S.Actions>
          <Button
            title={<i className="tim-icons icon-watch-time" />}
            color="link"
            onClick={() => refModal.current.changeVisibleModal(true)}
          />
        </S.Actions>
      )}
      <LogMonitoredIndicators type={TYPES_LOGS.MONITORED_INDICATORS} idType={id} ref={refModal} />
    </S.Container>
  );
};

MonitoredIndicators.defaultProps = {
  id: null,
  numericMarkers: '',
  text: '',
  itHas: undefined,
  chosenIndicator: () => {},
  hasHover: true,
  colorTitle: '#FFF',
  lastAccreditation: null
};

MonitoredIndicators.propTypes = {
  id: PropTypes.number,
  numericMarkers: PropTypes.string,
  text: PropTypes.string,
  itHas: PropTypes.bool,
  chosenIndicator: PropTypes.func,
  hasHover: PropTypes.bool,
  colorTitle: PropTypes.string,
  lastAccreditation: PropTypes.any
};

export default MonitoredIndicators;
