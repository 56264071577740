import { SHOW_DRAWER, HIDE_DRAWER, SET_LOADING, CREATE_STATE_LOADING } from './patterns';

export function showDrawer() {
  return {
    type: SHOW_DRAWER
  };
}

export function setListLoading(list) {
  return {
    type: CREATE_STATE_LOADING,
    list
  };
}

export function setLoading(ref, loading, complete = false, error = false) {
  return {
    type: SET_LOADING,
    ref,
    loading,
    complete,
    error
  };
}

export function hideDrawer() {
  return {
    type: HIDE_DRAWER
  };
}
