import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from '~/components/Sidebar';
import AdminNavbar from '~/components/Navbars';
import Footer from '~/components/Footer';
import FixedPlugin from '~/components/FixedPlugin';
import { GROUPS } from '~/constants';

const PrivateLayout = ({ children }) => {
  const history = useHistory();

  const isRoutePolicy = history?.location?.pathname === '/lgpd';

  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const [backgroundColor, setBackgroundColor] = useState('primary');
  const [sidebarOpened, setSidebarOpened] = useState(
    document.documentElement.className.indexOf('nav-open') !== -1
  );

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setSidebarOpened(!sidebarOpened);
  };

  const handleBgClick = (color) => setBackgroundColor(color);

  const showBrandText = () => {
    switch (groupId) {
      case GROUPS.ADMIN:
        return 'ADMIN';
      case GROUPS.COMPANY_USER:
        return 'OPERADORA';
      case GROUPS.EVALUATOR:
        return 'AVALIADOR';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="wrapper">
        {isRoutePolicy ? (
          children
        ) : (
          <>
            <Sidebar bgColor={backgroundColor} toggleSidebar={toggleSidebar} />
            <div className="main-panel" data={backgroundColor}>
              <AdminNavbar
                brandText={showBrandText()}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              {children}
              <Footer fluid />
            </div>
          </>
        )}
      </div>
      {!isRoutePolicy ? (
        <FixedPlugin bgColor={backgroundColor} handleBgClick={handleBgClick} />
      ) : null}
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default PrivateLayout;
