import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent, ButtonAdd, Table } from './styles';
import { Button, Modal } from '~/components';
import { UpdateMeeting } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { TYPES_MEETINGS } from '~/constants';
import { alphabeticallySort } from '~/config/helpers';

const Meeting = forwardRef(({ meeting }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [initEditDetails, setInitEditDetails] = useState(false);
  const [myTimeOut, setMyTimeOut] = useState(null);
  const [dateUpdate, setDateUpdate] = useState('');

  const [dateState, setDateState] = useState(meeting.date);
  const [placeState, setPlaceState] = useState(meeting.place);
  const [scheduleState, setScheduleState] = useState(meeting.schedule);
  const [participantsState, setParticipantsState] = useState(
    alphabeticallySort(meeting.participants, true, 'name')
  );

  const [participantName, setParticipantName] = useState('');
  const [participantOccupation, setParticipantOccupation] = useState('');

  const changeVisibleModal = (active) => setOpenModal(active);

  const handleChange = (event, setState) => {
    const text = event.target.value;
    setState(text);
    if (myTimeOut) clearTimeout(myTimeOut);
  };

  const changeMeeting = useCallback(async () => {
    try {
      setLoadingDetails(true);
      await UpdateMeeting({
        id: meeting.id,
        date: dateState,
        place: placeState,
        schedule: scheduleState,
        participants: participantsState
      });
      setDateUpdate(new Date().toLocaleTimeString());
      setInitEditDetails(true);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingDetails(false);
    }
  }, [meeting, dateState, placeState, scheduleState, participantsState]);

  const addParticipant = async () => {
    setParticipantsState(
      alphabeticallySort(
        [
          ...participantsState,
          {
            id: null,
            name: participantName,
            occupation: participantOccupation
          }
        ],
        true,
        'name'
      )
    );
    setParticipantName('');
    setParticipantOccupation('');
  };

  const removeParticipant = async (index) => {
    setParticipantsState(
      alphabeticallySort(
        participantsState.filter((elm, ind) => ind !== index),
        true,
        'name'
      )
    );

    setParticipantName('');
    setParticipantOccupation('');
  };

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  useEffect(() => {
    setMyTimeOut(setTimeout(changeMeeting, 1000));
  }, [dateState, placeState, scheduleState, participantsState, changeMeeting]);

  const LoadingSaving = () => (
    <div>
      {loadingDetails ? (
        <Saving>
          <Spinner size="sm" animation="border" />
          ...salvando
        </Saving>
      ) : null}
      {initEditDetails && !loadingDetails ? (
        <Saving color="#252525">
          <h6>Dados atualizados em: {dateUpdate}</h6>
        </Saving>
      ) : null}
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title={`Reunião de ${
          meeting.type === TYPES_MEETINGS.INITIAL ? 'Abertura' : 'Encerramento'
        }`}
        size="lg"
        modalClassName="modal-meeting-container"
        unmountOnClose={false}
      >
        {LoadingSaving()}
        <ModalContainer>
          <ModalContent>
            <Row>
              <Col xs="6">
                <L.Input
                  label="Data"
                  name="dateState"
                  value={dateState || ''}
                  onChange={(event) => {
                    handleChange(event, setDateState);
                  }}
                />
              </Col>
              <Col xs="6">
                <L.Input
                  label="Horário"
                  name="scheduleState"
                  value={scheduleState || ''}
                  onChange={(event) => {
                    handleChange(event, setScheduleState);
                  }}
                />
              </Col>
              <Col xs="6">
                <L.Input
                  label="Local"
                  name="placeState"
                  value={placeState || ''}
                  onChange={(event) => {
                    handleChange(event, setPlaceState);
                  }}
                />
              </Col>
            </Row>
            <hr className="separator" />
            <h4>Adicionar Participante</h4>
            <Row>
              <Col xs="5">
                <L.Input
                  label="Nome"
                  name="name"
                  value={participantName || ''}
                  onChange={(event) => setParticipantName(event.target.value)}
                />
              </Col>
              <Col xs="5">
                <L.Input
                  label="Cargo/Função"
                  name="occupation"
                  value={participantOccupation || ''}
                  onChange={(event) => setParticipantOccupation(event.target.value)}
                />
              </Col>
              <Col xs="2">
                <ButtonAdd>
                  <Button
                    disabled={participantName === '' || participantOccupation === ''}
                    onClick={addParticipant}
                    title={<i className="tim-icons icon-simple-add" />}
                  />
                </ButtonAdd>
              </Col>
            </Row>
            <hr className="min-separator" />
            <h4>Lista de participantes</h4>
            <Table>
              <tbody>
                <tr>
                  <th className="th-title">Nome</th>
                  <th className="th-title">Cargo/Função</th>
                  <th className="th-title">Apagar</th>
                </tr>
                {participantsState.map((elm, ind) => (
                  <tr key={`table-list-meeting-${ind}`}>
                    <td className="center">{elm.name}</td>
                    <td className="center">{elm.occupation}</td>
                    <td className="center --yesNo">
                      <Button
                        title={<i className="tim-icons icon-simple-delete" />}
                        onClick={() => removeParticipant(ind)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalContent>
        </ModalContainer>
        {LoadingSaving()}
      </Modal>
    </div>
  );
});

Meeting.defaultProps = {
  meeting: {
    id: 0,
    type: 1,
    date: null,
    schedule: null,
    place: null,
    participants: []
  }
};

Meeting.propTypes = {
  meeting: PropTypes.any
};

export default Meeting;
