import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 100;
`;

export const Strip = styled.div`
  background: ${(props) => (props.isDev ? '#701ce5' : '#e51c46')};
  position: relative;
  margin: ${(props) => (props.top ? props.top : '30px')} auto;
  width: 130px;
  color: #fff;
  line-height: 20px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  &:before,
  &:after {
    content: '';
    line-height: 0;
    border-style: solid;
    border-width: 10px;
    position: absolute;
    top: 10px;
    z-index: -1;
    width: 25px;
  }
  &:before {
    border-color: ${(props) =>
      props.isDev
        ? '#5212B3FF #5212B3FF #5212B3FF transparent'
        : '#bc1438 #bc1438 #bc1438 transparent'};
    left: -15px;
  }
  &:after {
    border-color: ${(props) =>
      props.isDev
        ? '#5212B3FF transparent #5212B3FF #5212B3FF'
        : '#bc1438 transparent #bc1438 #bc1438'};
    right: -15px;
  }
`;

export const Text = styled.div`
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 0;
    line-height: 1px;
    border-style: solid;
    border-width: 5px;
    z-index: 1;
  }

  &:before {
    border-color: ${(props) =>
      props.isDev
        ? '#510d94 #510d94 transparent transparent'
        : '#940d2a #940d2a transparent transparent'};
    bottom: -10px;
    left: 0;
  }

  &:after {
    border-color: ${(props) =>
      props.isDev
        ? '#510d94 transparent transparent #510d94'
        : '#940d2a transparent transparent #940d2a'};
    bottom: -10px;
    right: 0;
  }
`;
