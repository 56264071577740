import React, { useState } from 'react';
import classNames from 'classnames';

const FixedPlugin = ({ bgColor, handleBgClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = () => setShowDropdown(!showDropdown);

  const activateMode = (mode) => {
    switch (mode) {
      case 'light':
        document.body.classList.add('white-content');
        break;
      default:
        document.body.classList.remove('white-content');
        break;
    }
  };

  return (
    <div className="fixed-plugin">
      <div
        className={classNames('dropdown show-dropdown', {
          show: showDropdown
        })}
      >
        <div onClick={handleClick}>
          <i className="fa fa-cog fa-2x" />
        </div>
        <ul className="dropdown-menu show">
          <li className="header-title">BACKGROUND MENU</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={classNames('badge filter badge-primary', {
                  active: bgColor === 'primary'
                })}
                data-color="primary"
                onClick={() => handleBgClick('primary')}
              />{' '}
              <span
                className={classNames('badge filter badge-secondary', {
                  active: bgColor === 'secondary'
                })}
                data-color="secondary"
                onClick={() => handleBgClick('secondary')}
              />{' '}
              <span
                className={classNames('badge filter badge-info', {
                  active: bgColor === 'blue'
                })}
                data-color="blue"
                onClick={() => handleBgClick('blue')}
              />{' '}
              <span
                className={classNames('badge filter badge-success', {
                  active: bgColor === 'green'
                })}
                data-color="green"
                onClick={() => handleBgClick('green')}
              />{' '}
            </div>
          </li>
          <li className="adjustments-line text-center color-change">
            <span className="color-label">MODO LIGHT</span>{' '}
            <span className="badge light-badge mr-2" onClick={() => activateMode('light')} />{' '}
            <span className="badge dark-badge ml-2" onClick={() => activateMode('dark')} />{' '}
            <span className="color-label">MODO DARK</span>{' '}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FixedPlugin;
