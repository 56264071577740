import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import Cover from '~/components/Report/Cover';
import DimensionsData from '~/components/Report/DimensionsData';
import Resume from '~/components/Report/Resume';
import Meeting from '~/components/Report/Meeting';
import PeopleInterviewed from '~/components/Report/PeopleInterviewed';
import FinalComments from '~/components/Report/FinalComments';
import BackCover from '~/components/Report/BackCover';
// import Diary from '~/components/Report/Diary';
import { calculatePages } from '~/config/helpers';
import {
  TYPES_MEETINGS,
  EDITION_REPORT_RN_507,
  RN_507_TYPES,
  RN_507_CLASSIFICATION_NAME,
  ATTACHMENT_REPORT,
  EVALUATION_TYPE,
  ACCREDITED_REPORT,
  CONSIDERATIONS_PRE_REPORT,
  EVALUATION_TYPE_TEXT,
  RN_507_REPORT_OBJECTIVE,
  RN_507_SCOPE_REPORT,
  RN_507_MINIMUM_OF_EXCELLENCE_ITEMS,
  RN_507_CONSIDERATIONS_ITEMS,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';

const Pre = ({ accreditation, onlyGaps, withStrongPoints }) => {
  const [totalPages, setTotalPages] = useState(0);
  const pagesDetails = calculatePages(
    accreditation,
    7,
    6,
    2,
    totalPages,
    (total) => setTotalPages(total + 1),
    onlyGaps,
    withStrongPoints
  );

  accreditation.resume.createdDateTimestamp = accreditation?.createdDateTimestamp;

  return (
    <>
      <Cover
        evaluationType={`Relatório de Avaliação - Pré-Avaliação ${
          RN_507_CLASSIFICATION_NAME[accreditation?.classification]
        }`}
        evaluationObjective={
          RN_507_REPORT_OBJECTIVE[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
        evaluationScope={RN_507_SCOPE_REPORT[RN_507_TYPES.PRE][accreditation?.classification]}
        attachmentReport={
          ATTACHMENT_REPORT[CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)][
            EVALUATION_TYPE.RN_507
          ]
        }
        accredited={ACCREDITED_REPORT[EVALUATION_TYPE.RN_507]}
        accreditation={accreditation}
        evaluators={[...accreditation?.evaluators, ...accreditation?.evaluatorsAdmin]}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
        isPre={accreditation?.type_rn452 === RN_507_TYPES.PRE}
        isSelf={accreditation?.type_rn452 === RN_507_TYPES.SELF_EVALUATION}
        isSuper={accreditation?.type_rn452 === RN_507_TYPES.SUPERVISION}
      />
      <BackCover
        accreditation={accreditation}
        startPage={2}
        totalPages={totalPages}
        minimumOfExcellenceItems={RN_507_MINIMUM_OF_EXCELLENCE_ITEMS[accreditation?.classification]}
        considerations={
          CONSIDERATIONS_PRE_REPORT(
            RN_507_CONSIDERATIONS_ITEMS[
              CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
            ][accreditation?.classification]
          )[EVALUATION_TYPE.RN_507][RN_507_TYPES.PRE]
        }
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
        isPre={accreditation?.type_rn452 === RN_507_TYPES.PRE}
        isSelf={accreditation?.type_rn452 === RN_507_TYPES.SELF_EVALUATION}
        isSuper={accreditation?.type_rn452 === RN_507_TYPES.SUPERVISION}
      />
      <Resume
        data={accreditation?.resume}
        startPage={3}
        evaluationTextType={EVALUATION_TYPE_TEXT.RN_507}
        totalItems={
          RN_507_CONSIDERATIONS_ITEMS[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][accreditation?.classification]
        }
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
        type={RN_507_TYPES.PRE}
        dimensions={accreditation?.dimensionsList}
      />
      <Meeting
        meeting={accreditation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.INITIAL)[0]}
        startPage={4}
        totalPages={totalPages}
        evaluationType={EVALUATION_TYPE.RN_507}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
      />

      <PeopleInterviewed
        people={accreditation?.peopleInterviewed}
        startPage={5}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
      />
      <Meeting
        meeting={accreditation?.meetings.filter((elm) => elm.type === TYPES_MEETINGS.FINAL)[0]}
        startPage={6}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
      />
      {/* <Diary */}
      {/*  diary={accreditation?.diary} */}
      {/*  startPage={7} */}
      {/*  totalPages={totalPages} */}
      {/*  editionReport={EDITION_REPORT_RN_507_PRE[VERSIONS_OF_CHANGES.FIRST_VERSION][accreditation?.classification]} */}
      {/*  evaluators={{ */}
      {/*    evaluators: accreditation?.evaluators, */}
      {/*    evaluatorsAdmin: accreditation?.evaluatorsAdmin, */}
      {/*    evaluatorsCompany: accreditation?.evaluatorsCompany */}
      {/*  }} */}
      {/* /> */}
      {accreditation?.dimensionsList.map((elm) => (
        <DimensionsData
          key={uniqId()}
          id={elm}
          data={accreditation.dimensions[elm]}
          startPage={pagesDetails[elm].start}
          separatorRequirements={pagesDetails[elm].separatorRequirements}
          totalPages={totalPages}
          evaluationType={EVALUATION_TYPE.RN_507}
          auditedAreas={accreditation?.auditedAreas[elm]}
          editionReport={
            EDITION_REPORT_RN_507[
              CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
            ][RN_507_TYPES.PRE][accreditation?.classification]
          }
        />
      ))}
      <FinalComments
        accreditation={accreditation}
        startPage={totalPages}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.PRE][accreditation?.classification]
        }
      />
    </>
  );
};

Pre.defaultProps = {
  accreditation: {},
  onlyGaps: false,
  withStrongPoints: false
};

Pre.propTypes = {
  accreditation: PropTypes.any,
  onlyGaps: PropTypes.bool,
  withStrongPoints: PropTypes.bool
};

export default Pre;
