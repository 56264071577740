import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Saving, ModalContainer, ModalContent, ResumeIsRemote } from './styles';
import { Modal, MonitoredIndicators, Select } from '~/components';
import { UpdateResume } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { resumeContentEvaluation, RN_507_TYPES, VERSIONS_OF_CHANGES } from '~/constants';

const optionsIsRemote = [
  { value: null, label: '-' },
  { value: 0, label: 'Presencial' },
  { value: 1, label: 'Remota' }
];

const Resume = forwardRef(
  (
    {
      id,
      startDay,
      endDay,
      month,
      year,
      customText,
      isFit,
      isRemote,
      level,
      type,
      dimensions,
      evaluationTextType,
      totalItems,
      customTextFit,
      createdDateTimestamp
    },
    ref
  ) => {
    const [startDayState, setStartDayState] = useState(startDay);
    const [endDayState, setEndDayState] = useState(endDay);
    const [monthState, setMonthState] = useState(month);
    const [yearState, setYearState] = useState(year);
    const [customTextState, setCustomTextState] = useState(customText);
    const [isFitState, setIsFitState] = useState(isFit);
    const [isRemoteState, setIsRemoteState] = useState(isRemote);
    const [levelState, setLevelState] = useState(level);
    const [customTextFitState, setCustomTextFitState] = useState(customTextFit);

    const [openModal, setOpenModal] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [initEditDetails, setInitEditDetails] = useState(false);
    const [myTimeOut, setMyTimeOut] = useState(null);
    const [dateUpdate, setDateUpdate] = useState('');

    const changeVisibleModal = (active) => setOpenModal(active);

    const prepareIsRemote = () => {
      if (isRemote === true) return { value: 1, label: 'Remota' };
      if (isRemote === false) return { value: 0, label: 'Presencial' };
      return { value: null, label: '-' };
    };

    const handleFit = (fit) => {
      setIsFitState(fit);
      if (myTimeOut) clearTimeout(myTimeOut);
    };

    const onChangeIsRemote = (remote) => {
      setIsRemoteState(remote.value);
      if (myTimeOut) clearTimeout(myTimeOut);
    };

    const handleChange = (event, setState) => {
      const text = event.target.value;
      setState(text);
      if (myTimeOut) clearTimeout(myTimeOut);
    };

    const changeResume = useCallback(async () => {
      try {
        setLoadingDetails(true);
        await UpdateResume({
          id,
          startDay: startDayState,
          endDay: endDayState,
          month: monthState,
          year: yearState,
          customText: customTextState,
          isFit: isFitState,
          isRemote: isRemoteState,
          level: levelState,
          customTextFit: customTextFitState
        });
        setDateUpdate(new Date().toLocaleTimeString());
        setInitEditDetails(true);
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
      } finally {
        setLoadingDetails(false);
      }
    }, [
      id,
      startDayState,
      endDayState,
      monthState,
      yearState,
      isFitState,
      isRemoteState,
      levelState,
      customTextState,
      customTextFitState
    ]);

    useImperativeHandle(ref, () => ({
      changeVisibleModal
    }));

    useEffect(() => {
      setMyTimeOut(setTimeout(changeResume, 1000));
    }, [
      startDayState,
      endDayState,
      monthState,
      yearState,
      customTextState,
      isFitState,
      isRemoteState,
      levelState,
      changeResume,
      customTextFitState
    ]);

    const LoadingSaving = () => (
      <div>
        {loadingDetails ? (
          <Saving>
            <Spinner size="sm" animation="border" />
            ...salvando
          </Saving>
        ) : null}
        {initEditDetails && !loadingDetails ? (
          <Saving color="#252525">
            <h6>Dados atualizados em: {dateUpdate}</h6>
          </Saving>
        ) : null}
      </div>
    );

    return (
      <div>
        <Modal
          isOpen={openModal}
          toggle={() => changeVisibleModal(false)}
          title="Resumo"
          size="lg"
          modalClassName="modal-resume-container"
          unmountOnClose={false}
        >
          {LoadingSaving()}
          <ModalContainer>
            <ResumeIsRemote>
              <Select
                label="Forma da avaliação"
                name="scope"
                list={optionsIsRemote}
                isMulti={false}
                onChangeOption={onChangeIsRemote}
                defaultValue={prepareIsRemote()}
              />
            </ResumeIsRemote>
            {resumeContentEvaluation(
              {
                level: levelState,
                isFit: isFitState,
                type,
                dimensions,
                createdDateTimestamp
              },
              evaluationTextType,
              totalItems
            )[VERSIONS_OF_CHANGES.FIRST_VERSION].map((elm, ind) => {
              if (elm.allTypes && elm.onlyReport && ind === 0)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <span>
                      A avaliação foi realizada no período de{' '}
                      <L.InputResume
                        value={startDayState || ''}
                        name="startDay"
                        onChange={(event) => {
                          handleChange(event, setStartDayState);
                        }}
                      />{' '}
                      a{' '}
                      <L.InputResume
                        value={endDayState || ''}
                        name="endDay"
                        onChange={(event) => {
                          handleChange(event, setEndDayState);
                        }}
                      />{' '}
                      de{' '}
                      <L.InputResume
                        value={monthState || ''}
                        name="month"
                        onChange={(event) => {
                          handleChange(event, setMonthState);
                        }}
                      />{' '}
                      de{' '}
                      <L.InputResume
                        value={yearState || ''}
                        name="year"
                        onChange={(event) => {
                          handleChange(event, setYearState);
                        }}
                      />{' '}
                      de forma {isRemoteState ? 'remota' : 'presencial'}.
                    </span>
                  </ModalContent>
                );

              if (elm.isSelect && type !== RN_507_TYPES.SUPERVISION)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <MonitoredIndicators
                      hasHover={false}
                      colorTitle="#252525"
                      chosenIndicator={handleFit}
                      itHas={isFitState}
                      text={
                        type === RN_507_TYPES.PRE
                          ? 'Recomenda avaliação de acreditação?'
                          : 'Está apta?'
                      }
                    />
                  </ModalContent>
                );

              if (type === RN_507_TYPES.ACCREDITATION && elm.hasFit && elm.forTypes?.includes(type))
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    {isFitState ? (
                      <span>
                        a equipe avaliadora concluiu que a Operadora está apta a ser acreditada no
                        Nível{' '}
                        <L.InputResume
                          value={levelState || ''}
                          name="levelState"
                          onChange={(event) => {
                            handleChange(event, setLevelState);
                          }}
                        />{' '}
                        , segundo o Programa de Acreditação de OPS estabelecido pela RN 507 da ANS.
                      </span>
                    ) : (
                      <span>{elm.text}</span>
                    )}
                  </ModalContent>
                );

              if (elm.isCustom)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <L.Input
                      name="customText"
                      rows={3}
                      multiline
                      value={customTextState || ''}
                      onChange={(event) => {
                        handleChange(event, setCustomTextState);
                      }}
                    />
                  </ModalContent>
                );

              if (type === RN_507_TYPES.PRE && elm.isCustomFit)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <L.Input
                      name="customTextFit"
                      rows={3}
                      multiline
                      value={customTextFitState || ''}
                      onChange={(event) => {
                        handleChange(event, setCustomTextFitState);
                      }}
                    />
                  </ModalContent>
                );

              if (type === RN_507_TYPES.ACCREDITATION && elm.hasFit && elm.forTypes?.includes(type))
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    Considerando o resultado da avaliação, a equipe de avaliadores{' '}
                    {!isFitState && 'não '}recomenda a avaliação para Acreditação e se coloca a
                    disposição para quaisquer esclarecimentos julgados necessários.
                  </ModalContent>
                );

              if (elm.isTitle)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <h4>{elm.text}</h4>
                  </ModalContent>
                );

              if (elm.showForAll && (elm.allTypes || elm.forTypes?.includes(type)) && !elm.isTitle)
                return (
                  <ModalContent key={`resume-content-${ind}`}>
                    <span>{elm.text}</span>
                  </ModalContent>
                );
              return null;
            })}
          </ModalContainer>
          {LoadingSaving()}
        </Modal>
      </div>
    );
  }
);

Resume.defaultProps = {
  id: 0,
  startDay: '',
  endDay: '',
  month: '',
  year: '',
  customText: '',
  isFit: null,
  isRemote: null,
  level: '',
  evaluationTextType: '',
  totalItems: '',
  type: '',
  dimensions: [],
  customTextFit: ''
};

Resume.propTypes = {
  id: PropTypes.number,
  startDay: PropTypes.string,
  endDay: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  customText: PropTypes.string,
  evaluationTextType: PropTypes.string,
  totalItems: PropTypes.string,
  isFit: PropTypes.any,
  isRemote: PropTypes.any,
  level: PropTypes.string,
  type: PropTypes.any,
  dimensions: PropTypes.any,
  customTextFit: PropTypes.string
};

export default Resume;
