import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Line, Bar } from 'react-chartjs-2';
import download from 'downloadjs';
import uniqId from 'uniqid';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import history from '~/config/history';
import { Button as ButtonA4, Select } from '~/components';
import { chartExample3, chartOptions as chartDefault, defaultDatasets } from '~/constants/charts';
import { ExportReport, CustomGraphs } from '~/services/private';
import { graphsDashRequest, setCustomGraphs } from '~/store/modules/graphs/actions';
import { companiesRequest } from '~/store/modules/companies/actions';
import { EVALUATION_TYPE, RN_507_TYPES } from '~/constants';
import { validateForm } from './validateForm';
import * as S from './styles';
import * as L from '~/modules/_layouts/private/styles';
import { ADMIN } from '~/routes/names';

const Loading = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const INITIAL_STATE_EXPORT_FORM = {
  companies: [],
  type: ''
};

const TYPES = [
  {
    id: RN_507_TYPES.PRE,
    title: 'Pré-Avaliação'
  },
  {
    id: RN_507_TYPES.ACCREDITATION,
    title: 'Acreditação'
  },
  {
    id: RN_507_TYPES.SELF_EVALUATION,
    title: 'Autoavaliação'
  }
];

const Dashboard = () => {
  const allTypes = [
    {
      id: RN_507_TYPES.PRE,
      name: 'Pré-avaliação'
    },
    {
      id: RN_507_TYPES.SUPERVISION,
      name: 'Supervisão'
    },
    {
      id: RN_507_TYPES.ACCREDITATION,
      name: 'Acreditação'
    },
    {
      id: RN_507_TYPES.SELF_EVALUATION,
      name: 'Autoavaliação'
    }
  ];

  const [chosenType, setChosenType] = useState(RN_507_TYPES.PRE);
  const [nameGraph, setNameGraph] = useState('Pré-Avaliação');
  const [listCompany, setListCompany] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingCustom, setLoadingCustom] = useState(false);

  const { companies } = useSelector((state) => state.companies);
  const {
    companies: totalCompanies,
    allClients,
    allAccreditationTypes,
    allAccreditationMonths,
    fetch
  } = useSelector((state) => state.graphs);
  const dispatch = useDispatch();

  const listCompanies = useCallback(() => dispatch(companiesRequest()), [dispatch]);

  const getGraphs = useCallback(() => dispatch(graphsDashRequest(EVALUATION_TYPE.RN_507)), [
    dispatch
  ]);

  const dataChartMonths = (canvas) => {
    return {
      labels: allAccreditationMonths[chosenType]?.map((elm) => elm.month)?.reverse(),
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Total',
          fill: true,
          borderColor: '#1f8ef1',
          pointBackgroundColor: '#1f8ef1',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#1f8ef1',
          data: allAccreditationMonths[chosenType]?.map((elm) => elm.sum)?.reverse()
        }
      ]
    };
  };

  const dataChartAllClients = (canvas) => {
    return {
      labels: allClients
        .map((elm) => elm.month)
        .reverse()
        .splice(6, 6),
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Total',
          fill: true,
          borderColor: '#00d6b4',
          pointBackgroundColor: '#00d6b4',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#00d6b4',
          data: allClients
            .map((elm) => elm.sum)
            .reverse()
            .splice(6, 6)
        }
      ]
    };
  };

  const chartOptions = {
    ...chartDefault,
    scales: {
      ...chartDefault.scales,
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            suggestedMin: 0,
            stepSize: 20,
            padding: 20,
            fontColor: '#9a9a9a'
          }
        }
      ]
    }
  };

  const dataChartTypes = (canvas) => {
    return {
      labels: allTypes.map((elm) => elm.name),
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Quantidade',
          fill: true,
          borderColor: '#d048b6',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: Object.keys(allAccreditationTypes).map((key) => allAccreditationTypes[key])
        }
      ]
    };
  };

  useEffect(() => {
    getGraphs();
    listCompanies();
  }, [getGraphs, listCompanies]);

  useEffect(() => {
    const prepareCompanies = companies.active.map((elm) => ({
      value: elm.id,
      label: elm.name
    }));
    setListCompany(prepareCompanies);
  }, [companies.active]);

  const exportXls = async (obj) => {
    const chosenCompanies = obj?.companies ? obj.companies.map((elm) => elm.value) : [];
    try {
      setLoadingExport(true);
      const { data } = await ExportReport({
        type: parseInt(obj.type, 10),
        companies: chosenCompanies
      });
      download(
        atob(data.base),
        'Base.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
      toast.success('Arquivo baixado');
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingExport(false);
    }
  };

  const customGraphs = async (obj) => {
    setLoadingCustom(true);
    const chosenCompanies = obj?.companies ? obj.companies.map((elm) => elm.value) : [];
    try {
      const { data } = await CustomGraphs({
        type: parseInt(obj.type, 10),
        companies: chosenCompanies,
        firstDay: obj?.firstDay,
        lastDay: obj?.lastDay
      });

      dispatch(setCustomGraphs(data));

      history.push(ADMIN.CUSTOM_GRAPHS);
      toast.success('Gráficos gerados!');
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingCustom(false);
    }
  };

  return (
    <>
      {fetch.itsLoading ? (
        <div className="content">
          <Row>
            <Col xs="12">
              <Loading>
                <Spinner size="md" color="white" animation="border" />
              </Loading>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">
                        Gráfico anual - Módulo de Acreditação realizadas
                      </h5>
                      <CardTitle tag="h2">{nameGraph}</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup className="btn-group-toggle float-right" data-toggle="buttons">
                        {allTypes.map((elm) => (
                          <Button
                            key={uniqId()}
                            tag="label"
                            className={classNames('btn-simple', {
                              active: elm.id === chosenType
                            })}
                            color="info"
                            size="sm"
                            onClick={() => {
                              setChosenType(elm.id);
                              setNameGraph(elm.name);
                            }}
                          >
                            <input defaultChecked className="d-none" name="options" type="radio" />
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              {elm.name}
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                        ))}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line data={dataChartMonths} options={chartOptions} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Avaliação mais realizada</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-delivery-fast text-primary" /> Autoavaliação
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar data={dataChartTypes} options={chartExample3.options} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total de Clientes</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-send text-success" /> {totalCompanies}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line data={dataChartAllClients} options={chartOptions} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left">
                      <h5 className="card-category">Relatórios</h5>
                      <CardTitle tag="h2">Gráficos Customizados</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <S.Body>
                    <h5>
                      Escolha o tipo da avaliação, período e quais OPS serão vinculadas nos gráficos
                      abaixo.
                    </h5>
                    <L.Form
                      schema={validateForm(true)}
                      onSubmit={customGraphs}
                      initialData={INITIAL_STATE_EXPORT_FORM}
                    >
                      <Row>
                        <Col xs="6">
                          <L.InputContainer>
                            <L.Select label="Tipo" name="type" options={TYPES} />
                          </L.InputContainer>
                        </Col>
                        <Col xs="6">
                          <L.InputContainer>
                            <Select label="OPS" name="companies" list={listCompany} />
                          </L.InputContainer>
                        </Col>
                        <Col xs="6">
                          <L.Input type="date" label="Data início" name="firstDay" />
                        </Col>
                        <Col xs="6">
                          <L.Input type="date" label="Data FIm" name="lastDay" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <L.ButtonContainer top="20">
                            <ButtonA4
                              title="Gerar gráfico"
                              type="submit"
                              loading={loadingCustom}
                              block
                            />
                          </L.ButtonContainer>
                        </Col>
                      </Row>
                    </L.Form>
                  </S.Body>
                </CardBody>
              </Card>
            </Col>
            <Col xs="6">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left">
                      <h5 className="card-category">Relatórios</h5>
                      <CardTitle tag="h2">Exportar Avaliações</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <S.Body>
                    <h5>
                      Ao clicar abaixo uma planilha será baixada com os dados das últimas avaliações
                      das OPS escolhidas.
                    </h5>
                    <L.Form
                      schema={validateForm()}
                      onSubmit={exportXls}
                      initialData={INITIAL_STATE_EXPORT_FORM}
                    >
                      <Row>
                        <Col xs="6">
                          <L.InputContainer>
                            <L.Select label="Tipo" name="type" options={TYPES} />
                          </L.InputContainer>
                        </Col>
                        <Col xs="6">
                          <L.InputContainer>
                            <Select label="OPS" name="companies" list={listCompany} />
                          </L.InputContainer>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <L.ButtonContainer>
                            <ButtonA4
                              title="Baixar Excel"
                              type="submit"
                              loading={loadingExport}
                              block
                            />
                          </L.ButtonContainer>
                        </Col>
                      </Row>
                    </L.Form>
                  </S.Body>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default Dashboard;
