import styled from 'styled-components';
import { colors } from '~/constants';

export const ModalContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ModalContent = styled.div`
  margin-bottom: 20px;
  h4 {
    color: #525f7f;
  }
  hr.separator {
    margin-top: 40px;
  }
  hr.min-separator {
    margin-top: 15px;
  }
`;

export const ModalLoading = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
  th,
  td {
    border: 1px solid black;
    padding-left: 10px;
    &.center {
      text-align: center;
    }
  }

  .th-title {
    padding-left: 0;
    text-align: center;
    font-weight: bold;
  }

  .td-title {
    padding-left: 0;
    text-align: center;
    width: 170px;
    font-weight: bold;
    &.--yesNo {
      width: 50px;
    }
  }
  .btn-secondary {
    padding: 5px 20px !important;
  }
`;

export const SubTitle = styled.h5`
  color: ${colors.black};
  font-size: 13pt;
`;
