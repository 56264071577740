import produce from 'immer';
import { SHOW_DRAWER, HIDE_DRAWER, CREATE_STATE_LOADING, SET_LOADING } from './patterns';

const INITIAL_STATE = {
  drawer: false,
  loadingUpload: {}
};

export default function header(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SHOW_DRAWER: {
        draft.drawer = true;
        break;
      }
      case CREATE_STATE_LOADING: {
        draft.loadingUpload = action.list;
        break;
      }
      case SET_LOADING: {
        draft.loadingUpload = {
          ...draft.loadingUpload,
          [action.ref]: {
            loading: action.loading,
            complete: action.complete,
            error: action.error
          }
        };
        break;
      }
      case HIDE_DRAWER: {
        draft.drawer = false;
        break;
      }
      default:
    }
  });
}
