import React from 'react';
import { PropTypes } from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import * as S from './styles';

const RequirementCheck = ({ requirement, isChecked, checkRequirement }) => {
  return (
    <FormGroup check>
      <Label check>
        <Input
          defaultChecked={isChecked}
          type="checkbox"
          onChange={(event) => checkRequirement(event.target.checked)}
        />
        <span className="form-check-sign">
          <span className="check" />
        </span>
        <S.Line>
          <S.Marker>{requirement.numericMarkers}</S.Marker>
          <S.Question>
            <S.Text>{requirement.text}</S.Text>
          </S.Question>
        </S.Line>
      </Label>
    </FormGroup>
  );
};

RequirementCheck.defaultProps = {
  requirement: {},
  isChecked: false,
  checkRequirement: () => {}
};

RequirementCheck.propTypes = {
  requirement: PropTypes.any,
  isChecked: PropTypes.bool,
  checkRequirement: PropTypes.func
};

export default RequirementCheck;
