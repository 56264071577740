import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Modal } from '~/components';
import { GetLogs } from '~/services/private';
import { Table, ModalContainer, ModalContent, ModalLoading, SubTitle } from './styles';
import {
  TYPES_LOGS,
  GROUPS_NAME,
  EVALUATION_TYPE,
  OPTIONS_DEPLOYMENT_TIME_RN507,
  OPTIONS_DEPLOYMENT_TIME_RN506
} from '~/constants';

const RequirementItems = forwardRef(({ idType, evaluationType }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logScope, setLogScope] = useState([]);
  const [logDeploy, setLogDeploy] = useState([]);

  const defineDeploymentTime = () => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return OPTIONS_DEPLOYMENT_TIME_RN507;
      case EVALUATION_TYPE.RN_506:
        return OPTIONS_DEPLOYMENT_TIME_RN506;
      default:
        return [];
    }
  };

  const OPTIONS_DEPLOYMENT_TIME = defineDeploymentTime();

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  const changeVisibleModal = async (active) => {
    setOpenModal(active);
    if (active) {
      try {
        setLoading(true);
        const scopes = await GetLogs({
          type: TYPES_LOGS.REQUIREMENTS_ITEMS_SCOPE,
          idType
        });
        setLogScope(scopes.data);

        const deployments = await GetLogs({
          type: TYPES_LOGS.REQUIREMENTS_ITEMS_DEPLOYMENT_TIME,
          idType
        });
        setLogDeploy(deployments.data);
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao capturar o LOG.');
      } finally {
        setLoading(false);
      }
    }
  };

  const prepareLog = (scopeLog = false, deployLog = false, log) => {
    if (scopeLog) {
      if (log === '') return '-';
      if (log === '1') return 'Atendido';
      if (log === '0') return 'Não Atendido';
      return '';
    }
    if (deployLog) {
      const filter = OPTIONS_DEPLOYMENT_TIME.filter((elm) => elm.value === parseInt(log, 10));
      if (filter.length > 0) {
        return filter[0].label;
      }
      return '';
    }
    return '';
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Log"
        unmountOnClose={false}
      >
        <ModalContainer>
          <ModalContent>
            {loading ? (
              <ModalLoading>
                <Spinner size="sm" animation="border" />
              </ModalLoading>
            ) : (
              <>
                <SubTitle>Escopo</SubTitle>
                <Table>
                  <tbody>
                    <tr>
                      <th className="th-title">Usuário</th>
                      <th className="th-title">Perfil</th>
                      <th className="th-title">Antes</th>
                      <th className="th-title">Depois</th>
                      <th className="th-title">Horário</th>
                    </tr>
                    {logScope.map((elm, ind) => (
                      <tr key={`table-list-meeting-${ind}`}>
                        <td className="center">{elm.userDetails.name}</td>
                        <td className="center">{GROUPS_NAME[parseInt(elm.groupId, 10)]}</td>
                        <td className="center">{prepareLog(true, false, elm.beforeChange)}</td>
                        <td className="center">{prepareLog(true, false, elm.afterChange)}</td>
                        <td className="center">{elm.createdDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <SubTitle>Tempo de Implantação</SubTitle>
                <Table>
                  <tbody>
                    <tr>
                      <th className="th-title">Usuário</th>
                      <th className="th-title">Perfil</th>
                      <th className="th-title">Antes</th>
                      <th className="th-title">Depois</th>
                      <th className="th-title">Horário</th>
                    </tr>
                    {logDeploy.map((elm, ind) => (
                      <tr key={`table-list-meeting-${ind}`}>
                        <td className="center">{elm.userDetails.name}</td>
                        <td className="center">{GROUPS_NAME[parseInt(elm.groupId, 10)]}</td>
                        <td className="center">{prepareLog(false, true, elm.beforeChange)}</td>
                        <td className="center">{prepareLog(false, true, elm.afterChange)}</td>
                        <td className="center">{elm.createdDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

RequirementItems.defaultProps = {
  idType: null,
  evaluationType: 0
};

RequirementItems.propTypes = {
  idType: PropTypes.any,
  evaluationType: PropTypes.string
};

export default RequirementItems;
