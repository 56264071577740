import Http from '~/config/http';

export const GetEvaluators = () => Http.get('/evaluators');

export const CreateEvaluator = (body) => Http.post('/evaluators', body);

export const UpdateEvaluator = (body) => Http.put('/evaluators', body);

export const EnabledEvaluator = (body) => Http.put('/evaluators/enabled', body);

export const ConfirmTerm = () => Http.post('/evaluators/confirmTerm');
