import React from 'react';
import { PropTypes } from 'prop-types';
import * as S from './styles';

const FooterReport = ({ editionReport, actualPage, totalPage }) => {
  const date = new Date(Date.now()).toLocaleString().split(' ')[0];

  return (
    <S.Footer>
      <span>{editionReport}</span>
      <span>
        CÓPIA IMPRESSA CONTROLADA PELO USUÁRIO <span className="--show-print">- {date}</span>
      </span>
      <span>
        Página {actualPage} de {totalPage}
      </span>
    </S.Footer>
  );
};

FooterReport.defaultProps = {
  editionReport: '',
  actualPage: 0,
  totalPage: 100
};

FooterReport.propTypes = {
  editionReport: PropTypes.string,
  actualPage: PropTypes.number,
  totalPage: PropTypes.number
};

export default FooterReport;
