import * as Yup from 'yup';
import { RN_507_TYPES, RN_506_TYPES, EVALUATION_TYPE } from '~/constants';

export const validateForm = (type, evaluationType = EVALUATION_TYPE.RN_507) => {
  if (evaluationType === EVALUATION_TYPE.RN_507)
    return Yup.object().shape({
      company: Yup.string().required('Este campo é obrigatório'),
      evaluators:
        type !== RN_507_TYPES.SELF_EVALUATION
          ? Yup.array().required('Este campo é obrigatório').nullable()
          : Yup.array(),
      evaluatorObserver: Yup.array().nullable(),
      leader:
        type !== RN_507_TYPES.SELF_EVALUATION
          ? Yup.object().required('Este campo é obrigatório').nullable()
          : Yup.object(),
      director:
        type === RN_507_TYPES.ACCREDITATION
          ? Yup.object().required('Este campo é obrigatório').nullable()
          : Yup.object().nullable(),
      dimensions:
        type === RN_507_TYPES.SUPERVISION
          ? Yup.array().required('Este campo é obrigatório').nullable()
          : Yup.array()
    });
  if (evaluationType === EVALUATION_TYPE.RN_506)
    return Yup.object().shape({
      company: Yup.string().required('Este campo é obrigatório'),
      evaluators:
        type !== RN_506_TYPES.SELF_EVALUATION
          ? Yup.array().required('Este campo é obrigatório').nullable()
          : Yup.array(),
      evaluatorObserver: Yup.array().nullable(),
      leader:
        type !== RN_506_TYPES.SELF_EVALUATION
          ? Yup.object().required('Este campo é obrigatório').nullable()
          : Yup.object(),
      director:
        type === RN_506_TYPES.CERTIFICATION
          ? Yup.object().required('Este campo é obrigatório').nullable()
          : Yup.object().nullable(),
      requirementNumbers:
        type === RN_506_TYPES.SUPERVISION
          ? Yup.array().required('Este campo é obrigatório').nullable()
          : Yup.array()
    });
  return Yup.object().shape({});
};
