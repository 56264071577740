import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback, Card } from 'reactstrap';
import InputMask from "react-input-mask";
import { Button } from '~/components';
import * as S from './../Login/styles';
import history from "~/config/history";
import {ResetPass} from "~/services/public";
import { PUBLIC } from '~/routes/names';
import {toast} from "react-toastify";

export default function Login() {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [cpf, setCpf] = useState(null);

  const submit = async () => {
    try {
      setLoading(true);
      const { data } = await ResetPass({
        email,
        cpf
      });
      toast.success(data);
      history.push(PUBLIC.LOGIN);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a atualização.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Card>
        <Card>
          <S.ContainerHeader>
            <img
              alt="Logo a4quality"
              src="https://a4quality.com/wp-content/uploads/2020/05/logo_a4q.png"
            />
          </S.ContainerHeader>
          <S.ContainerCard>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="*****@***.com"
                type="email"
                invalid={email === ''}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormFeedback>Campo obrigatório</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>CPF</Label>
              <InputMask
                mask="999.999.999-99"
                maskChar={null}
                defaultValue={cpf}
                onChange={(e) => setCpf(e.target.value)}
              >
                {() => <Input
                  placeholder="999.999.999-99"
                  invalid={cpf === ''}
                />}
              </InputMask>
              <FormFeedback>Campo obrigatório</FormFeedback>
            </FormGroup>
            <Button color="primary" onClick={submit} loading={loading} title="Alterar senha" />
          </S.ContainerCard>
        </Card>
      </S.Card>
    </S.Container>
  );
}
