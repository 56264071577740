import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const IconFlat = ({ icon, color, onClick }) => {
  return (
    <S.Container color={color} onClick={onClick}>
      <i className={`${icon[0]} ${icon[1]}`} />
    </S.Container>
  );
};

IconFlat.propTypes = {
  icon: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func
};

IconFlat.defaultProps = {
  icon: ['tim-icons', 'icon-pencil'],
  color: 'primary',
  onClick: () => {}
};

export default IconFlat;
