import React from 'react';
import { Card, CardHeader, Row, Col, CardTitle } from 'reactstrap';
import {
  NavbarBrand,
  CardBody,
} from 'reactstrap';
import * as S from './styles';
import Footer from '../../../components/Footer';
import logo from '~/assets/img/brand/logo_w.png';

const Policy = () => {

  return (
    <S.Container className="main-panel" data="primary">
      <S.Navbar>
        <NavbarBrand href="/">
          <S.Img src={logo} alt="react-logo" />
        </NavbarBrand>
      </S.Navbar>
      <S.Body className="container">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h3">Política de Privacidade</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <S.CardBody>
                  <p>
                    Quando você é cadastrado na <span className="brand">A4Quality</span> você nos fornece alguns dados pessoais com o objetivo de viabilizar a sua operação.
                  </p>
                  <p>
                    Prezamos pela segurança dos seus dados, pelo respeito a sua privacidade e pela transparência com você e, por isso, dedicamos
                    este documento para explicar como os seus dados pessoais serão tratados pela <span className="brand">A4Quality</span> e quais são as medidas que aplicamos para mantê-los seguros.
                  </p>
                  <p>
                    <span className="brand">A4Quality Healthcare – Auditoria e Certificação Ltda</span>, inscrito no CNPJ 15.398.600/0001-27, com sede na Cidade do Rio de Janeiro,
                    na Praia de Botafogo 501 Torre A - 1º, e segundo a definição trazida pela Lei Geral de Proteção de Dados “LGPD” (Lei Federal nº 13.709.2018),
                    em todo o tempo seremos o controlador das suas informações, sendo assim, responsável por definir o que acontece com estes dados e por protegê-los.
                  </p>

                  <h3>
                    QUAIS DADOS SÃO COLETADOS:
                  </h3>

                  <p>
                    Coletaremos apenas as informações necessárias para viabilizar seu cadastro no sistema e para a geração das avaliações de acordo com as normas da ANS.
                  </p>

                  <h4>
                    PARA ACESSO À FERRAMENTA:
                  </h4>

                  <p>
                    <span className="brand">Avaliador:</span><br/>
                    Assinatura digitalizada<br/>
                    Nome Completo<br/>
                    CPF<br/>
                    Telefone<br/>
                    E-mail<br/>
                    Graduação<br/>
                    Pós-Graduação<br/>
                    Se possui experiência em Acreditação e Auditoria
                  </p>

                  <p>
                    <span className="brand">OPS:</span><br/>
                    Logo digitalizada<br/>
                    Nome<br/>
                    CNPJ<br/>
                    Registro ANS<br/>
                    Segmentação<br/>
                    Pessoa de Contato<br/>
                    Telefone<br/>
                    E-mail<br/>
                    Porte<br/>
                    Nº Colaboradores<br/>
                    Nº Beneficiários<br/>
                    IDSS<br/>
                    Endereço
                  </p>

                  <h3>COMO NÓS UTILIZAMOS OS SEUS DADOS</h3>

                  <p>
                    Nós utilizamos os dados para poder criar as avaliações de acordo com as normas da ANS e garantir um atendimento de qualidade e uma melhor experiência na criação dessas avaliações.
                  </p>

                  <h3>COM QUEM NÓS PODEMOS COMPARTILHAR OS DADOS</h3>

                  <p>
                    Para a execução das atividades acima listadas, sempre que necessário, nós poderemos compartilhar os seus dados pessoais com as operadoras.
                  </p>

                  <h3>ARMAZENAMENTO E SEGURANÇA DOS DADOS PESSOAIS</h3>

                  <p>
                    Nós armazenamos seus dados de forma segura em data centers localizados no Brasil, bem como no exterior.
                    Neste caso de armazenamento no exterior, são adotadas todas as medidas legais aplicáveis, em conformidade com a
                    Lei Geral de Proteção de Dados e suas futuras regulamentações, garantindo a proteção e privacidade dos seus dados pessoais.
                  </p>

                  <p>
                    Nós adotamos as melhores técnicas para proteger os dados pessoais coletados de acessos não autorizados, destruição, perda, alteração,
                    comunicação ou qualquer forma de tratamento inadequado ou ilícito, inclusive mecanismos de criptografia. Ressaltamos, contudo, que nenhuma
                    plataforma é completamente segura.
                  </p>

                  <p>
                    Todos os dados sensíveis coletados acima para o acesso a ferramenta são armazenados em um sistema criptográfico utilizando
                    chaves públicas e privadas no padrão RSA de 2048 bits. Essas chaves estão armazenadas no servidor e são protegidas contra acessos indevidos.
                  </p>

                  <p>
                    Seus dados serão mantidos durante todo o período de criação de uma avaliação e após sua conclusão. Faremos a
                    retenção dos seus dados pelo prazo necessário, respeitando os prazos estabelecidos na legislação aplicável.
                  </p>

                  <p>
                    Se você tiver qualquer preocupação ou suspeita de que os seus dados estejam em risco, por favor, entre em
                    contato conosco por meio dos nossos canais de atendimento que ficaremos felizes em te auxiliar prontamente.<br/><br/>

                    Contato: (21) 2586-6255<br/>
                    <a href="https://a4quality.com/fale-conosco/">Clique aqui para mais opções de contato</a>
                  </p>

                  <h4>
                    DESENVOLVEDOR RESPONSÁVEL:
                  </h4>

                  <p>
                    Rafael de Vasconcelos Freitas<br/>
                    Telefone: (81) 99716-9991<br/>
                    E-mail: contato@rafafreitas.com
                  </p>
                </S.CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </S.Body>
      <Footer fluid />
    </S.Container>
  );
}

export default Policy;
