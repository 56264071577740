import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Modal } from '~/components';
import { GetLogs } from '~/services/private';
import { Table, ModalContainer, ModalContent, ModalLoading, SubTitle } from './styles';
import { GROUPS_NAME, TYPES_LOGS_NAME } from '~/constants';

const RequirementComments = forwardRef(({ type, idType, toggle }, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [log, setLogs] = useState([]);

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  const changeVisibleModal = async (active) => {
    setOpenModal(active);
    if (active) {
      try {
        setLoading(true);
        const { data } = await GetLogs({
          type,
          idType
        });
        setLogs(data);
      } catch (e) {
        const error = e?.data?.result;
        toast.error(error || 'Aconteceu um problema ao capturar o LOG.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => {
          changeVisibleModal(false);
          toggle();
        }}
        className="modal-comments-log"
        title="Log"
        unmountOnClose={false}
      >
        <ModalContainer>
          <ModalContent>
            {loading ? (
              <ModalLoading>
                <Spinner size="sm" animation="border" />
              </ModalLoading>
            ) : (
              <>
                <SubTitle>{TYPES_LOGS_NAME[type]}</SubTitle>
                <Table>
                  <tbody>
                    <tr>
                      <th className="th-title">Usuário</th>
                      <th className="th-title">Perfil</th>
                      <th className="th-title">Antes</th>
                      <th className="th-title">Depois</th>
                      <th className="th-title">Horário</th>
                    </tr>
                    {log.map((elm, ind) => (
                      <tr key={`table-list-meeting-${ind}`}>
                        <td className="center">{elm.userDetails.name}</td>
                        <td className="center">{GROUPS_NAME[parseInt(elm.groupId, 10)]}</td>
                        <td className="center">{elm.beforeChange}</td>
                        <td className="center">{elm.afterChange}</td>
                        <td className="center">{elm.createdDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

RequirementComments.defaultProps = {
  type: null,
  idType: null,
  toggle: () => {}
};

RequirementComments.propTypes = {
  type: PropTypes.any,
  idType: PropTypes.any,
  toggle: PropTypes.func
};

export default RequirementComments;
