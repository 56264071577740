import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import Cover from '~/components/Report/Cover';
import DimensionsData from '~/components/Report/DimensionsData';
import { calculatePages } from '~/config/helpers';
import {
  ACCREDITED_REPORT,
  ATTACHMENT_REPORT,
  CALCULATE_FOR_WEATHER_VERSIONS,
  EDITION_REPORT_RN_507,
  EVALUATION_TYPE,
  RN_507_CLASSIFICATION_NAME_FOR_REPORT,
  RN_507_REPORT_OBJECTIVE,
  RN_507_SCOPE_REPORT,
  RN_507_TYPES,
} from '~/constants';

const Self = ({ accreditation }) => {
  const [totalPages, setTotalPages] = useState(0);
  const pagesDetails = calculatePages(accreditation, 2, 1, 2, totalPages, setTotalPages);

  return (
    <>
      <Cover
        evaluationType={`Relatório de Autoavaliação Assistida - segmento ${
          RN_507_CLASSIFICATION_NAME_FOR_REPORT[accreditation?.classification]
        }`}
        evaluationObjective={
          RN_507_REPORT_OBJECTIVE[CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)][RN_507_TYPES.SELF_EVALUATION][
            accreditation?.classification
          ]
        }
        evaluationScope={
          RN_507_SCOPE_REPORT[RN_507_TYPES.SELF_EVALUATION][accreditation?.classification]
        }
        attachmentReport={
          ATTACHMENT_REPORT[CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)][
            EVALUATION_TYPE.RN_507
          ]
        }
        accredited={ACCREDITED_REPORT[EVALUATION_TYPE.RN_507]}
        accreditation={accreditation}
        evaluators={accreditation?.evaluatorsCompany}
        totalPages={totalPages}
        editionReport={
          EDITION_REPORT_RN_507[
            CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
          ][RN_507_TYPES.SELF_EVALUATION][accreditation?.classification]
        }
        isPre={accreditation?.type_rn452 === RN_507_TYPES.PRE}
        isSelf={accreditation?.type_rn452 === RN_507_TYPES.SELF_EVALUATION}
        isSuper={accreditation?.type_rn452 === RN_507_TYPES.SUPERVISION}
      />
      {accreditation?.dimensionsList.map((elm) => (
        <DimensionsData
          key={uniqId()}
          id={elm}
          data={accreditation.dimensions[elm]}
          startPage={pagesDetails[elm].start}
          separatorRequirements={pagesDetails[elm].separatorRequirements}
          totalPages={totalPages}
          evaluationType={EVALUATION_TYPE.RN_507}
          editionReport={
            EDITION_REPORT_RN_507[
              CALCULATE_FOR_WEATHER_VERSIONS(accreditation?.createdDateTimestamp)
            ][RN_507_TYPES.SELF_EVALUATION][accreditation?.classification]
          }
        />
      ))}
    </>
  );
};

Self.defaultProps = {
  accreditation: {}
};

Self.propTypes = {
  accreditation: PropTypes.any
};

export default Self;
