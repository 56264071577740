import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';
import { SERVER_SUBSCRIPTIONS_IMG, CALCULATE_FOR_WEATHER_VERSIONS } from '~/constants';

const FinalComments = ({ accreditation, editionReport, startPage, totalPages, RNType }) => {
  const { users } = useSelector((state) => state.users);

  const [version, setVersion] = useState();

  useEffect(() => {
    if (accreditation && accreditation.createdDateTimestamp) {
      setVersion(CALCULATE_FOR_WEATHER_VERSIONS(accreditation.createdDateTimestamp));
    }
  }, [accreditation]);

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Comentários Finais
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.Row bottom={'FIRST_VERSION' || version === 'SECOND_VERSION' ? 20 : 120}>
        A A4Quality agradece a participação de todos os colaboradores e parabeniza a liderança da{' '}
        {accreditation?.company?.name} pela iniciativa de implantar a RN {RNType} e posicioná-la
        como uma organização que busca a melhoria contínua de sua gestão.
        <br />
        <br />
        Ressaltamos que a A4Quality, em conformidade com seu Código de Ética, compromete-se a manter
        sigilo referente às informações obtidas durante o processo de avaliação, bem como não
        revelar assuntos discutidos, em relação às instalações, serviços e produtos, sem a devida
        autorização.
        <br />
        <br />
        Colocamo-nos à disposição para quaisquer esclarecimentos necessários quanto ao conteúdo do
        presente relatório.
      </S.Row>

      <S.Row>
        {version === 'FIRST_VERSION' || version === 'SECOND_VERSION' ? (
          <div>
            <S.FinalSubscriptionContainer>
              <S.FinalSubscription center overName>
                <img
                  src={
                    SERVER_SUBSCRIPTIONS_IMG +
                    users.inactive.filter((elm) => elm.id === 3)[0]?.subscription
                  }
                  alt="Assinatura"
                />
              </S.FinalSubscription>
              <strong>Jeanine Barbosa Cavalcanti Sheludiakoff</strong>
            </S.FinalSubscriptionContainer>
            <div>
              Gerente de Acreditação e Qualidade da A4Quality
              <br />
              Services jeaninebarbosa@a4quality.com
              <br />
              tel: +55(21) 2586-6255
              <br />
              www.a4quality.com
            </div>
          </div>
        ) : (
          <div>
            tel: +55(21) 2586-6255
            <br />
            www.a4quality.com
          </div>
        )}
      </S.Row>

      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

FinalComments.defaultProps = {
  accreditation: {},
  startPage: 0,
  totalPages: 0,
  RNType: '507',
  editionReport: ''
};

FinalComments.propTypes = {
  accreditation: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  RNType: PropTypes.string,
  editionReport: PropTypes.string
};

export default FinalComments;
