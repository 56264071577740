import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { RN_506_TYPES } from '~/constants';
import Self from './Self';
import Certification from './Certification';
import Supervision from './Supervision';
import Pre from './Pre';
import * as S from './styles';

const Types = forwardRef(({ evaluation, onlyGaps, error }, ref) => {
  switch (evaluation?.type_rn440) {
    case RN_506_TYPES.PRE:
      return (
        <div ref={ref}>
          <Pre evaluation={evaluation} onlyGaps={onlyGaps} />
        </div>
      );
    case RN_506_TYPES.SUPERVISION:
      return (
        <div ref={ref}>
          <Supervision evaluation={evaluation} />
        </div>
      );
    case RN_506_TYPES.CERTIFICATION:
      return (
        <div ref={ref}>
          <Certification evaluation={evaluation} />
        </div>
      );
    case RN_506_TYPES.SELF_EVALUATION:
      return (
        <div ref={ref}>
          <Self evaluation={evaluation} />
        </div>
      );
    default:
      return (
        <div ref={ref}>
          <S.NotFound>{error}</S.NotFound>
        </div>
      );
  }
});

Types.defaultProps = {
  evaluation: {},
  onlyGaps: false,
  error: null
};

Types.propTypes = {
  evaluation: PropTypes.any,
  onlyGaps: PropTypes.bool,
  error: PropTypes.string
};

export default Types;
