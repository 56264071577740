import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import '~/config/reactotronConfig';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'tools'],
  stateReconciler
};

const sagaMonitor = process.env.REACT_APP_SCOPE === 'dev' ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, middlewares);

sagaMiddleware.run(rootSaga);

export default store;
