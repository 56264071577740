import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';

const Prerequisites = ({ prerequisites, startPage, totalPages, editionReport, reportName }) => {
  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Atendimento aos Pré-Requisitos para a {reportName}
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.Table bottomMargin requirementsTable>
        <tbody>
          <tr>
            <th className="th-title" colSpan="3">
              Descrição
            </th>
            <th className="th-title">Atende</th>
          </tr>
          {prerequisites.map((elm) => (
            <tr key={uniqId()}>
              <td className="td-title left wrap" colSpan="3">{`${elm.details.text}`}</td>
              <td className="td-title --yesNo">{elm.itHas ? 'Sim' : 'Não'}</td>
            </tr>
          ))}
        </tbody>
      </S.Table>
      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

Prerequisites.defaultProps = {
  prerequisites: [],
  startPage: 0,
  totalPages: 0,
  editionReport: '',
  reportName: ''
};

Prerequisites.propTypes = {
  prerequisites: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string,
  reportName: PropTypes.string
};

export default Prerequisites;
