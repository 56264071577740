import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import uniqId from 'uniqid';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { chartExample3, defaultDatasets } from '~/constants/charts';

const Dashboard = () => {
  const { customGraphs } = useSelector((state) => state.graphs);

  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const listScore = (d) =>
      customGraphs.map((elm) => parseFloat(elm.dimensions[d].dimensionScore));

    const listCompany = () => customGraphs.map((elm) => elm.company.name);

    const prepare = {
      1: { score: listScore(1), company: listCompany() },
      2: { score: listScore(2), company: listCompany() },
      3: { score: listScore(3), company: listCompany() },
      4: { score: listScore(4), company: listCompany() }
    };

    setDimensions(prepare);
  }, [customGraphs]);

  const dataChartTypes = (canvas, dimension) => {
    return {
      labels: dimensions[dimension].company,
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Quantidade',
          fill: true,
          borderColor: '#d048b6',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: dimensions[dimension].score
        }
      ]
    };
  };

  return (
    <div className="content">
      <Row>
        {Object.keys(dimensions).map((dimension) => (
          <Col xs="12" key={uniqId()}>
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Gráfico Customizado</h5>
                    <CardTitle tag="h2">Dimensão {dimension}</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={(canvas) => dataChartTypes(canvas, dimension)}
                    options={chartExample3.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Dashboard;
