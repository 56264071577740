import styled from 'styled-components';
import { colors, alphas } from '~/constants';

export const Body = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Details = styled.div`
  padding: 0 0 10px 0;
`;

export const DetailsButton = styled.div`
  padding: 0 5px 0 5px;
`;

export const TabContainer = styled.div`
  padding-top: 10px;
`;

export const CollapseContainer = styled.div`
  padding-left: ${(props) => (props.max ? props.max : '14px')};
`;

export const Saving = styled.div`
  display: flex;
  flex-direction: row;
  h6 {
    ${(props) => (props.color ? `color: ${props.color};` : '')}
  }
`;

export const TitleDimension = styled.div`
  padding: 10px 0 0 23px;
`;

export const AuditedAreaBtn = styled.div`
  margin-top: 10px 0 0 20px;
`;

export const LastAccreditation = styled.span`
  font-size: 11px;
  color: ${colors.green};
`;

export const LogoCompany = styled.div`
  padding: 0 30px 0 0;
  display: flex;
  flex-direction: row-reverse;
  img {
    width: 150px;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ModalContent = styled.div`
  margin-bottom: 20px;
  h4 {
    color: #525f7f;
  }
  hr.separator {
    margin-top: 40px;
  }
  hr.separator-min {
    margin-top: 0px !important;
  }
  hr.min-separator {
    margin-top: 15px;
  }
  .btn-action-modal {
    width: 100%;
    margin-top: 10px;
  }
`;

export const ButtonAdd = styled.div`
  margin-top: 42px;
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid black;
  th,
  td {
    border: 1px solid black;
    padding-left: 10px;
    &.center {
      text-align: center;
    }
  }

  .th-title {
    padding-left: 0;
    text-align: center;
    font-weight: bold;
  }

  .td-title {
    padding-left: 0;
    text-align: center;
    width: 170px;
    font-weight: bold;
    &.--yesNo {
      width: 50px;
    }
  }
  .btn-secondary {
    padding: 5px 20px !important;
  }
`;

export const Actions = styled.div`
  min-width: 50px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
`;

export const Loading = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999;
  background-color: ${colors.black + alphas.a10};
`;

export const LineCheckbox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 20px;
  .label-check {
    margin-top: 20px;
  }
`;

export const ResumeIsRemote = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
