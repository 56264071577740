import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Spinner } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import history from '~/config/history';
import {
  STATUS_EVALUATION,
  RN_507_TYPES,
  EVALUATION_TYPE,
  RN_506_TYPES,
  RN_507_CLASSIFICATION
} from '~/constants';
import { Button, Modal } from '~/components';
import Progress from './Progress';
import * as S from './styles';
import { joinEvaluators } from '~/config/helpers';
import { SubmitFeedback, SubmitFinished } from '~/services/private';
import { RN_506_ROUTES, RN_507_ROUTES } from '~/routes/names';

const BasePageEvaluationCompany = ({
  module,
  title,
  text,
  data,
  reload,
  itsLoading,
  evaluationType,
  classification,
  RNType
}) => {
  const { user } = useSelector((store) => store.auth);
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [modal, setModal] = useState(false);
  const [idEdit, setIdEdit] = useState(false);
  const [idSubmit, setIdSubmit] = useState(false);
  const [hasFeedback, setHasFeedback] = useState(false);

  const isSelfEvaluation =
    RNType === RN_507_TYPES.SELF_EVALUATION || RNType === RN_506_TYPES.SELF_EVALUATION;

  const clickSelfEvaluation = (id) => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        history.push(RN_507_ROUTES(classification, id, true).FILL);
        break;
      case EVALUATION_TYPE.RN_506:
        history.push(RN_506_ROUTES(classification, id, true).FILL);
        break;
      default:
        toast.warning('Tipo de avaliação não informado!');
    }
  };

  const finished = async () => {
    try {
      setLoadingFinished(true);
      const obj = await SubmitFinished({
        id: idSubmit
      });
      reload();
      toast.success(obj.data);
      setModal(false);
    } catch (e) {
      const err = e?.data?.result;
      toast.error(err || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingFinished(false);
    }
  };

  const feedback = async () => {
    try {
      setLoadingFeedback(true);
      const obj = await SubmitFeedback({
        id: idSubmit
      });
      reload();
      toast.success(obj.data);
      setModal(false);
    } catch (e) {
      const err = e?.data?.result;
      toast.error(err || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingFeedback(false);
    }
  };

  const SelfHead = (elm, index) => (
    <>
      <S.Title>
        {index + 1}º Avaliação {elm.analysisUser && '- (Possui Feedback)'}
      </S.Title>
      <S.Text>Criada em {elm?.evaluation?.createdDate}</S.Text>
      {elm.analysisUser ? (
        <S.Text>O feedback será realizado por {elm.analysisUser.name}</S.Text>
      ) : null}
      <S.Text>Status: {elm?.evaluation?.statusDetails.name}</S.Text>
      {elm?.evaluation?.status === STATUS_EVALUATION.OPEN ||
      elm?.evaluation?.status === STATUS_EVALUATION.STARTED ? (
        <S.Btn>
          <Button title="Preencher Avaliação" onClick={() => clickSelfEvaluation(elm.id)} />
        </S.Btn>
      ) : null}
      {elm?.evaluation?.status === STATUS_EVALUATION.STARTED &&
      elm?.leaderApproval?.id === user.id &&
      elm.analysisUser &&
      parseInt(elm.progress, 10) === 100 ? (
        <S.Btn>
          <Button
            color="info"
            title="Enviar para Feedback"
            onClick={() => {
              setHasFeedback(true);
              setTitleModal('Deseja envia sua autoavaliação para feedback?');
              setIdSubmit(elm?.evaluation?.id);
              setIdEdit(elm?.id);
              setModal(true);
            }}
            loading={loadingFeedback}
          />
        </S.Btn>
      ) : null}
      {elm.status === STATUS_EVALUATION.STARTED &&
      elm?.leaderApproval?.id === user.id &&
      !elm.analysisUser &&
      parseInt(elm.progress, 10) === 100 ? (
        <S.Btn>
          <Button
            color="info"
            title="Finalizar avaliação"
            onClick={() => {
              setHasFeedback(false);
              setTitleModal('Deseja finalizar sua autoavaliação?');
              setIdSubmit(elm?.evaluation?.id);
              setIdEdit(elm?.id);
              setModal(true);
            }}
            loading={loadingFinished}
          />
        </S.Btn>
      ) : null}
    </>
  );

  const Head = (elm, index) => (
    <>
      <S.Title>{index + 1}º Avaliação </S.Title>
      <S.Text>Criada em {elm.evaluation.createdDate}</S.Text>
      <S.Text>
        Avaliadores: {joinEvaluators(elm.evaluatorsDetails, elm.evaluatorsAdminDetails)}
      </S.Text>
      <S.Text>Líder responsável: {elm?.leaderApproval?.name}</S.Text>
      {elm.evaluation.analysisUser ? (
        <S.Text>Análise e Decisão: {elm.evaluation.analysisUser.name}</S.Text>
      ) : null}
      <S.Text>
        Status: {elm.evaluation.statusDetails.name} - {elm.evaluation.statusDetails.time}
      </S.Text>
    </>
  );

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">{module}</h5>
                  <CardTitle tag="h2">{title}</CardTitle>
                  <h5>{text}</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                {itsLoading ? (
                  <S.Loading>
                    <Spinner size="md" color="white" animation="border" />
                  </S.Loading>
                ) : data.length > 0 ? (
                  data.map((elm, index) => (
                    <S.EvaluationContainer key={`evaluation-container-${index}`}>
                      {isSelfEvaluation ? SelfHead(elm, index) : Head(elm, index)}
                      <S.ProgressContainer key={`evaluation-container-${index}`}>
                        <Progress
                          type={RNType}
                          status={elm.evaluation.status}
                          itsLoading={itsLoading}
                          percent={parseFloat(elm.progress)}
                          analysisUser={elm.evaluation.analysisUser}
                        />
                      </S.ProgressContainer>
                      <S.HR />
                    </S.EvaluationContainer>
                  ))
                ) : (
                  <Col xs="12">
                    <S.TabEmpty>
                      <h4>Nenhuma {title} foi iniciada.</h4>
                    </S.TabEmpty>
                  </Col>
                )}
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={() => setModal(false)} title="Etapa final">
        <div className="content">
          <Row>
            <Col xs="12">{titleModal}</Col>
          </Row>
          <div style={{ marginTop: 40 }}>
            <Row>
              <Col xs="6">
                <Button
                  color="info"
                  title="Não, ainda preciso analisar"
                  onClick={() => clickSelfEvaluation(idEdit)}
                  block
                />
              </Col>
              <Col xs="6">
                <Button
                  color="primary"
                  title="Sim"
                  onClick={hasFeedback ? feedback : finished}
                  loading={loadingFinished || loadingFeedback}
                  block
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};

BasePageEvaluationCompany.defaultProps = {
  module: '',
  title: '',
  text: '',
  evaluationType: EVALUATION_TYPE.RN_507,
  classification: RN_507_CLASSIFICATION.MEDICAL_HOSPITAL,
  RNType: RN_507_TYPES.ACCREDITATION,
  itsLoading: false,
  data: [],
  reload: () => {}
};

BasePageEvaluationCompany.propTypes = {
  module: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  evaluationType: PropTypes.number,
  classification: PropTypes.number,
  RNType: PropTypes.number,
  itsLoading: PropTypes.bool,
  data: PropTypes.any,
  reload: PropTypes.func
};

export default BasePageEvaluationCompany;
