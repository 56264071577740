import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { textPageEvaluations } from '~/config/helpers';
import { BasePageEvaluationCompany } from '~/components';
import { GetEvaluations } from '~/services/private';

const EvaluationListCompany = ({ evaluationType, classification, match }) => {
  const [evaluations, setEvaluations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const location = useLocation();
  const RNType = parseInt(match.params.type, 10);

  const [textPage, setTextPage] = useState(
    textPageEvaluations(evaluationType, RNType, false, classification)
  );

  const listEvaluations = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);
      const { data } = await GetEvaluations(evaluationType, classification, RNType);
      setEvaluations(data);
    } catch (err) {
      setError(true);
      setEvaluations([]);
      toast.error(err.data.result);
    } finally {
      setLoading(false);
    }
  }, [RNType, classification, evaluationType]);

  const reload = () => listEvaluations().finally(() => {});

  useEffect(() => {
    setTextPage(textPageEvaluations(evaluationType, RNType, false, classification));
    listEvaluations().finally(() => {});
  }, [location.pathname, listEvaluations, evaluationType, match.path, RNType, classification]);

  return (
    <>
      <BasePageEvaluationCompany
        module={textPage.minTitle}
        title={textPage.title}
        text={textPage.text}
        itsLoading={loading}
        error={error}
        reload={reload}
        data={evaluations}
        evaluationType={evaluationType}
        classification={classification}
        RNType={RNType}
      />
    </>
  );
};
export default EvaluationListCompany;
