import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { CreateEvaluator, UpdateEvaluator } from '~/services/private';
import { Button, FileInput } from '~/components';
import history from '~/config/history';
import { validateForm } from './validateForm';
import * as L from '~/modules/_layouts/private/styles';
import * as S from './styles';
import { SERVER_SUBSCRIPTIONS_IMG } from '~/constants';
import { ADMIN } from '~/routes/names';

const INITIAL_STATE = {
  id: '',
  name: '',
  cpf: '',
  phone: '',
  graduate: '',
  postGraduate: '',
  accountId: '',
  email: '',
  minimumExperienceInBusinessAudit: '',
  minimumExperienceInControllership: '',
  minimumExperienceInHealthAccreditation: '',
  minimumExperienceInHealthAaudit: ''
};

const options = [
  { id: '1', title: 'Sim' },
  { id: '0', title: 'Não' }
];

const EditEvaluator = ({ location }) => {
  const isEdition = location.pathname === '/edit-evaluator';

  const evaluator = isEdition ? location.state.evaluator : INITIAL_STATE;

  const [loading, setLoading] = useState(false);
  const [alterImageInEdition, setAlterImageInEdition] = useState(false);
  const [file, setFile] = useState({});

  const previewImage = !isEdition
    ? undefined
    : {
        id: evaluator.id,
        name: `Assinatura_${evaluator.name}`,
        url: SERVER_SUBSCRIPTIONS_IMG + evaluator.subscription
      };

  const Create = async (newUser) => {
    try {
      if (!file.base64) {
        toast.error('Escolha uma foto');
        return;
      }
      setLoading(true);
      const { data } = await CreateEvaluator({
        ...newUser,
        file: {
          ...file
        },
        account: {
          email: newUser.email
        }
      });
      toast.success(data);
      history.push(ADMIN.EVALUATORS);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a atualização.');
    } finally {
      setLoading(false);
    }
  };

  const Update = async (newUser) => {
    try {
      if (alterImageInEdition && !file.base64) {
        toast.error('Escolha uma foto');
        return;
      }
      setLoading(true);
      const { data } = await UpdateEvaluator({
        ...evaluator,
        ...newUser,
        file: {
          ...file
        },
        account: {
          id: evaluator.accountId,
          email: newUser.email
        }
      });
      toast.success(data);
      history.push(ADMIN.EVALUATORS);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdition && !location.state) {
      history.push(ADMIN.EVALUATORS);
    }
  }, [isEdition, location]);

  const confirm = (data) => {
    if (isEdition) {
      Update(data);
    } else {
      Create(data);
    }
  };

  const chosenFile = (chosen) => {
    setFile(chosen);
    if (isEdition) setAlterImageInEdition(true);
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Administrativo</h5>
                  <CardTitle tag="h2">{isEdition ? 'Editar' : 'Cadastrar'} Avaliador</CardTitle>
                  <h5>Antes de submeter o formulário, preencha todos os campos obrigatórios.</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <L.Form schema={validateForm} onSubmit={confirm} initialData={evaluator}>
                  <Row>
                    <Col xs="6">
                      <L.InputContainer>
                        <FileInput chosenFile={chosenFile} preview={previewImage} />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Nome" name="name" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="cpf-number">
                          CPF
                        </label>
                        <InputMask
                          mask="999.999.999-99"
                          maskChar={null}
                          defaultValue={evaluator.cpf}
                        >
                          {() => <L.Input name="cpf" type="text" id="cpf-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="phone-number">
                          Telefone
                        </label>
                        <InputMask
                          mask="(99) 99999-9999"
                          maskChar={null}
                          defaultValue={evaluator.phone}
                        >
                          {() => <L.Input name="phone" type="phone" id="phone-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="E-mail" name="email" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Graduação" name="graduate" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Pós-graduação" name="postGraduate" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Select
                          label="Experiência mínima de 5 (cinco) anos em auditoria empresarial"
                          name="minimumExperienceInBusinessAudit"
                          options={options}
                        />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Select
                          label="Experiência mínima de 5 (cinco) anos em controladoria"
                          name="minimumExperienceInControllership"
                          options={options}
                        />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Select
                          label="Experiência mínima de 5 (cinco) anos em Acreditação em saúde"
                          name="minimumExperienceInHealthAccreditation"
                          options={options}
                        />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Select
                          label="Experiência mínima de 5 (cinco) anos em auditoria em saúde"
                          name="minimumExperienceInHealthAaudit"
                          options={options}
                        />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.ButtonContainer>
                        <Button
                          title={isEdition ? 'Editar' : 'Cadastrar'}
                          type="submit"
                          loading={loading}
                          block
                        />
                      </L.ButtonContainer>
                    </Col>
                  </Row>
                </L.Form>
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default EditEvaluator;
