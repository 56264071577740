import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionsButtons } from '~/components';
import { userRequest } from '~/store/modules/users/actions';
import { EnabledUser } from '~/services/private';

import history from '~/config/history';
import BasePage from '~/components/BasePage';
import { ADMIN } from '~/routes/names';

const Users = () => {
  const { users, fetch } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const listUsers = useCallback(() => dispatch(userRequest()), [dispatch]);

  const ActiveUser = async (account) => {
    try {
      const { data } = await EnabledUser({
        id: account.id,
        status: !account.active
      });
      toast.success(data.result);
      listUsers();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    }
  };

  const AddUser = () => {
    history.push(ADMIN.USERS_NEW);
  };

  const EditUser = (userID, account) => {
    const userFilter = users[account.active ? 'active' : 'inactive'].filter(
      (elm) => elm.id === userID
    );

    const user = {
      ...userFilter[0],
      email: userFilter[0].account.email,
      accountId: userFilter[0].account.id,
      minimumExperienceInBusinessAudit: userFilter[0].minimumExperienceInBusinessAudit ? '1' : '0',
      minimumExperienceInControllership: userFilter[0].minimumExperienceInControllership
        ? '1'
        : '0',
      minimumExperienceInHealthAccreditation: userFilter[0].minimumExperienceInHealthAccreditation
        ? '1'
        : '0',
      minimumExperienceInHealthAaudit: userFilter[0].minimumExperienceInHealthAaudit ? '1' : '0',
      graduate: userFilter[0].universityGraduate
    };
    history.push(ADMIN.USERS_EDIT, { user });
  };

  useEffect(() => {
    listUsers();
  }, [listUsers]);

  const columns = [
    {
      name: 'name',
      label: 'Nome'
    },
    {
      name: 'cpf',
      label: 'Cpf'
    },
    {
      name: 'phone',
      label: 'Telefone'
    },
    {
      name: 'account',
      label: 'E-Mail',
      options: {
        customBodyRender: (value) => value.email
      }
    },
    {
      name: 'account',
      label: 'Ativo',
      options: {
        customBodyRender: (value) => (value.active ? 'Sim' : 'Não')
      }
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, data) => (
          <ActionsButtons
            labelModal="o usuário"
            hasActive
            active={data.rowData[3]?.active}
            hasEdit
            actionEdit={() => EditUser(value, data.rowData[3])}
            actionPower={() => ActiveUser(data.rowData[3])}
          />
        )
      }
    }
  ];

  return (
    <BasePage
      title="Diretoria"
      text="Os usuários abaixo possuem acesso ADMIN ao sistema."
      itsLoading={fetch.itsLoading}
      btnText="Cadastrar Diretoria"
      btnAction={AddUser}
      columns={columns}
      data={users}
    />
  );
};
export default Users;
