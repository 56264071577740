import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { userSetLoading, setUsers } from './actions';
import { USER_REQUEST } from './patterns';
import { GetUsers } from '~/services/private';

function* getUsers() {
  try {
    const { data } = yield call(GetUsers);
    yield put(setUsers({ users: data }));
  } catch (err) {
    toast.error(err.result);
  } finally {
    yield put(userSetLoading(false));
  }
}

export default all([takeLatest(USER_REQUEST, getUsers)]);
