import styled from 'styled-components';
import { colors } from '~/constants';

export const Body = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
`;

export const Loading = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CoverPage = styled.div`
  position: relative;
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    // min-height: initial;
    min-height: 360mm;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`;

export const CoverPageLogo = styled.div`
  ${(props) => (props.center ? 'margin: auto;' : '')}
  ${(props) => (props.overName ? 'margin-bottom: -30px;' : '')}
  max-width: 152px !important;

  img {
    margin-bottom: 12px;
  }
`;

export const CoverSubscription = styled.div`
  margin: auto;
  img {
    height: 40px !important;
  }
  div.withImg {
    max-width: 130px;
    margin: auto;
  }
`;

export const FinalSubscriptionContainer = styled.div`
  max-width: 300px !important;
`;

export const FinalSubscription = styled.div`
  max-width: 152px !important;
  ${(props) => (props.center ? 'margin: auto;' : '')}
  ${(props) => (props.overName ? 'margin-bottom: -30px;' : '')}
`;

export const CoverDiary = styled.div`
  margin: auto;
  img.report-diary-img {
    width: 100%;
    margin: auto;
  }
  div.report-diary-div {
    width: 100%;
    margin: auto;
  }
  @media print {
    div.report-diary-img {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    div.report-diary-div {
      width: 100%;
      margin: auto;
    }
  }
`;

export const ContainerSubscriptions = styled.div`
  // min-width: 150px;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    margin: 0 5px 0 5px;
  }
`;

export const CoverPageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  div {
    width: 100%;
    h4 {
      text-align: center;
      color: ${colors.black};
      line-height: 1.45em;
      margin-bottom: 0px;
    }
    p {
      text-align: center;
      color: ${colors.black};
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid black;
  ${(props) => (props.bottomMargin ? 'margin-bottom: 30px;' : '')}
  ${(props) => (props.bottomMarginMin ? 'margin-bottom: 25px;' : '')}
  ${(props) => (props.bottomMarginForCover ? 'margin-bottom: 20px;' : '')}
  ${(props) => (props.bottomMarginMinForCover ? 'margin-bottom: 15px;' : '')}
  ${(props) => (props.bottomMarginForDiary ? 'margin-bottom: 5px;' : '')}
  ${(props) => (props.requirementsTable ? 'font-size: 11px !important;' : '')}

  tr.is-comments {
    td {
      height: 80px !important;
      vertical-align: top;
      text-align: left;
      padding-top: 5px;
      white-space: pre-wrap;
      span {
        font-size: 9px;
        text-decoration: underline;
        &.--no-underline {
          font-weight: bold;
          text-decoration: none;
        }
        &.center {
          width: 100%;
          text-align: center;
        }
      }
      p {
        &.center {
          width: 100%;
          text-align: center;
          color: ${colors.text} !important;
        }
      }
    }

    &.--has-feedback {
      td {
        height: 80px !important;
      }
    }
  }

  th,
  td {
    border: 1px solid black;
    padding-left: 10px;
    &.--isListEvaluators{
      font-size: 8pt !important;
    }
    &.--isListEvaluatorsPre{
      padding: 5px 10px;
    }
    &.center {
      text-align: center;
    }
    &.left {
      padding-left: 5px !important;
      text-align: left !important;
    }
    &.right {
      padding-right: 5px !important;
      text-align: right !important;
    }
    &.--noBold {
      text-align: justify;
      font-weight: normal !important;
    }
    &.--withPadding {
      padding: 5px;
    }
    p,
    span {
      &.default {
        color: ${colors.text} !important;
        font-weight: normal !important;
      }
      &.center {
        width: 100%;
        text-align: center;
        color: ${colors.text} !important;
        font-weight: normal !important;
      }
    }
  }

  .th-title {
    padding-left: 0;
    text-align: center;
    font-weight: bold;
    &.left {
      padding-left: 5px !important;
      text-align: left !important;
    }
    &.--min-padding {
      padding-left: 3px;
      padding-right: 3px;
    }
    &.--more-width {
      min-width: 100px;
    }
    &.--is-diary-date {
      width: 75px;
      font-size: 9px !important;
    }
    &.--is-diary-time {
      width: 115px;
      font-size: 9px !important;
    }
    &.--is-diary-job {
      font-size: 9px !important;
    }
  }
  .td-title {
    padding-left: 0;
    text-align: center;
    width: 170px;
    font-weight: bold;
    &.--2th {
      width: 100px;
    }
    &.--yesNo {
      width: 50px;
    }
    &.--dimension {
      width: 300px;
    }
    &.wrap {
      white-space: pre-wrap;
    }
    &.--icon-type {
      width: 50px !important;
      i {
        font-size: 20px;
      }
    }
  }

  .--people-interviewed {
    font-size: 9.5px !important;
  }

  .--meeting {
    font-size: 9px !important;
    &.--meeting-width{
      width: 250px !important;
    }
  }

  .--is-audited-areas {
    font-size: 11px !important;
  }
`;

export const Signature = styled.div`
  display: flex;
`;

export const NotFound = styled.h2`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: ${colors.black} !important;
`;

export const CardColumns = styled.div`
  column-count: ${(props) => (props.columnCount ? props.columnCount : '3')};
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
  // break-inside: avoid-column;
  orphans: 1;
  widows: 1;
`;

export const Chart = styled.div`
  width: 100%;
  margin-bottom: 10px;
  div.chart-dimension {
    margin-left: auto;
    margin-right: auto;
  }
  @media print {
    div.chart-dimension {
      width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Legend = styled.div`
  width: 100%;
  margin-bottom: 10px;
  th {
    width: 33% !important;
  }
`;

export const CardColumnsItems = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Title = styled.h4`
  color: ${colors.black} !important;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Subtitle = styled.h4`
  color: ${colors.text} !important;
  font-weight: bold;
  font-size: 16px !important;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const MinSubtitle = styled.h4`
  color: ${colors.text} !important;
  ${(props) => (props.bold ? 'font-weight: bold;' : '')}
  font-size: ${(props) => (props.min ? '10px' : '13px')} !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  table {
    ${(props) => (props.withTable ? `max-width: 400px !important;` : '')}
  }

  ${(props) => (props.spaceBetween ? `justify-content: space-between;` : '')}

  ${(props) => (props.bottom ? `margin-bottom: ${props.bottom}px;` : '')}
`;

export const TitleDimension = styled.h4`
  color: ${colors.text} !important;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  text-align: center;
`;

export const ResumeContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ResumeContent = styled.div`
  margin-bottom: 10px;
  color: ${colors.text} !important;
  span {
    text-align: justify;
    white-space: pre-wrap;
    display: block;
    font-size: 12px;
  }
  h4 {
    font-size: 14px;
    color: ${colors.text} !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 210mm !important;
  bottom: 20mm;
  padding-right: 40mm;
  span {
    font-size: 10px;
  }
  .--show-print {
    display: none;
    @media print {
      display: inline;
    }
  }
  @media print {
    width: 220mm !important;
    position: absolute;
    bottom: 10mm !important;
    padding-right: 0mm;
  }
`;
