import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 2px;
  h5 {
    margin-bottom: 0px !important;
  }

  &:hover {
    -webkit-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    -moz-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const CheckContainer = styled.div`
  label.form-check-label {
    margin-bottom: 13px;
  }
`;

export const Marker = styled.h5`
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
  color: ${colors.black} !important;
`;

export const Question = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.h5`
  width: 100%;
  font-size: 14px;
  color: ${colors.black} !important;
`;

export const Details = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const HR = styled.hr`
  border: 1px solid #c0c0c0;
`;
