import { COMPANIES_REQUEST, COMPANIES_SET_ITS_LOADING, COMPANIES_SET_LIST } from './patterns';

export function companiesRequest() {
  return {
    type: COMPANIES_REQUEST
  };
}

export function setCompanies(payload) {
  return {
    type: COMPANIES_SET_LIST,
    payload
  };
}

export function companiesSetLoading(itsLoading) {
  return {
    type: COMPANIES_SET_ITS_LOADING,
    itsLoading
  };
}
