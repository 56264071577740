export const GROUPS = {
  ADMIN: 1,
  EVALUATOR: 2,
  COMPANY_USER: 3
};

export const GROUPS_NAME = {
  1: 'Diretoria',
  2: 'Avaliador',
  3: 'Cliente'
};

export const GROUPS_LEADER_APPROVAL = {
  1: 'director',
  2: 'evaluator'
};
