import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import {
  GetCompanyUsers,
  CreateCompanyUser,
  UpdateCompanyUser,
  EnabledCompanyUser,
  CreateCompany,
  UpdateCompany
} from '~/services/private';
import { ActionsButtons, BasePage, Button, Modal, FileInput } from '~/components';
import history from '~/config/history';
import { validateForm, validateFormUser } from './validateForm';
import * as L from '~/modules/_layouts/private/styles';
import * as S from './styles';
import { SERVER_COMPANIES_IMG } from '~/constants';
import { ADMIN } from '~/routes/names';

const INITIAL_STATE = {
  id: '',
  nome: '',
  cnpj: '',
  ansRecord: '',
  segmentation: '',
  contactPerson: '',
  phone: '',
  email: '',
  address: '',
  port: '',
  numberOfEmployees: '',
  numberOfBeneficiaries: '',
  idss: ''
};

const INITIAL_STATE_USER = {
  id: '',
  nome: '',
  cpf: '',
  phone: '',
  email: '',
  type: ''
};

const options = [
  { id: '1', title: 'Contato OPS' },
  { id: '2', title: 'Usuário comum' }
];

const EditCompanies = ({ location }) => {
  const isEdition = location.pathname === '/edit-company';
  const company = isEdition ? location.state.company : INITIAL_STATE;
  const previewImage = !isEdition
    ? undefined
    : {
        id: company.id,
        name: company.name,
        url: SERVER_COMPANIES_IMG + company.image
      };

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [iisEditionUser, setIisEditionUser] = useState(false);
  const [companyUser, setCompanyUser] = useState(INITIAL_STATE_USER);
  const [listUsers, setListUsers] = useState({});
  const [file, setFile] = useState({});
  const [alterImageInEdition, setAlterImageInEdition] = useState(false);

  const listCompaniesUsers = useCallback(async () => {
    try {
      setLoadingList(true);
      const { data } = await GetCompanyUsers(company.id);
      setListUsers(data);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar na listagem de usuários.');
    } finally {
      setLoadingList(false);
    }
  }, [company]);

  useEffect(() => {
    if (isEdition) {
      listCompaniesUsers().finally();
    }
  }, [listCompaniesUsers, isEdition]);

  const Create = async (newCompany) => {
    if (!file.base64) {
      toast.error('Escolha uma foto');
      return;
    }
    try {
      setLoading(true);
      const { data } = await CreateCompany({
        ...newCompany,
        file: {
          ...file
        }
      });
      toast.success(data.result);
      history.push(ADMIN.COMPANIES);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a atualização.');
    } finally {
      setLoading(false);
    }
  };

  const Update = async (newCompany) => {
    if (alterImageInEdition && !file.base64) {
      toast.error('Escolha uma foto');
      return;
    }
    try {
      setLoading(true);
      const { data } = await UpdateCompany({
        ...company,
        ...newCompany,
        file: {
          ...file
        }
      });
      toast.success(data);
      history.push(ADMIN.COMPANIES);
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdition && !location.state) {
      history.push(ADMIN.COMPANIES);
    }
  }, [isEdition, location]);

  const confirm = async (data) => {
    if (isEdition) {
      await Update(data);
    } else {
      await Create(data);
    }
  };

  const chosenFile = (chosen) => {
    setFile(chosen);
    if (isEdition) setAlterImageInEdition(true);
  };

  const InitCreateCompanyUser = () => {
    setCompanyUser(INITIAL_STATE_USER);
    setIisEditionUser(false);
    setOpenModal(true);
  };

  const confirmUser = async (data) => {
    if (iisEditionUser) {
      await UpdateUserCompany(data);
    } else {
      await CreateUserCompany(data);
    }
  };

  const CreateUserCompany = async (newUser) => {
    try {
      setLoadingUser(true);
      const { data } = await CreateCompanyUser({
        ...newUser,
        company: company.id,
        account: {
          email: newUser.email
        }
      });
      toast.success(data);
      setOpenModal(false);
      await listCompaniesUsers();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoadingUser(false);
    }
  };

  const EditUserCompany = async (userID, account) => {
    setIisEditionUser(true);
    const userFilter = listUsers[account.active ? 'active' : 'inactive'].filter(
      (elm) => elm.id === userID
    );

    const choice = {
      ...userFilter[0],
      email: userFilter[0].account.email
    };
    setCompanyUser(choice);
    setOpenModal(true);
  };

  const UpdateUserCompany = async (newUser) => {
    try {
      setLoadingUser(true);
      const { data } = await UpdateCompanyUser({
        ...companyUser,
        ...newUser,
        account: {
          id: companyUser.account.id,
          email: newUser.email
        }
      });
      toast.success(data);
      setOpenModal(false);
      await listCompaniesUsers();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    } finally {
      setLoadingUser(false);
    }
  };

  const ActiveCompanyUser = async (account) => {
    try {
      const { data } = await EnabledCompanyUser({
        id: account.id,
        status: !account.active
      });
      toast.success(data);
      await listCompaniesUsers();
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação');
    }
  };

  const columns = [
    {
      name: 'name',
      label: 'Nome'
    },
    {
      name: 'cpf',
      label: 'Cpf'
    },
    {
      name: 'phone',
      label: 'Telefone'
    },
    {
      name: 'account',
      label: 'E-Mails',
      options: {
        customBodyRender: (value) => value.email
      }
    },
    {
      name: 'account',
      label: 'Ativo',
      options: {
        customBodyRender: (value) => (value.active ? 'Sim' : 'Não')
      }
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, data) => (
          <ActionsButtons
            labelModal="o usuário"
            hasActive
            active={data.rowData[3]?.active}
            hasEdit
            actionEdit={() => EditUserCompany(value, data.rowData[3])}
            actionPower={() => ActiveCompanyUser(data.rowData[3])}
          />
        )
      }
    }
  ];

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Administrativo</h5>
                  <CardTitle tag="h2">{isEdition ? 'Editar' : 'Cadastrar'} Empresa</CardTitle>
                  <h5>Antes de submeter o formulário, preencha todos os campos obrigatórios.</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <L.Form schema={validateForm} onSubmit={confirm} initialData={company}>
                  <Row>
                    <Col xs="6">
                      <L.InputContainer>
                        <FileInput chosenFile={chosenFile} preview={previewImage} />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Nome" name="name" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="cnpj-number">
                          CNPJ
                        </label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          maskChar={null}
                          defaultValue={company.cnpj}
                        >
                          {() => <L.Input name="cnpj" type="phone" id="cnpj-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Registro ANS" name="ansRecord" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Segmentação" name="segmentation" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Pessoa de contato" name="contactPerson" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="phone-number">
                          Telefone
                        </label>
                        <InputMask
                          mask="(99) 99999-9999"
                          maskChar={null}
                          defaultValue={company.phone}
                        >
                          {() => <L.Input name="phone" type="phone" id="phone-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="E-mail" name="email" />
                      </L.InputContainer>
                    </Col>

                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Porte" name="port" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Nº Colaboradores" name="numberOfEmployees" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Nº Beneficiários" name="numberOfBeneficiaries" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="IDSS" name="idss" />
                      </L.InputContainer>
                    </Col>

                    <Col xs="5">
                      <L.InputContainer>
                        <L.Input label="Endreço" name="address" rows={3} multiline />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.ButtonContainer>
                        <Button
                          title={isEdition ? 'Editar' : 'Cadastrar'}
                          type="submit"
                          loading={loading}
                          block
                        />
                      </L.ButtonContainer>
                    </Col>
                  </Row>
                </L.Form>
              </S.Body>
              {isEdition && (
                <BasePage
                  title="Usuários com acesso sistema"
                  text="Abaixo estão listadostodos os usuários vinculados a empresa que podem acessar a ferramenta"
                  itsLoading={loadingList}
                  columns={columns}
                  btnText="Novo usuário"
                  data={listUsers}
                  btnProps={{
                    outline: true
                  }}
                  btnAction={InitCreateCompanyUser}
                  hideForSubpage
                />
              )}
              <Modal
                isOpen={openModal}
                toggle={() => setOpenModal(false)}
                title={`${iisEditionUser ? 'Editar' : 'Cadastrar'} usuário`}
              >
                <L.Form schema={validateFormUser} onSubmit={confirmUser} initialData={companyUser}>
                  <Row>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="Nome" name="name" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="cpf-number">
                          CPF
                        </label>
                        <InputMask
                          mask="999.999.999-99"
                          maskChar={null}
                          defaultValue={companyUser.cpf}
                        >
                          {() => <L.Input name="cpf" type="text" id="cpf-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Input label="E-mail" name="email" />
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <label className="label-form" htmlFor="phone-number">
                          Telefone
                        </label>
                        <InputMask
                          mask="(99) 99999-9999"
                          maskChar={null}
                          defaultValue={companyUser.phone}
                        >
                          {() => <L.Input name="phone" type="phone" id="phone-number" />}
                        </InputMask>
                      </L.InputContainer>
                    </Col>
                    <Col xs="4">
                      <L.InputContainer>
                        <L.Select label="Perfil" name="type" options={options} />
                      </L.InputContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <L.ButtonContainer>
                        <Button
                          title={iisEditionUser ? 'Editar' : 'Cadastrar'}
                          type="submit"
                          loading={loadingUser}
                          block
                        />
                      </L.ButtonContainer>
                    </Col>
                  </Row>
                </L.Form>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default EditCompanies;
