import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 15px 0;
  border-radius: 30px;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.62475rem;
  padding: 10px 8px;
  line-height: 1.625rem;
`;

export const MenuContainer = styled.div`
  padding-left: 46px;
  a::before {
    background-color: #ccc3c3 !important;
    height: 4px !important;
    width: 4px !important;
  }
`;
