import Http from '~/config/http';
import { EVALUATION_TYPE } from '~/constants';

export const GetEvaluations = (evaluationType, classification, RNType) =>
  Http.get(`/evaluation/list/${evaluationType}/${classification}/${RNType}`);

export const GetReport = (id, isPreview) =>
  Http.get(`/evaluation/report/${id}${isPreview ? '/preview' : ''}`);

export const GetRequirements = (id, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.get(`/rn-452/requirements/${id}`);
    case EVALUATION_TYPE.RN_506:
      return Http.get(`/rn-440/requirements/${id}`);
    default:
      return null;
  }
};

export const CreateEvaluation = (body, evaluationType) => {
  switch (evaluationType) {
    case EVALUATION_TYPE.RN_507:
      return Http.post('/rn-452/create', body);
    case EVALUATION_TYPE.RN_506:
      return Http.post('/rn-440/create', body);
    default:
      return null;
  }
};

export const UpdatePrerequisites = (body, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.put('/rn-452/prerequisites', body);
    case EVALUATION_TYPE.RN_506:
      return Http.put('/rn-440/prerequisites', body);
    default:
      return null;
  }
};

export const UpdateIndicators = (body, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.put('/rn-452/update/indicators', body);
    case EVALUATION_TYPE.RN_506:
      return Http.put('/rn-440/update/indicators', body);
    default:
      return null;
  }
};

export const UpdateRequirements = (body, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.put('/rn-452/update/requirements', body);
    case EVALUATION_TYPE.RN_506:
      return Http.put('/rn-440/update/requirements', body);
    default:
      return null;
  }
};

export const UpdateComments = (body, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.put('/rn-452/update/requirements/comments', body);
    case EVALUATION_TYPE.RN_506:
      return Http.put('/rn-440/update/requirements/comments', body);
    default:
      return null;
  }
};

export const DeleteFiles = (body, typeEvaluation = EVALUATION_TYPE.RN_507) => {
  switch (typeEvaluation) {
    case EVALUATION_TYPE.RN_507:
      return Http.put('/rn-452/delete/requirements/files', body);
    case EVALUATION_TYPE.RN_506:
      return Http.put('/rn-440/delete/requirements/files', body);
    default:
      return null;
  }
};

export const SubmitAnalysis = (body) => Http.put('/evaluation/submitAnalysis', body);

export const SubmitFinishedSupervision = (body) =>
  Http.put('/evaluation/submitFinishedSupervision', body);

export const SubmitFinished = (body) => Http.put('/evaluation/submitFinished', body);

export const SubmitFeedback = (body) => Http.put('/evaluation/submitFeedback', body);

export const DeleteEvaluation = (body) => Http.put(`/evaluation/delete`, body);
