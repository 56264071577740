import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Button } from '~/components';

import * as S from './styles';
import { GROUPS, SERVER_COMPANIES_IMG, STATUS_EVALUATION, RN_507_TYPES } from '~/constants';
import CardEvaluationCompany from '~/components/CardEvaluationCompany';

const listTabPane = [
  {
    id: STATUS_EVALUATION.OPEN,
    name: 'open',
    text: 'Aberto',
    groups: [GROUPS.ADMIN, GROUPS.EVALUATOR],
    types: [
      RN_507_TYPES.PRE,
      RN_507_TYPES.SUPERVISION,
      RN_507_TYPES.ACCREDITATION,
      RN_507_TYPES.SELF_EVALUATION
    ]
  },
  {
    id: STATUS_EVALUATION.STARTED,
    name: 'started',
    text: 'Iniciado',
    groups: [GROUPS.ADMIN, GROUPS.EVALUATOR],
    types: [
      RN_507_TYPES.PRE,
      RN_507_TYPES.SUPERVISION,
      RN_507_TYPES.ACCREDITATION,
      RN_507_TYPES.SELF_EVALUATION
    ]
  },
  {
    id: STATUS_EVALUATION.ANALYSIS_AND_DECISION,
    name: 'analysisAndDecision',
    text: 'Análise e decisão',
    groups: [GROUPS.ADMIN],
    types: [RN_507_TYPES.ACCREDITATION]
  },
  {
    id: STATUS_EVALUATION.FEEDBACK,
    name: 'feedback',
    text: 'Feedback',
    groups: [GROUPS.ADMIN],
    types: [RN_507_TYPES.SELF_EVALUATION]
  },
  {
    id: STATUS_EVALUATION.FINISHED,
    name: 'finished',
    text: 'Finalizado',
    groups: [GROUPS.ADMIN],
    types: [
      RN_507_TYPES.PRE,
      RN_507_TYPES.SUPERVISION,
      RN_507_TYPES.ACCREDITATION,
      RN_507_TYPES.SELF_EVALUATION
    ]
  }
];

const BasePageEvaluation = ({
  module,
  title,
  text,
  type,
  data,
  btnText,
  btnAction,
  btnProps,
  itsLoading,
  clickEvaluation
}) => {
  const [activeTab, setActiveTab] = useState(STATUS_EVALUATION.OPEN);

  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const tabs = listTabPane.filter(
    (elm) => elm.types.includes(type) && elm.groups.includes(groupId)
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const hasBadge = (elm) => {
    if (elm.type === RN_507_TYPES.SELF_EVALUATION) {
      switch (elm.status) {
        case STATUS_EVALUATION.OPEN:
        case STATUS_EVALUATION.STARTED:
          if (elm.analysisUser) return 'Possui Feedback';
          return null;
        case STATUS_EVALUATION.FEEDBACK:
          return 'Em Feedback';
        case STATUS_EVALUATION.FINISHED:
          if (elm.analysisUser) return 'Feedback Realizado';
          return null;
        default:
          return null;
      }
    } else {
      return parseFloat(elm.progress) === 100 ? '100% Preenchida' : null;
    }
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">{module}</h5>
                  <CardTitle tag="h2">{title}</CardTitle>
                  <h5>{text}</h5>
                  {btnText && <Button title={btnText} onClick={btnAction} {...btnProps} />}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <S.Body>
                <Nav tabs>
                  {tabs.map((tabPane) => (
                    <NavItem key={tabPane.id}>
                      <NavLink
                        className={classNames({ active: activeTab === tabPane.id })}
                        onClick={() => {
                          toggle(tabPane.id);
                        }}
                      >
                        {tabPane.text}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {tabs.map((tabPane) => (
                    <TabPane tabId={tabPane.id} key={tabPane.id}>
                      <S.TabContainer>
                        <Row>
                          {itsLoading ? (
                            <S.Loading>
                              <Spinner size="md" color="white" animation="border" />
                            </S.Loading>
                          ) : data[tabPane.name]?.length > 0 ? (
                            data[tabPane.name].map((elm) => (
                              <Col xs="4" key={`${tabPane.id}-${elm.evaluation.id}`}>
                                <CardEvaluationCompany
                                  onClick={() =>
                                    clickEvaluation(
                                      elm.id,
                                      elm.evaluation.status,
                                      parseFloat(elm.progress),
                                      elm.leaderApproval,
                                      elm.evaluation.analysisUser,
                                      elm.evaluation.id
                                    )
                                  }
                                  title={elm?.evaluation?.company?.name}
                                  img={SERVER_COMPANIES_IMG + elm?.evaluation?.company?.image}
                                  progress={parseFloat(elm.progress)}
                                  time={elm?.evaluation?.statusDetails?.time}
                                  status={elm?.evaluation?.statusDetails?.name}
                                  badge={hasBadge(elm)}
                                />
                              </Col>
                            ))
                          ) : (
                            <Col xs="12">
                              <S.TabEmpty>
                                <h4>Nenhuma avaliação com esse status encontrada</h4>
                              </S.TabEmpty>
                            </Col>
                          )}
                        </Row>
                      </S.TabContainer>
                    </TabPane>
                  ))}
                </TabContent>
              </S.Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

BasePageEvaluation.defaultProps = {
  module: '',
  title: '',
  text: '',
  type: RN_507_TYPES.ACCREDITATION,
  itsLoading: false,
  data: {
    open: [],
    started: [],
    submittedForApproval: [],
    analysisAndDecision: [],
    correctionAndAdjustments: [],
    finished: []
  },
  btnText: undefined,
  btnProps: {},
  btnAction: () => {},
  clickEvaluation: () => {}
};

BasePageEvaluation.propTypes = {
  module: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.number,
  itsLoading: PropTypes.bool,
  data: PropTypes.any,
  btnText: PropTypes.string,
  btnAction: PropTypes.any,
  btnProps: PropTypes.any,
  clickEvaluation: PropTypes.func
};

export default BasePageEvaluation;
