import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Details,
  DetailsButton,
  TabContainer,
  Body,
  Saving,
  TitleDimension,
  LastAccreditation,
  AuditedAreaBtn,
  LogoCompany,
  Actions
} from './styles';
import { UpdateIndicators, UpdateRequirements } from '~/services/private';
import PrerequisitesList from './PrerequisitesList';
import ResumeModal from './Resume';
import MeetingModal from './Meeting';
import DateModal from './Date';
import DateValidity from './DateValidity';
import CompanyFields from './CompanyFields';
import PeopleInterviewed from './PeopleInterviewed';
import ControlEvaluators from './Evaluators';
import AuditedAreas from './AuditedAreas';
// import Diary from './Diary';
import ControlVisualization from './ControlVisualization';
import DeleteModal from './Delete';
import { Button } from '~/components';
import CollapseIndicators from '~/components/BasePageEvaluationView/CollapseIndicators';
import CollapseRequirements from '~/components/BasePageEvaluationView/CollapseRequirements';
import {
  DIMENSIONS_TITLE_RN_507,
  GROUPS,
  SERVER_COMPANIES_IMG,
  GROUPS_LEADER_APPROVAL,
  RN_507_TYPES,
  TYPES_MEETINGS,
  EVALUATION_TYPE,
  EVALUATION_TYPE_TEXT,
  RN_507_CONSIDERATIONS_ITEMS,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';
import history from '~/config/history';
import { RN_507_ROUTES } from '~/routes/names';
import { textPageEvaluations } from '~/config/helpers';

const RN507View = ({
  evaluationId,
  RNType,
  title,
  companyFields,
  reportDate,
  reportDateValidity,
  text,
  company,
  dimensionsList,
  dimensions,
  statusDetails,
  leaderApproval,
  prerequisites,
  meetings,
  peopleInterviewed,
  resume,
  // diary,
  auditedAreas,
  lastAccreditation,
  evaluators,
  controlVisualization,
  classification,
  createdDateTimestamp
}) => {
  const refModalPrerequisites = useRef();
  const refModalResume = useRef();
  const refModalDate = useRef();
  const refModalDateValidity = useRef();
  const refModalCompanyFields = useRef();
  const refModalInitialMeeting = useRef();
  const refModalFinalMeeting = useRef();
  const refModalPeopleInterviewed = useRef();
  // const refModalDiary = useRef();
  const refModalAudited = useRef();
  const refModalAuditedEvaluator = useRef({});
  const refModalControlVisualization = useRef({});
  const refModalEvaluators = useRef({});
  const refModalDelete = useRef({});

  const textPage = textPageEvaluations(EVALUATION_TYPE.RN_507, RNType, false, classification);

  const { user } = useSelector((store) => store.auth);

  const showOnlyAdmin = user.account.groupId === GROUPS.ADMIN;

  const showDetails =
    RNType !== RN_507_TYPES.SELF_EVALUATION &&
    (user.account.groupId === GROUPS.ADMIN || user.account.groupId === GROUPS.EVALUATOR) &&
    (user.account.groupId === GROUPS.ADMIN ||
      (leaderApproval?.type === GROUPS_LEADER_APPROVAL[user.account.groupId] &&
        leaderApproval.id === user.id) ||
      user.account.groupId === GROUPS.ADMIN);

  const showControlDetails =
    RNType === RN_507_TYPES.SELF_EVALUATION ||
    user.account.groupId === GROUPS.ADMIN ||
    (user.account.groupId === GROUPS.EVALUATOR &&
      leaderApproval?.type === GROUPS_LEADER_APPROVAL[user.account.groupId] &&
      leaderApproval.id === user.id);

  const showAuditedBtn =
    RNType !== RN_507_TYPES.SELF_EVALUATION &&
    user.account.groupId === GROUPS.EVALUATOR &&
    (leaderApproval?.type === GROUPS_LEADER_APPROVAL[GROUPS.ADMIN] ||
      (leaderApproval?.type === GROUPS_LEADER_APPROVAL[GROUPS.EVALUATOR] &&
        leaderApproval?.id !== user.id));

  const [loadingSave, setLoadingSave] = useState(false);
  const [initEdit, setInitEdit] = useState(false);
  const [dateUpdate, setDateUpdate] = useState('');
  const [activeTab, setActiveTab] = useState(dimensionsList[0]);
  const [dropMenu, setDropMenu] = useState(false);
  const [evaluatorsListControl, setEvaluatorsListControl] = useState([]);

  const toggleMenuDetails = () => setDropMenu(!dropMenu);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changeIndicators = async (id, itHas) => {
    try {
      setLoadingSave(true);
      await UpdateIndicators({
        id,
        itHas
      });
      setInitEdit(true);
      setDateUpdate(new Date().toLocaleTimeString());
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingSave(false);
    }
  };

  const changeRequirements = async (id, scope = null, time = null, TYPE) => {
    try {
      setLoadingSave(true);
      await UpdateRequirements({
        id,
        scope,
        time,
        type: TYPE
      });
      setInitEdit(true);
      setDateUpdate(new Date().toLocaleTimeString());
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar o salvamento automático.');
    } finally {
      setLoadingSave(false);
    }
  };

  const detailsShow = (types) => types.includes(RNType);

  useEffect(() => {
    const list = controlVisualization.filter((c) => c.evaluator === `e_${user.id}`);
    setEvaluatorsListControl(list);
  }, [evaluators, controlVisualization, user.id]);

  const userEvaluatorObserver = () => {
    const evaluatorObserver = evaluators.evaluatorObserver.filter(
      (evaluator) => evaluator.account.id === user.account.id
    );
    return evaluatorObserver.length > 0;
  };

  const validateDimensionControl = (d) => {
    const test = evaluatorsListControl.filter(
      (elm) => parseInt(elm.dimension, 10) === d && elm.requirement === null
    );
    return test.length > 0;
  };

  const isObs = () => {
    const isObservable = evaluators.evaluatorObserver.filter((elm) => elm.id === user.id);

    return isObservable.length > 0;
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="8">
                  <h5 className="card-category">
                    {textPage.minTitle} - {textPage.title}
                  </h5>
                  <CardTitle tag="h2">{title}</CardTitle>
                  <Details>
                    <h6>Status: {statusDetails?.name}</h6>
                    <h6>{statusDetails?.time}</h6>
                  </Details>
                </Col>
                <Col sm="4">
                  <LogoCompany>
                    <img alt="..." src={SERVER_COMPANIES_IMG + company.image} />
                  </LogoCompany>
                </Col>
                <Col className="text-left" sm="12">
                  <Actions>
                    {showDetails ? (
                      <>
                        <Button
                          title={
                            <>
                              <i className="tim-icons icon-paper" /> - Preview
                            </>
                          }
                          onClick={() =>
                            history.push(
                              RN_507_ROUTES(classification, evaluationId, true).REPORT_PREVIEW
                            )
                          }
                          color="primary"
                          id="tooltipPreview"
                        />
                        <Button
                          title={
                            <>
                              <i className="tim-icons icon-badge" /> - Controle
                            </>
                          }
                          onClick={() =>
                            refModalControlVisualization.current.changeVisibleModal(true)
                          }
                          color="secondary"
                        />
                        <Button
                          title={
                            <>
                              <i className="fas fa-users-cog" /> - Avaliadores
                            </>
                          }
                          onClick={() => refModalEvaluators.current.changeVisibleModal(true)}
                          color="info"
                        />
                        <DetailsButton>
                          <ButtonDropdown isOpen={dropMenu} toggle={toggleMenuDetails}>
                            <DropdownToggle color="tertiary" caret>
                              <i className="tim-icons icon-attach-87" /> - Preparar Relatório
                            </DropdownToggle>
                            <DropdownMenu>
                              {detailsShow([
                                RN_507_TYPES.PRE,
                                RN_507_TYPES.ACCREDITATION,
                                RN_507_TYPES.SUPERVISION
                              ]) ? (
                                <>
                                  {/* <DropdownItem */}
                                  {/*  onClick={() => refModalDiary.current.changeVisibleModal(true)} */}
                                  {/* > */}
                                  {/*  Agenda */}
                                  {/* </DropdownItem> */}
                                  {/* <DropdownItem divider /> */}
                                  <DropdownItem
                                    onClick={() => refModalAudited.current.changeVisibleModal(true)}
                                  >
                                    Áreas Auditadas
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() => refModalDate.current.changeVisibleModal(true)}
                                  >
                                    Data
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      refModalDateValidity.current.changeVisibleModal(true)
                                    }
                                  >
                                    Vigência
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      refModalCompanyFields.current.changeVisibleModal(true)
                                    }
                                  >
                                    Dados Operadora
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      refModalInitialMeeting.current.changeVisibleModal(true)
                                    }
                                  >
                                    Reunião de Abertura
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      refModalFinalMeeting.current.changeVisibleModal(true)
                                    }
                                  >
                                    Reunião de Encerramento
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() => refModalResume.current.changeVisibleModal(true)}
                                  >
                                    Resumo
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      refModalPeopleInterviewed.current.changeVisibleModal(true)
                                    }
                                  >
                                    Pessoas Entrevistadas
                                  </DropdownItem>
                                  {detailsShow([
                                    RN_507_TYPES.ACCREDITATION,
                                    RN_507_TYPES.SUPERVISION
                                  ]) ? (
                                    <>
                                      <DropdownItem divider />
                                      <DropdownItem
                                        onClick={() =>
                                          refModalPrerequisites.current.changeVisibleModal(true)
                                        }
                                      >
                                        Pré-Requisitos
                                      </DropdownItem>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </DropdownMenu>
                          </ButtonDropdown>
                        </DetailsButton>
                      </>
                    ) : null}
                    {showOnlyAdmin && (
                      <Button
                        title={
                          <>
                            <i className="fas fa-trash" /> - Apagar
                          </>
                        }
                        onClick={() => refModalDelete.current.changeVisibleModal(true)}
                        color="danger"
                      />
                    )}
                  </Actions>
                  <h5>{text}</h5>
                  {lastAccreditation !== null && user.account.groupId !== GROUPS.COMPANY_USER ? (
                    <LastAccreditation>* Resultado na última Acreditação</LastAccreditation>
                  ) : null}
                  {loadingSave && (
                    <Saving>
                      <Spinner size="sm" animation="border" />
                      ...salvando
                    </Saving>
                  )}
                  {initEdit && !loadingSave ? <h6>Dados atualizados em: {dateUpdate}</h6> : null}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Body>
                <Nav tabs>
                  {dimensionsList.map((dimension) => {
                    if (showControlDetails || validateDimensionControl(dimension) || isObs())
                      return (
                        <NavItem key={`dimension-nav-item-${dimension}`}>
                          <NavLink
                            className={classNames({ active: activeTab === dimension })}
                            onClick={() => {
                              toggle(dimension);
                            }}
                          >
                            Dimensão {dimension}
                          </NavLink>
                        </NavItem>
                      );
                    return null;
                  })}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {dimensionsList.map((tabPane) => {
                    if (showControlDetails || validateDimensionControl(tabPane) || isObs())
                      return (
                        <TabPane tabId={tabPane} key={tabPane}>
                          <TabContainer>
                            <TitleDimension>
                              <h6>{DIMENSIONS_TITLE_RN_507[tabPane]}</h6>
                            </TitleDimension>

                            {showAuditedBtn && (
                              <AuditedAreaBtn>
                                <Button
                                  color="tertiary"
                                  onClick={() =>
                                    refModalAuditedEvaluator.current[tabPane].changeVisibleModal(
                                      true
                                    )
                                  }
                                  title="Áreas Auditadas"
                                />
                                <AuditedAreas
                                  ref={(elm) => {
                                    refModalAuditedEvaluator.current[tabPane] = elm;
                                  }}
                                  evaluationId={evaluationId}
                                  dimensionsList={[tabPane]}
                                  auditedAreas={auditedAreas}
                                />
                              </AuditedAreaBtn>
                            )}
                            <Row>
                              <Col xs="12">
                                <CollapseIndicators
                                  indicators={dimensions[tabPane].indicators}
                                  changeIndicators={changeIndicators}
                                  lastAccreditation={
                                    lastAccreditation?.dimensions?.[tabPane]?.indicators
                                  }
                                  userEvaluatorObserver={userEvaluatorObserver()}
                                />
                              </Col>

                              <Col xs="12">
                                <CollapseRequirements
                                  evaluationType={EVALUATION_TYPE.RN_507}
                                  RNType={RNType}
                                  dimension={tabPane}
                                  requirements={dimensions[tabPane].requirements}
                                  changeRequirements={changeRequirements}
                                  evaluatorsListControl={evaluatorsListControl}
                                  showControlDetails={showControlDetails}
                                  isObs={isObs()}
                                  lastAccreditation={
                                    lastAccreditation?.dimensions?.[tabPane]?.requirements
                                  }
                                  userEvaluatorObserver={userEvaluatorObserver()}
                                />
                              </Col>
                            </Row>
                          </TabContainer>
                        </TabPane>
                      );
                    return null;
                  })}
                </TabContent>
                {showDetails && (
                  <>
                    <PrerequisitesList prerequisites={prerequisites} ref={refModalPrerequisites} />
                    <ResumeModal
                      createdDateTimestamp={createdDateTimestamp}
                      id={resume?.id}
                      startDay={resume?.startDay}
                      endDay={resume?.endDay}
                      month={resume?.month}
                      year={resume?.year}
                      customText={resume?.customText}
                      customTextFit={resume?.customTextFit}
                      isFit={resume?.isFit}
                      isRemote={resume?.isRemote}
                      level={resume?.level}
                      type={RNType}
                      evaluationTextType={EVALUATION_TYPE_TEXT.RN_507}
                      totalItems={
                        RN_507_CONSIDERATIONS_ITEMS[
                          CALCULATE_FOR_WEATHER_VERSIONS(createdDateTimestamp)
                        ][classification]
                      }
                      dimensions={dimensionsList}
                      ref={refModalResume}
                    />
                    <DateModal
                      ref={refModalDate}
                      id={evaluationId}
                      dateStart={reportDate.start}
                      dateEnd={reportDate.end}
                    />
                    <DateValidity
                      ref={refModalDateValidity}
                      id={evaluationId}
                      dateStart={reportDateValidity.start}
                      dateEnd={reportDateValidity.end}
                    />
                    <CompanyFields
                      ref={refModalCompanyFields}
                      id={evaluationId}
                      fields={companyFields}
                    />
                    <MeetingModal
                      meeting={meetings.filter((elm) => elm.type === TYPES_MEETINGS.INITIAL)[0]}
                      ref={refModalInitialMeeting}
                    />
                    <MeetingModal
                      meeting={meetings.filter((elm) => elm.type === TYPES_MEETINGS.FINAL)[0]}
                      ref={refModalFinalMeeting}
                    />
                    <PeopleInterviewed
                      ref={refModalPeopleInterviewed}
                      evaluationId={evaluationId}
                      people={peopleInterviewed}
                    />
                    {/* <Diary */}
                    {/*  ref={refModalDiary} */}
                    {/*  evaluationId={evaluationId} */}
                    {/*  diary={diary} */}
                    {/*  evaluators={evaluators} */}
                    {/* /> */}
                    <AuditedAreas
                      ref={refModalAudited}
                      evaluationId={evaluationId}
                      dimensionsList={dimensionsList}
                      evaluationType={EVALUATION_TYPE.RN_507}
                      auditedAreas={auditedAreas}
                    />
                    <ControlVisualization
                      ref={refModalControlVisualization}
                      evaluationId={evaluationId}
                      evaluationType={EVALUATION_TYPE.RN_507}
                      evaluators={{ ...evaluators, leaderApproval }}
                      dimensionsList={dimensionsList}
                      controlVisualization={controlVisualization}
                      dimensions={dimensions}
                    />
                    <ControlEvaluators
                      ref={refModalEvaluators}
                      evaluationId={evaluationId}
                      evaluatorsChoose={evaluators}
                      leader={leaderApproval}
                    />
                  </>
                )}
                {showOnlyAdmin && <DeleteModal evaluationId={evaluationId} ref={refModalDelete} />}
              </Body>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

RN507View.defaultProps = {
  evaluationId: null,
  RNType: null,
  reportDate: {},
  companyFields: {},
  title: '',
  text: '',
  company: {},
  statusDetails: {},
  dimensionsList: [],
  dimensions: [],
  leaderApproval: {},
  prerequisites: [],
  controlVisualization: [],
  meetings: [
    {
      type: null
    }
  ],
  peopleInterviewed: [],
  createdDateTimestamp: null,
  resume: {},
  // diary: '',
  auditedAreas: {},
  lastAccreditation: null,
  evaluators: {
    evaluators: null,
    evaluatorsAdmin: null,
    evaluatorsCompany: null
  }
};

RN507View.propTypes = {
  evaluationId: PropTypes.number,
  RNType: PropTypes.number,
  title: PropTypes.string,
  reportDate: PropTypes.any,
  text: PropTypes.string,
  company: PropTypes.object,
  statusDetails: PropTypes.object,
  companyFields: PropTypes.object,
  dimensionsList: PropTypes.any,
  dimensions: PropTypes.any,
  leaderApproval: PropTypes.any,
  createdDateTimestamp: PropTypes.any,
  prerequisites: PropTypes.any,
  controlVisualization: PropTypes.any,
  meetings: PropTypes.any,
  peopleInterviewed: PropTypes.any,
  resume: PropTypes.any,
  // diary: PropTypes.any,
  auditedAreas: PropTypes.any,
  lastAccreditation: PropTypes.any,
  evaluators: PropTypes.any
};

export default RN507View;
