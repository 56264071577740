import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import withClearCache from './ClearCache';

import Routes from './routes';
import history from './config/history';
import GlobalStyle from './styles/global';
import store from './store';

const persistor = persistStore(store);

const MainApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer position="bottom-right" />
        </Router>
      </PersistGate>
    </Provider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
