const RN_507_CLASSIFICATION = {
  MEDICAL_HOSPITAL: 1,
  DENTAL: 2,
  SELF_MANAGEMENT: 3
};

const RN_506_CLASSIFICATION = {
  APS: 1
};

export const RN_507_ROUTES = (classificationType, param) => {
  switch (classificationType) {
    case RN_507_CLASSIFICATION.MEDICAL_HOSPITAL:
      return {
        LIST: `/rn-507/medical-hospital/list/${param || ':type'}`,
        FILL: `/rn-507/medical-hospital/fill/${param || ':id'}`,
        REPORT: `/rn-507/medical-hospital/report/${param || ':id'}`,
        REPORT_PREVIEW: `/rn-507/medical-hospital/report/${param || ':id'}/preview`,
        REPORT_ONLY_GAPS: `/rn-507/medical-hospital/report/${param || ':id'}/onlyGaps`,
        REPORT_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/medical-hospital/report/${
          param || ':id'
        }/onlyGaps/withStrongPoints`,
        REPORT_PREVIEW_ONLY_GAPS: `/rn-507/medical-hospital/report/${
          param || ':id'
        }/preview/onlyGaps`,
        REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/medical-hospital/report/${
          param || ':id'
        }/preview/onlyGaps/withStrongPoints`
      };
    case RN_507_CLASSIFICATION.DENTAL:
      return {
        LIST: `/rn-507/dental/list/${param || ':type'}`,
        FILL: `/rn-507/dental/fill/${param || ':id'}`,
        REPORT: `/rn-507/dental/report/${param || ':id'}`,
        REPORT_PREVIEW: `/rn-507/dental/report/${param || ':id'}/preview`,
        REPORT_ONLY_GAPS: `/rn-507/dental/report/${param || ':id'}/onlyGaps`,
        REPORT_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/dental/report/${
          param || ':id'
        }/onlyGaps/withStrongPoints`,
        REPORT_PREVIEW_ONLY_GAPS: `/rn-507/dental/report/${param || ':id'}/preview/onlyGaps`,
        REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/dental/report/${
          param || ':id'
        }/preview/onlyGaps/withStrongPoints`
      };
    case RN_507_CLASSIFICATION.SELF_MANAGEMENT:
      return {
        LIST: `/rn-507/self-management/list/${param || ':type'}`,
        FILL: `/rn-507/self-management/fill/${param || ':id'}`,
        REPORT: `/rn-507/self-management/report/${param || ':id'}`,
        REPORT_PREVIEW: `/rn-507/self-management/report/${param || ':id'}/preview`,
        REPORT_ONLY_GAPS: `/rn-507/self-management/report/${param || ':id'}/onlyGaps`,
        REPORT_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/self-management/report/${
          param || ':id'
        }/onlyGaps/withStrongPoints`,
        REPORT_PREVIEW_ONLY_GAPS: `/rn-507/self-management/report/${
          param || ':id'
        }/preview/onlyGaps`,
        REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-507/self-management/report/${
          param || ':id'
        }/preview/onlyGaps/withStrongPoints`
      };
    default:
      return {};
  }
};

export const RN_506_ROUTES = (classificationType, param) => {
  switch (classificationType) {
    case RN_506_CLASSIFICATION.APS:
      return {
        LIST: `/rn-506/APS/list/${param || ':type'}`,
        FILL: `/rn-506/APS/fill/${param || ':id'}`,
        REPORT: `/rn-506/APS/report/${param || ':id'}`,
        REPORT_PREVIEW: `/rn-506/APS/report/${param || ':id'}/preview`,
        REPORT_ONLY_GAPS: `/rn-506/APS/report/${param || ':id'}/onlyGaps`,
        REPORT_PREVIEW_ONLY_GAPS: `/rn-506/APS/report/${param || ':id'}/preview/onlyGaps`,
        REPORT_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-506/APS/report/${
          param || ':id'
        }/onlyGaps/withStrongPoints`,
        REPORT_PREVIEW_ONLY_GAPS_WITH_STRONG_POINTS: `/rn-506/APS/report/${
          param || ':id'
        }/preview/onlyGaps/withStrongPoints`
      };
    default:
      return {};
  }
};

export const PUBLIC = {
  LOGIN: `/login`,
  FORGOT_PASS: `/ForgotPass`,
  POLICY: `/lgpd`
};

export const ALL = () => ({
  HOME: '/home',
  PROFILE: `/profile`,
  COMING_SOON: '/coming-soon'
});

export const ADMIN = {
  DASHBOARD: '/dashboard',
  CUSTOM_GRAPHS: '/custom-graphs',
  COMPANIES: `/companies`,
  COMPANIES_EDIT: `/edit-company`,
  COMPANIES_NEW: `/new-company`,
  USERS: `/users`,
  USERS_NEW: `/new-user`,
  USERS_EDIT: `/edit-user`,
  EVALUATORS: `/evaluators`,
  EVALUATORS_NEW: `/new-evaluator`,
  EVALUATORS_EDIT: `/edit-evaluator`
};
