import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Card, Collapse } from 'reactstrap';
import * as S from './styles';
import { Button, RequirementItem } from '~/components';

const RequirementList = ({
  evaluationType,
  RNType,
  numericMarkers,
  text,
  items,
  chosenDeploymentTime,
  chosenScope,
  lastAccreditation,
  userEvaluatorObserver
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.Container>
      <S.Line>
        <S.Marker>{numericMarkers}</S.Marker>
        <S.Question>
          <Button title={text} color="link" onClick={() => setIsOpen(!isOpen)} />
        </S.Question>
      </S.Line>
      <S.CollapseContainer>
        <Collapse isOpen={isOpen}>
          <Card>
            {items.map((elm, index) => (
              <RequirementItem
                evaluationType={evaluationType}
                RNType={RNType}
                serverFiles={elm.files}
                key={`requirementItem-${elm.id}`}
                id={elm.id}
                numericMarkers={elm.details.numericMarkers}
                text={elm.details.text}
                typeRequirement={elm.details.type}
                evidenceTip={elm.details.evidenceTip}
                interpretationTip={elm.details.interpretationTip}
                deploymentTime={elm.deploymentTime}
                scope={elm.scope}
                chosenDeploymentTime={chosenDeploymentTime}
                chosenScope={chosenScope}
                comment={elm.comment}
                feedback={elm.feedback}
                changedPoint={elm.changedPoint}
                evidence={elm.evidence}
                improvementOpportunity={elm.improvementOpportunity}
                nonAttendance={elm.nonAttendance}
                strongPoint={elm.strongPoint}
                lastAccreditation={lastAccreditation ? lastAccreditation[index] : null}
                userEvaluatorObserver={userEvaluatorObserver}
              />
            ))}
          </Card>
        </Collapse>
      </S.CollapseContainer>
    </S.Container>
  );
};

RequirementList.defaultProps = {
  evaluationType: null,
  RNType: null,
  numericMarkers: '',
  text: '',
  items: [],
  chosenDeploymentTime: () => {},
  chosenScope: () => {},
  lastAccreditation: null
};

RequirementList.propTypes = {
  evaluationType: PropTypes.number,
  RNType: PropTypes.number,
  numericMarkers: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.any,
  chosenDeploymentTime: PropTypes.func,
  chosenScope: PropTypes.func,
  lastAccreditation: PropTypes.any
};

export default RequirementList;
