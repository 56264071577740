import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';
import { userRequest } from '~/store/modules/users/actions';
import {
  SERVER_SUBSCRIPTIONS_IMG,
  EVALUATION_TYPE,
  CALCULATE_FOR_WEATHER_VERSIONS
} from '~/constants';

const BackCover = ({
  accreditation,
  totalPages,
  editionReport,
  minimumOfExcellenceItems,
  considerations,
  startPage,
  isPre,
  isSuper,
  isRN506
}) => {
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const requirementGroupName = () => {
    switch (accreditation?.type) {
      case EVALUATION_TYPE.RN_507:
        return {
          SINGULAR: 'Dimensão',
          PLURAL: 'Dimensões'
        };
      case EVALUATION_TYPE.RN_506:
        return {
          SINGULAR: 'Requisito',
          PLURAL: 'Requisitos'
        };
      default:
        return {
          SINGULAR: 'Dimensão',
          PLURAL: 'Dimensões'
        };
    }
  };

  const listUsers = useCallback(() => dispatch(userRequest()), [dispatch]);

  const [version, setVersion] = useState();

  useEffect(() => {
    listUsers();
  }, [listUsers]);

  useEffect(() => {
    if (accreditation && accreditation.createdDateTimestamp) {
      setVersion(CALCULATE_FOR_WEATHER_VERSIONS(accreditation.createdDateTimestamp));
    }
  }, [accreditation]);

  const showValidity = () => {
    const start = accreditation?.reportValidityStartDate;
    const end = accreditation?.reportValidityEndDate;

    if (isSuper && isRN506) {
      return start && end ? `${start} a ${end}` : 'Data da vigência não preenchida';
    }
    switch (accreditation?.resume?.level) {
      case '0':
        return 'Não vigente';
      case '1':
        return start && end ? `${start} a ${end}` : 'Data da vigência não preenchida';
      case '2' || '3':
        return start && end ? `${start} a ${end}` : 'Data da vigência não preenchida';
      default:
        return 'Nível não informado';
    }
  };

  const below70 = accreditation?.dimensionsList.filter(
    (elm) => accreditation?.dimensions[elm].dimensionScore < 70
  );

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>

      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th colSpan="4" className="th-title">
              Informações da Operadora (situação na data da avaliação)
            </th>
          </tr>
          <tr>
            <td className="td-title">Porte</td>
            <td>{accreditation?.companyPort}</td>
            <td className="td-title">IDSS</td>
            <td>{accreditation?.companyIdss}</td>
          </tr>
          <tr>
            <td className="td-title">Nº Colaboradores</td>
            <td>{accreditation?.companyNumberOfEmployees}</td>
            <td className="td-title">Nº Beneficiários</td>
            <td>{accreditation?.companyNumberOfBeneficiaries}</td>
          </tr>
        </tbody>
      </S.Table>

      {isSuper ? (
        <>
          <S.Table bottomMargin>
            <tbody>
              <tr>
                <th colSpan={accreditation?.dimensionsList.length} className="th-title">
                  Última Acreditação
                </th>
              </tr>
              <tr>
                {accreditation?.dimensionsList.map((elm) => (
                  <td key={uniqId()} className="td-title">
                    {`${requirementGroupName().SINGULAR} ${elm}`}
                  </td>
                ))}
              </tr>
              <tr>
                {accreditation?.dimensionsList.map((elm) => (
                  <td key={uniqId()} className="center">
                    {accreditation?.dimensions[elm].dimensionScoreLastAccreditation}
                  </td>
                ))}
              </tr>
            </tbody>
          </S.Table>
          <S.Table bottomMargin>
            <tbody>
              <tr>
                <th colSpan={accreditation?.dimensionsList.length} className="th-title">
                  Supervisão
                </th>
              </tr>
              <tr>
                {accreditation?.dimensionsList.map((elm) => (
                  <td key={uniqId()} className="td-title">
                    {`${requirementGroupName().SINGULAR} ${elm}`}
                  </td>
                ))}
              </tr>
              <tr>
                {accreditation?.dimensionsList.map((elm) => (
                  <td key={uniqId()} className="center">
                    {accreditation?.dimensions[elm].dimensionScore}
                  </td>
                ))}
              </tr>
            </tbody>
          </S.Table>
        </>
      ) : (
        <S.Table bottomMargin>
          <tbody>
            <tr>
              <th colSpan={accreditation?.dimensionsList?.length} className="th-title">
                Pontuação Total: {accreditation?.totalPoints}
              </th>
            </tr>
            <tr>
              {accreditation?.dimensionsList.map((elm) => (
                <td key={uniqId()} className="td-title">
                  {`${requirementGroupName().SINGULAR} ${elm}`}
                </td>
              ))}
            </tr>
            <tr>
              {accreditation?.dimensionsList.map((elm) => (
                <td key={uniqId()} className="center">
                  {accreditation?.dimensions[elm].dimensionScore}
                </td>
              ))}
            </tr>
          </tbody>
        </S.Table>
      )}

      {!isSuper ? (
        <S.Table bottomMargin>
          <tbody>
            <tr>
              <th className="th-title" colSpan="4">
                Conclusão e Recomendação da Equipe Avaliadora
              </th>
            </tr>
            <tr>
              <td className="td-title" colSpan="3">
                Pontuação:
              </td>
              <td colSpan="1" className="center">
                {' '}
                {accreditation?.totalPoints}
              </td>
            </tr>
            <tr>
              <td className="td-title --dimension" colSpan="3">
                {requirementGroupName().PLURAL} com pontuação abaixo de 70 pontos:
              </td>
              <td colSpan="1" className="center">
                {' '}
                {below70.join(',')}
              </td>
            </tr>
            <tr>
              <td className="td-title --dimension" colSpan="3">
                Total de itens de excelência atendidos
              </td>
              <td className="center" colSpan="1">
                {accreditation?.totalItemsOfExcellence} (Mínimo de {minimumOfExcellenceItems}
                {isSuper && isRN506 ? ')' : ' para Nível I)'}
              </td>
            </tr>
            {!isPre && (
              <>
                <tr>
                  <td className="td-title --dimension" colSpan="3">
                    Total de Pessoas entrevistadas
                  </td>
                  <td className="center" colSpan="1">
                    {accreditation?.peopleInterviewed.length}
                  </td>
                </tr>
                <tr>
                  <td className="td-title --dimension" colSpan="3">
                    Vigência
                  </td>
                  <td colSpan="1" className="center">
                    {showValidity()}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </S.Table>
      ) : null}

      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4">
              Distribuição do Relatório - o presente relatório é estritamente confidencial à
            </th>
          </tr>
          <tr>
            <td colSpan="2" className="--noBold --withPadding">
              <p className="default">Arquivos da A4Quality</p>
              <p className="default">
                OPS auditada: {accreditation?.company?.contactPerson} -{' '}
                {accreditation?.company?.email}
              </p>
              {!isPre && <p className="default">ANS / DIDES</p>}
            </td>
          </tr>
        </tbody>
      </S.Table>

      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4">
              Considerações
            </th>
          </tr>
          <tr>
            <td colSpan="2" className="--noBold --withPadding">
              {considerations}
            </td>
          </tr>
          <tr>
            {isPre ? (
              <td colSpan="2" className="--noBold --withPadding">
                Dúvidas sobre este relatório podem ser esclarecidas a qualquer tempo com a equipe da
                A4Quality.
              </td>
            ) : !isRN506 && !isSuper ? (
              <td colSpan="2" className="--noBold --withPadding">
                Após a aprovação, o presente relatório é analisado por membros da Equipe de Análise
                e Decisão da A4Quality, responsáveis por ratificar a conclusão e a recomendação da
                equipe auditora.
              </td>
            ) : null}
          </tr>
          <tr>
            {version === 'FIRST_VERSION' || version === 'SECOND_VERSION' ? (
              <td colSpan="2" className="--noBold --withPadding">
                <p className="center">
                  Este relatório foi aprovado pela Gerente de Acreditação da A4Quality.
                </p>
                <div>
                  <S.CoverPageLogo center overName>
                    <img
                      src={
                        SERVER_SUBSCRIPTIONS_IMG +
                        users.inactive.filter((elm) => elm.id === 3)[0]?.subscription
                      }
                      alt="Assinatura"
                    />
                  </S.CoverPageLogo>
                  <p className="center">{users.inactive.filter((elm) => elm.id === 3)[0]?.name}</p>
                </div>
              </td>
            ) : (
              <td colSpan="2" className="--noBold --withPadding">
                <p className="center">Este relatório foi aprovado pela Diretoria da A4Quality.</p>
              </td>
            )}
          </tr>
        </tbody>
      </S.Table>

      <FooterReport actualPage={startPage} editionReport={editionReport} totalPage={totalPages} />
    </S.CoverPage>
  );
};

BackCover.defaultProps = {
  accreditation: {},
  minimumOfExcellenceItems: 0,
  considerations: 0,
  startPage: 0,
  totalPages: 0,
  editionReport: '',
  isRN506: false
};

BackCover.propTypes = {
  accreditation: PropTypes.any,
  minimumOfExcellenceItems: PropTypes.number,
  considerations: PropTypes.number,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string,
  isRN506: PropTypes.bool
};

export default BackCover;
