import produce from 'immer';
import {
  REQUEST_GRAPHS_DASH,
  LOADING_GRAPHS_DASH,
  SET_GRAPHS_DASH,
  SET_CUSTOM_GRAPHS
} from './patterns';

const INITIAL_STATE = {
  companies: null,
  allClients: [],
  customGraphs: [],
  allAccreditationTypes: {},
  allAccreditationMonths: {},
  fetch: {
    loading: false,
    error: false
  }
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_GRAPHS_DASH: {
        draft.fetch.itsLoading = true;
        break;
      }
      case SET_CUSTOM_GRAPHS: {
        draft.customGraphs = action.payload;
        break;
      }
      case LOADING_GRAPHS_DASH: {
        draft.fetch.itsLoading = action.itsLoading;
        break;
      }
      case SET_GRAPHS_DASH: {
        draft.companies = action.payload.companies;
        draft.allClients = action.payload.allClients;
        draft.allAccreditationTypes = action.payload.allAccreditationTypes;
        draft.allAccreditationMonths = action.payload.allAccreditationMonths;
        break;
      }
      default:
    }
  });
}
