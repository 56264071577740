import React from 'react';
import { PropTypes } from 'prop-types';
import * as S from './style';

const StripBanner = ({ text, top, isDev }) => {
  return (
    <S.Container>
      <S.Strip top={top} isDev={isDev} className="trip">
        <S.Text className="text" isDev={isDev}>
          {text}
        </S.Text>
      </S.Strip>
    </S.Container>
  );
};

StripBanner.defaultProps = {
  text: 'Título',
  top: '30px',
  isDev: false
};

StripBanner.propTypes = {
  text: PropTypes.any,
  top: PropTypes.any,
  isDev: PropTypes.bool
};

export default StripBanner;
