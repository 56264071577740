import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import { GetReport } from '~/services/private';
import * as S from './styles';
import Types from './Types';
import { Button } from '~/components';
import { EVALUATION_TYPE, GROUPS, RN_506_TYPES } from '~/constants';
import history from '~/config/history';
import { RN_506_ROUTES } from '~/routes/names';

const ReportRN506 = ({ match }) => {
  const {
    user: {
      account: { groupId }
    }
  } = useSelector((state) => state.auth);

  const [evaluation, setEvaluation] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const printRef = useRef();

  const GetRequirementsList = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await GetReport(match.params.id, match.path.includes('preview'));
      setEvaluation(data);
      toast.success('Relatório carregado');
    } catch (e) {
      const result = e?.data?.result;
      const msg = result || 'Aconteceu um problema ao carregar o relatório.';
      setError(msg);
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  }, [match.params.id, match.path]);

  useEffect(() => {
    GetRequirementsList().finally(() => {});
  }, [GetRequirementsList]);

  const printShortcut = useCallback((event) => {
    if (event.which === 80) {
      event.stopPropagation();
      document.getElementById('print-report').click();
    }
  }, []);

  const removePrintShortcut = useCallback(
    () => window.removeEventListener('keyup', printShortcut),
    [printShortcut]
  );

  useEffect(() => {
    window.addEventListener('keyup', printShortcut);
    return removePrintShortcut;
  }, [printShortcut, removePrintShortcut]);

  return (
    <>
      {loading ? (
        <div className="content">
          <Row>
            <Col xs="12">
              <S.Loading>
                <Spinner size="md" color="white" animation="border" />
              </S.Loading>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <S.Body className="content">
            {!error && groupId === GROUPS.ADMIN ? (
              <ReactToPrint
                trigger={() => (
                  <Button
                    id="print-report"
                    style={{ marginBottom: 25 }}
                    color="primary"
                    title="Imprimir"
                  />
                )}
                content={() => printRef.current}
              />
            ) : null}
            {groupId === GROUPS.ADMIN && (
              <Button
                id="print-report"
                style={{ marginBottom: 25 }}
                color="secondary"
                title="Realizar ajustes"
                onClick={() => {
                  switch (evaluation.type) {
                    case EVALUATION_TYPE.RN_506:
                      history.push(
                        RN_506_ROUTES(evaluation.classification, evaluation.id_rn440, true).FILL
                      );
                      break;
                    default:
                      toast.warning('Tipo da avaliação não informado!');
                  }
                }}
              />
            )}
            {evaluation?.type_rn440 === RN_506_TYPES.PRE && (
              <Button
                id="print-report"
                style={{ marginBottom: 25 }}
                color="tertiary"
                title={
                  match.url.includes('/onlyGaps')
                    ? 'Visualização completa'
                    : 'Visualizar somente Gaps'
                }
                onClick={() =>
                  history.push(
                    match.url.includes('onlyGaps')
                      ? match.url.replace('/onlyGaps', '')
                      : `${match.url}/onlyGaps`
                  )
                }
              />
            )}
            <Types
              evaluation={evaluation}
              onlyGaps={match.url.includes('/onlyGaps')}
              error={error}
              ref={printRef}
            />
          </S.Body>
        </div>
      )}
    </>
  );
};

export default ReportRN506;
