import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { RN506View } from '~/components/BasePageEvaluationView';
import { GetRequirements } from '~/services/private';
import { EVALUATION_TYPE } from '~/constants';

const Loading = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const RN506Fill = ({ match }) => {
  const [evaluation, setEvaluation] = useState({});
  const [loading, setLoading] = useState(true);

  const GetRequirementsList = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await GetRequirements(match.params.id, EVALUATION_TYPE.RN_506);
      setEvaluation(data);
      toast.success('Avaliação carregada');
    } catch (e) {
      const error = e?.data?.result;
      toast.error(error || 'Aconteceu um problema ao realizar a operação.');
    } finally {
      setLoading(false);
    }
  }, [match.params.id]);

  useEffect(() => {
    GetRequirementsList().finally(() => {});
  }, [GetRequirementsList]);

  return (
    <>
      {loading ? (
        <div className="content">
          <Row>
            <Col xs="12">
              <Loading>
                <Spinner size="md" color="white" animation="border" />
              </Loading>
            </Col>
          </Row>
        </div>
      ) : (
        <RN506View
          evaluationId={parseInt(evaluation?.id, 10)}
          classification={evaluation?.classification}
          title={evaluation?.company?.name}
          reportDate={{
            start: evaluation?.reportStartDateInput,
            end: evaluation?.reportEndDateInput
          }}
          reportDateValidity={{
            start: evaluation?.reportValidityStartDateInput,
            end: evaluation?.reportValidityEndDateInput
          }}
          createdDateTimestamp={evaluation?.createdDateTimestamp}
          company={evaluation?.company}
          RNType={evaluation?.type_rn440}
          text="Abaixo estão listadas todas as dimensões vinculadas a esta avaliação. Após qualquer alteração os dados serão salvos automaticamente."
          itsLoading={fetch.itsLoading}
          dimensionsList={evaluation?.dimensionsList}
          dimensions={evaluation?.dimensions}
          statusDetails={evaluation?.statusDetails}
          leaderApproval={evaluation?.leaderApproval}
          meetings={evaluation?.meetings}
          peopleInterviewed={evaluation?.peopleInterviewed}
          resume={evaluation?.resume}
          diary={evaluation?.diary}
          auditedAreas={evaluation?.auditedAreas}
          lastAccreditation={evaluation?.lastEvaluation}
          controlVisualization={evaluation?.controlVisualization}
          companyFields={{
            companyIdss: evaluation?.companyIdss,
            companyNumberOfBeneficiaries: evaluation?.companyNumberOfBeneficiaries,
            companyNumberOfEmployees: evaluation?.companyNumberOfEmployees,
            companyPort: evaluation?.companyPort
          }}
          evaluators={{
            evaluators: evaluation?.evaluators || [],
            evaluatorsAdmin: evaluation?.evaluatorsAdmin || [],
            evaluatorsCompany: evaluation?.evaluatorsCompany || [],
            evaluatorObserver: evaluation?.evaluatorObserver || []
          }}
        />
      )}
    </>
  );
};
export default RN506Fill;
