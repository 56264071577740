import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { ProgressBar, Step } from 'react-step-progress-bar';
import uniqId from 'uniqid';
import { colors, STATUS_EVALUATION, RN_507_TYPES } from '~/constants';
import open from '~/assets/img/steps/open.png';
import play from '~/assets/img/steps/play.png';
import comments from '~/assets/img/steps/comments.png';
import decision from '~/assets/img/steps/decision.png';
import finished from '~/assets/img/steps/document.png';
import * as S from './styles';

const Progress = ({ status, type, analysisUser, percent, itsLoading }) => {
  const [percentLine, setPercentLine] = useState(0);

  useEffect(() => {
    if (!itsLoading) {
      const elements = document.getElementsByClassName('RSPBstep');
      if (elements.length > 0) {
        for (let i = 0; i <= elements.length; i++) {
          if (elements[i] && elements[i].offsetWidth === 0) {
            elements[i].style.width = '40px';
          }
        }
      }
    }
  }, [itsLoading]);

  useEffect(() => {
    if (type === RN_507_TYPES.SELF_EVALUATION) {
      if (analysisUser) {
        switch (status) {
          case STATUS_EVALUATION.OPEN:
            setPercentLine(0);
            break;
          case STATUS_EVALUATION.STARTED:
            setPercentLine(percent / 2 - 0.1);
            break;
          case STATUS_EVALUATION.FEEDBACK:
            setPercentLine(50);
            break;
          case STATUS_EVALUATION.FINISHED:
            setPercentLine(100);
            break;
          default:
            setPercentLine(0);
        }
      } else {
        switch (status) {
          case STATUS_EVALUATION.OPEN:
          case STATUS_EVALUATION.STARTED:
            setPercentLine(percent - 0.1);
            break;
          case STATUS_EVALUATION.FINISHED:
            setPercentLine(100);
            break;
          default:
            setPercentLine(0);
        }
      }
    } else {
      switch (status) {
        case STATUS_EVALUATION.OPEN:
          setPercentLine(0);
          break;
        case STATUS_EVALUATION.STARTED:
          if (percent === 100) {
            setPercentLine(66);
          } else {
            setPercentLine(34 + percent / 3);
          }
          break;
        case STATUS_EVALUATION.ANALYSIS_AND_DECISION:
          setPercentLine(67);
          break;
        case STATUS_EVALUATION.FINISHED:
          setPercentLine(100);
          break;
        default:
          setPercentLine(0);
          break;
      }
    }
  }, [status, type, analysisUser, percent]);

  const Img = (statusImage) => {
    switch (statusImage) {
      case 'open':
        return open;
      case 'play':
        return play;
      case 'inApprove':
        return comments;
      case 'analyze':
        return decision;
      case 'finished':
        return finished;
      default:
        return null;
    }
  };

  return (
    <ProgressBar
      percent={percentLine}
      filledBackground={`linear-gradient(to right, ${colors.secondary}, ${colors.primary})`}
    >
      {type === RN_507_TYPES.SELF_EVALUATION && analysisUser
        ? [
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle right="-28px">Iniciada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('play')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle right="-22px">Feedback</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('analyze')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <S.ProgressTitleContainer>
                  <S.ProgressTitle right="3px">Finalizada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('finished')}
                  />
                </S.ProgressTitleContainer>
              )}
            </Step>
          ]
        : type === RN_507_TYPES.SELF_EVALUATION && !analysisUser
        ? [
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle right="-28px">Iniciada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('play')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <S.ProgressTitleContainer>
                  <S.ProgressTitle right="3px">Finalizada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('finished')}
                  />
                </S.ProgressTitleContainer>
              )}
            </Step>
          ]
        : [
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle>Aberta</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('open')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle>Iniciada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('play')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <div>
                  <S.ProgressTitle>Análise</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src={Img('analyze')}
                  />
                </div>
              )}
            </Step>,
            <Step key={uniqId()} transition="scale">
              {({ accomplished }) => (
                <S.ProgressTitleContainer>
                  <S.ProgressTitle right="-3px">Finalizada</S.ProgressTitle>
                  <img
                    alt="step"
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, width: 40 }}
                    width="30"
                    src={Img('finished')}
                  />
                </S.ProgressTitleContainer>
              )}
            </Step>
          ]}
    </ProgressBar>
  );
};

Progress.defaultProps = {
  type: RN_507_TYPES.ACCREDITATION,
  status: STATUS_EVALUATION.OPEN,
  analysisUser: null,
  itsLoading: false,
  percent: 0
};

Progress.propTypes = {
  type: PropTypes.number,
  status: PropTypes.number,
  analysisUser: PropTypes.any,
  itsLoading: PropTypes.bool,
  percent: PropTypes.any
};

export default Progress;
