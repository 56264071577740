import { createBrowserHistory } from 'history';
import { scroll } from './helpers';

const history = createBrowserHistory({
  forceRefresh: false // Set true to force full page refreshes
});

history.listen((location) => {
  const { hash } = location;
  if (hash) {
    scroll(hash.replace('#', ''));
  } else {
    scroll(null);
  }
});

export default history;
