import produce from 'immer';
import {
  AUTH_REQUEST,
  AUTH_LOGIN,
  AUTH_SET_TOKEN,
  AUTH_SET_ITS_LOADING,
  AUTH_LOGOUT,
  AUTH_PROFILE_REQUEST,
  AUTH_TERM
} from './patterns';

const INITIAL_STATE = {
  isLogged: false,
  token: null,
  user: {
    id: null,
    name: '',
    cpf: '',
    phone: '',
    statementOfResponsibility: null,
    account: {
      id: null,
      email: '',
      groupId: null,
      create: '',
      active: null,
      social: null
    }
  },
  fetch: {
    loading: false,
    error: false
  }
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case AUTH_REQUEST: {
        draft.fetch.itsLoading = true;
        break;
      }
      case AUTH_SET_ITS_LOADING: {
        draft.fetch.itsLoading = action.itsLoading;
        break;
      }
      case AUTH_PROFILE_REQUEST: {
        draft.fetch.itsLoading = true;
        break;
      }
      case AUTH_LOGIN: {
        draft.isLogged = true;
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        break;
      }
      case AUTH_SET_TOKEN: {
        draft.token = action.token;
        break;
      }
      case AUTH_TERM: {
        draft.user.statementOfResponsibility = true;
        break;
      }
      case AUTH_LOGOUT: {
        draft.isLogged = INITIAL_STATE.isLogged;
        draft.token = INITIAL_STATE.token;
        draft.user = INITIAL_STATE.user;
        break;
      }
      default:
    }
  });
}
