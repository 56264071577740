export const colors = {
  primary: '#97884f',
  secondary: '#E3A587',
  tertiary: '#495057',
  success: '#00f2c3',
  info: '#1d8cf8',
  warning: '#ff8d72',
  danger: '#fd5d93',
  black: '#222a42',
  white: '#fff',
  orange: '#ae722b',
  text: '#525f7f',
  green: '#86e08a'
};

export const alphas = {
  a00: '00',
  a10: '1A',
  a20: '33',
  a25: '40',
  a30: '4D',
  a40: '66',
  a50: '80',
  a60: '99',
  a70: 'B3',
  a80: 'CC',
  a85: 'D9',
  a90: 'E6'
};
