import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import { Bar, Line } from 'react-chartjs-2';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import { chartExample3, chartOptions as chartDefault, defaultDatasets } from '~/constants/charts';
import { showTypeIcon } from '~/config/helpers';
import {
  colors,
  DIMENSIONS_TITLE_RN_506,
  DIMENSIONS_TITLE_RN_507,
  EVALUATION_TYPE,
  TYPES_REQUIREMENTS
} from '~/constants';
import FooterReport from './FooterReport';

const DimensionsData = ({ id, data, startPage, totalPages, editionReport, evaluationType }) => {
  const dataChartRN507 = (canvas) => {
    const listSumPoints = Object.keys(data.requirements).map(
      (key) => data.requirements[key].sumPoints
    );

    const listNumericMarkers = Object.keys(data.requirements).map(
      (key) => data.requirements[key].numericMarkers
    );

    return {
      labels: listNumericMarkers,
      datasets: [
        {
          ...defaultDatasets(canvas),
          label: 'Parcial',
          borderColor: colors.primary,
          pointBackgroundColor: colors.primary,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: colors.primary,
          data: listSumPoints.map((elm) => parseFloat(elm.sumPartial))
        },
        {
          ...defaultDatasets(canvas),
          label: 'Final',
          borderColor: '#1f8ef1',
          pointBackgroundColor: '#1f8ef1',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#1f8ef1',
          data: listSumPoints.map((elm) => parseFloat(elm.sumTotal))
        }
      ]
    };
  };

  const chartOptions = {
    ...chartDefault,
    legend: {
      display: true
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 20,
            padding: 20,
            fontColor: '#9a9a9a'
          }
        }
      ]
    }
  };

  const dataChartRN506 = (canvas) => {
    const listMarkers = Object.keys(data.requirements)
      .map((key) => data.requirements[key].table)
      .map((arr) => arr.map((elm) => elm.marker))[0];

    const listPoints = Object.keys(data.requirements)
      .map((key) => data.requirements[key].table)
      .map((arr) => arr.map((elm) => elm.pointing))[0];

    const ctx = canvas.getContext('2d');

    const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, 'rgba(151,136,79, 0.5)');
    gradientStroke.addColorStop(0.4, 'rgba(151,136,79,0.0)');
    gradientStroke.addColorStop(0, 'rgba(151,136,79,0)');

    return {
      labels: listMarkers,
      datasets: [
        {
          ...defaultDatasets(canvas),
          backgroundColor: gradientStroke,
          label: 'Pontuação',
          borderColor: colors.primary,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 1,
          pointBorderWidth: 2,
          data: listPoints
        }
      ]
    };
  };

  const optionsChartRN506 = {
    ...chartExample3.options,
    scales: {
      ...chartExample3.options.scales,
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: 'rgba(225,78,202,0.1)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            fontColor: '#9e9e9e',
            beginAtZero: true,
            stepSize: 1
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: 'rgba(225,78,202,0.1)',
            zeroLineColor: 'transparent'
          },
          ticks: {
            padding: 0,
            fontColor: '#9e9e9e'
          }
        }
      ]
    }
  };

  const showDimensionsTitle = () => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return DIMENSIONS_TITLE_RN_507[id];
      case EVALUATION_TYPE.RN_506:
        return DIMENSIONS_TITLE_RN_506[id];
      default:
        return DIMENSIONS_TITLE_RN_507[id];
    }
  };

  const showTitle = () => {
    switch (evaluationType) {
      case EVALUATION_TYPE.RN_507:
        return `Resultados da Dimensão ${id}`;
      case EVALUATION_TYPE.RN_506:
        return `Resultados do Requisito ${id}`;
      default:
        return `Resultados da Dimensão ${id}`;
    }
  };

  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              {showTitle()}
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.TitleDimension>{showDimensionsTitle()}</S.TitleDimension>
      <S.Title>Pontuação: {data.dimensionScore}</S.Title>
      <S.Chart>
        <div className="chart-dimension">
          {evaluationType === EVALUATION_TYPE.RN_507 ? (
            <Line data={dataChartRN507} options={chartOptions} />
          ) : null}
          {evaluationType === EVALUATION_TYPE.RN_506 ? (
            <Bar data={dataChartRN506} options={optionsChartRN506} />
          ) : null}
        </div>
      </S.Chart>
      <S.Legend>
        <S.Table requirementsTable>
          <tbody>
            <tr>
              <th className="th-title" scope="row">
                <i className={showTypeIcon(TYPES_REQUIREMENTS.ESSENTIAL)} /> - Essencial
              </th>
              <th className="th-title" scope="row">
                <i className={showTypeIcon(TYPES_REQUIREMENTS.COMPLEMENTARY)} /> - Complementar
              </th>
              <th className="th-title" scope="row">
                <i className={showTypeIcon(TYPES_REQUIREMENTS.EXCELLENCE)} /> - Excelência
              </th>
            </tr>
          </tbody>
        </S.Table>
      </S.Legend>

      <S.CardColumns>
        {Object.keys(data.requirements).map((key) => (
          <S.CardColumnsItems key={uniqId()}>
            <S.Table bottomMarginForCover requirementsTable>
              <tbody>
                <tr>
                  <th className="th-title --min-padding" scope="row">
                    Requisito
                  </th>
                  <th className="th-title --min-padding" scope="row">
                    Item
                  </th>
                  <th className="th-title --min-padding" scope="row">
                    Pontuação
                  </th>
                  <th className="th-title --min-padding" scope="row">
                    Tipo
                  </th>
                </tr>
                <tr>
                  <th className="th-title" rowSpan={data.requirements[key].table.length + 1}>
                    {data.requirements[key].numericMarkers}
                  </th>
                </tr>
                {data.requirements[key].table.map((elm) => (
                  <tr key={uniqId()}>
                    <th className="th-title">{elm.marker}</th>
                    <th className="th-title">{elm.pointing}</th>
                    <th className="th-title">
                      <i className={showTypeIcon(elm.type)} />
                    </th>
                  </tr>
                ))}
                <tr>
                  <th className="th-title" colSpan="2">
                    Pontuação Parcial
                  </th>
                  <th className="th-title" colSpan="2">
                    {data.requirements[key].sumPoints.sumPartial}
                  </th>
                </tr>
                <tr>
                  <th className="th-title" colSpan="2">
                    Pontuação Final
                  </th>
                  <th className="th-title" colSpan="2">
                    {data.requirements[key].sumPoints.sumTotal}
                  </th>
                </tr>
              </tbody>
            </S.Table>
          </S.CardColumnsItems>
        ))}
      </S.CardColumns>
      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

DimensionsData.defaultProps = {
  id: 1,
  data: {
    indicators: [],
    requirements: {},
    dimensionScore: ''
  },
  startPage: 0,
  totalPages: 0,
  evaluationType: 0,
  editionReport: ''
};

DimensionsData.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  evaluationType: PropTypes.number,
  editionReport: PropTypes.string
};

export default DimensionsData;
