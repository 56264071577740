import React from 'react';
import { PropTypes } from 'prop-types';
import uniqId from 'uniqid';
import * as S from './styles';
import logo from '~/assets/img/brand/logo_a4.png';
import FooterReport from './FooterReport';
import {
  TYPES_MEETINGS,
  coveredTopicsInitialMeeting,
  coveredTopicsFinalMeeting,
  EVALUATION_TYPE
} from '~/constants';
const Meeting = ({
  meeting,
  startPage,
  totalPages,
  editionReport,
  evaluationType
}) => {
  const coveredTopics =
    meeting.type === TYPES_MEETINGS.INITIAL
      ? coveredTopicsInitialMeeting[evaluationType]
      : coveredTopicsFinalMeeting[evaluationType];

  const participantsHalf = Math.ceil(meeting.participants.length / 2);
  const firstHalf = meeting.participants.slice(0, participantsHalf);
  const secondHalf = meeting.participants.slice(participantsHalf);
  return (
    <S.CoverPage>
      <S.CoverPageHeader>
        <S.CoverPageLogo>
          <img src={logo} alt="react-logo" />
        </S.CoverPageLogo>
      </S.CoverPageHeader>
      <S.Table bottomMargin>
        <tbody>
          <tr>
            <th className="th-title" colSpan="4" scope="row">
              Reunião de {meeting.type === TYPES_MEETINGS.INITIAL ? 'Abertura' : 'Encerramento'}
            </th>
          </tr>
        </tbody>
      </S.Table>
      <S.Row>
        <S.MinSubtitle bold>Data:</S.MinSubtitle>
        <S.MinSubtitle>{meeting.date}</S.MinSubtitle>
      </S.Row>
      <S.Row>
        <S.MinSubtitle bold>Horário:</S.MinSubtitle>
        <S.MinSubtitle>{meeting.schedule}</S.MinSubtitle>
      </S.Row>
      <S.Row>
        <S.MinSubtitle bold>Local:</S.MinSubtitle>
        <S.MinSubtitle>{meeting.place}</S.MinSubtitle>
      </S.Row>
      <S.Subtitle>Participantes:</S.Subtitle>
      <S.Row spaceBetween withTable>
        <S.Table bottomMargin requirementsTable>
          <tbody>
            <tr>
              <th className="th-title" colSpan="4">
                Nome
              </th>
              <th className="th-title">Cargo/Função</th>
            </tr>
            {firstHalf.map((elm) => (
              <tr key={uniqId()}>
                <td className="--meeting --meeting-width left" colSpan="4">
                  {elm.name}
                </td>
                <td className="--meeting left">{elm.occupation}</td>
              </tr>
            ))}
          </tbody>
        </S.Table>
        <S.Table bottomMargin requirementsTable>
          <tbody>
            <tr>
              <th className="th-title" colSpan="4">
                Nome
              </th>
              <th className="th-title">Cargo/Função</th>
            </tr>
            {secondHalf.map((elm) => (
              <tr key={uniqId()}>
                <td className="--meeting --meeting-width left" colSpan="4">
                  {elm.name}
                </td>
                <td className="--meeting left">{elm.occupation}</td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </S.Row>

      <S.MinSubtitle bold>Assuntos tratados:</S.MinSubtitle>
      {coveredTopics.map((elm) => (
        <S.MinSubtitle key={uniqId()} min>
        {elm}
      </S.MinSubtitle>
      ))}
      <FooterReport editionReport={editionReport} actualPage={startPage} totalPage={totalPages} />
    </S.CoverPage>
  );
};

Meeting.defaultProps = {
  meeting: {
    id: 0,
    type: 1,
    date: null,
    schedule: null,
    place: null,
    participants: []
  },
  startPage: 0,
  totalPages: 0,
  editionReport: '',
  evaluationType: 1
};

Meeting.propTypes = {
  meeting: PropTypes.any,
  startPage: PropTypes.number,
  totalPages: PropTypes.number,
  editionReport: PropTypes.string
};

export default Meeting;
