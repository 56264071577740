import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import IconFlat from '../IconFlat';
import Button from '../Button';
import * as S from './style';

const ActionsButtons = ({
  actionEdit,
  actionPower,
  actionDelete,
  hasActive,
  hasDelete,
  hasEdit,
  active,
  labelModal
}) => {
  const [modalPower, setModalPower] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const togglePower = () => setModalPower(!modalPower);
  const toggleDelete = () => setModalDelete(!modalDelete);

  return (
    <S.Container>
      {hasEdit && <IconFlat icon={['tim-icons', 'icon-pencil']} onClick={actionEdit} />}
      {hasActive && <IconFlat icon={['tim-icons', 'icon-button-power']} onClick={togglePower} />}
      {hasDelete && <IconFlat icon={['tim-icons', 'icon-trash-simple']} onClick={toggleDelete} />}
      <Modal isOpen={modalPower} toggle={togglePower} backdrop>
        <ModalBody>
          <S.Title>
            <h2>
              Deseja {active ? 'inativar' : 'ativar'} {labelModal}?
            </h2>
          </S.Title>
          <S.ModalButtons>
            <Button
              title="Sim"
              color="primary"
              onClick={() => {
                actionPower();
                togglePower();
              }}
            />{' '}
            <Button title="Não" color="danger" onClick={togglePower} />{' '}
          </S.ModalButtons>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete} backdrop>
        <ModalBody>
          <S.Title>
            <h2>Deseja apagar {labelModal}?</h2>
          </S.Title>
          <S.ModalButtons>
            <Button
              title="Sim"
              color="primary"
              onClick={() => {
                actionDelete();
                toggleDelete();
              }}
            />{' '}
            <Button title="Não" color="danger" onClick={toggleDelete} />{' '}
          </S.ModalButtons>
        </ModalBody>
      </Modal>
    </S.Container>
  );
};

ActionsButtons.propTypes = {
  actionEdit: PropTypes.func,
  actionPower: PropTypes.func,
  actionDelete: PropTypes.func,
  hasActive: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  active: PropTypes.bool,
  labelModal: PropTypes.string
};

ActionsButtons.defaultProps = {
  actionEdit: () => {},
  actionPower: () => {},
  actionDelete: () => {},
  hasActive: false,
  hasDelete: false,
  hasEdit: false,
  active: true,
  labelModal: ''
};

export default ActionsButtons;
