import { takeLatest, put, all } from 'redux-saga/effects';
import { showDrawer, hideDrawer } from './actions';
import { SHOW_DRAWER, HIDE_DRAWER } from './patterns';

const show = () => {
  put(showDrawer());
};

const hide = () => {
  put(hideDrawer());
};

export default all([takeLatest(SHOW_DRAWER, show), takeLatest(HIDE_DRAWER, hide)]);
