import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => colors[props.color]};
  cursor: pointer;
  font-size: 15px;
  width: 30px;
  height: 30px;
  color: #fff;
  &:hover {
    background-color: ${(props) => colors[props.color]}95;
  }
  &:active {
    background-color: ${(props) => colors[props.color]};
  }
`;
