import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 10px;
  h5 {
    margin-bottom: 0px !important;
  }

  &:hover {
    -webkit-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    -moz-box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);
    box-shadow: 1px 1px 12px 2px rgba(192, 192, 192, 0.29);

    // -webkit-box-shadow: 1px 1px 12px 1px rgba(156, 153, 153, 0.19);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(156, 153, 153, 0.19);
    // box-shadow: 1px 1px 12px 1px rgba(156, 153, 153, 0.19);
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Marker = styled.h5`
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
`;

export const Question = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.h5`
  width: 100%;
  font-size: 14px;
`;

export const Details = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const TitleDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;

export const TitleText = styled.h5`
  font-size: 14px;
`;

export const Bold = styled.h5`
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
`;

export const HR = styled.hr`
  border: 1px solid #c0c0c0;
`;

export const Actions = styled.div`
  min-width: 50px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BtnLogContainer = styled.div`
  display: flex;
  flex-direction: row;
  .btn.btn-link {
    color: ${colors.black} !important;
    padding: 2px !important;
    margin-top: -13px;
    margin-left: 5px;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const InputFileContainer = styled.div`
  padding-bottom: 10px;
`;

export const File = styled.div`
  a {
    color: #000 !important;
    &:hover {
      text-decoration: underline;
    }
  }
  i {
    margin-left: 7px;
    cursor: pointer;
    padding-bottom: 4px;
  }
  div {
    margin-left: 5px;
    padding-top: 7px;
  }
`;

export const LastAccreditation = styled.div`
  width: 100%;
  ${(props) => (props.modal ? `margin-top: -10px;` : '')};
  span {
    font-size: 11px !important;
    color: ${(props) => (!props.color ? colors.green : props.color)} !important;
  }
`;
