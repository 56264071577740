import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { ModalContainer, ModalContent } from './styles';
import { Button, Modal } from '~/components';
import { DeleteEvaluation } from '~/services/private';
import * as L from '~/modules/_layouts/private/styles';
import { ADMIN } from '~/routes/names';
import history from '~/config/history';

const Delete = forwardRef(({ evaluationId }, ref) => {
  const [password, setPassword] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeVisibleModal = (active) => setOpenModal(active);

  const confirmDelete = async () => {
    try {
      setLoading(true);
      if (password) {
        await DeleteEvaluation({
          id: evaluationId,
          password
        });
        toast.success('Avaliação apagada!');
        history.push(ADMIN.DASHBOARD);
      } else {
        toast.warning('Informe sua senha!');
      }
    } catch (e) {
      const error = e?.data?.message;
      toast.error(error || 'Aconteceu um problema ao tentar apagar a avaliação');
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    changeVisibleModal
  }));

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => changeVisibleModal(false)}
        title="Deseja apagar a Avaliação?"
        size="sm"
        unmountOnClose={false}
      >
        <ModalContainer>
          <ModalContent>
            <Row>
              <Col xs="12">
                <L.Input
                  label="Informe sua senha"
                  name="dateStart"
                  type="password"
                  value={password || ''}
                  onChange={(event) => {
                    const text = event.target.value;
                    setPassword(text);
                  }}
                />
                <Button
                  color="primary"
                  onClick={confirmDelete}
                  loading={loading}
                  className="btn-action-modal"
                  title="Confirmar"
                />
                <span>* Essa ação não poderá ser desfeita</span>
              </Col>
            </Row>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </div>
  );
});

Delete.defaultProps = {
  evaluationId: 0
};

Delete.propTypes = {
  evaluationId: PropTypes.number
};

export default Delete;
